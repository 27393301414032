<ng-container *ngIf="!uploadImage">
  <ion-avatar (click)="onClick()" class="avatar-border" [ngClass]="{'avatar-border-inactive': !user || user.uState==0 }"
    [ngStyle]="{width: size+'px', height: size+'px', cursor: uploadEnable?'pointer':'unset'}">
    <ion-img [src]="getProfileUrl()||fittUtil.getDefaultUrl(user && user.uGender)" style="width: 100%; height: 100%;"></ion-img>
  </ion-avatar>
  <ion-button *ngIf="user && showStateCircle" mode="ios" fill="clear" class="link-icon"
    [ngStyle]="{width: linkSize+'px', height: linkSize+'px', 'background-color': getConnectColor()}"> </ion-button>
  <ion-button mode="ios" class="edit-icon" fill="clear" *ngIf="showEdit">
    <ion-icon color="black1" name="pencil-sharp"></ion-icon>
  </ion-button>
</ng-container>

<image-uploader *ngIf="uploadImage" [image]="uploadImage" (remove)="uploadImage=null" (complete)="onCompleteUpload()" [circle]="true"
  [hasClose]="false" class="avatar-border" [ngClass]="{'avatar-border-inactive': !user || user.uState==0 }"
  [ngStyle]="{width: size+'px', height: size+'px', cursor: uploadEnable?'pointer':'unset'}">
</image-uploader>
