<ion-row>
  <ion-label class="value" [ngStyle]="{'font-size': valueSize + 'px', 'white-space' : space}" *ngIf="!reverse"> {{value}} </ion-label>
</ion-row>
<ion-row>
  <ion-label class="text" [ngStyle]="{'font-size': textSize + 'px'}" *ngIf="showText"> {{text}} </ion-label>
</ion-row>
<ion-row>
  <ion-label class="value" [ngStyle]="{'font-size': valueSize + 'px','margin-bottom' : textMargin +'px' }" *ngIf="reverse"> {{value}} </ion-label>
</ion-row>
<ion-row>
  <ion-label class="unit" [ngStyle]="{'font-size': textSize + 'px', 'margin-top' : textMargin + 'px'}" *ngIf="reverse"> {{unit}} </ion-label>
</ion-row>
