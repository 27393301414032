<div class="wrapper">
  <div class="icon">
    <ion-icon name="{{icon}}"></ion-icon>
  </div>
  <div style="height: 148px;">
    <p>{{title}}</p>
    <ng-container *ngIf="body">
      <ion-label>{{body}}</ion-label>
    </ng-container>
  </div>
  <ion-input [(ngModel)]="inputData" class="input-container" [placeholder]="placeHolder">
  </ion-input>
  <div class="footer">
    <ion-button fill="clear" class="close-btn" (click)="close()">{{'취소'|translate}}</ion-button>
    <ion-button class="submit-btn" (click)="submit()">{{'확인'|translate}}</ion-button>
  </div>
</div>
