import { FMTCorrectiveExercises, FMTCorrectiveStatus, FMTCorrectivePart, FMTCorrectiveExercise } from '@fitt-lib/models/exercise-test/fmt-corrective-exercises';
import * as _ from 'lodash';

export class FmtSurvey {
  cervical: { tag: FMTCorrectivePart, front: number, back: number, left: number, right: number, seq: Array<number> }
  overhead: { tag: FMTCorrectivePart, center: number, seq: Array<number> }
  single: { tag: FMTCorrectivePart, left: number, right: number, seq: Array<number> }
  split: { tag: FMTCorrectivePart, left: number, right: number, seq: Array<number> }
  shoulder: { tag: FMTCorrectivePart, left: number, right: number, seq: Array<number> }
  straight: { tag: FMTCorrectivePart, left: number, right: number, seq: Array<number> }
  push: { tag: FMTCorrectivePart, center: number, seq: Array<number> }
  bird: { tag: FMTCorrectivePart, left: number, right: number, seq: Array<number> }

  constructor(
    tmtSurvey: Array<Array<number>>,
  ) {
    tmtSurvey = _.cloneDeep(tmtSurvey);
    this.cervical = { tag: 'CERVICAL SPINE', front: tmtSurvey[0].shift(), back: tmtSurvey[0].shift(), left: tmtSurvey[0].shift(), right: tmtSurvey[0].shift(), seq: [...tmtSurvey[0]] }
    this.overhead = { tag: 'OVERHEAD SQUAT', center: tmtSurvey[1].shift(), seq: [...tmtSurvey[1]] }
    this.single = { tag: 'SINGLE LEG STEP', left: tmtSurvey[2].shift(), right: tmtSurvey[2].shift(), seq: [...tmtSurvey[2]] }
    this.split = { tag: 'SPLIT SQUAT', left: tmtSurvey[3].shift(), right: tmtSurvey[3].shift(), seq: [...tmtSurvey[3]] }
    this.shoulder = { tag: 'SHOULDER ROTATION', left: tmtSurvey[4].shift(), right: tmtSurvey[4].shift(), seq: [...tmtSurvey[4]] }
    this.straight = { tag: 'STRAIGHT-LEG RAISE', left: tmtSurvey[5].shift(), right: tmtSurvey[5].shift(), seq: [...tmtSurvey[5]] }
    this.push = { tag: 'PUSH-UP', center: tmtSurvey[6].shift(), seq: [...tmtSurvey[6]] }
    this.bird = { tag: 'BIRD DOG', left: tmtSurvey[7].shift(), right: tmtSurvey[7].shift(), seq: [...tmtSurvey[7]] }
  }

  getMovability(): 'Good' | 'Normal' | 'Bad' {
    let status: 'Good' | 'Normal' | 'Bad';
    const sum = [
      Math.min(this.cervical.front, this.cervical.back, this.cervical.left, this.cervical.right),
      Math.min(this.shoulder.left, this.shoulder.right),
      Math.min(this.straight.left, this.straight.right)
    ].reduce((sum, acc) => {
      return sum + acc;
    }, 0);
    if (sum >= 8) {
      status = 'Good';
    } else if (sum >= 6) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }
    return status;
  }

  getStability(): 'Good' | 'Normal' | 'Bad' {
    let status: 'Good' | 'Normal' | 'Bad';
    const sum = [
      Math.min(this.push.center),
      Math.min(this.bird.left, this.bird.right)
    ].reduce((sum, acc) => {
      return sum + acc;
    }, 0);
    if (sum >= 5) {
      status = 'Good';
    } else if (sum >= 4) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }
    return status;
  }

  getMotorControl(): 'Good' | 'Normal' | 'Bad' {
    let status: 'Good' | 'Normal' | 'Bad';
    const sum = [
      Math.min(this.overhead.center),
      Math.min(this.single.left, this.single.right),
      Math.min(this.split.left, this.split.right)
    ].reduce((sum, acc) => {
      return sum + acc;
    }, 0);
    if (sum >= 8) {
      status = 'Good';
    } else if (sum >= 6) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }
    return status;
  }

  isAsymmetry(key: keyof FmtSurvey): boolean | undefined {
    if (this[key].hasOwnProperty('left') && this[key].hasOwnProperty('right')) {
      return this[key]['left'] !== this[key]['right'];
    } else {
      return undefined;
    }
  }

  getAsymmetryList(): Array<number> {
    const properties: Array<keyof FmtSurvey> = Object.getOwnPropertyNames(this) as Array<keyof FmtSurvey>;
    const asymmetryList: Array<number> = properties.reduce((ary, acc, idx) => {
      if (this.isAsymmetry(acc)) {
        ary.push(idx + 1);
      }
      return ary;
    }, []);
    return asymmetryList;
  }

  getShoulderPain(): 'Yes' | 'Yes_L' | 'Yes_R' | 'Yes_LR' | 'No' {
    if (this.shoulder.left === 0 && this.shoulder.right === 0) {
      return 'Yes_LR';
    } else if (this.shoulder.left === 0) {
      return 'Yes_L';
    } else if (this.shoulder.right === 0) {
      return 'Yes_R';
    } else if (this.shoulder.seq[5] === 1) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  getCorePain(): 'Yes' | 'No' {
    const hasPain = this.straight.seq[3] === 1 || this.push.seq[3] === 1;
    const result: 'Yes' | 'No' = hasPain ? 'Yes' : 'No';
    return result;
  }

  getCervicalPain(): 'Yes' | 'No' {
    const hasPain = [this.cervical.back, this.cervical.front, this.cervical.left, this.cervical.right].includes(0) ||
      this.cervical.seq[5] === 1;
    const result: 'Yes' | 'No' = hasPain ? 'Yes' : 'No';
    return result;
  }

  getFmtScore(): number {
    const sum: number = [
      Math.min(this.cervical.front, this.cervical.back, this.cervical.left, this.cervical.right),
      Math.min(this.shoulder.left, this.shoulder.right),
      Math.min(this.straight.left, this.straight.right),
      Math.min(this.push.center),
      Math.min(this.bird.left, this.bird.right),
      Math.min(this.overhead.center),
      Math.min(this.single.left, this.single.right),
      Math.min(this.split.left, this.split.right)
    ].reduce((sum, acc) => {
      return sum + acc;
    }, 0);
    return sum;
  }

  getMaxScore(): number {
    const MAX_SCORE = 3;
    return Object.keys(this).reduce(acc => acc + MAX_SCORE, 0);
  }

  getCervicalResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' } {
    let status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad';

    const sumAry: Array<number> = [
      this.cervical.seq[0], this.cervical.seq[1], this.cervical.seq[2], this.cervical.seq[3],
      this.cervical.seq[4] === 0 ? 1 : 0,
      this.cervical.seq[5] === 0 ? 1 : 0
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum === sumAry.length) {
      status = 'Good';
    } else if (sum >= 3) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    if (!this.cervical.front || !this.cervical.back || !this.cervical.left || !this.cervical.right || this.cervical.seq[5]) {
      status = 'Pain Bad';
    }

    return { percent, status };
  }

  getMotorControlResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' } {
    let status: 'Good' | 'Normal' | 'Bad';

    const sumAry: Array<number> = [
      this.overhead.seq[0], this.overhead.seq[6] === 0 ? 1 : 0,
      this.single.seq[7] === 0 ? 1 : 0,
      this.split.seq[2], this.split.seq[4] === 0 ? 1 : 0,
      this.push.seq[2],
      this.bird.seq[0], this.bird.seq[1], this.bird.seq[2], this.bird.seq[3] === 0 ? 1 : 0
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum >= 9) {
      status = 'Good';
    } else if (sum >= 4) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    return { percent, status };
  }

  getPelvisLegResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' } {
    let status: 'Good' | 'Normal' | 'Bad';

    const sumAry: Array<number> = [
      this.overhead.seq[0], this.overhead.seq[3] === 0 ? 1 : 0, this.overhead.seq[4] === 0 ? 1 : 0, this.overhead.seq[5] === 0 ? 1 : 0,
      this.single.seq[2] === 0 ? 1 : 0, this.single.seq[4], this.single.seq[5] === 0 ? 1 : 0,
      this.split.seq[1] === 0 ? 1 : 0,
      this.straight.seq[0], this.straight.seq[1], this.straight.seq[2] === 0 ? 1 : 0
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum >= 10) {
      status = 'Good';
    } else if (sum >= 4) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    return { percent, status };
  }

  getCoreResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' } {
    let status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad';

    const sumAry: Array<number> = [
      this.overhead.seq[0], this.overhead.seq[2] === 0 ? 1 : 0,
      this.single.seq[0], this.single.seq[1], this.single.seq[3] === 0 ? 1 : 0, this.single.seq[4], this.single.seq[6],
      this.split.seq[0] === 0 ? 1 : 0, this.split.seq[2],
      this.straight.seq[0], this.straight.seq[1], this.straight.seq[2] === 0 ? 1 : 0, this.straight.seq[3] === 0 ? 1 : 0,
      this.push.seq[0] === 0 ? 1 : 0, this.push.seq[1] === 0 ? 1 : 0, this.push.seq[2], this.push.seq[3] === 0 ? 1 : 0,
      this.bird.seq[0], this.bird.seq[1],
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum >= 18) {
      status = 'Good';
    } else if (sum >= 6) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    if (this.straight.seq[3] || this.push.seq[3]) {
      status = 'Pain Bad';
    }

    return { percent, status };
  }

  getThoracicResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' } {
    let status: 'Good' | 'Normal' | 'Bad';

    const sumAry: Array<number> = [
      this.cervical.seq[6] === 0 ? 1 : 0,
      this.overhead.seq[0], this.overhead.seq[1] === 0 ? 1 : 0, this.overhead.seq[2] === 0 ? 1 : 0,
      this.single.seq[6],
      this.split.seq[0] === 0 ? 1 : 0, this.split.seq[3],
      this.shoulder.seq[4] === 0 ? 1 : 0,
      this.bird.seq[0], this.bird.seq[1],
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum >= 9) {
      status = 'Good';
    } else if (sum >= 4) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    return { percent, status };
  }

  getShoulderResult(): { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' } {
    let status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad';

    const sumAry: Array<number> = [
      this.overhead.seq[0],
      this.shoulder.seq[0], this.shoulder.seq[1], this.shoulder.seq[2], this.shoulder.seq[3],
      this.shoulder.seq[5] === 0 ? 1 : 0,
      this.bird.seq[0]
    ];
    const sum: number = sumAry.reduce((sum, acc) => {
      return sum + acc;
    }, 0);

    const percent = ((sum * 100) / sumAry.length);
    if (sum >= 7) {
      status = 'Good';
    } else if (sum >= 4) {
      status = 'Normal';
    } else {
      status = 'Bad';
    }

    if (!this.shoulder.left || !this.shoulder.right || this.shoulder.seq[5]) {
      status = 'Pain Bad';
    }

    return { percent, status };
  }

  getCorrectivePartStatus(part): FMTCorrectiveStatus {
    if (this.hasPain(part)) { return 'BAD'; }

    const calcStatus = (val): FMTCorrectiveStatus => {
      switch (val) {
        case 3: return 'GOOD';
        case 2: return 'NORMAL';
        default: return 'BAD';
      }
    }
    const hasCenter = Object.keys(part).includes('center');
    const score = hasCenter ? part.center : Math.min(part.left, part.right);
    const status = calcStatus(score);
    return status;
  }

  hasPain(part): boolean {
    const tag: FMTCorrectivePart = part.tag;
    switch (tag) {
      case 'CERVICAL SPINE':
        if (part.seq[5] === 0) { return true; }
      case 'SHOULDER ROTATION':
        if (part.seq[5] === 0) { return true; }
      case 'STRAIGHT-LEG RAISE':
        if (part.seq[3] === 0) { return true; }
      case 'PUSH-UP':
        if (part.seq[3] === 0) { return true; }
      default:
        return false;
    }
  }

  getCorrectiveExercises() {
    const mergeCorrectiveExercises = (a: Array<FMTCorrectiveExercise>, b: Array<FMTCorrectiveExercise>) => {
      for (const bExercise of b) {
        const sameExerciseIdx = a.findIndex((aExercise: FMTCorrectiveExercise) => aExercise.name === bExercise.name);
        if (sameExerciseIdx >= 0) {
          a[sameExerciseIdx].recommendedAt.push(...bExercise.recommendedAt);
        } else {
          a.push(bExercise);
        }
      }
      return a;
    };
    const correctiveExercises =
      [this.cervical, this.straight, this.shoulder, this.bird, this.push, this.split, this.single, this.overhead]
        .reduce((res, cur) => {
          const tag: FMTCorrectivePart = cur.tag;
          const status: FMTCorrectiveStatus = this.getCorrectivePartStatus(cur);
          // this.log(status);
          const recommendedExercises = FMTCorrectiveExercises.recommend(tag, status);
          return mergeCorrectiveExercises(res, recommendedExercises);
        }, [] as Array<FMTCorrectiveExercise>)
        .sort((a, b) => b.recommendedAt.length - a.recommendedAt.length);

    const recommendPop = (tag: FMTCorrectivePart) => {
      const idx = correctiveExercises.findIndex((exercise: FMTCorrectiveExercise) =>
        exercise.recommendedAt.findIndex((recommendedAt) => recommendedAt.bodyPart === tag) >= 0
      );
      return correctiveExercises.splice(idx, 1)[0].name;
    }

    return {
      mobility: [recommendPop('CERVICAL SPINE'), recommendPop('STRAIGHT-LEG RAISE'), recommendPop('SHOULDER ROTATION')],
      stability: [recommendPop('BIRD DOG'), recommendPop('PUSH-UP')],
      motorControl: [recommendPop('SPLIT SQUAT'), recommendPop('SINGLE LEG STEP'), recommendPop('OVERHEAD SQUAT')]
    };
  }
}
