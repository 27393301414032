import { StringUnion } from '@shared-lib/utils/string-union';

const UNIT_TYPE = StringUnion('m', 'cm', 'km', 'kg', 'ft', 'in', 'pace', 'kph', 'mph', 'mi', 'lbs', 'm/ft', 'cm/ft', 'km/mi', 'm/mi', 'kg/lbs', 'kph/mph');
export type UnitType = typeof UNIT_TYPE.type;

export const convertUnit = (args: { value, from: UnitType, to: UnitType, suffixUnit?: boolean, toFixed?: number }) => {
  const { from, to, suffixUnit, toFixed = 0 } = args;
  let value = Number(args.value);
  let suffix: string = '';
  let result;

  // from > meter or kg
  switch (from) {
    case 'cm': value *= 0.01; break;
    case 'm':
    case 'pace':  // eslint-disable-next-line no-self-assign
      value = value; break;  // nothing
    case 'km': value *= 1000; break;
    case 'ft': value = value * 0.3048; break;
    case 'in': value = value * 0.0254; break;
    case 'mi': value = value * 1609.344; break;
    case 'kg': // eslint-disable-next-line no-self-assign
      value = value; break;
    case 'lbs': value = value * 0.45359237; break;
  }

  // meter or kg > to
  switch (to) {
    case 'm': result = value; suffix = 'm'; break;
    case 'cm': result = value * 100; suffix = 'cm'; break;
    case 'km': result = value * 0.001; suffix = 'km'; break;
    case 'ft': result = value * 3.280839895; suffix = 'ft'; break;
    case 'in': result = value * 39.37007874; suffix = 'inch'; break;
    case 'mi': result = value * 0.00062137119224; suffix = 'mi'; break;
    case 'kph': result = value; suffix = 'km/h'; break;
    case 'mph': result = value * 1.609344; suffix = 'mi/h'; break;
    case 'kg': result = value; suffix = 'kg'; break;
    case 'lbs': result = Math.round(value * 2.2046226218); suffix = 'lbs'; break;
  }


  if (result || result === 0) {
    return `${result.toFixed(toFixed)}${suffixUnit ? suffix : ''}`;
  }

  return;
}
