<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="black2">{{'모달.검사 목록'|translate}}</ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button fill="clear" [ngClass]="{'not-yet': true, 'prod-mode': !util.isDebugMode()}">
        <ion-icon color="black1" name="search-outline"></ion-icon>
      </ion-button> -->
  </ion-toolbar>
</ion-header>

<ion-content #ionContent>
  <ion-spinner *ngIf="loadState=='loading'" name="crescent" class="center-loading"></ion-spinner>
  <ion-row *ngIf="loadState === 'loaded' && (items === undefined || items.length === 0)" class="empty-data">
    <ion-text color="black2" class="title bold">
      {{'건강.운동검사 기록이 없습니다.'|translate}}
    </ion-text>
    <ion-text color="black1" class="desc">
      {{'건강.운동검사 기록을 추가해보세요.'|translate}}
    </ion-text>
  </ion-row>
  <ion-virtual-scroll #virtualScroll [items]="items" *ngIf="loadState === 'loaded' && items && items.length > 0">
    <ion-row *virtualItem="let item;" class="test-item" [ngClass]="{'smt': testSuperType === 'smt'}" (click)="showExerciseTestResult(item)">
      <ion-col size="8">
        <ion-label color="black2" style="font-size: 15px;" class="bold">
          {{('건강.' + item.EXERCISE_TEST_Title) | translate}}
        </ion-label>
        <ion-label color="black2" style="font-size: 13px; margin: 4px 0;" *ngIf="['smt3', 'smt4', 'smt5'].includes(item.EXERCISE_TEST_Type)">
          {{item.EXERCISE_TEST_Data.type}}
        </ion-label>
        <ion-label color="gray5" style="font-size: 11px;">
          {{item.EXERCISE_TEST_InDate|ftdate:'yyyy.MM.dd (E) HH:mm'}}
        </ion-label>
      </ion-col>
      <ion-col size="4">

        <ion-label color="black2" class="font12" style="text-align: right;">
          {{'모달.검사'|translate}} {{item.EXERCISE_TEST_Order}}{{'모달.회차'|translate}}
        </ion-label>
      </ion-col>
    </ion-row>
  </ion-virtual-scroll>
  <ion-infinite-scroll (ionInfinite)="doInfinite($event)" *ngIf="isMoreItems">
    <ion-infinite-scroll-content></ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
