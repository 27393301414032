import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Plugins } from '@capacitor/core';
const { SocialAccountSignin } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class SsoService extends FtLibBaseComponent {

  isAndroid = this.platform.is('android');
  isIOS = this.platform.is('ios');
  snsDomain = this.environment.socialLogin.webLoginCallbackUrl;

  constructor(
    public platform: Platform,
    private appleSignin: SignInWithApple,
    @Inject('environment') private environment: any,
  ) {
    super({ enableLog: false });
  }

  clear() {
    this.log('clear');
  }

  async getSignUpUserInfo(snsType: string, signinData: any) {
    const snsIDStr = signinData.id;
    // console.log(signinData.email);
    this.log('getSignUpInfo snsIDStr = ' + snsIDStr + ', snsType = ' + snsType);
    const signupInfo = await this.gApi.auth.getSignUpInfoWithSNS({ snsId: snsIDStr, snsType: snsType });
    return signupInfo;
  }

  async doSNSSignOut(email: string) {
    console.log("doSNSSignOut");
    if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
      try {
        const snsTypeInfo = await this.gApi.auth.getSignUpSNSType({ email: email });
        const snsTypes = (await this.gApi.auth.getSocialTypes({ email: email })).snstype;
        if (snsTypeInfo.result || snsTypes !== undefined) {
          if (snsTypeInfo.snstype == 'naver' || snsTypes.sns3 !== undefined) {
            console.log("snstypes:", snsTypeInfo.snstype, snsTypeInfo.snstype, snsTypes.sns3);
            this.doMobileNaverSignOut();
          }
          if (snsTypeInfo.snstype == 'google' || snsTypes.sns1 !== undefined) {
            if (this.platform.is('android')) {
              this.doMobileGoogleSignOut();
            }
          }
        }
      } catch (e) {
        console.log('doSNSSignOut : ', e);
      }
    }
  }

  async initMobileNaverSignIn() {
    if (this.util.isMobileApp()) {
      const rst = await SocialAccountSignin.initNaverSignData({
        naverClientId: this.environment.socialLogin.naverClientId,
        naverClientSecret: this.environment.socialLogin.naverClientSecretKey,
        naverClientAppName: this.environment.socialLogin.naverClientAppName,
        naverClientIOSUrlScheme: this.environment.socialLogin.naverClientIOSUrlScheme
      });
      return rst;
    }
  }

  async getInfoMobileNaverSignIn() {
    let naverLoginInfo;
    if (this.isAndroid || this.isIOS) {
      const rst = await this.initMobileNaverSignIn();
      if (rst.success) {
        const userToken = await SocialAccountSignin.naverSignIn({});
        this.log('Naver Login = ' + JSON.stringify(userToken));
        // userToken.token, userToken.refreshToken, userToken.tokenExpireDate, userToken.tokenType
        naverLoginInfo = await SocialAccountSignin.naverGetUserInfo({});
        console.log(naverLoginInfo);
        this.log('Naver userInfo = ' + JSON.stringify(naverLoginInfo));
        this.log('Naver userInfo id = ' + JSON.stringify(naverLoginInfo.id)); //SNS ID
      }
    }
    return naverLoginInfo;
  }

  async initMobileKakaoSignIn() {
    if (this.util.isMobileApp()) {
      const rst = await SocialAccountSignin.initKakaoSignData({
        kakaoClientId: this.environment.socialLogin.kakaoClientId,
      });
      return rst;
    }
  }

  async getInfoMobileKakaoSignIn() {
    let kakaoLoginInfo;
    if (this.util.isMobileApp && this.isAndroid) {
      const rst = await this.initMobileKakaoSignIn();
      if (rst.success) {
        const userToken = await SocialAccountSignin.kakaoSignIn({});
        this.log('Kakao Login = ' + JSON.stringify(userToken));
        // userToken.token, userToken.refreshToken, userToken.tokenExpireDate, userToken.tokenType
        kakaoLoginInfo = await SocialAccountSignin.kakaoGetUserInfo({});
        console.log(kakaoLoginInfo);
        this.log('Kakao userInfo = ' + JSON.stringify(kakaoLoginInfo));
        this.log('Kakao userInfo id = ' + JSON.stringify(kakaoLoginInfo.id)); //SNS ID
        this.log('Kakao userInfo email = ' + JSON.stringify(kakaoLoginInfo.email));
        return kakaoLoginInfo;
      }
    } else if (this.util.isMobileApp && this.isIOS) {
      const rst = await this.initMobileKakaoSignIn();
      if (rst.success) {
        const kakaoLoginInfo = await SocialAccountSignin.kakaoSignIn({});
        this.log('Kakao Login = ' + JSON.stringify(kakaoLoginInfo));
        // userToken.token, userToken.refreshToken, userToken.tokenExpireDate, userToken.tokenType
        // kakaoLoginInfo = await SocialAccountSignin.kakaoGetUserInfo({});
        console.log(kakaoLoginInfo);
        console.log('Kakao userInfo = ' + JSON.stringify(kakaoLoginInfo));
        console.log('Kakao userInfo id = ' + JSON.stringify(kakaoLoginInfo.id)); //SNS ID
        console.log('Kakao userInfo email = ' + JSON.stringify(kakaoLoginInfo.email));
        return kakaoLoginInfo;
      }
    }
  }

  async doMobileNaverSignOut() {
    if (this.isAndroid || this.isIOS) {
      const rst = await this.initMobileNaverSignIn();
      if (rst.success) {
        await SocialAccountSignin.naverSignOut({});
        this.log('Naver Logout');
      }
    }
  }

  async getRefreshTokenMobileNaver() {
    let refreshToken;
    if (this.isAndroid || this.isIOS) {
      refreshToken = await SocialAccountSignin.naverRefreshToken({});
      this.log('getNaverRefreshToken = ' + JSON.stringify(refreshToken));
    }
    return refreshToken;
  }

  async getResetTokenMobileNaver() {
    let resetToken;
    if (this.isAndroid || this.isIOS) {
      resetToken = await SocialAccountSignin.naverResetToken({});
      this.log('getNaverResetToken = ' + JSON.stringify(resetToken));
    }
    return resetToken;
  }

  getWebNaverConnInfo() {
    const naverWebInfo = {
      url: `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${this.environment.socialLogin.naverClientId}&redirect_uri=${this.snsDomain}&state=1151361640292053898490867335632651198767`,
      type: 'naver'
    }
    return naverWebInfo;
  }

  // getWebNaverSignOutInfo() {
  //   const naverWebInfo = {
  //     url: `https://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=fpaQHccorFJM8a7gJcd7&client_secret=lARUk9lOML&code=${code}&service_provider=naver&access_token=${}`,
  //     type: 'naver'
  //   }
  //   return naverWebInfo;
  // }

  async getInfoMobileGoogleSignIn() {
    let googleLoginInfo;
    if (this.isAndroid) {
      await SocialAccountSignin.initGoogleSignData({
        googleClientId: this.environment.socialLogin.googleCLientId
      });
      googleLoginInfo = await SocialAccountSignin.googleSignIn({});
      console.log('Google login info = ' + JSON.stringify(googleLoginInfo));
      // let googleUser = await SocialAccountSignin.googleSignOut({});
      console.log('Google login id = ' + JSON.stringify(googleLoginInfo.id)); //SNS ID
      let userName = googleLoginInfo.familyName + googleLoginInfo.givenName;
      if (userName == undefined || googleLoginInfo.familyName == undefined || googleLoginInfo.givenName == undefined) {
        userName = googleLoginInfo.displayName;
      }
      googleLoginInfo.name = userName;
    }
    return googleLoginInfo;
  }

  async doMobileGoogleSignOut() {
    let googleLogoutInfo;
    if (this.isAndroid) {
      await SocialAccountSignin.initGoogleSignData({
        googleClientId: this.environment.socialLogin.googleCLientId
      });
      googleLogoutInfo = await SocialAccountSignin.googleSignOut({});
    }
    return googleLogoutInfo;
  }

  getWebGoogleConnInfo() {
    const googleWebInfo = {
      url: `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile&client_id=${this.environment.socialLogin.googleCLientId}&redirect_uri=${this.snsDomain}`,
      type: 'google'
    }
    return googleWebInfo;
  }

  // getWeGoogleSignOutInfo() {
  //   const googleWebInfo = {
  //     url: ``,
  //     type: 'google'
  //   }
  //   return googleWebInfo;
  // }

  async getInfoMobileAppleSignIn() {
    if (this.isIOS) {
      await this.appleSignin.signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
        ]
      })
        .then(async (appleLoginInfo: AppleSignInResponse) => {
          const appleUserInfo = appleLoginInfo;
          appleUserInfo['id'] = appleLoginInfo.user;
          console.log('Apple login appleLoginInfo user = ' + JSON.stringify(appleUserInfo.user)); // SNS ID
          console.log('Apple login info123 = ' + JSON.stringify(appleUserInfo));
          return appleUserInfo;
        })
        .catch((error: AppleSignInErrorResponse) => {
          alert(this.translate.instant('인증.계정 인증에 실패했습니다.\n잠시 후 다시 시도해주세요.') + '\n' + error.localizedDescription + '(' + error.code + ')');
          console.error(error);
        });
    }
  }

  getWebAppleConnInfo() {
    const appleWebInfo = {
      url: `https://appleid.apple.com/auth/authorize?client_id=kr.fitt.fitt4.services&redirect_uri=${this.snsDomain}&response_type=code%20id_token&response_mode=fragment&state=real`,
      type: 'apple'
    }
    return appleWebInfo;
  }

  // async kakaoLogin() {
  //   if (this.platform.is('android') || this.platform.is('ios')) {
  //     // this.log('kakao Login info = ');
  //   }
  //   else {
  //     const url = `https://kauth.kakao.com/oauth/authorize?client_id=d9ce5494b08ca571eb5cef76b89f7e77&redirect_uri=${this.snsDomain}&response_type=code`

  //     // this.openWindow.opener.getSnsData = this.getSnsData;
  //   }
  // }

  // async facebookLogin() {
  //   if (this.platform.is('android') || this.platform.is('ios')) {
  //     // this.log('facebook Login info = ');
  //   }
  //   else {
  //     const url = `https://www.facebook.com/v4.0/dialog/oauth?client_id=505528553692266&redirect_uri=${this.snsDomain}&state=1151361640292053898490867335632651198767`

  //   }
  // }

  // getAuthToken(): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     const begin = new Date();
  //     if (!this.afAuth.auth.currentUser) {
  //       await this.verifyAuth();
  //     }
  //     const idToken = await this.afAuth.auth.currentUser.getIdToken();
  //     resolve(idToken);
  //   });
  // }
  // getRecord(args: { table: string, fields?: Array<string>, where?: any, orderBy?: string, limit?: Array<number> }): Promise<any> {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       // const res = await this.fittUtil.remoteApi({
  //       //   api: 'util/getRecord',
  //       //   param: args,
  //       // });
  //       // resolve(res.record);
  //       const { table, where, fields, orderBy, limit } = args;
  //       const q = `
  //         SELECT
  //           ${fields ? fields.join(',') : '*'}
  //         FROM ${table}
  //         ${where ? `WHERE ${this.util.makeWhereParamters(where)}` : ''}
  //         ${orderBy ? `ORDER BY ${[].concat(orderBy).join(',')}` : ''}
  //         ${limit ? `LIMIT ${limit.join(',')}` : ''};
  //       `;
  //       this.log('getRecord', q);
  //       const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
  //       resolve(res.result);
  //     }
  //     catch (error) {
  //       reject(error);
  //     }
  //   });
  // }
}
