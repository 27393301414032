import { TranslationLocPipe } from './trans-loc.pipe';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TransLocService } from '@shared-lib/services/trans-loc.service';

@NgModule({
  declarations: [TranslationLocPipe],
  providers: [TranslationLocPipe],
  exports: [TranslationLocPipe],
})
export class TranslationLocPipeModule {
  public static forRoot(jsonUrl: any): ModuleWithProviders<TranslationLocPipeModule> {
    console.log('TranslationLocPipeModule.forRoot', jsonUrl);
    return {
      ngModule: TranslationLocPipeModule,
      providers: [
        TransLocService,
        {
          provide: 'jsonUrl', // you can also use InjectionToken
          useValue: jsonUrl
        }
      ]
    };
  }
}
