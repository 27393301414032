import { Inject, Injectable } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { PtReport, Post, Comment } from '@fitt-lib/models/fitt-models';
import { Schedule } from '@fitt-lib/models/schedule-models';
import { ModalController, Platform } from '@ionic/angular';
import { FcmService } from '@fitt-lib/services/fcm.service';
import { SsoService } from '@fitt-lib/services/sso.service';
import { SocialLoginAlert } from '@fitt-lib/modals/social-login-alert/social-login-alert';

@Injectable({
  providedIn: 'root'
})
export class FireService extends FtLibBaseComponent {
  constructor(
    private afAuth: AngularFireAuth,
    private afData: AngularFireDatabase,
    private fcm: FcmService,
    private platform: Platform,
    private gSso: SsoService,
    private modalCtrl: ModalController,
    @Inject('environment') private environment: any,
  ) {
    super({ enableLog: false });

  }
  reAuthEmail(args: { email: string }): Promise<any> {
    const { email } = args
    return new Promise(async (resolve, reject) => {
      try {
        this.log('email', email);
        const res = await this.fittUtil.remoteApi({
          api: 'auth/isAuthEmail', param: {
            email: email
          }
        });
        this.log('res', res);
        const result = await this.fittUtil.remoteApi({
          api: 'auth/sendAuthEmail',
          param: {
            email: res.result.MEMBER_Email,
            name: res.result.MEMBER_Name,
            seq: res.result.MEMBER_Seq,
            isCurrentLangKo: this.util.isCurrentLangKo()
          }
        });

        resolve(result);
      }

      catch (error) {
        reject(error);
      }
    });
  }
  signup(args: { email: string, password: string, name: string, tel: string, tel2: string, agreeMarketing: boolean, agreePolicy: boolean, agreeTerms: boolean, birth: string, gender: string, height: string, weight: string, snstype: string, snsid: string, isCurrentLangKo: boolean }): Promise<any> {
    const { email, password, name, tel, tel2, agreeMarketing, agreePolicy, agreeTerms, birth, height, weight, gender, snstype, snsid, isCurrentLangKo = this.util.isCurrentLangKo() } = args;
    return new Promise(async (resolve, reject) => {
      // 계정 생성
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'auth/signupMember', param: {
            email, password, name, tel, tel2, agreeMarketing, agreePolicy, agreeTerms, birth, height, weight, gender, snstype, snsid, isCurrentLangKo
          }
        });

        this.log('res', res);


        //TODO : 사용하는지 확인 후 삭제가능
        await this.fittUtil.remoteApi({
          api: 'auth/isAuthEmail', param: {
            email: email
          }
        });

        const date = new Date();
        const yyyy = date.getFullYear() + 1;
        const startDate = date.toISOString();
        const endDate = new Date(yyyy, date.getMonth(), date.getDate()).toISOString()
        const mSeq = res.result['mSeq'];

        await this.fittUtil.remoteApi({
          api: 'coupon/putWelcomeCoupon', param: {
            mSeq: mSeq,
            startDate: new Date(startDate).format('yyyy-MM-dd HH:mm:ss'),
            endDate: new Date(endDate).format('yyyy-MM-dd HH:mm:ss')
          }
        });

        resolve(undefined);
      }

      catch (error) {
        reject(error);
      }
    });
  }

  getCustomToken(args: { uid: String }): Promise<any> {
    const uid = args.uid;
    return new Promise(async (resolve, reject) => {
      try {
        const result = this.fittUtil.remoteApi({
          api: 'auth/getCustomToken',
          param: {
            uid: uid
          }
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  getUserByEmail(args: { email: String }): Promise<any> {
    const email = args.email;
    return new Promise(async (resolve, reject) => {
      try {
        const result = this.fittUtil.remoteApi({
          api: 'auth/getUserByEmail',
          param: {
            email: email,
          }
        });
        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  }

  setSocialConnection(args: { type: String, uid: String }): Promise<any> {
    const type = args.type;
    const uid = args.uid;
    return new Promise(async (resolve, reject) => {
      try {
        this.fittUtil.remoteApi({
          api: 'auth/setSocialConnection',
          param: {
            type: type,
            uid: uid
          }
        });
      } catch (error) {
        reject(error);
      }
    });
  }

  login(args: { email: string, password: string }): Promise<any> {
    let { email } = args;
    const { password } = args;
    return new Promise(async (resolve, reject) => {
      try {
        try {

          //공용 로그인
          const value = '@fitt4internal.kr';
          if (email.indexOf('@') == -1) {
            email = email + value;
          }

          console.log('login', localStorage.keepLogin, localStorage.keepLogin == 'true');
          await this.afAuth.auth.setPersistence(localStorage.keepLogin == 'true' ? 'local' : 'session');
          const userByEmail = (await this.getUserByEmail({ email: email })).result;
          // const userData = userByEmail.userData[userByEmail.uid];
          const snsUserData = JSON.parse(localStorage.getItem('snsdata'));
          const getSocialTypes = (await this.gApi.auth.getSocialTypes({ email: email })).snstype;

          if (localStorage.getItem('snsdata') == null || localStorage.getItem('snsdata') == '') { // sns 로그인 아닐 시 바로 email and password 방식 로그인 시행(오류 시 회원가입 절차)
            console.log("this is log1:", getSocialTypes);
            console.log("snsdata localstorage check: ", snsUserData);
            if (getSocialTypes.sns1 !== undefined || getSocialTypes.sns2 !== undefined || getSocialTypes.sns3 !== undefined || getSocialTypes.sns4 !== undefined) {
              console.log("this is log2");
              await this.afAuth.auth.signInWithEmailAndPassword(email, password);
            } else {
              const availableSnsType = new Array<string>();

              if (getSocialTypes.sns1 !== undefined) {
                availableSnsType.push("google");
              }
              if (getSocialTypes.sns2 !== undefined) {
                availableSnsType.push("apple");
              }
              if (getSocialTypes.sns3 !== undefined) {
                availableSnsType.push("naver");
              }
              if (getSocialTypes.sns4 !== undefined) {
                availableSnsType.push("kakao");
              }

              const modal = await this.modalCtrl.create({ component: SocialLoginAlert, componentProps: { title: this.translate.instant("인증.이미 가입된 계정"), context: this.translate.instant("인증.이메일 정보가 일치하는 계정이 존재합니다. 아래 계정으로 로그인 해주세요."), body: availableSnsType } });

              this.util.showLoadingPopup(false);
              await modal.present();
              const alertRes = await modal.onDidDismiss();
              if (alertRes) {
                return;
              }
            }
          } else { // 이하 sns 로그인 로직
            const socialData = (await this.gApi.auth.checkSocialData({ snsId: snsUserData['id'] })).data;
            const customToken = (await this.getCustomToken({ uid: userByEmail.uid })).result;
            console.log("socialData ", socialData);
            if (userByEmail == null) {
              console.log("userByEmail", userByEmail);
              throw new Error(); // 예외처리 필요
            } else {
              // if (userData['socialConnections'] == null) {// socialConnections 란이 null 일 시 해당 값 부여 <<<< 레거시 코드(폐기 필요)
              //   if (userData['mSnsType'] == null) {
              //     this.setSocialConnection({ type: 'email', uid: userByEmail.uid }); // snsType == null 일 시 email 부여
              //   } else {
              //     this.setSocialConnection({ type: snsUserData['snstype'], uid: userByEmail.uid }); // snsType != null 일 시 해당 snsType 부여
              //   }
              // }

              // const socialConnection = Object.keys(userData['socialConnections']);

              // socialConnection.filter((item) => {
              //   if (item == snsUserData['snstype']) { // snsLogin + 해당 이메일 계정 존재함 + 현재 로그인 하는 SNS 와 타입 일치시 => 바로 로그인
              //     this.loginWithToken({ tok: userByEmail.uid });
              //     return;
              //   }
              // });

              if (socialData !== undefined) {
                console.log("userByEmail", userByEmail);
                await this.afAuth.auth.signInWithCustomToken(customToken.ctok);
                // this.loginWithToken({ tok: userByEmail.uid });
              } else {
                const alert = await this.util.alertCreate({
                  title: this.translate.instant('인증.이미 가입된 계정'),
                  message: this.translate.instant('인증.이메일 정보가 일치하는 계정이 존재합니다. 기존 계정에 연동하시겠습니까?'),
                  buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok' }]
                });

                this.util.showLoadingPopup(false);
                await alert.present();
                const result = await alert.onDidDismiss();

                if (result && result.role === 'ok') {
                  let snsTypeString = "0"
                  switch (snsUserData['snstype']) {
                    case "google": snsTypeString = "1"; break;
                    case "apple": snsTypeString = "2"; break;
                    case "naver": snsTypeString = "3"; break;
                    case "kakao": snsTypeString = "4"; break;
                  }
                  this.log("log this: ", email, snsUserData['snstype'], snsUserData['id']);
                  await this.gApi.auth.setSocialData({ email: email, snsType: snsTypeString, snsId: snsUserData['id'] });
                  await this.afAuth.auth.signInWithCustomToken(customToken.ctok);
                } else {
                  console.log('result && result.role');
                  localStorage.setItem('snsdata', '');
                  return;
                }
              }

              // if (socialConnection.length == 1 && socialConnection[0] == 'email') { // snsLogin + 해당 이메일 계정 존재함 + socialConnection 에 email 밖에 없음 => 계정 연동 여부 질문 후 연동 및 로그인
              //   const alert = await this.util.alertCreate({
              //     message: this.translate.instant('회원관리.기존에 가입된 피트 계정과 연동됩니다.'),
              //     buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok' }]
              //   });

              //   this.util.showLoadingPopup(false);
              //   await alert.present();
              //   const result = await alert.onDidDismiss();

              //   if (result && result.role === 'ok') {
              //     await this.setSocialConnection({ type: localStorage.getItem('snstype'), uid: userByEmail.uid });
              //     await this.afAuth.auth.signInWithCustomToken(customToken.ctok);
              //   } else {
              //     localStorage.setItem('snsdata', '');
              //     throw new Error();
              //   }
              // } else {
              //   await this.afAuth.auth.signInWithCustomToken(customToken.ctok);
              // }
            }
          }
        }
        catch (error) {
          // if (error.auth === 0) {
          //   throw (error);
          // }
          // if (error.code != 'auth/user-not-found') throw (error);
          // firebase 에 사용자가 없으므로 fiit3에서 조회
          const res = await this.fittUtil.remoteApi({
            api: 'auth/legacyLogin', param: {
              email: email, pw: password, type: 0, device: 'mobile'
            }
          });  // api, param 가상
          if (res.code <= 0) {
            throw (res);
          }
          await this.afAuth.auth.signInWithEmailAndPassword(email, password);
        }
        this.log("snsdata here: ", this.afAuth.auth.currentUser.providerData);
        if (!this.afAuth.auth.currentUser.emailVerified) {
          await this.logout(false);
          reject({ auth: 0, email: email });
          return;
        }
        const uid = this.afAuth.auth.currentUser.uid;
        this.fittUtil.setMyEmail({ email, uid });
        this.log('login.members.uuid', { uuid: this.fittUtil.get_uuid() });
        if (this.util.isMobileApp()) {
          this.afData.database.ref(`/members/${uid}/uuid`).set(await this.fittUtil.get_uuid());
        }

        resolve(this.afAuth.auth.currentUser.uid);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  loginWithToken(args: { tok: string }): Promise<any> {
    const { tok } = args;
    return new Promise(async (resolve, reject) => {
      try {
        try {
          await this.afAuth.auth.signInWithCustomToken(tok);

          if (!this.afAuth.auth.currentUser.emailVerified) {
            const askAlert = confirm("이메일 미인증 회원입니다. 인증메일을 재발송 하시겠습니까?");
            if (!askAlert) {
              const res = await this.fittUtil.remoteApi({
                api: 'auth/isAuthEmail', param: {
                  email: this.afAuth.auth.currentUser.email
                }
              });

              const sendMail = await this.fittUtil.remoteApi({
                api: 'auth/sendAuthEmail',
                param: {
                  email: res.result.MEMBER_Email,
                  name: res.result.MEMBER_Name,
                  seq: res.result.MEMBER_Seq,
                  isCurrentLangKo: this.util.isCurrentLangKo()
                }
              });
              // this.logout();
              location.href = 'http://fitt.kr';
              resolve(sendMail);
            } else {
              location.href = 'http://fitt.kr';
              reject(undefined);
            }
          }
        }
        catch (error) {
          if (error.code != 'auth/user-not-found') throw (error);
        }
        // await this.afStore.collection('test').doc('forst').set({ name: '처음', value: 5 });
        this.log('setMyEmail', { email: this.afAuth.auth.currentUser.email, uid: this.afAuth.auth.currentUser.uid });
        this.fittUtil.setMyEmail({ email: this.afAuth.auth.currentUser.email, uid: this.afAuth.auth.currentUser.uid });
        resolve(this.afAuth.auth.currentUser.uid);
      }
      catch (error) {
        reject(error);
      }
    });
  }

  setLoginSession(args: {
    gSeq: number,
    device: string,
    uuid: string,
  }): Promise<any> {
    const { gSeq, device, uuid } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const deviceRef = this.afData.database.ref(`/login-session/gyms/${gSeq}/`).child(`${device}`);

        deviceRef.set({
          uuid: uuid
        });
        resolve(this.afAuth.auth.currentUser.uid);
      } catch (error) {
        reject(error);
      }
    });
  }

  async logout(reload: boolean = true) {
    try {
      this.util.showLoadingPopup(true);
      if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
        const myInfo = await this.gApi.auth.getMyInfo();
        this.gSso.doSNSSignOut(myInfo.member.mEmail);
        if (localStorage.push_token) {
          const topics: Array<any> = JSON.parse(localStorage.getItem('fcmTopics'));
          this.fcm.unsubscribeFromTopic({ topics: topics }); //최초설치 후 로그인한 이후 로그아웃시 이부분 타면서 멈추고 무한로딩.
          this.fcm.deleteToken(this.afAuth.auth.currentUser.uid);
          // this.log('unsubscribeFromTopic', unsubscribeFromTopic);
        }
        await this.fcm.unregister();
      }
      localStorage.setItem('snsdata', '');
      localStorage.setItem('snsdata1', '');
      await this.afAuth.auth.signOut();
    } catch (e) {
      this.error(e);
    } finally {
      const isNotWebUuid = window.localStorage.getItem('web_uuid') ? false : true;
      this.gApi.clear();
      this.fittUtil.clear(isNotWebUuid);
      reload && location.reload();
      this.util.showLoadingPopup(false);
    }
  }

  getCheckups(): Promise<any> {
    // date: String; // 검사일
    // type: string; // 운동검사 종류
    // elapsedTime: number; // 소요시간

    // const now = new Date().toISOString().slice(0, 10);
    return new Promise((resolve, reject) => {
      const checkups = [];
      checkups.push({ type: 'run', name: 'YMCA 자전거 검사 (고급)', date: '2019-12-01', elapsedTime: 1200, vo2max: 38, avrAge: 10, cardiacAge: 39, healthAge: 70, danger: 0 });
      checkups.push({ type: 'run', name: 'YMCA 자전거 검사 (중급)', date: '2019-12-02', elapsedTime: 900, vo2max: 37, avrAge: 9, cardiacAge: 39, healthAge: 50, danger: 1 });
      checkups.push({ type: 'run', name: 'FITT Bruce 검사 (중급)', date: '2019-12-03', elapsedTime: 500, vo2max: 36, avrAge: 8, cardiacAge: 39, healthAge: 60, danger: 2 });
      checkups.push({ type: 'run', name: '2.4km 달리기 검사 (초급)', date: '2019-12-04', elapsedTime: 600, vo2max: 35, avrAge: 7, cardiacAge: 39, healthAge: 20, danger: 3 });
      checkups.push({ type: 'run', name: '12분 달리기 검사 (초급)', date: '2019-12-05', elapsedTime: 700, vo2max: 34, avrAge: 6, cardiacAge: 39, healthAge: 30, danger: 2 });
      checkups.push({ type: 'run', name: '5분 달리기 검사 (초급)', date: '2019-12-05', elapsedTime: 400, vo2max: 33, avrAge: 5, cardiacAge: 39, healthAge: 40, danger: 1 });
      checkups.push({ type: 'run', name: '12분 달리기 검사 (중급)', date: '2019-12-06', elapsedTime: 1000, vo2max: 26, avrAge: 4, cardiacAge: 39, healthAge: 10, danger: 0 });
      resolve(checkups);
    });
  }

  getHSRCheckups(): Promise<any> {
    // date: String; // 검사일
    // type: string; // 운동검사 종류
    // elapsedTime: number; // 소요시간

    return new Promise((resolve, reject) => {
      const checkups = [];
      checkups.push({ date: '2019.01.01', elapsedTime: 1200, vo2max: 38, avrAge: 10, cardiacAge: 39, healthAge: 70, danger: 0 });
      checkups.push({ date: '2019.01.02', elapsedTime: 900, vo2max: 37, avrAge: 9, cardiacAge: 39, healthAge: 50, danger: 1 });
      checkups.push({ date: '2019.01.03', elapsedTime: 500, vo2max: 36, avrAge: 8, cardiacAge: 39, healthAge: 60, danger: 2 });
      checkups.push({ date: '2019.01.04', elapsedTime: 600, vo2max: 35, avrAge: 7, cardiacAge: 39, healthAge: 20, danger: 3 });
      checkups.push({ date: '2019.01.05', elapsedTime: 700, vo2max: 34, avrAge: 6, cardiacAge: 39, healthAge: 30, danger: 2 });
      checkups.push({ date: '2019.01.06', elapsedTime: 400, vo2max: 33, avrAge: 5, cardiacAge: 39, healthAge: 40, danger: 1 });
      checkups.push({ date: '2019.01.07', elapsedTime: 1000, vo2max: 26, avrAge: 4, cardiacAge: 39, healthAge: 10, danger: 0 });
      resolve(checkups);
    });
  }

  getRecommend(): Promise<any> {
    return new Promise((resolve, reject) => {
      const rcmdArr = [];
      rcmdArr.push({ title: '일반인', context: '주간 1,000Kcal/ 일간 143Kcal', status: true, min: 1000 });
      rcmdArr.push({ title: '운동초보자', context: '주간 600Kcal/ 일간 86Kcal', status: false, min: 200 });
      rcmdArr.push({ title: '체력 향상을 원하는 분', context: '주간 800Kcal/ 일간 114Kcal', status: false, min: 800 });
      rcmdArr.push({ title: '만성 질환 예방을 원하는 분', context: '주간 1,000Kcal/ 일간 143Kcal', status: false, min: 1000 });
      rcmdArr.push({ title: '비만 예방을 원하는 분', context: '주간 1,500Kcal/ 일간 214Kcal', status: false, min: 1500 });
      rcmdArr.push({ title: '비만 개선을 원하는 분', context: '주간 2,000Kcal/ 일간 286Kcal', status: false, min: 2000 });
      rcmdArr.push({ title: '심혈관계 질환 예방 및 개선을 원하는 분', context: '주간 2,200Kcal/ 일간 314Kcal', status: false, min: 2200 });
      rcmdArr.push({ title: '당뇨 환자', context: '주간 1,000Kcal/ 일간 143Kcal', status: false, min: 1000 });
      rcmdArr.push({ title: '고지혈증 환자', context: '주간 1,200Kcal/ 일간 171Kcal', status: false, min: 1200 });
      rcmdArr.push({ title: '고혈압 환자', context: '주간 700Kcal/ 일간 100Kcal', status: false, min: 700 });
      resolve(rcmdArr);
    });
  }
  getCheckupState(): Promise<any> {
    return new Promise((resolve, reject) => {
      const checkUpArr = [];
      checkUpArr.push({
        '2019-01-01': {
          'bmi': 10,
          'waist': 11,
          'fat': 12,
          'whr': 13,
          'sugar': 14,
          'pressure': 16,
          'totalc': 15,
          'hdlc': 13,
          'ldlc': 123,
          'lipid': 1
        }
      })
      checkUpArr.push({
        '2019-01-02': {
          'bmi': 10,
          'waist': 11,
          'fat': 12,
          'whr': 13,
          'sugar': 14,
          'pressure': 16,
          'totalc': 15,
          'hdlc': 13,
          'ldlc': 123,
          'lipid': 1
        }
      })
      resolve(checkUpArr);
    });
  }

  ptReports = {
    list: (args: { gSeq: number, tSeq?: number, uSeq?: number, rKey?: string, count?: number }): Promise<Array<PtReport>> => {
      const { gSeq, tSeq, uSeq, rKey } = args;
      const count = args.count || 20;
      return new Promise(async (resolve, reject) => {
        try {
          if (!gSeq) {
            throw { message: 'invalid param' };
          }
          const response = await this.fittUtil.remoteApi({
            api: '/b2b/user/listPtReports', param: { gSeq: gSeq, tSeq: tSeq, uSeq: uSeq, rKey: rKey, count: count }
          });
          if (response.code == 1) {
            resolve(response.result);
          }
        }
        catch (error) {
          reject(error);
        }
      });
    },
    get: (args: { rKey: string }): Promise<PtReport> => {
      const { rKey } = args;
      return new Promise(async (resolve, reject) => {
        try {
          if (!rKey) {
            throw { message: 'invalid param' };
          }

          this.log('ptReports.get', rKey);
          resolve({ rKey: rKey, ...(await this.afData.database.ref(`/pt-reports/list/${rKey}`).once('value')).val() });
        }
        catch (error) {
          reject(error);
        }
      });
    },
    set: (args: { report: PtReport, schedule?: Schedule }): Promise<boolean> => {
      const { report, schedule } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const gSeq = report.gSeq;
          const tSeq = report.tSeq;
          const uSeq = report.uSeq;
          if (!gSeq || !tSeq || !uSeq) {
            throw { message: 'invalid param' };
          }

          const rKey = report.rKey || this.afData.database.ref(`/pt-reports/list`).push().key;
          this.log('ptReports.set', report);
          const updates = {};
          updates[`/pt-reports/list/${rKey}`] = report;
          updates[`/pt-reports/gym/${gSeq}/${rKey}`] = report;
          updates[`/pt-reports/trainer/${tSeq}/${rKey}`] = report;
          updates[`/pt-reports/user/${uSeq}/${rKey}`] = report;
          await this.afData.database.ref().update(updates);

          const q = `UPDATE FITT_DB.TSCHEDULE SET SCHEDULE_ReportKey='${rKey}' WHERE SCHEDULE_Seq=${report.sSeq}`;
          this.log('ptReports.set q', q);
          await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          if (schedule) {
            schedule.sReportKey = rKey;
          }

          resolve(true);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    remove: (args: { rKey: string }): Promise<boolean> => {
      const { rKey } = args;
      return new Promise(async (resolve, reject) => {
        try {
          if (!rKey) {
            throw { message: 'invalid param' };
          }

          const report = (await this.afData.database.ref(`/pt-reports/list/${rKey}`).once('value')).val();
          const gSeq = report.gSeq;
          const tSeq = report.tSeq;
          const uSeq = report.uSeq;
          const updates = {};
          updates[`/pt-reports/list/${rKey}`] = null;
          updates[`/pt-reports/gym/${gSeq}/${rKey}`] = null;
          updates[`/pt-reports/trainer/${tSeq}/${rKey}`] = null;
          updates[`/pt-reports/user/${uSeq}/${rKey}`] = null;
          await this.afData.database.ref().update(updates);
          resolve(true);
        }
        catch (error) {
          reject(error);
        }
      });
    },
  }

  posts = {
    getDateCount: (args: { gSeq: number, startDate?: string, endDate?: string }): Promise<any> => {
      // const today = new Date().toISOString().substr(0, 10);
      const refPath = `/board/${args.gSeq}/stats`;
      // const startDate = args.startDate || today;
      // const endDate = args.endDate || today;
      return new Promise<any>(async (resolve, reject) => {
        try {
          const cntList = (await this.afData.database.ref(refPath + ``).once('value')).val();
          resolve(cntList);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    getPostList: (args: { gSeq: number, board?: string, lastKey?: string, count?: number }): Promise<any> => {
      const refPath = `/board/${args.gSeq}/posts`;
      return new Promise<any>(async (resolve, reject) => {
        try {
          const csList = (await this.afData.database.ref(refPath).orderByKey().once('value')).val();
          resolve(csList);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    getCommentList: (args: { pKey: string }): Promise<any> => {
      const refPath = `/board/comments/${args.pKey}`;
      return new Promise<any>(async (resolve, reject) => {
        try {
          const commentList = (await this.afData.database.ref(refPath).orderByKey().once('value')).val();
          resolve(commentList);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    putPost: (args: { gSeq: number, board: string, post: Post }): Promise<any> => {
      const { gSeq, board, post } = args;
      return new Promise<any>(async (resolve, reject) => {
        try {
          if (!board || !post) {
            throw { message: 'invalid param' };
          }
          await this.afData.database.ref(`/board/${gSeq}/posts/${board}`).push().set(post);
          resolve(undefined);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    putComment: (args: { pKey: string, comment: Comment }): Promise<any> => {
      const { pKey, comment } = args;
      const refPath = `/board/comments/${args.pKey}`;
      return new Promise<any>(async (resolve, reject) => {
        try {
          if (!pKey || !comment) {
            throw { message: 'invalid param' };
          }
          await this.afData.database.ref(refPath).push().set(comment);
          resolve(undefined);
        }
        catch (error) {
          this.log(error);
          reject(error);
        }
      });
    },
    setStatePost: (args: { gSeq: number, pKey: string, board: string, state: string }): Promise<any> => {
      const { gSeq, pKey, board, state } = args;
      return new Promise<any>(async (resolve, reject) => {
        try {
          if (!board || !state) {
            throw { message: 'invalid param' };
          }
          await this.afData.database.ref(`/board/${gSeq}/posts/${board}/${pKey}`).update({ state: state });
          resolve(undefined);
        }
        catch (error) {
          reject(error);
        }
      });
    },
  }

  loadItems(args: { refPath: string, filterKeys?: Array<string>, filterVal?: string, lastKey?: string, count?: number }): Promise<any> {
    const { refPath, filterKeys, filterVal, lastKey } = args;
    // const { refPath, lastKey } = args;
    const count = args.count || 20;
    // const filterKeys = ['notification/title', 'notification/body', 'notification/titleLocArgs', 'notification/bodyLocArgs'];
    // const filterVal = 'FITT';

    return new Promise<any>(async (resolve, reject) => {
      try {
        if (!refPath) throw { message: 'invalid param' };

        const responce = await this.fittUtil.remoteApi({ api: 'util/once', param: { refPath, filterKeys, filterVal, lastKey, count } });
        this.log('loadItems', responce.result);
        resolve(responce.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  async resetPasswordInit(email: string) {
    const emailStr = email.replace(/^\s+|\s+$/g, '');
    const actionCodeSettings = {
      url: `${this.environment.firebase.resetPasswordURL}`,
      iOS: {
        bundleId: 'kr.fitt.fitt4'
      },
      android: {
        packageName: 'kr.fitt.fitt4',
        installApp: true,
        minimumVersion: (await this.fittUtil.getVersionInfo()).version
      },
      handleCodeInApp: false,
    };
    return this.afAuth.auth.sendPasswordResetEmail(emailStr, actionCodeSettings);
  }

  insertPackage(args: {
    token: string, gSeq: number, tSeq: number, pName: string, pType: string, pSession: number, pPeriod: number,
    pAmount: number, pEntranceCount: number, pUseStartTime: string, pUseEndTime: string, pUseDay: Array<string>, cSeqs: Array<number>
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/insertPackage', param: args
        });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  getUsedSessionCount(args: { gSeq: number, paSeq: number, date: any }): Promise<any> {
    const { gSeq, paSeq, date } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getUsedSessionCount', param: {
            gSeq, paSeq, date
          }
        });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  getPackages(args: { gSeq: number, paType: string, ing: string }): Promise<any> {
    const { gSeq, paType, ing } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getPackages', param: {
            token: this.gApi.getAuthToken(), gSeq, paType, ing
          }
        });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  getPackageRank(args: { gSeq: number, startDate: string, endDate: string, }): Promise<any> {
    const { gSeq, endDate, startDate } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getPackagePaymentRanking', param: {
            gSeq, startDate, endDate,
          }
        });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  testModule(args: { html: string }): Promise<any> {
    const { html } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/entrance/test1', param: {
            html
          }
        });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  setVisitData(args: { gSeq: number, date: string, type: string, uSeq: number, uid: string, name: string }): Promise<any> {
    const { gSeq, date, type, uSeq, uid, name } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/entrance/setVisitData', param:
            { gSeq, date, type, uSeq, uid, name }
        });
        resolve(res);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  getVisitData(args: { gSeq: number, date: string }): Promise<any> {
    const { gSeq, date } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/entrance/getVisitData', param:
            { gSeq, date }
        });
        const { visitsToday, visitsFirstDate, visitsTotal } = res.result;

        let visitsThisMonth = 0;
        for (const element in res.result.visitsThisMonth) {
          visitsThisMonth += res.result.visitsThisMonth[element];
        }

        const getMonthDiff = (pre, after) => {
          let months = 0;
          if (typeof pre.getMonth === 'function' || typeof after.getMonth === 'function') {
            months = (after.getFullYear() - pre.getFullYear()) * 12;
            months -= pre.getMonth();
            months += after.getMonth();
          }
          return months <= 0 ? 0 : months;
        }
        let monthDiff = 0;
        for (const element in visitsFirstDate) {
          monthDiff = getMonthDiff(new Date(element), new Date());
        }
        const visitsAvgPerMonth = Number((visitsTotal / (monthDiff + 1)).toFixed(1));

        const result = { visitsToday: (!visitsToday ? 0 : visitsToday), visitsThisMonth, visitsAvgPerMonth }
        resolve(result);
        // resolve(res);
      }
      catch (error) {
        reject(error);
      }
    });
  }

  setSalesQuantity(args: {
    item: string,
    count: number
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const available = this.getAvailableQuantity({
          item: args.item
        });
        if (available) {
          const sales = (await this.afData.database.ref(`/marketing/${args.item}/sales`).once('value')).val();
          const salesRef = this.afData.database.ref(`/marketing/${args.item}/sales`);

          salesRef.set(Number(sales + args.count));
        } else {
          reject('Over the limit');
        }
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  }

  getAvailableQuantity(args: {
    item: string
  }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const limit = (await this.afData.database.ref(`/marketing/${args.item}/limit`).once('value')).val();
        let sales = (await this.afData.database.ref(`/marketing/${args.item}/sales`).once('value')).val();

        sales = sales ? sales : 0;
        const available = limit - sales > 0 ? limit - sales : 0;
        resolve(available);
      } catch (error) {
        reject(error);
      }
    });
  }
}
