import { AlertController } from '@ionic/angular';
import { Directive, HostListener, Injector, Input, ElementRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


// [조형상]: InputValidatorDirective 작업중. 미완료.
@Directive({ selector: '[inputValidator]' })
export class InputValidatorDirective {
  @Input() value: string;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() inputPattern: string = '';

  validate: boolean = false;
  @Output() onValidate: EventEmitter<boolean> = new EventEmitter<boolean>();

  hasFocus: boolean = false;

  constructor(
    private host: ElementRef,
  ) {
  }

  afterInit: boolean = false;
  ngAfterViewInit() {
    this.afterInit = true;
    this.transform();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.afterInit && changes.value && changes.value.firstChange) {
      console.log('InputValidatorDirective.changes', this.host);
      this.transform();
    }
  }
  transform(target?: HTMLInputElement) {
    const oldValue = this.host.nativeElement.value;
    let newValue = new String(oldValue).replace(/[^0-9]/g, '');
    newValue = newValue.slice(0, 4) + (newValue.slice(4, 6) ? `-${newValue.slice(4, 6)}` : '') + (newValue.slice(6, 8) ? `-${newValue.slice(6, 8)}` : '');
    console.log('InputValidatorDirective.transform', oldValue, newValue);
    if (oldValue != newValue) {
      const { selectionStart, selectionEnd } = target || { selectionStart: 0, selectionEnd: 0 };
      if (this.hasFocus) {
        console.log('InputValidatorDirective.getSelection', this.host, target && target.selectionStart);
      }
      this.host.nativeElement.value = newValue;
      if (target) {
        console.log('InputValidatorDirective.setSelectionRange', { selectionStart, selectionEnd });
        target.setSelectionRange(selectionStart, selectionEnd);
      }
      setTimeout(() => {
        this.checkValidate();
        console.log('InputValidatorDirective.transform', oldValue, newValue, this.validate);
        this.valueChange.emit(this.host.nativeElement.value);
      });
      // setTimeout(() => {
      //   target.setSelectionRange(selectionStart, selectionEnd);
      // }, 10);
    }
  }
  checkValidate() {
    const oldValue = this.host.nativeElement.value;
    const value = new String(oldValue).replace(/[^0-9]/g, '');
    const oldValidate = this.validate;
    this.validate = value.length == 8;
    if (this.validate != oldValidate) {
      this.onValidate.emit(this.validate);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const { key, code, keyCode, ctrlKey, metaKey, } = event;
    console.log('InputValidatorDirective.onKeyDown', event, { key, code, keyCode, ctrlKey, metaKey, event }, event.target.value, event.target.selectionStart);
    if (
      ['Tab', 'Enter', 'Escape'].includes(key) ||
      (ctrlKey || metaKey) && ['KeyA', 'KeyC'].includes(code) ||
      (35 <= keyCode && keyCode <= 40)  // Allow: home, end, left, right, top, bottom
    ) {
      // 관여하지 않는다.
      return;
    }
    else if (
      ['Backspace', 'Delete'].includes(key) ||
      (ctrlKey || metaKey) && ['KeyV', 'KeyX'].includes(code)) {
      setTimeout(() => this.transform(event.target));
    }
    else {
      if (!this.inputPattern.includes(key)) {
        e.preventDefault();
        return;
      }
      else {
        setTimeout(() => this.transform(event.target));
      }
    }

    // this.transform();

    // if ([8, 9, 13, 27, 46, 110, 190].includes(keyCode) ||
    //   (ctrlKey || metaKey) && ['KeyA', 'KeyC', 'KeyV', 'KeyX'].includes(code) ||  // Allow: Ctrl+A, Ctrl+C, Ctrl+V, Ctrl+X
    //   (35 <= keyCode && keyCode <= 40)  // Allow: home, end, left, right, top, bottom
    // ) {
    //   // let it happen, don't do anything
    //   // 8: backspace
    //   // 9: tab --
    //   // 13: enter --
    //   // 27: esc --
    //   // 46: delete
    //   // 110: ext .
    //   // 190: .
    //   return;
    // }
    // Ensure that it is a number and stop the keypress

    // if (!this.inputPattern.includes(key)) {
    //   e.preventDefault();
    //   return;
    // }
    // else {
    //   this.transform();
    // }

    // if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
    //   return;
    // else {
    //   this.transform();
    // }

  }
  @HostListener('blur', ['$event'])
  @HostListener('ionBlur', ['$event'])
  onBlur(event) {
    this.transform();
  }

  @HostListener('focusin', ['$event'])
  @HostListener('focusout', ['$event'])
  onFocus(event) {
    this.hasFocus = event.type == 'focusin';
  }

  @HostListener('select', ['$event'])
  onSelect(event) {
    console.log('onSelect', event);
  }


}

// @Directive({ selector: 'ion-input[numberOnly]' })
@Directive({ selector: '[numberOnly]' })
export class NumberOnlyDirective {
  constructor(
    // private host: ElementRef
  ) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const keyID = (event.which) ? event.which : event.keyCode;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
      return;
    else
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
  }
  @HostListener('blur', ['$event'])
  @HostListener('ionBlur', ['$event'])
  onBlur(event) {
    const e = <KeyboardEvent>event;
    const keyID = (event.which) ? event.which : event.keyCode;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
      return;
    else
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
  }
}

@Directive({ selector: '[monthNumberOnly]' })
export class MonthNumberOnlyDirective {
  constructor(
    // private host: ElementRef
  ) {
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    if (!e.shiftKey) {
      const newValue = event.target.value.substring(0, event.target.selectionStart) + event.key + event.target.value.substring(event.target.selectionEnd);
      const newInt = parseInt(newValue);
      if (newInt < 0 || newInt > 12) {
        e.preventDefault();
      }
    }
  }
}

@Directive({ selector: '[dateNumberOnly]' })
export class DateNumberOnlyDirective {
  constructor(
    // private host: ElementRef
  ) {
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    if (!e.shiftKey) {
      const newValue = event.target.value.substring(0, event.target.selectionStart) + event.key + event.target.value.substring(event.target.selectionEnd);
      const newInt = parseInt(newValue);
      if (newInt < 0 || newInt > 31) {
        e.preventDefault();
      }
    }
  }
}

@Directive({ selector: '[minuteNumberOnly]' })
export class MinuteNumberOnlyDirective {
  translate = this.injector.get(TranslateService);
  @Input('minuteNumberOnly') options: { min: number, max: number, onInvalid: Function } = { min: 0, max: Number.MAX_SAFE_INTEGER, onInvalid: () => { } };
  @Input('onInvalid') onInvalid: Function;

  constructor(
    private injector: Injector,
    private alertController: AlertController,
  ) {
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const keyID = (event.which) ? event.which : event.keyCode;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }

    if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
      return;
    else
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
  }
  @HostListener('blur', ['$event'])
  @HostListener('ionBlur', ['$event'])
  async onBlur(event) {
    if (event.target.value == "") {
      return;
    }
    if (Number(event.target.value) < this.options.min || Number(event.target.value) > this.options.max) {
      event.target.value = "";
      if (this.options.onInvalid) {
        this.options.onInvalid();
      } else {
        const alert = await this.alertController.create({
          header: this.translate.instant('모달.유효한 시간이 아닙니다.'),
          message: this.options.min + ':00 ~ ' + this.options.max + ':59' + this.translate.instant('모달.까지만 입력됩니다. 그 이하 및 그 이상의 시간은 입력할 수 없습니다.'),
          buttons: [{ text: 'ok', role: 'ok' }]
        });
        await alert.present();
        await alert.dismiss();
      }
    } else {
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
    }
  }
}

@Directive({ selector: '[secondNumberOnly]' })
export class SecondNumberOnlyDirective {
  constructor(
    // private host: ElementRef
  ) {
  }
  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;
    const keyID = (event.which) ? event.which : event.keyCode;
    if ([46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
    if (keyID == 8 || keyID == 46 || keyID == 37 || keyID == 39)
      return;
    else
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
  }
  @HostListener('blur', ['$event'])
  @HostListener('ionBlur', ['$event'])
  onBlur(event) {
    if (Number(event.target.value) < 0) {
      event.target.value = "0";
    } else if (Number(event.target.value) > 59) {
      event.target.value = "59";
    } else {
      event.target.value = new String(event.target.value).replace(/[^0-9.]/g, "");
    }
  }
}
