import FmtCorrectives from '@fitt-lib/assets/exercise-test/fmt-correctives.json';

export type FMTCorrectiveStandard = 'mobility' | 'stability' | 'motorControl';

export type FMTCorrectivePart = 'CERVICAL SPINE' | 'STRAIGHT-LEG RAISE' | 'SHOULDER ROTATION' | 'BIRD DOG' | 'PUSH-UP' | 'SPLIT SQUAT' | 'SINGLE LEG STEP' | 'OVERHEAD SQUAT';

export type FMTCorrectiveStatus = 'GOOD' | 'NORMAL' | 'BAD';

export type FMTRecommendedAt = {
  standard: FMTCorrectiveStandard;
  bodyPart: FMTCorrectivePart;
  status: FMTCorrectiveStatus;
}

export type FMTCorrectiveExercise = {
  name: string;
  nameKo: string;
  link: string;
  recommendedAt: Array<FMTRecommendedAt>;
}
export class FMTCorrectiveExercises {
  public static exerciseList: Array<FMTCorrectiveExercise> = FmtCorrectives as Array<FMTCorrectiveExercise>;

  public static recommend(bodyPart: FMTCorrectivePart, status: FMTCorrectiveStatus): Array<FMTCorrectiveExercise> {
    const res = this.exerciseList.reduce((res, correctiveExercise) => {
      for (const recommendedAt of correctiveExercise.recommendedAt) {
        if (recommendedAt.bodyPart === bodyPart && recommendedAt.status === status) {
          const element: FMTCorrectiveExercise = {
            name: correctiveExercise.name,
            nameKo: correctiveExercise.nameKo,
            link: correctiveExercise.link,
            recommendedAt: [{ standard: recommendedAt.standard, bodyPart: recommendedAt.bodyPart, status: recommendedAt.status }]
          };
          res.push(element);
          break;
        }
      }
      return res;
    }, [] as Array<FMTCorrectiveExercise>);
    return res;
  }

  public static getCorrectiveExerciseList(standards: Array<FMTCorrectiveStandard>, status: Array<FMTCorrectiveStatus>): Array<FMTCorrectiveExercise> {
    const res = this.exerciseList.filter(exercise => {
      let isRecommended: boolean = false;
      for (const recommend of exercise.recommendedAt) {
        if (standards.includes(recommend.standard) && status.includes(recommend.status)) {
          isRecommended = true;
          break;
        }
      }
      return isRecommended;
    });
    return res;
  }
}
