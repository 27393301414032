import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'web-alert',
  templateUrl: './web-alert.html',
  styleUrls: ['./web-alert.scss'],
})
export class WebAlert extends BaseComponent implements OnInit {

  @Input() icon: string;
  @Input() context: string;
  @Input() body: Array<string>;
  @Input() width: string = '320px';
  @Input() height: string = '280px';

  constructor(
    private modalCtrl: ModalController,
    private elementRef: ElementRef,
  ) {
    super({ enableLog: false });

  }

  async ngOnInit() {
    this.util.parentElementSize(this.elementRef, this.width, this.height);
  }
  close() {
    this.modalCtrl.dismiss();
  }
}
