<ion-header mode="ios" class="header-wrapper">
  <ion-toolbar mode="ios" color="light" style="--border-width: 0;">
    <ion-label slot="start" color="primary" style="font-size: 1.6rem;">{{title}}</ion-label>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-item lines="none" class="no-padding" style="--background: transparent;">
    <ion-avatar slot="start" class="side-profile avatar-border" (click)="profileClick()">
      <img [src]="user.uProfile" *ngIf="user && user.uProfile">
      <img [src]="fittUtil.getDefaultUrl(user && user.uGender)" *ngIf="!(user && user.uProfile)">
    </ion-avatar>
    <div style="margin: auto 0; width: -webkit-fill-available;" (click)="profileClick()">
      <div style="display: flex; align-items: center;">
        <ion-label> {{name}} </ion-label>
      </div>
      <h4 style="font-size: 12px; color: var(--ft-color-black2); margin: 0;"> {{email}} </h4>
    </div>
  </ion-item>
</ion-header>
