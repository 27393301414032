<ng-container *ngIf="!editMode">
  <ion-header mode="ios">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start">
        <ion-button fill="clear" (click)="close()">
          <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{'회원관리.검사자 정보'|translate}}</ion-title>
      <ion-buttons slot="end" *ngIf="loadState === 'loading'" class="ion-margin-end">
        <ion-spinner></ion-spinner>
      </ion-buttons>
      <ion-buttons slot="end" *ngIf="loadState === 'loaded'">
        <ion-button fill="clear" [disabled]="!isOwnGym()" (click)="edit(true)">
          <ion-icon color="black1" name="pencil-sharp"></ion-icon>
        </ion-button>
        <ion-button fill="clear" [disabled]="!isOwnGym()" (click)="trash()" *ngIf="fittUtil.permission.check('회원 삭제')">
          <ion-icon color="black1" name="trash"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <div class="profile-panel">
    <ion-item lines="none">
      <ft-user-profile #userProfile *ngIf="gymUser" [size]="70" [user]="gymUser.user" [showStateCircle]="false"> </ft-user-profile>
      <ion-label style="margin-left: 2px;">
        <div>
          <ion-text *ngIf="fittUtil.planPermission.check('회원관리.구분 탭') && gymUser.user.uState==1" color="main" class="bold" style="font-size: 13px;">
            {{userDivs}}
          </ion-text>
        </div>
        <div style="display: flex; align-items: baseline;">
          <ion-label style="font-size: 20px;">
            <ion-text class="bold"> {{gymUser.user.uName}} </ion-text>
            <!-- <ion-text> {{'모달.회원님'|translate}} </ion-text> -->
          </ion-label>
        </div>
        <div>
          <ion-text color="gray6" style="font-size: 12px;"> {{subText}} </ion-text>
        </div>
      </ion-label>
    </ion-item>
  </div>
  <ion-content>
    <div class="payment-panel">
      <ion-row class="button-bar">
        <ion-col>
          <ion-button fill="clear" [disabled]="!isOwnGym()" (click)="clickExerciseTest('add')">
            <div [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
              <ion-icon name="accessibility" color="primary"></ion-icon>
              <ion-label> {{'건강.움직임능력검사'|translate}}</ion-label>
            </div>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button fill="clear" [disabled]="!isOwnGym()" (click)="clickExerciseTest('history')">
            <div [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
              <ion-icon name="document-text-outline" color="primary"></ion-icon>
              <ion-label> {{'건강.검사 기록'|translate}}</ion-label>
            </div>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>

    <ft-flex-row>
      <ion-label left> {{'연락처'|translate}} </ion-label>
      <ng-container right>
        <ion-label> {{gymUser.user.uTel.phoneFormat()}} </ion-label>
        <ion-buttons *ngIf="util.isMobileApp()" slot="end" style="margin-left: auto; padding: 0;">
          <ion-button fill="outline" color="primary" class="circle" (click)="gData.sendMessage(gymUser.user.uTel)">
            <ion-icon name="mail-sharp"></ion-icon>
          </ion-button>
          <ion-button fill="outline" color="primary" class="circle" (click)="gData.sendCall(gymUser.user.uTel)" *ngIf="util.isMobileApp()">
            <ion-icon name="call-sharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ng-container>
    </ft-flex-row>
    <ft-flex-row>
      <ion-label left>{{'이메일'|translate}}</ion-label>
      <ion-label right>{{gymUser.user.uEmail}}</ion-label>
    </ft-flex-row>
    <ft-flex-row>
      <ion-label left>{{'생년월일'|translate}}</ion-label>
      <ion-label right>{{'컴포넌트.만 _세'|translate:"{age:" + util.getAge(gymUser.user.uBirth) +
        "}"}}</ion-label>
    </ft-flex-row>

    <ft-flex-row (click)="editUserMemo()" style="cursor: pointer;">
      <ion-label left>{{'모달.메모'|translate}}</ion-label>
      <ng-container right>
        <ion-text style="margin: 8px 0;">{{gymUser.user.uMemo}}</ion-text>
        <ion-text style="margin: 8px 0;" *ngIf="!gymUser.user.uMemo">{{'컴포넌트.메모 추가'|translate}}</ion-text>
      </ng-container>
    </ft-flex-row>
  </ion-content>
</ng-container>


<ng-container style="width: 100%; height: 100%;" *ngIf="editMode">
  <ion-header mode="ios">
    <ion-toolbar mode="ios">
      <ion-buttons slot="start">
        <ion-button fill="clear" (click)="edit(false)">
          <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>{{(newMode ? "회원관리.검사자 등록" : "회원관리.검사자 정보" ) |translate}}</ion-title>
      <ion-buttons slot="end">
        <ion-button fill="clear" color="primary" [disabled]="isDisabled()" (click)="save()"> {{'저장'|translate}}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-item lines="none" class="profile-panel" style="height: 134px;" (click)="editProfile($event)">
      <div style="position: relative; margin: 16px auto;">
        <ion-avatar *ngIf="!uploadImage" slot="start" class="profile avatar-border" [ngClass]="{'avatar-border-inactive': activate!='1'}">
          <img src="{{profile}}" *ngIf="profile">
          <img [src]="fittUtil.getDefaultUrl(gender)" *ngIf="!profile">
        </ion-avatar>
        <image-uploader *ngIf="uploadImage" class="profile avatar-border" [image]="uploadImage" (remove)="uploadImage=null"
          (complete)="onCompleteUpload()" [circle]="true" [hasClose]="false" [ngClass]="{'avatar-border-inactive': activate!='1'}">
        </image-uploader>
        <div class="edit-photo" *ngIf="!isChanged">
          <ion-icon name="camera"></ion-icon>
        </div>
      </div>
    </ion-item>
    <div class="edit-list fixed-flex-layout">
      <div style="text-align: right;">
        <ion-item lines="none">{{'이름'|translate}}</ion-item>
        <ion-item lines="none">{{'생년월일'|translate}}</ion-item>
        <ion-item lines="none">{{'성별'|translate}}</ion-item>
        <ion-item lines="none">{{'연락처'|translate}}</ion-item>
        <ion-item lines="none">{{'이메일'|translate}}</ion-item>
      </div>
      <div class="input-panel">
        <ion-item lines="none" style="display: flex; align-items: center;">
          <ion-input type="text" placeholder="{{'모달.검사자 이름 (한글, 영문 대소문자)'|translate}}" [(ngModel)]="name" class="input-container name-placeholder">
          </ion-input>
        </ion-item>
        <ion-item lines="none">
          <ng-container *ngIf="!util.isMobileApp()">
            <ion-input class="input-container" numberOnly minlength="4" maxlength="4" style="margin-right: 8px; flex:1"
              placeholder="{{('인증.출생연도'|translate)}}" [(ngModel)]="birthYear" (ionChange)="setBirthdayInWeb()">
            </ion-input>
            <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="margin-right: 8px; flex:1"
              placeholder="{{('인증.월'|translate)}}" [(ngModel)]="birthMonth" (ionChange)="setBirthdayInWeb()">
            </ion-input>
            <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="flex:1" placeholder="{{('인증.일'|translate)}}"
              [(ngModel)]="birthDay" (ionChange)="setBirthdayInWeb()">
            </ion-input>
          </ng-container>
          <ion-datetime *ngIf="util.isMobileApp()" slot="start" displayFormat="YYYY/MM/DD" [(ngModel)]="birthday"
            placeholder="{{'모달.생년월일을 입력해 주세요.'|translate}}" [doneText]="'확인'|translate" [cancelText]="'취소'|translate"
            style="width: 100%; margin: 0; --padding-start: 8px; --placeholder-color: rgba(0, 0, 0, 0.4);">
          </ion-datetime>
        </ion-item>
        <ion-item lines="none">
          <ion-radio-group class="single-line" [(ngModel)]="gender">
            <ion-item lines="none">
              <ion-radio id="radio-male" value="male"></ion-radio>
              <ion-label> {{'남자'|translate}} </ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-radio id="radio-female" value="female"></ion-radio>
              <ion-label> {{'여자'|translate}} </ion-label>
            </ion-item>
          </ion-radio-group>
        </ion-item>
        <ion-item lines="none">
          <ion-input numberOnly type="tel" inputmode="tel" class="input-container" [(ngModel)]="tel"></ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-input type="email" class="input-container" [(ngModel)]="email"></ion-input>
        </ion-item>
      </div>
    </div>
  </ion-content>
</ng-container>
