import { Component, OnChanges, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

class FmtItem {
  subject: string;
  description: string;
  position: Array<string>;
  img: { normal: string, active: string, deactive: string, isActive: boolean };
  children: Array<{
    title: string, type: 'options' | 'boolean',
    desc?: string,
    options?: Array<string>,
    answer?: string,
    positive?: boolean
  }>
}

@Component({
  selector: 'ft-fmt-details',
  templateUrl: './ft-fmt-details.html',
  styleUrls: ['./ft-fmt-details.scss'],
})
export class FtFmtDetailsComponent extends FtLibBaseComponent implements OnChanges {
  @Input() fmtItemList: Array<FmtItem>;
  @Input() answerList: Array<Array<any>>;

  fmtSubjects: Array<string> = [
    '경추\n움직임평가',
    '오버헤드\n스쿼트',
    '싱글레그\n스텝',
    '스플릿 스쿼트',
    '숄더\n로테이션 터치',
    '스트레이트\n레그레이즈',
    '푸쉬 업',
    '버드 독',
  ];

  srcActiveFmtItemImg: string;
  activatedIndex: number = 0;

  constructor(
  ) {
    super();
  }

  ngOnChanges() {
    this.util.instantAll(this.fmtSubjects, 'fmt2');
    this.activateFmtItem(0);
  }

  activateFmtItem(idx: number) {
    this.fmtItemList.map(ele => ele.img.isActive = false);
    this.fmtItemList[idx].img.isActive = true;
    this.srcActiveFmtItemImg = this.fmtItemList[idx].img.normal;
    this.activatedIndex = idx;
  }

  getOptionSurveys() {
    const length = this.fmtItemList[this.activatedIndex].children.filter(child => child.type === 'options').length;
    return this.answerList[this.activatedIndex].slice(0, length);
  }

  getOptionsEnTitle() {
    if (this.getOptionSurveys().length > 3) {
      return ['Anterior', 'Posterior', 'Left', 'Right'];
    } else if (this.getOptionSurveys().length > 1) {
      return ['Left', 'Right'];
    } else {
      return ['Bilateral'];
    }
  }

  getBooleanSurveys() {
    const length = this.fmtItemList[this.activatedIndex].children.filter(child => child.type === 'options').length;
    return this.answerList[this.activatedIndex].slice(length);
  }

  getBooleanQuestions() {
    return this.fmtItemList[this.activatedIndex].children.filter(child => child.type === 'boolean');
  }

  getSurveyTextColor(idx: number, survey: string): string {
    let yesColor = 'sub4';
    let noColor = 'main';
    if (this.getBooleanQuestions()[idx].positive) {
      yesColor = 'main';
      noColor = 'sub4';
    }

    if (survey === '1') {
      return yesColor;
    } else {
      return noColor;
    }
  }

  getSurveyText(survey: string) {
    if (survey === '1') {
      return 'YES';
    } else {
      return 'NO';
    }
  }

  hasPainInSurvey(fmtCheckIndex: number): boolean {
    // 통증 문항 YES/NO 해당 탭 확인
    switch (fmtCheckIndex) {
      case 0:
        if (this.answerList[fmtCheckIndex][9] === '1') {
          return true;
        }
        break;
      case 4:
        if (this.answerList[fmtCheckIndex][7] === '1') {
          return true;
        }
        break;
    }

    const length = this.fmtItemList[fmtCheckIndex].children.filter(child => child.type == 'options').length;
    for (let i = 0; i < length; i++) {
      if (this.answerList[fmtCheckIndex][i] === '0') {
        return true;
      }
    }

    return false;
  }
}
