<!-- <ion-header>
  <ion-toolbar color="primary">
    <ion-title>
      <ion-icon name="calendar-number"></ion-icon> 
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->

<div>
  <owl-date-time-inline *ngIf="pickerType!='month'" [(ngModel)]="selectedDate" [pickerType]="pickerType" [min]="minDate" [max]="maxDate"></owl-date-time-inline>
  <owl-date-time-inline *ngIf="pickerType=='month' && showMonthPicker" [(ngModel)]="selectedDate" [pickerType]="'calendar'" [startView]="'multi-years'" [min]="minDate" [max]="maxDate"
    (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event)"></owl-date-time-inline>
</div>

<ion-footer>
  <ion-toolbar style="border-top: 1px solid rgba(0,0,0,.12);">
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="close()">{{'cancel'|translate}}</ion-button>
      <ion-button class="click-on-enter" color="primary" (click)="close(true)">{{'ok'|translate}}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>