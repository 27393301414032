<ng-container>
  <ion-row>
    <ion-col [size]="util.isMobileApp() ? 12 : 5" class="column" [ngClass]="util.isMobileApp() ? 'mobile' : 'web left'">
      <ion-row>
        <ion-text color="black1" class="diagnosis-title" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">{{'건강.진단 분석'|translate}}</ion-text>
      </ion-row>
      <ion-row class="diagnosis" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
        <ion-col size="4">
          <p class="grade" [ngClass]="report.movability">{{report.movability}}</p>
          <p class="title ko">{{'건강.가동성'|translate}}</p>
          <p class="title en">mobility</p>
        </ion-col>
        <ion-col size="4">
          <p class="grade" [ngClass]="report.stability">{{report.stability}}</p>
          <p class="title ko">{{'건강.안정성'|translate}}</p>
          <p class="title en">stability</p>
        </ion-col>
        <ion-col size="4">
          <p class="grade" [ngClass]="report.motorControl">{{report.motorControl}}</p>
          <p class="title ko">{{'건강.운동 조절'|translate}}</p>
          <p class="title en">motorcontrol</p>
        </ion-col>
      </ion-row>
      <ion-row class="body-pain" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
        <div class="cervical-pain">
          <p class="title ko">{{'건강.경추부 통증'|translate}}</p>
          <p class="title en">cervical pain</p>
          <p class="pain" [ngClass]="report.cervicalPain">{{report.cervicalPain}}</p>
          <img src="assets/fitt-lib/img/fmt-analysis-line-cervical-pain.png">
        </div>
        <div class="shoulder-pain">
          <p class="title ko">{{'건강.어깨 통증'|translate}}</p>
          <p class="title en">shoulder pain</p>
          <p class="pain" [ngClass]="report.shoulderPain">{{report.shoulderPain}}</p>
          <img src="assets/fitt-lib/img/fmt-analysis-line-shoulder-pain.png">
        </div>
        <div class="core-pain">
          <p class="title ko">{{'건강.중심부 통증'|translate}}</p>
          <p class="title en">core pain</p>
          <p class="pain" [ngClass]="report.corePain">{{report.corePain}}</p>
          <img src="assets/fitt-lib/img/fmt-analysis-line-core-pain.png">
        </div>
        <div class="body-pain-graphic" style="margin: 0 auto;">
          <img src="assets/fitt-lib/img/fmt-analysis-upper-body.png">
          <div class="container">
            <img class="cervical"
              [src]="report.cervicalPain == 'No' ? 'assets/fitt-lib/img/fmt-analysis-cervical-pain-no.png' : 'assets/fitt-lib/img/fmt-analysis-cervical-pain-yes.png'">
            <img class="shoulder"
              [src]="report.shoulderPain == 'No' ? 'assets/fitt-lib/img/fmt-analysis-shoulder-pain-no.png' : 'assets/fitt-lib/img/fmt-analysis-shoulder-pain-yes.png'">
            <img class="core"
              [src]="report.corePain == 'No' ? 'assets/fitt-lib/img/fmt-analysis-core-pain-no.png' : 'assets/fitt-lib/img/fmt-analysis-core-pain-yes.png'">
          </div>
        </div>
      </ion-row>
    </ion-col>
    <ion-col [size]="util.isMobileApp() ? 12 : 7" class="column" [ngClass]="util.isMobileApp() ? 'mobile' : 'web right'">
      <ion-row style="flex-direction: column; align-items: baseline;">
        <ion-text color="black1" class="left-right-balance-title" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">{{'건강.좌-우 불균형'|translate}}
          <ion-icon *ngIf="util.isMobileApp()" name="information-circle-outline" (click)="showLeftRightBalanceGuide()"></ion-icon>
          <ion-icon *ngIf="!util.isMobileApp()" name="information-circle-outline" [tooltip]="tooltipObject.text" [width]="tooltipObject.width"
            [max-width]="tooltipObject.width"></ion-icon>
        </ion-text>
        <p class="font12" style="color: #868e96;">{{'건강.검사동작 중 신체의 좌-우가 불균형한 동작을 표시합니다.'|translate}}</p>
      </ion-row>
      <ion-row class="left-right-balance" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">1. {{'건강.경추의 굴곡과 신전'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-cervical.png">
            <img style="width: 26px; position: absolute; top: 40%; left: 32%;" [src]="isBalanced(0)[0]">
            <img style="width: 26px; position: absolute; top: 40%; right: 32%;" [src]="isBalanced(0)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(0).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(0).R}} point
            </span>
          </p>
        </ion-col>
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">3. {{'건강.싱글 레그 스텝'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-single-leg-step.png">
            <img style="width: 26px; position: absolute; top: 52%; left: 30%;" [src]="isBalanced(2)[0]">
            <img style="width: 26px; position: absolute; top: 52%; right: 30%;" [src]="isBalanced(2)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(2).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(2).R}} point
            </span>
          </p>
        </ion-col>
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">4. {{'건강.스플릿 스쿼트'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-split-squat.png">
            <img style="width: 26px; position: absolute; bottom: 10%; left: 38%;" [src]="isBalanced(3)[0]">
            <img style="width: 26px; position: absolute; bottom: 10%; right: 20%;" [src]="isBalanced(3)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(3).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(3).R}} point
            </span>
          </p>
        </ion-col>
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">5. {{'건강.숄더 로테이션 터치'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-shoulder-rotation-touch.png">
            <img style="width: 26px; position: absolute; top: 48%; left: 14%;" [src]="isBalanced(4)[0]">
            <img style="width: 26px; position: absolute; top: 48%; right: 14%;" [src]="isBalanced(4)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(4).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(4).R}} point
            </span>
          </p>
        </ion-col>
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">6. {{'건강.스트레이트 레그레이즈'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-straight-leg-raise.png">
            <img style="width: 26px; position: absolute; bottom: 0%; right: 0%;" [src]="isBalanced(5)[0]">
            <img style="width: 26px; position: absolute; top: 32%; right: 28%;" [src]="isBalanced(5)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(5).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(5).R}} point
            </span>
          </p>
        </ion-col>
        <ion-col [size]="util.isMobileApp() ? 6 : 4">
          <p class="font13 bold" style="text-align: center; color: var(--ft-color-gray5);">8. {{'건강.버드 독'|translate}}</p>
          <div class="container">
            <img src="assets/fitt-lib/img/fmt-analysis-bird-dog.png">
            <img style="width: 26px; position: absolute; top: 40%; left: 0%;" [src]="isBalanced(7)[0]">
            <img style="width: 26px; position: absolute; top: 40%; right: 0%;" [src]="isBalanced(7)[1]">
          </div>
          <p class="point-text">
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">L : </span>{{this.getLeftRightSurveyData(7).L}} point
            </span>
            <span class="bold">
              <span class="bold" style="color: var(--ft-color-main);">R : </span>{{this.getLeftRightSurveyData(7).R}} point
            </span>
          </p>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ng-container>
