
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'textmask' })
export class TextMaskPipe implements PipeTransform {
  constructor(
  ) { }
  transform(value: string, param: Array<number>) {
    // console.log('TextMaskPipe', value, param);
    if (!value) return '';
    if (!param) return value;
    if (param.length == 1) {
      return `${value.substring(0, param[0])}${'*'.repeat(value.length - param[0])}`;
    }
    else {
      return `${value.substring(0, param[0])}${'*'.repeat(param[1] - param[0])}${value.substring(param[1])}`;
    }
  }
}
