import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'input-alert',
  templateUrl: './input-alert.html',
  styleUrls: ['./input-alert.scss'],
})
export class InputAlert extends BaseComponent implements OnInit {

  @Input() icon: string;
  @Input() title: string;
  @Input() body: string;
  @Input() width: string = '320px';
  @Input() height: string = '350px';
  @Input() placeHolder: string = '';

  inputData: string = '';

  constructor(
    private modalCtrl: ModalController,
    private elementRef: ElementRef,
  ) {
    super({ enableLog: false });

  }

  async ngOnInit() {
    this.util.parentElementSize(this.elementRef, this.width, this.height);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  submit() {
    if (this.inputData) {
      this.modalCtrl.dismiss({
        inputData: this.inputData,
      });
    } else {
      this.util.alertCreate({
        title: '오류',
        message: '이메일을 입력하세요',
        buttons: [{ text: '확인', role: 'ok' }]
      });
    }

  }
}
