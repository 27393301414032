import { NgModule } from '@angular/core';
import { SelectNoShowComponent } from './select-no-show';
import { SharedModule } from '@shared-lib';

@NgModule({
  declarations: [
    SelectNoShowComponent,
  ],
  imports: [
    SharedModule
  ],
  entryComponents: [
    SelectNoShowComponent,
  ],
})
export class SelectNoShowCompModule { }
