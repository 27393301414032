import { assignValues } from '@fitt-lib/models/schedule-models';
import { StringUnion } from '@shared-lib/utils/string-union';

export const USER_POINT_STATE = StringUnion('결제', '환불', '유효기간 만료', '유효기간 수정', '금액 차감', '금액 차증', '사용 취소', '무효 처리');
export type UserPointState = typeof USER_POINT_STATE.type;

const USER_PAYMENT_TYPE = StringUnion('wire', 'account', 'card', 'phone', 'cash', 'online_card', 'requested');
export type UserPaymentType = typeof USER_PAYMENT_TYPE.type;

const SIGN = StringUnion('+', '-', '');
type Sign = typeof SIGN.type;

export class UserPoint {
  uptSeq: number;                           // USER_POINT_Seq
  upSeq: number;                            // USER_PAYMENT_Seq
  gSeq: number;                             // GYM_Seq
  tSeq: number;                             // TRAINER_Seq
  htSeq: number;                            // HANDLE_TRAINER_Seq
  uSeq: number;                             // USER_Seq
  uptAmount: number;                        // USER_POINT_Amount
  uptBalance: number;                       // USER_POINT_Balance
  uptStartDate: string | Date;              // USER_POINT_StartDate
  uptEndDate: string | Date | undefined;    // USER_POINT_EndDate
  uptInDate: string | Date;                 // USER_POINT_InDate
  uptModifyDate: string | Date;             // USER_POINT_ModifyDate
  upCancelDate: string | Date;              // USER_POINT_ModifyDate

  static readonly UPDATE_EVENT = 'userPoint:update';

  constructor(values: Object) {
    assignValues(this, values);
  }

  isIndefinite(): boolean {
    return this.uptEndDate === null;
  }

  getAbleBalanceAt(date: Date): number {
    const viewTime = date.getTime();
    const [startTime, endTime] = [new Date(this.uptStartDate).getTime(), new Date(this.uptEndDate || new Date()).getTime()];
    const isDisable = startTime > viewTime || (!this.isIndefinite() && endTime < viewTime);

    return isDisable ? 0 : this.uptBalance;
  }
}

export class UserPointLog extends UserPoint {
  uptuSeq: number;
  uptuAmount: number;
  uptuState: UserPointState;
  uptuInDate: string | Date;

  uptdSeq: number;
  uptdAmount: number;

  upSeq: number;
  upType: UserPaymentType;
  upAmount: number;
  upRegistDate: string | Date;
  upInDate: string | Date;

  bonusAmount: number;        // 추가 적립금

  paName: string;

  constructor(values: Object) {
    super(values);
    assignValues(this, values);

    this.bonusAmount = this.uptAmount - this.upAmount
  }

  getSign(): Sign {
    const signs: Record<UserPointState | 'default', Sign> = {
      '결제': '+',
      '환불': '-',
      '유효기간 만료': '-',
      '유효기간 수정': '-',
      '금액 차감': '-',
      '금액 차증': '+',
      '사용 취소': '+',
      '무효 처리': '+',
      'default': '',
    };

    return signs[this.uptuState] || signs['default'];
  }

  getAmount(): number {
    return USER_POINT_STATE.guard(this.uptuState) ? parseInt(`${this.getSign()}${this.uptuAmount || 0}`, 10) : 0;
  }
}

export type UserPointDetail = {
  uptSeq: number,
  uptuSeq: number,
  uptdAmount: number,
};

