import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-text-box',
  templateUrl: './ft-text-box.component.html',
  styleUrls: ['./ft-text-box.component.scss'],
})
export class TextBoxComponent extends BaseComponent implements OnInit {
  @Input() heading: string;
  @Input() text: string;

  constructor(
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    this.log('ngOnInit');
  }

}
