<ion-header>
  <ion-button fill="clear" (click)="close()">
    <ion-icon color="black1" name="close"></ion-icon>
  </ion-button>
  <ng-container *ngIf="title">
    <ion-title>{{title}}</ion-title>
  </ng-container>

</ion-header>
<ion-content>
  <ol>
    <ng-container *ngIf="infomationItems">
      <li class="infomation-title" *ngFor="let item of infomationItems" [ngStyle]="{'font-weight': infoTitleFontBold ? 'bold' : 'normal'}">
        {{item.title | translate}}
        <ul *ngIf="item && item.content">
          <li class="infomation-content" *ngFor="let content of item.content">
            {{content | translate}}
          </li>
        </ul>
      </li>

    </ng-container>

  </ol>
</ion-content>
