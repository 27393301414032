import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from '@angular/core';
import { UserPoint } from '@fitt-lib/models/point-models';
import { LoadingController, ModalController } from '@ionic/angular';
import _ from 'lodash';
import { TPackage, Trainer, User } from '@fitt-lib/models/fitt-models';
import { PaymentModalType, PAYMENT_MODAL_TYPE } from '@fitt-lib/models/payment-models';
import { UserPaymentService } from '@fitt-lib/services/user-payment.service';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

@Component({
  selector: 'add-payment-info',
  templateUrl: './add-payment-info.html',
  styleUrls: ['./add-payment-info.scss'],
})
export class AddPaymentInfo extends FtLibBaseComponent implements OnInit {
  packageType: string = 'HEALTH';

  startDate: string;
  startYear: string = '';
  startMonth: string = '';
  startDay: string = '';

  paymentRegistYear: string;
  paymentRegistMonth: string = '';
  paymentRegistDay: string = '';
  paymentRegistDate: string = '';

  startDateOptions: Array<number>;
  packageList: TPackage[];
  packagePlaceholder: string = this.translate.instant('모달.패키지를 선택하세요');
  selectedPackageIndex: number;
  selectedPackage: TPackage;
  paSession: number;
  sessionCount: number;
  packagePeriod: number = 0;
  pAmount: number;
  bonusAmount: number;
  uptAmount: number;
  trainerList: Array<Trainer>;
  trainerWithRetireList: Array<Trainer>;
  orgTSeq: number;
  tSeq: number;
  gSeq: number;
  paymentType: string;
  memoString: string = '';

  code: any = 0;
  saveResultAlert: HTMLIonAlertElement;
  userPoint: UserPoint;

  edited: boolean = false;

  displayManager: Record<string, boolean> = {
    package: false,
    point: false,
    paymentInfo: false,
    save: false,
    paymentRequest: false
  };

  loadState: LoadState = 'none';

  @Input() user: User;
  @Input() canUserCancel: boolean = false;
  @Input() isPayRequest: boolean;
  @Input() modalType: PaymentModalType = 'ticket';

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private modalCtrl: ModalController,
    private upService: UserPaymentService,
    private loadingController: LoadingController,
  ) {
    super();
  }

  async ngOnInit() {
    this.log('ngOnInit', this.canUserCancel, this.user);
    !this.util.isMobileApp() && this.util.parentElementSize(this.elementRef, '600px', '650px');
    const loading = await this.loadingController.create({
      message: 'loading'
    });
    try {
      await loading.present();

      await this.initDisplayManager();
      this.initPaymentDate();

      const myInfo = await this.gApi.auth.getMyInfo();
      this.log('ngOnInit', myInfo, this.canUserCancel);

      const gSeq = myInfo.getGymSeq();
      this.gSeq = gSeq;
      await this.getPackageList();
      this.packageSelectFocus({});

      this.trainerWithRetireList = [
        // 담당 없음의 경우 tSeq = 0 (현재 TUSER 테이블에 담당 트레이너가 없는 경우 tSeq = 0 으로 일치 시켜줌)
        new Trainer({ tSeq: 0, tName: this.translate.instant('담당 없음'), }),
        ...await this.gApi.b2b.getGymTrainers({ gSeq: gSeq, retire: true }),
      ];
      this.trainerList = this.trainerWithRetireList.filter(trainer => !trainer.tRetire);
      this.orgTSeq = _.get(this.user, 'tSeq') || myInfo.getTrainerSeq();
      this.tSeq = this.orgTSeq;
    } catch (error) {
      this.error(error);
    } finally {
      await loading.dismiss();
      !this.cdr['destroyed'] && this.cdr.detectChanges();
    }
  }

  async initDisplayManager() {
    if (PAYMENT_MODAL_TYPE.guard(this.modalType)) {
      this.displayManager.package = ['ticket', 'paymentRequest'].includes(this.modalType);
      this.displayManager.point = ['userPoint'].includes(this.modalType);
      this.displayManager.paymentInfo = ['ticket', 'userPoint'].includes(this.modalType);
      this.displayManager.save = ['ticket', 'userPoint'].includes(this.modalType);
      this.displayManager.paymentRequest = ['paymentRequest'].includes(this.modalType);
    } else {
      await this.close();
      throw new Error('initDisplayManager.invalid modalType');
    }
  }

  initPaymentDate() {
    this.paymentRegistDate = new Date().format('yyyy/MM/dd');
    this.paymentRegistYear = this.paymentRegistDate.split('/')[0];
    this.paymentRegistMonth = this.paymentRegistDate.split('/')[1];
    this.paymentRegistDay = this.paymentRegistDate.split('/')[2];
    this.startDate = new Date().format('yyyy/MM/dd');
    this.startYear = this.startDate.split('/')[0];
    this.startMonth = this.startDate.split('/')[1];
    this.startDay = this.startDate.split('/')[2];
  }

  async packageSelectFocus(event) {
    const translateObject = await this.translate.instant(['모달.알림', '모달.사용 가능한 패키지가 없습니다.\n 패키지를 생성해주세요.']);
    const notice = translateObject['모달.알림'];
    const plzCreatePackage = translateObject['모달.사용 가능한 패키지가 없습니다.\n 패키지를 생성해주세요.']
    if (this.packageList.length === 0 && this.modalType !== 'userPoint') {
      const alert = await this.util.alertCreate({
        title: notice, message: plzCreatePackage
      });
      await alert.onDidDismiss();
    }
  }

  async save() {
    if (this.loadState === 'loading') {
      return;
    }

    try {
      this.loadState = 'loading';
      if (this.code === 1 && this.saveResultAlert !== undefined) {
        await this.saveResultAlert.dismiss();
        [this.code, this.saveResultAlert] = [0, undefined];
        await this.close();
        return;
      }

      this.displayManager.point && this.setPointPackageInfo();
      if (await this.isValidInput()) {
        const { startDate, endDate } = this.upService.getExpiryDate(this.startDate, this.packagePeriod);
        const upSeq = await this.saveTicket(startDate, endDate);
        upSeq && this.displayManager.point && await this.saveUserPoint(upSeq, startDate, endDate);
        await this.close();
      }
    } catch (error) {
      this.error(error);
    } finally {
      this.loadState = 'loaded';
    }
  }

  async saveTicket(startDate: string, endDate: string): Promise<number> {
    this.log('saveTicket', { selectedPackage: this.selectedPackage });
    let upSeq: number;
    try {
      this.setVaildDateInMobile('startDate');
      this.setVaildDateInMobile('paymentRegistDate');


      const gymSeq = this.gSeq;
      const trainerSeq = this.getTrainerSeq();
      const htSeq = this.fittUtil.myInfo.getTrainerSeq();
      const { uSeq: userSeq, uName, } = this.user;
      const { paType: packageType, paSeq: packageSeq, paName: packageName, } = this.selectedPackage
      const userPaymentType = this.paymentType;
      const sessionCount = this.sessionCount;
      const sessionPeriod = this.packagePeriod;
      const amount = this.pAmount;
      const userPaymentPeriod = sessionPeriod;

      const getTrainerName = (tSeq: number): string => {
        if (this.tSeq === 0) return this.translate.instant('담당 없음');

        const trainer = this.trainerWithRetireList.find(trainer => trainer.tSeq === tSeq);
        return trainer ? `${trainer.tName}T` : '';
      }
      const tName: string = getTrainerName(this.tSeq);
      const orgTName: string = getTrainerName(this.orgTSeq);
      const translateObject = this.translate.instant(['모달.알림', '모달.결제 정보 입력이 완료되었습니다.', '모달.결제 요청이 완료되었습니다.', '모달.결제 정보 입력중 오류가 발생하였습니다.']);
      const notice = translateObject['모달.알림'];
      const sameTrainer = this.orgTSeq === this.tSeq;
      if (!sameTrainer) {
        const showAlert = async () => {
          const alert1 = await this.util.alertCreate({
            title: notice,
            message: this.translate.instant('모달._ 회원님의 담당 트레이너가 _에서 _(으)로 변경 됩니다. 저장하시겠습니까?', { uName: uName, orgTName: orgTName, tName: tName, }),
            buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok', }],
          });
          await alert1.present();
          return await alert1.onDidDismiss();
        };

        const { role } = await showAlert();
        if (role !== 'ok') {
          return;
        }
      }

      const insertParam = {
        tSeq: trainerSeq,
        htSeq: htSeq,
        uSeq: userSeq,
        gSeq: gymSeq,
        paSeq: packageSeq,
        paType: packageType,
        paName: packageName, // 결제 알림에 필요, USER_PAYMENT에 들어가지 않는 값이다.
        upType: userPaymentType,
        upPeriod: userPaymentPeriod || 0,
        upSession: ['PT', 'GX', 'ETC', 'OT', 'TEST'].includes(packageType) ? sessionCount : 0,
        upMemo: this.memoString,
        amount: amount,
        sDate: startDate,
        eDate: endDate,
        writerEmail: localStorage.myEmail, // 결제 알림에 필요, USER_PAYMENT에 들어가지 않는 값이다.
        registDate: new Date(this.paymentRegistDate).format('yyyy-MM-ddT00:00:00'),
        reqStatus: this.isPayRequest ? 'requested' : 'completion',   //completion, requested, meet, expired, deny
        isCurrentLangKo: this.util.isCurrentLangKo()
      };

      await this.util.showLoadingPopup(true);
      const insertResponse = await this.gApi.b2b.insertUserPayment(insertParam);
      await this.util.showLoadingPopup(false);
      const { code, upSeq: _upSeq } = insertResponse;
      const completeInsertPaymentInfo = (this.isPayRequest ? translateObject['모달.결제 요청이 완료되었습니다.'] : translateObject['모달.결제 정보 입력이 완료되었습니다.']);
      const errorOccured = translateObject['모달.결제 정보 입력중 오류가 발생하였습니다.'];

      if (code === 1) {
        upSeq = _upSeq;
        this.code = code;
        this.saveResultAlert = await this.util.alertCreate({
          title: notice, message: completeInsertPaymentInfo
        });
        this.saveResultAlert.present();
        await this.saveResultAlert.onDidDismiss();
        this.edited = true;
      } else {
        this.saveResultAlert = await this.util.alertCreate({
          title: notice, message: errorOccured
        });
        this.saveResultAlert.present();
        await this.saveResultAlert.onDidDismiss();
      }
    } catch (error) {
      const translateObject = this.translate.instant(['error', '모달.오류가 발생하였습니다.']);
      await this.util.alertCreate({ title: translateObject['error'], message: translateObject['모달.오류가 발생하였습니다.'], });
      this.saveResultAlert.present();
    } finally {
      await this.util.showLoadingPopup(false);
    }
    return upSeq;
  }

  async saveUserPoint(upSeq: number, startDate: string, endDate: string) {
    try {
      await this.util.showLoadingPopup(true);


      await this.gApi.b2b.insertUserPoint({
        upSeq: upSeq,
        tSeq: this.getTrainerSeq(),
        user: this.user,
        uptAmount: this.uptAmount,
        uptStartDate: startDate,
        uptEndDate: this.packagePeriod === 0 ? undefined : endDate,
      });
    } catch (error) {
      this.error(error);
      const translateObject = this.translate.instant(['error', '모달.오류가 발생하였습니다.']);
      await this.util.alertCreate({ title: translateObject['error'], message: translateObject['모달.오류가 발생하였습니다.'], });
    } finally {
      await this.util.showLoadingPopup(false);
    }
  }

  setPointPackageInfo() {
    if (!this.selectedPackage) {
      this.selectedPackage = new TPackage({ paSeq: -1, paType: 'POINT', paName: this.translate.instant('회원관리.적립금') });
    }
    this.packageType = 'POINT';
  }

  async onSelectUser(event) {
    this.user = event.user;
    // TODO: KJY => 세부시나리오 필요.
    // 1. 이전결제내역 여부는 만료된사용권인지? 사용중인이용권인지? 종류별로 무조건 이전 마지막 결제했던 동일 Seq 패키지로 설정.
    // 2. 결제 패지지 내용이 수정된 경우 처리는? 설정 X
    // const res = await this.gApi.b2b.getPaymentTickets({ uSeq: this.user.uSeq });
    // const lastExpirePackage = res.result.expiredList[0];
    // this.lastPayPackageRequest(lastExpirePackage);
  }

  amountChange(event: CustomEvent, target: 'pAmount' | 'sessionCount' | 'uptAmount') {
    const value: string = event.detail.value;
    const regex = /[^0-9]/g;
    if (regex.test(value)) {
      const numberic = value.replace(/\D/g, '');
      this[target] = parseInt(numberic);
    }
  }


  pointChange() {
    this.uptAmount = (this.pAmount || 0) + (this.bonusAmount || 0);
  }

  async isValidInput(): Promise<boolean> {
    let errorMessage: string;
    const translateObject = this.translate.instant([
      '모달.알림', '모달.패키지를 선택해주세요.', '모달.세션 수를 입력해 주세요.', '모달.결제일을 입력해 주세요.',
      '모달.시작일을 입력해 주세요.', '모달.금액을 입력해 주세요.', '모달.결제수단을 선택해 주세요.', '모달.트레이너를 선택해 주세요.'
    ]);
    const packageType = this.getPackageType();
    const tSeq = this.getTrainerSeq();
    const isThereTrainer = Boolean(tSeq);

    if (this.paymentRegistDate === undefined) {
      errorMessage = translateObject['모달.결제일을 입력해 주세요.'];
    } else if (this.startDate === undefined) {
      errorMessage = translateObject['모달.시작일을 입력해 주세요.'];
    } else if (this.displayManager.package && this.selectedPackageIndex === undefined) {
      errorMessage = translateObject['모달.패키지를 선택해주세요.'];
    } else if (this.paSession !== 0 && (this.sessionCount === undefined || this.sessionCount <= 0) && (packageType === 'PT' || packageType === 'OT' || packageType === 'TEST')) {
      errorMessage = translateObject['모달.세션 수를 입력해 주세요.'];
    } else if (this.pAmount === undefined || this.pAmount < 0) {
      errorMessage = translateObject['모달.금액을 입력해 주세요.'];
    } else if (this.paymentType === undefined) {
      if (this.isPayRequest == true && this.displayManager.paymentRequest) {
        this.paymentType = 'request'; // 트레이너 계정에서 회원에게 결제요청시.
      }
      else {
        errorMessage = translateObject['모달.결제수단을 선택해 주세요.'];
      }
    } else if (!isThereTrainer) {
      errorMessage = translateObject['모달.트레이너를 선택해 주세요.'];
    }

    if (errorMessage !== undefined) {
      const alert = await this.util.alertCreate({
        title: translateObject['모달.알림'], message: errorMessage
      });
      await alert.onDidDismiss();
      return false;
    }
    return true;
  }

  async getPackageList() {
    const { packageList } = await this.gApi.b2b.getGymPackageList({ gSeq: this.gSeq, paType: this.getPackageType() });
    this.packageList = packageList;
    this.log('this.packageList', this.packageList);
    this.packagePlaceholder = this.packageList.length > 0 ? this.translate.instant('모달.패키지를 선택하세요') : this.translate.instant('모달.사용 가능한 패키지가 없습니다.');
  }

  parseInteger = (num: number) => num === Infinity ? '' : Math.floor(num);

  selectPackage(event) {
    if (this.selectedPackageIndex === undefined) return;
    this.selectedPackage = this.packageList[this.selectedPackageIndex];
    this.paSession = this.selectedPackage['paSession'];
    this.sessionCount = this.paSession;
    this.packagePeriod = this.selectedPackage['paPeriod'];
    this.pAmount = this.selectedPackage['paAmount'];
    !this.cdr['destroyed'] && this.cdr.detectChanges();
  }

  getTrainerSeq(): number {
    const isIncumbentTrainer = Boolean(this.trainerList.find(t => t.tSeq === this.tSeq));

    return isNaN(this.tSeq) || !isIncumbentTrainer ? undefined : this.tSeq;
  }

  getPackageType = () => {
    return this.packageType || 'ETC';
  };

  getEndDate() {
    return this.packagePeriod > 0 ? new Date(this.startDate).getNewDate('month', this.packagePeriod).getNewDate('date', -1).format('yyyy/MM/dd') : this.translate.instant('모달.제한없음');
  }

  async segmentChanged(event) {
    this.packageList = undefined;
    this.selectedPackageIndex = undefined;
    this.selectedPackage = undefined;
    this.sessionCount = undefined;
    this.packagePeriod = undefined;
    this.pAmount = undefined;
    const loading = await this.loadingController.create({
      message: 'loading'
    });
    await loading.present();
    await this.getPackageList();
    this.packageSelectFocus({});
    loading.dismiss();
  }

  async close() {
    if (this.edited) {
      await this.modalCtrl.dismiss({ gymUser: this.user, edited: this.edited });
    } else {
      await this.modalCtrl.dismiss();
    }
  }

  setVaildDateInWeb(dateType: string) {
    const [year, month, day] = dateType === 'startDate' ?
      [this.startYear, this.startMonth, this.startDay] :
      [this.paymentRegistYear, this.paymentRegistMonth, this.paymentRegistDay];

    let result: string;
    if (year.length === 4 && (Number(month) > 0 && Number(month) < 13) && (Number(day) > 0 && Number(day) < 32)) {
      const monthZero = month.length < 2 && Number(month) < 10 ? '0' : '';
      const dayZero = day.length < 2 && Number(day) < 10 ? '0' : '';
      result = year + "." + monthZero + month + "." + dayZero + day;
    } else {
      result = undefined;
    }

    if (dateType === 'startDate') {
      this.startDate = result;
    } else {
      this.paymentRegistDate = result;
    }

    if (this.util.isIosTabletWeb()) {
      this.setVaildDateInMobile('startDate');
      this.setVaildDateInMobile('paymentRegistDate');
    }


  }

  getValidDateFormat(date: string) {
    if (date && date.includes('.')) {
      date = date.replace(/\./g, '/');
    }
    return date;
  }

  setVaildDateInMobile(dateType: 'startDate' | 'paymentRegistDate') {
    let dateObj: string = this[dateType];

    dateObj = this.getValidDateFormat(dateObj);
    this[dateType] = new Date(dateObj).format('yyyy/MM/dd');
  }

  async lastPayPackageRequest(lastExpirePackage) {
    const lastPackageAlert = await this.util.alertCreate({
      title: this.translate.instant('온라인결제.이전 결제 패키지'),
      message: this.translate.instant('온라인결제.이전 결제 패키지_-_-_ 팝업메세지', {
        userName: this.user.uName,
        packageName: lastExpirePackage.paName,
        payAmount: lastExpirePackage.paAmount
      }),
      buttons: [{ text: this.translate.instant('취소'), role: 'cancel' }, { text: this.translate.instant('확인'), role: 'ok' }]
    });
    lastPackageAlert.present();
  }

  async doPayRequest() {
    const payConfirmAlert = await this.util.alertCreate({
      title: this.translate.instant('온라인결제.온라인 결제 요청'),
      message: this.translate.instant('온라인결제.온라인 결제 요청_-_ 팝업메세지', {
        packageName: this.selectedPackage.paName,
        payAmount: this.pAmount,
        userName: this.user.uName,
      }),
      buttons: [{ text: this.translate.instant('취소'), role: 'cancel' }, { text: this.translate.instant('확인'), role: 'ok' }]
    });
    await payConfirmAlert.present();

    const result = await payConfirmAlert.onDidDismiss();

    if (result && result.role === 'ok') {
      const { startDate, endDate } = this.upService.getExpiryDate(this.startDate, this.packagePeriod);

      await this.saveTicket(startDate, endDate);
      this.modalCtrl.dismiss({ edited: true });
    }
  }

  getTitle(): string {
    const titles: { [key in PaymentModalType]: string } = {
      ticket: '회원관리.결제정보 입력',
      paymentRequest: '모달.결제요청',
      userPoint: '회원관리.적립금 충전'
    };

    return this.modalType ? this.translate.instant(titles[this.modalType]) : '';
  }
}
