import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

@Component({
  selector: 'social-login-alert',
  templateUrl: './social-login-alert.html',
  styleUrls: ['./social-login-alert.scss'],
})
export class SocialLoginAlert extends FtLibBaseComponent implements OnInit {

  @Input() context: string;
  @Input() body: Array<string>;
  @Input() width: string = '320px';
  @Input() height: string = '280px';

  constructor(
    private modalCtrl: ModalController,
    private elementRef: ElementRef,
  ) {
    super({ enableLog: false });

  }

  async ngOnInit() {
    this.util.parentElementSize(this.elementRef, this.width, this.height);
  }
  close() {
    this.modalCtrl.dismiss();
  }
}
