import { Component, OnChanges, Input, ViewChild, ElementRef } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { FmtReport } from '@fitt-lib/models/exercise-test/fmt';
import { GymUser } from '@fitt-lib/models/fitt-models';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'ft-fmt-report',
  templateUrl: './ft-fmt-report.html',
  styleUrls: ['./ft-fmt-report.scss']
})
export class FtFMTReportComponent extends FtLibBaseComponent implements OnChanges {
  @ViewChild('radarChartCanvas') radarChartCanvas: ElementRef<HTMLCanvasElement>;

  @Input() gymId: string;
  @Input() gymUser: GymUser;
  @Input() report: FmtReport = new Object() as FmtReport;

  radarChart: Chart;



  constructor() {
    super();
    Chart.register(...registerables); // mobile 내 chart.js register
    Chart.register(ChartDataLabels);
  }

  ngOnChanges() {
    setTimeout(() => this.setRadarChart(), 0);
  }

  setRadarChart() {
    if (!this.radarChartCanvas) { return; }
    const canvas = this.radarChartCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    const chartDataList = [
      this.report.cervicalResult,
      this.report.motorControlResult,
      this.report.pelvisLegResult,
      this.report.coreResult,
      this.report.thoracicResult,
      this.report.shoulderResult
    ];

    const radarData: ChartData = {
      labels: ['A', 'B', 'C', 'D', 'E', 'F'],
      datasets: [{
        label: '',
        data: chartDataList.map(val => val.percent),
        backgroundColor: 'rgba(238, 241, 243, 0.6)',
        borderColor: '#8E9C9F',
        borderWidth: 1,
        datalabels: {
          labels: {
            title: {
              display: false,
            },
          }
        }
      }]
    };

    const radarOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        r: {
          min: 0,
          max: 100
        }
      },
      plugins: {
        title: { display: false },
        legend: { display: false },
      }
    };

    if (this.radarChart) this.radarChart.destroy();
    this.radarChart = new Chart(ctx, {
      type: 'radar',
      data: radarData,
      options: radarOptions
    });
  }
}
