<ion-label #label class="floating" [ngStyle]="{display: position ? 'block' : 'none'}" [ngClass]="{'disabled-value': isDisabledValue !== undefined && isDisabledValue}">{{title}}</ion-label>
<div #container class="container" [ngClass]="{'disabled-value': isDisabledValue !== undefined && isDisabledValue}">
  <ion-input #input [(ngModel)]="value" [type]="type" [inputmode]="inputmode" [name]="formControlName" [placeholder]="placeholder" [disabled]="disabled" [mask]="mask" (ionFocus)="inputFocus($event)"
    (ionBlur)="inputBlur($event)"></ion-input>
  <ion-text *ngIf="unitLabel" style="padding: 0 4px;">{{unitLabel}}</ion-text>
  <ion-text class="label" *ngIf="hasLabel">
    {{hasLabel}}</ion-text>
  <ion-button *ngIf="hasClose" [ngStyle]="{'opacity': isFocused ? 1 : 0}" tabindex="-1" fill="clear" class="input-button" [disabled]="disabled" (click)="clear()">
    <ion-icon name="close-outline"></ion-icon>
  </ion-button>
</div>
