export class Execise {
  key?: string; // test data를 위해 옵션처리
  name: string;
  startDate?: number;
  endDate?: number;
  chargeCount?: number;
  usedCount?: number;
}

export class TestViewItem {
  url: string;
  name: string;
  desc: string;
  pageName: string;
  imageSrc?: string;
}

export class BeforeItem {
  question: string;
  label?: string;
  rowLength?: number;
  optionNoNumber?: boolean;
  options?: Array<{ key: string, value?: number }>;
  memoLines?: number;
  memoPlaceholder?: string;
  memoMaxLength?: number;
  memoAbleOptions?: string;
  multiResponse?: boolean;        // default: false
  noMendatory?: boolean;          // default: false
  input?: {
    answers: Array<{ key: string, value?: any, destination?: any }>;
    memo: string;
  }

  constructor(params) {
    const { question, label, rowLength, optionNoNumber, options, memoLines, memoPlaceholder, memoMaxLength, memoAbleOptions, multiResponse, noMendatory, input } = params;

    this.question = question;
    this.label = label || '';
    this.rowLength = rowLength || 3;
    this.optionNoNumber = optionNoNumber || false;
    this.options = options || [];
    this.memoLines = memoLines;
    this.memoPlaceholder = memoPlaceholder || '';
    this.memoMaxLength = memoMaxLength;
    this.memoAbleOptions = memoAbleOptions;
    this.multiResponse = multiResponse || false;
    this.noMendatory = noMendatory || false;
    this.input = input || { answers: [], memo: '' };
  }
}

export class Before4Definition {
  name: string;
  description: string;
}

export class Before6Item {
  fvc: number;
  fev: number;
  fef: number;

  constructor(params) {
    const { fvc, fev, fef } = params;

    this.fvc = fvc || undefined;
    this.fev = fev || undefined;
    this.fef = fef || undefined;
  }
}

export interface TmtReport {
  memo?: string
  VO2Max: number
  name: string
  age: number
  gender: Gender
  weight: number
  height: number
  seconds: number

  percent: number
  avrList: Array<{ percent: number, VO2Max: number, pace: number, time?: number, distance?: number }>
  mets: number
  pace: number
  rate: string
  rank: number
  cardioAge: number
  avrVO2Max: number
  avrPace: number
  avrTime?: number
  avrMets: number
  controlPace: string
  controlPercent: string
  controlMets: string
  controlVO2: string

  fatBurnSpeed: number
  cardioTrainningSpeed: number
  hiitSpeed: number
  outerGoals: Array<number>
  patientsSafeZones: Array<string>
  recoverySpeed: number
  intervalHighSpeed: number
  intervalHighSpeedKcal: number
  intervalLowSpeed: number
  intervalLowSpeedKcal: number
  rep400: number
  rep500: number
  intermittentFlatSpeed: number
  intermittentSlopeSpeed: number
  intermittentKcal: number
}

export class TmtItem {
  distance?: number;
  minute?: number;
  second?: number;
  VO2Max?: number;
  memo: string;

  constructor(params) {
    const { distance, minute, second, memo } = params;

    this.distance = distance || undefined;
    this.minute = minute || undefined;
    this.second = second || undefined;
    this.memo = memo || '';
  }
}
export class Tmt9Item {
  watt: number;
  hr: number;

  constructor(params) {
    const { watt, hr } = params;

    this.watt = watt || undefined;
    this.hr = hr || undefined;
  }
}

export class SameAgeItem {
  title: string;
  subtitle?: string;
  healthLife?: number;
  rank?: number;
  date: Date;
}

export type SmtHowMeasureType = 'Bench Press' | 'Shoulder Press' | 'Pull Down' | 'Seated Row' | 'Leg Press' | 'Squat' | 'Dead Lift' | 'Power Clean';

export class SmtHowMeasure {
  name: SmtHowMeasureType;
  url: Array<string>;
  isSelected: boolean;
  isMyopachynsis: boolean;
  isMuscularStrength: boolean;
}

export class Smt1RMMeasure {
  name: string;
  url: Array<string>;
  isSelected: boolean;
  last1RM: number;
  maxReps?: number;
}

export enum StrengthProgram {
  first = 0,
  second = 1
}

export class Smt5Program {
  kg: number;
  reps: number;
  idx?: StrengthProgram;
}

export class MobileSmtLastRecord {
  type: 'smt3' | 'smt4' | 'smt5' | undefined;
  subType: SmtHowMeasureType;
  oneRmRecord?: any;
  optimiseRecord?: any;
}

export const RESTING = 'Resting';
export const INTERVAL = 'interval';

export class LactateItem {
  testName?: string | number;
  speed?: number;
  lactate?: number;
  second?: number;
  heartRate?: number;
  text?: string;
  order?: number;

  constructor() { }
}

export class LactateTemplate {
  kmph: number;
  spkm: string;
  sp100m: string;
  hr: number | string;

  constructor(_kmph?: number, _spkm?: string, _sp100m?: string, _hr?: number | string,) {
    this.kmph = _kmph || 0;
    this.spkm = _spkm || '';
    this.sp100m = _sp100m || '';
    this.hr = _hr || 0;
  }
}

export class PointPosition {
  y: number;
  x: number;

  constructor(params) {
    const { y, x } = params;

    this.y = y || undefined;
    this.x = x || undefined;
  }
}

export type ChartBarData = {
  main: number,
  sub?: number,
  label?: string | string[],
  mainColor?: string,
  subColor?: string,
  customTooltip?: boolean,
  tooltipValue: number
};
