export class BookingRecord {
  recordDate: number;

  uid: string;
  userName: string;
  userProfile: string;
  gender: number;
  birthDay: number;

  tid: string;
  trainerName: string;

  bookingState: string;     // reserve, canceled, confirmed, complete
  bookingSubState?: string;  // autoCon, trReg, trCon
  exerciseType: string;     // PT, GX, checkup
  exerciseSubType?: string;  // 필라테스 등등
  bookingDate: number;
  bookingDateNew?: number;
  reserveCount?: number;
  reserveOrder?: number;
  maxNoShow?: number;
  curNoShow?: number;
}

export enum ScState {
  Adding = -1,                // 임시 상태임. 생성 중일때 화면처리를 위해 일시적으로 부여. backend에서는 사용할 일이 없음.
  Close = 0,
  Open = 1,
  AutoConfirm = 2,            /* 자동확정 */
  Delete = 3,                 /* 예약, 메모삭제 */
  Complete = 4,               /* 수업완료 */
  ReservationRequest = 5,     /* 예약신청 */  /* PT, POINT의 경우 회원의 신청. GX의 경우 수업정원 초과시 신청으로 대기상태. 즉, not confirmed */
  Confirm = 6,                /* 예약등록 */  /* PT, POINT의 경우 5번 상태에서 트레이너 확정. GX의 경우 수업정원 미달시 자동 확정. 즉, confirmed */
  ReservationByTrainer = 7,   /* 예약등록 */  /* PT, POINT의 경우 트레이너에 의해 직접 입력. GX의 경우 트레이너의 의한 입력 */
  Reject = 8,                 /* 예약반려 */
  CancelByUser = 9,           /* 예약취소 */
  CancelByTrainer = 10,       /* 예약취소 */
  RequestChange = 11,         /* 변경요청 */  /* 회원이 시간대 변경을 요청 */
  ConfirmChange = 12,         /* 변경승인 */  /* 회원의 예약 변경 신청을 승인 */
  RejectChange = 13,          /* 변경반려 */  /* 회원의 예약 변경 신청을 반려 */
  RegisterVirtual = 14,       /* 가상예약 */
  RegisterConfirm = 15,       /* 가상예약확정 */
  Cancel = 16,                /* 수업취소 */
  NoShowByUser = 17,          /* 노쇼 */
  NoShowByTrainer = 18,       /* 트레이너 노쇼 */
  NoShowReset = 19,           /* 노쇼 재설정 */
  Memo = 20,                  /* 메모 */
  CancelRequest = 21,         /* 신청취소 */   /* 회원의 예약 신청 취소 */
}

export class BookingState {
  /**
   * -1, 0, 1, 2, 4, 6, 7, 12, 15, 17, 18, 19, 20
   */
  static readonly ShowMemo: Array<number> = [
    ScState.Adding,
    ScState.Close,
    ScState.Open,
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.ConfirmChange,
    ScState.RegisterVirtual,
    ScState.RegisterConfirm,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
    ScState.Memo,
  ];

  /**
   * 0, 1
   */
  static readonly OpenClose: Array<number> = [
    ScState.Close,
    ScState.Open,
  ];
  /**
   *  2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
   */
  static readonly ShowOnSearched: Array<number> = [
    ScState.AutoConfirm,
    ScState.Delete,
    ScState.Complete,
    ScState.ReservationRequest,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.Reject,
    ScState.CancelByUser,
    ScState.CancelByTrainer,
    ScState.RequestChange,
    ScState.ConfirmChange,
    ScState.RejectChange,
    ScState.RegisterVirtual,
    ScState.RegisterConfirm,
    ScState.Cancel,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
    ScState.Memo,
    ScState.CancelRequest,
  ];

  /**
   * 2, 4, 5, 6, 7, 8, 11, 12, 13, 14, 15, 16, 20
   */
  static readonly ShowOnScheduler: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.ReservationRequest,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.Reject,
    ScState.RequestChange,
    ScState.ConfirmChange,
    ScState.RejectChange,
    ScState.RegisterVirtual,
    ScState.RegisterConfirm,
    ScState.Cancel,
    ScState.Memo,
  ];
  /**
   * 2, 3, 4, 5, 6, 7, 11, 12, 13, 15, 16, 17, 18, 19
   */
  static readonly ShowOnHistory: Array<number> = [
    ScState.AutoConfirm,
    ScState.Delete,
    ScState.Complete,
    ScState.ReservationRequest,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.RequestChange,
    ScState.ConfirmChange,
    ScState.RejectChange,
    ScState.RegisterConfirm,
    ScState.Cancel,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
  ];
  /**
   * 2, 4, 5, 6, 7, 12, 15, 17, 18, 19 동일 시간대에 취소 스케줄이 있으면 빨강 표기
   */
  static readonly RedWithCancel: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.ReservationRequest,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.ConfirmChange,
    ScState.RegisterConfirm,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
  ];
  /**
   * 2, 4, 6, 7, 11, 12, 13, 15
   */
  static readonly Reserved: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.RequestChange,
    ScState.ConfirmChange,
    ScState.RejectChange,
    ScState.RegisterConfirm,
  ];
  /**
   * 2, 4, 6, 7, 12, 15 모든 sType에 사용한다.
   */
  static readonly Confirm: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.ConfirmChange,
    ScState.RegisterConfirm,
  ];

  /**
   * 2, 6, 7, 12, 15 모든 sType에 사용한다.
   */
  static readonly ConfirmOnly: Array<number> = [
    ScState.AutoConfirm,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.ConfirmChange,
    ScState.RegisterConfirm,
  ];

  /**
   * 2, 4, 6, 7, 15, 17, 18, 19
   */
  static readonly ShowOnRecent: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.RegisterConfirm,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
  ];
  /**
   * 2, 4, 6, 7, 8, 9, 11, 12, 15, 21
   */
  static readonly TrainerCheck: Array<number> = [
    ScState.AutoConfirm,
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.Reject,
    ScState.CancelByUser,
    ScState.RequestChange,
    ScState.ConfirmChange,
    ScState.RegisterConfirm,
    ScState.CancelRequest,
  ];
  /**
   * 2, 8, 10, 16
   */
  static readonly UserCheck: Array<number> = [
    ScState.AutoConfirm,
    ScState.Reject,
    ScState.CancelByTrainer,
    ScState.Cancel
    // ScState.RejectChange,
  ];
  /**
   * 3, 8, 9, 10, 13, 16, 17, 18, 19, 21
   */
  static readonly Red: Array<number> = [
    ScState.Delete,
    ScState.Reject,
    ScState.CancelByUser,
    ScState.CancelByTrainer,
    ScState.RejectChange,
    ScState.Cancel,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
    ScState.CancelRequest,
  ];
  /**
   * 3, 10, 18
   */
  static readonly BundleCancel: Array<number> = [
    ScState.Delete,
    ScState.CancelByTrainer,
    ScState.NoShowByTrainer,
  ];
  /**
   * 4
   */
  static readonly Complete: Array<number> = [
    ScState.Complete,
  ];
  /**
   * 4, 6, 7
   */
  static readonly GxConfirm: Array<number> = [
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
  ];
  /**
   * 4, 6, 7, 15
   */
  static readonly NoTag: Array<number> = [
    ScState.Complete,
    ScState.Confirm,
    ScState.ReservationByTrainer,
    ScState.RegisterConfirm,
  ];
  /**
   * 4, 17, 18, 19
   */
  static readonly PointConsumed: Array<number> = [
    ScState.Complete,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
  ];
  /**
   * 5, 11
   */
  static readonly Wait: Array<number> = [
    ScState.ReservationRequest,
    ScState.RequestChange,
  ];
  /**
   * 8, 9, 10, 13, 16, 17, 18, 19, 21
   */
  static readonly Invalid: Array<number> = [
    ScState.Reject,
    ScState.CancelByUser,
    ScState.CancelByTrainer,
    ScState.RejectChange,
    ScState.Cancel,
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
    ScState.CancelRequest,
  ];
  /**
   * 9, 21
   */
  static readonly HideTrainerCheck: Array<number> = [
    ScState.CancelByUser,
    ScState.CancelRequest,
  ];
  /**
   * 8, 10, 13, 16
   * RejectChange의 경우 uCheck가 있더라도 보여야 함
   * 나중에 변경 스케줄은 원래 스케줄과 분리된다면 RejectChange가 포함되어야 함
   */
  static readonly HideUserCheck: Array<number> = [
    ScState.Reject,
    ScState.CancelByTrainer,
    ScState.RejectChange,
    ScState.Cancel,
  ];
  /**
   * 9, 10, 16, 21
   */
  static readonly Cancel: Array<number> = [
    ScState.CancelByUser,
    ScState.CancelByTrainer,
    ScState.Cancel,
    ScState.CancelRequest,
  ];
  /**
   * 11
   */
  static readonly RequestChange: Array<number> = [
    ScState.RequestChange,
  ];
  /**
   * 14
   */
  static readonly VirtualStates: Array<number> = [
    ScState.RegisterVirtual,
  ];
  /**
   * 17, 18, 19
   */
  static readonly NoShow: Array<number> = [
    ScState.NoShowByUser,
    ScState.NoShowByTrainer,
    ScState.NoShowReset,
  ];

  static isValid(sState: number): boolean {
    return !!sState && sState >= 2 && sState <= 21;
  }

  static getClassCountCondition(scheduleAlias?: string): string {
    const alias = scheduleAlias || 's';
    return `(${alias}.SCHEDULE_State IN (${this.Reserved.join(',')}) OR (${alias}.SCHEDULE_State IN (${this.NoShow.join(',')}) AND ${alias}.SCHEDULE_NoShowSession = 1))`;
  }
}
