import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-infomation',
  templateUrl: './ft-infomation.html',
  styleUrls: ['./ft-infomation.scss'],
})
export class FtInfomationModal extends BaseComponent implements OnInit {
  @Input() title: string;
  @Input() infomationItems: Array<{ title: string, content?: Array<string> }>;
  @Input() infoTitleFontBold?: boolean = false;
  constructor(
    private modalCtrl: ModalController
  ) {
    super()
  }

  ngOnInit() {

  }

  close() {
    this.modalCtrl.dismiss();
  }
}
