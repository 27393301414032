<ft-flex-row borderBottom="none">
  <ion-label left> {{'기간'|translate}} </ion-label>
  <ion-grid right>
    <ion-row>
      <ion-col size="6" class="no-padding-vertical">
        <ion-select hasFocusClass placeholder="{{'모달.제한없음'|translate}}" [(ngModel)]="months" (ionChange)="onMonthsChange()"
          class="input-container" style="max-width: 100%;">
          <ion-select-option [value]="0">{{'모달.제한없음'|translate}}</ion-select-option>
          <ion-select-option *ngFor="let period of periodList" [value]="period">
            {{period.pad(2)}}{{'일반.개월'|translate}}</ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="6"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="no-padding-vertical">
        <ion-label class="floating-label"> {{'모달.시작일'|translate}} </ion-label>
        <ion-input class="input-container" [value]="startDate" disabled>
        </ion-input>
      </ion-col>
      <ion-col size="6" class="no-padding-vertical">
        <ion-label class="floating-label" disabled> {{'모달.종료일'|translate}} </ion-label>
        <ion-input class="input-container" placeholder="{{'모달.제한없음'|translate}}" [value]="getEndDate()" disabled></ion-input>
      </ion-col>
    </ion-row>
  </ion-grid>
</ft-flex-row>
