import { NgModule } from '@angular/core';
import { BasicModule } from 'src/app/basic.module';
import { FmesSettingsMainModal } from './fmes-settings-main';
// import { SettingsSecurityModalModule } from '../settings-security/settings-security.module';



@NgModule({
  declarations: [
    FmesSettingsMainModal,
  ],
  imports: [
    BasicModule,
    // SettingsSecurityModalModule,    
  ],
  entryComponents: [
    FmesSettingsMainModal
  ],
})
export class FmesSettingsMainModalModule { }
