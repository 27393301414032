import { FittUtilService } from '@fitt-lib/services/fitt-util.service';
import { IsDebug } from '@ionic-native/is-debug/ngx';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared-lib';
import { LocalNotifications as NativeLocalNotifications } from '@ionic-native/local-notifications/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { SelectNoShowCompModule } from './modals/select-no-show/select-no-show.module';
import { AddPaymentInfoModule } from './modals/add-payment-info/add-payment-info.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SelectNoShowCompModule,
    AddPaymentInfoModule,
  ],
  providers: [
    IsDebug,
    NativeLocalNotifications,
    SignInWithApple,
    FittUtilService,
  ],
})
export class FittLibModule {
  public static forRoot({ environment, packageJson }): ModuleWithProviders<FittLibModule> {
    return {
      ngModule: FittLibModule,
      providers: [
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment
        },
        {
          provide: 'packageJson', // you can also use InjectionToken
          useValue: packageJson
        },
      ]
    };
  }
}
