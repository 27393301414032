import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'menu-pop-over',
  templateUrl: './menu-pop-over.html',
  styleUrls: ['./menu-pop-over.scss'],
})
export class MenuPopOver implements OnInit {

  buttons: Array<{ name: string, text?: string, icon?: { name: string, color: string }, img?: string, handler?: Function }> = [];
  constructor(
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
  ) {
  }
  ngOnInit() {
    this.buttons = this.navParams.get('buttons');
    // console.log('MenuPopOver buttons', this.buttons);
  }

  clickMenu(button) {
    // console.log('clickMenu name=' + button.name);
    if (button.handler) button.handler();
    this.popoverCtrl.dismiss(button);
  }

}
