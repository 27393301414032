import { Component, OnInit, ViewChild } from '@angular/core';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { ModalController, NavParams, IonVirtualScroll, IonContent, Platform } from '@ionic/angular';
import { FittMember, GymUser, User } from '@fitt-lib/models/fitt-models';
import { MobileFmtResultModal } from '@fitt-lib/modals/mobile-fmt-result/mobile-fmt-result';


type TestSuperType = 'tmt' | 'smt' | 'fmt';
type TestSubType = 'all' | 'tmt3' | 'tmt4' | 'tmt5' | 'tmt6' | 'tmt7' | 'smt2' | 'smt3' | 'smt4' | 'smt5' | 'fmt2';

@Component({
  selector: 'mobile-exercise-test-history',
  templateUrl: './mobile-exercise-test-history.html',
  styleUrls: ['./mobile-exercise-test-history.scss'],
})
export class MobileExerciseTestHistoryModal extends FmesBaseComponent implements OnInit {
  @ViewChild('virtualScroll') virtualScroll: IonVirtualScroll;
  @ViewChild('ionContent') ionContent: IonContent;

  mode: 'md' | 'ios';
  loadState: LoadState = 'none';
  isEditable: boolean = false;

  myInfo: FittMember;
  gymUser: GymUser;
  users: Array<User>;
  user: User;

  itemsIndex: number;
  items: Array<any>;
  orgItems: Array<any>;
  isMoreItems: boolean = true;

  allRecords: Array<any>;
  tmtRecords: Array<any>;
  smtRecords: Array<any>;
  fmtRecords: Array<any>;

  testSuperType: TestSuperType = 'fmt';
  testSubType: TestSubType = 'all';

  readonly SUBTYPES: {
    fmt: Array<{ value: TestSubType, title: string }>
  } = {
      fmt: [
        { value: 'fmt2', title: this.translate.instant('건강.움직임능력평가') }
      ]
    };
  subtypes: Array<{ value: TestSubType, title: string }> = this.SUBTYPES[this.testSuperType];

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private platform: Platform,
  ) {
    super();
  }

  async ngOnInit() {
    this.loadState = 'loading';

    this.gymUser = this.navParams.get('gymUser');
    this.myInfo = await this.gApi.auth.getMyInfo();

    const uSeq = this.navParams.get('uSeq');
    this.users = await this.gApi.auth.getMyUserData({ uSeq: this.myInfo.getUserSeq() });
    this.user = this.users.find(u => u.uSeq === uSeq);

    this.mode = !this.util.isMobileApp() || this.platform.is('android') ? 'md' : 'ios';
    const res = await this.gApi.exerciseApi.migrateFromFitt3({ uSeq: uSeq });
    this.log('ngOnInit migration', res);

    this.isEditable = this.navParams.get('isEditable');
    // this.log('ngOnInit gymUser', this.gymUser);
    await this.initExerciseRecords();
    this.doInfinite(null, 'init');
  }

  async initExerciseRecords() {
    this.loadState = 'loading';

    let records: Array<any>;
    if (this.gymUser) {
      records = await this.gApi.getRecord({
        table: 'TEXERCISE_TEST',
        fields: ['EXERCISE_TEST_Seq, GYM_Seq', 'USER_Seq', 'TRAINER_Name', 'EXERCISE_TEST_Order', 'EXERCISE_TEST_Type', 'EXERCISE_TEST_Title',
          `DATE_FORMAT(EXERCISE_TEST_InDate, '%Y-%m-%dT%H:%i:%s') as EXERCISE_TEST_InDate`, 'EXERCISE_TEST_Device', 'EXERCISE_TEST_Data',
          'USER_Height', 'USER_Weight'],
        where: {
          USER_Seq: this.gymUser.user.uSeq,
          EXERCISE_TEST_Type: 'fmt2',
          EXERCISE_TEST_Visible: 1
        },
        orderBy: 'EXERCISE_TEST_InDate DESC'
      });
    }
    this.allRecords = records;

    this.fmtRecords = records.filter(record =>
      record.EXERCISE_TEST_Type.match('fmt') !== null
    );
    this.log('initExerciseRecords', this.allRecords);
  }

  async doInfinite(event, type?) {
    if (type === 'init') {
      await this.setOrgItems();
      this.itemsIndex = 0;
      this.items = [];
      this.isMoreItems = true;
    }
    if (!this.isMoreItems) {
      return;
    }

    const displaySize = 15;
    for (let i = 0; i < displaySize; i++) {
      if (this.orgItems[this.itemsIndex + i] !== undefined) {
        this.items.push(this.orgItems[this.itemsIndex + i]);
      } else {
        break;
      }
    }

    if (this.items.length === this.orgItems.length) {
      this.isMoreItems = false;
    }

    this.virtualScroll && this.virtualScroll.checkRange(this.itemsIndex);
    this.itemsIndex = this.items.length;

    event && event.target.complete();
    this.loadState = 'loaded';
    // this.log('doInfinite');
  }

  setOrgItems() {
    let orgItems: Array<any>;
    switch (this.testSuperType) {
      case 'fmt':
        orgItems = this.fmtRecords;
    }
    if (this.testSubType !== 'all') {
      orgItems = orgItems.filter(record => record.EXERCISE_TEST_Type === this.testSubType);
    }
    orgItems = orgItems.filter(record => record.EXERCISE_TEST_Order !== 'null');
    this.orgItems = orgItems;
  }




  async showExerciseTestResult(record: any) {
    const componentProps: any = {
      record: record,
      isModifiable: true,
    };
    let component;

    if (this.gymUser) {
      componentProps.isFixedData = true;
      componentProps.isTrainer = true;
    }

    switch (this.testSuperType) {
      case 'fmt': {
        component = MobileFmtResultModal;
        componentProps.isEditable = this.isEditable;
        break;
      }
    }

    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: componentProps
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    if (!result.data) return;
    if (result.data.remove) {
      const index = this.orgItems.findIndex(item => item.EXERCISE_TEST_Seq == result.data.exerciseTestSeq);
      this.orgItems.splice(index, 1);
      await this.initExerciseRecords();
      this.doInfinite(null, 'init');
    }
  }


  async close() {
    await this.modalCtrl.dismiss();
  }
}
