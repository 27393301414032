import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class FmesUtilService extends FmesBaseComponent {
  authState: boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    super({ enableLog: true });
    this.afAuth.auth.onAuthStateChanged(user => {
      this.authState = !!user;
    });
  }
  isAuth(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.authState === undefined) {
        const unsubscribe = this.afAuth.auth.onAuthStateChanged(user => {
          unsubscribe();
          this.authState = !!user;
          resolve(this.authState);
        });
      }
      else {
        resolve(this.authState);
      }
    });
  }

  async setVirtualGym() {
    // 1. Fmes Virtual Gym이 있는지 확인.
    const myInfo = await this.gApi.auth.getMyInfo();

    if (myInfo.gyms.length === 0) {
      await this.gApi.fmes.setFmesVirtualGym({
        mEmail: myInfo.member.mEmail,
        mSeq: myInfo.member.mSeq
      });
      return true;
    } else {
      return false;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async resolve => {
      this.log('canActivate', { isMobile: this.util.isMobileApp(), authState: this.authState, url: state.url, extras: this.router.getCurrentNavigation().extras });
      if (!(await this.isAuth())) {
        this.log('canActivate false isAuth');
        this.util.isDebugMode() && await this.util.asyncWait(3000);

        this.router.navigate(['/login']);
        resolve(false);
        return;
      }

      resolve(true);
    });
  }
}


