export const environment = {
  forPublic: false,
  production: false,
  platform: 'web',
  project: 'fitt-fmes',
  mobile: false,
  remoteServerUrl: 'http://localhost:5001/fitt-4-dev/asia-east2',

  // mobile: true,
  // remoteServerUrl: 'https://asia-east2-fitt-4-dev.cloudfunctions.net',

  // mobile: false,
  // remoteServerUrl: 'http://172.30.1.179:5001/fitt-4-dev/asia-east2',

  // mobile: false,
  // remoteServerUrl: 'http://localhost:5001/fitt-4---pbe/asia-east2',

  // mobile: false,
  // remoteServerUrl: 'http://172.30.1.179:5001/fitt-4-dev/asia-east2',

  firebase: {
    apiKey: 'AIzaSyB6rXuJBBC9n730HAdGV0x560XgFiFpTRI',
    authDomain: 'fitt-4-dev.firebaseapp.com',
    databaseURL: 'https://fitt-4-dev.firebaseio.com',
    resetPasswordURL: 'https://fitt-4-dev.firebaseapp.com',
    projectId: 'fitt-4-dev',
    storageBucket: 'fitt-4-dev.appspot.com',
    messagingSenderId: '480584180687'
  },
  openApi: {
    host: 'http://localhost:3000',
    apiKey: 'fittadmin'
  },
  socialLogin: {
    googleCLientId: '480584180687-1v8jbne2u8365bctr9uq44no2q7hb8rn.apps.googleusercontent.com',
    googleCLientKey: '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',
    naverClientId: 'sP3K0KOMiJL0HaWNhD1A',
    naverClientSecretKey: 'yvzmGWkxAL',
    naverClientAppName: 'FITT',
    naverClientIOSUrlScheme: 'fittapp',
    kakaoClientId: 'd2a4c0bc6ad8834ad987ef1a15eeb8ba',
    webLoginCallbackUrl: 'http://localhost:4200/logincallback',
  },
  payment: {
    paypalUrl: 'https://api-m.sandbox.paypal.com',
    paypalClientId: 'AUy-CcHpIKvJmAPSBHKboH8uDJi1bA8JrXgR9hChTxiBOQ3yQoXCLv-BY3tAdTMSBTBYjyMPeOzM5100',
    paypalSecret: 'EEEPgNkHBCOIHOrY6Zz1aeGLpOgDjnZNNSoOM4JaqibJq7UmWftHDdSL4b91X7PAzvIoJRHuNCxmiKkJ',
    // paypalProductionUrl: 'https://api-m.paypal.com',
    // paypalProductionClientId: 'AddQDMy3YrDR3glOZ975Kjrrtj54T5nK7H1jYQsLuJL7oGkwA3K5tT7R19mN07ojXsteX5m_jWZ_nrRb',
    // paypalProductionSecret: 'EHOQg5D3cdOUBiFSdD5haDGSGRVnnUdfGN-bkQIYyLptAuwKcpW0x4xRlpAAXn-sTrb69VF8G6i2WyWC',
    nicepayPAMID: 'sj7102413m', // 지급대행
    nicepayPAKey: 'U4QjCL/3Lxu3gVbCmpw+mxUhs3uDYa0QKGQtjvc7IRMFdwsvPiqwkOCO8igFN2xMGuFrorkwlcJnzosmECiJqw==',
    nicepayGeneralMID: 'sj7102415m', // 간편/일반 결제
    nicepayGeneralKey: '8oYjDcNWw/ABQGP585sGxEU1xorA3DgJKg+UVvm+Ab37IUd4s4ArwpQUWPfxC6lojSCCRFTGzySxjcN4ovvUuw==',
  }
};
