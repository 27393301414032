import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

// mobile 전용 서비스
@Injectable({
  providedIn: 'root'
})
export class TransLocService {
  static currentLan: string = 'en';
  Math = Math;
  jsonUrl: string;
  constructor(
    @Inject('jsonUrl') jsonUrl: string,
    public translate: TranslateService,
    private http: HttpClient,
  ) {
    // console.log('TransLocService.constructor', jsonUrl);
    this.jsonUrl = jsonUrl;
  }

  notificationJson = {};
  getTranslateFromNotification(key: string, params?: Array<string>): Promise<string> {
    return new Promise((resolve) => {
      const translateFunc = (json) => {
        let message = json.app[key];
        if (params) {
          for (let i = 0; i < params.length; i++) {
            message = message.replace(`%${i + 1}$@`, params[i]);
          }
        }
        resolve(message);
      }

      if (!this.notificationJson[this.translate.currentLang]) {
        // import(`${this.jsonUrl}/${this.translate.currentLang}.json`).then(value => {
        //   this.notificationJson[this.translate.currentLang] = value.default;
        //   translateFunc(this.notificationJson[this.translate.currentLang]);
        // });
        this.http.get(`${this.jsonUrl}/${this.translate.currentLang}.json`).subscribe((json) => {
          this.notificationJson[this.translate.currentLang] = json;
          translateFunc(this.notificationJson[this.translate.currentLang]);
        });
      }
      else {
        translateFunc(this.notificationJson[this.translate.currentLang]);
      }
    });
  }
}
