
import { Pipe, PipeTransform, ChangeDetectorRef } from "@angular/core";
import { TransLocService } from "@shared-lib/services/trans-loc.service";

// TranslateService github 소스 참고하여 작성
@Pipe({
  name: 'transloc',
  pure: false
})
export class TranslationLocPipe implements PipeTransform {
  value: string = '';
  lastKey: string;
  lastParams: Array<string>;
  constructor(
    private ref: ChangeDetectorRef,
    private transLocSvc: TransLocService,
  ) {
    // console.log('TranslationLocPipe.constructor');
  }
  transform(args: { key: string, params?: Array<string> }) {
    // console.log('TranslationLocPipe.transform', args);
    if (!args) return;
    const { key, params } = args;
    if (!key || !key.length) {
      return key;
    }

    if (key == this.lastKey && JSON.stringify(params) == JSON.stringify(this.lastParams)) {
      return this.value;
    }

    this.lastKey = key;
    this.lastParams = params;
    (async () => {
      this.value = await this.transLocSvc.getTranslateFromNotification(key, params);
      this.ref.markForCheck();
    })();
    return this.value;
  }
}
