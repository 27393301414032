<div class="ion-padding">
  <ion-title style="color: var(--ft-color-black1); font-size: 19px;font-weight:bold;padding-left:0px !important">
    {{
    noshowType === 4 ? ('스케줄.노쇼 재설정'|translate) : ('스케줄.노쇼'|translate)
    }}
  </ion-title>
  <div style="margin-top:12px;color:var(--ft-color-black1);font-size:15px;white-space: pre-line;">
    {{('스케줄.노쇼 팝업 type'+noshowType)|translate}}
  </div>

  <ng-container *ngIf="isPointSchedule(); else elseTemplate">
    <div class="ion-margin-top">
      <span style="font-size: 15px">{{'스케줄.차감 금액'|translate}}</span>
      <ion-input type="number" [placeholder]="'급여정산.금액 입력'|translate" [(ngModel)]="noShowValue" class="input-container">
      </ion-input>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <div class="ion-margin-top">
      <span style="font-size: 15px">{{'스케줄.차감 세션'|translate}}</span>
      <ion-select id="dropdownButton" #noshowSelect interface="popover" [(ngModel)]="noShowValue"
        style="border-radius: 4px;border: solid 1px #b0bac5;margin-right: 10px; width: 100%; height: 40px;font-size: 14px">
        <ion-select-option *ngFor="let item of options" [value]="item.value">{{item.text}}</ion-select-option>
      </ion-select>
    </div>
  </ng-template>

  <div style="margin-top:30px;">
    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button id="cancelButton" fill="outline" style="width: 95px; height: 45px; --border-radius: 4px !important;" color="main" (click)="cancel()">
            {{'취소'|translate}}
          </ion-button>
          <ion-button id="confirmButton" fill="solid" style="width: 95px; height: 45px; --border-radius: 4px !important;" color="primary" (click)="save()"> {{'확인'|translate}} </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </div>
</div>
