import { Component, OnInit, Input, ChangeDetectorRef, ElementRef, Output, EventEmitter, Renderer2, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { StringUnion } from '@shared-lib/utils/string-union';
import { FadeOut } from '@shared-lib/animations/fade-out';

const UPLOAD_CATEGORY_TYPE = StringUnion('passbook', 'license', 'inBody', 'profile', 'logo');
export type UploadCategoryType = typeof UPLOAD_CATEGORY_TYPE.type;

export class Image {
  name: string;
  data?: string;
  path?: string;  // image upload시 사용하는 path
  url?: string;   // image upload후 획득한 url
  thiefColor?: string;
  exifData?: any;
}

@Component({
  selector: 'image-uploader',
  templateUrl: './image-uploader.html',
  styleUrls: ['./image-uploader.scss'],
  animations: FadeOut,
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploaderComponent extends BaseComponent implements OnInit {
  @Input() set image(value) {
    this._image = value;
  }
  @Input() type: UploadCategoryType;

  _image: Image;

  @Input() circle: boolean = false;
  @Input() hasClose: boolean = false;
  @Input() imageContain: boolean = false;

  @Output() remove = new EventEmitter();
  @Output() complete = new EventEmitter();

  percent: number;
  showProgress: boolean;
  uploadPercent$: Observable<number>;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private cdr: ChangeDetectorRef,
    private afStorage: AngularFireStorage,
  ) {
    super({ enableLog: true });
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.log('ngOnChanges', changes);
  }

  ngOnInit() {
    this.log('ngOnInit', { image: this._image, circle: this.circle, hasClose: this.hasClose });
    if (this.circle) {
      this.renderer.addClass(this.element.nativeElement, 'circle');
    }
  }

  ngAfterViewInit() {
    if (this._image) {
      setTimeout(() => this.uploadImage(), 500);
    }
  }

  async uploadImage() {
    this.log('uploadImage', this._image);
    if (this._image.url && !this._image.data) {
      return;
    }

    const path = this._image.path || `/${(() => {
      switch (this.type) {
        case 'passbook': return 'passbook';
        case 'license': return 'license';
        case 'inBody': return 'inBody';
        case 'profile': return 'profile';
        case 'logo': return 'logo';
      }
    })()}/${localStorage.uid}/${this.util.generateUUID()}.jpg`;

    const fileRef = this.afStorage.ref(path);
    const uploadTask = fileRef.putString(this._image.data, 'data_url', { customMetadata: { name: this._image.name, exifData: this._image.exifData } });
    this.uploadPercent$ = await uploadTask.percentageChanges();
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        this.log('uploadTask.finalize');
        fileRef.getDownloadURL().subscribe(url => {
          this._image.url = url;
          this.log('getDownloadURL', url);
        });
      })
    ).subscribe();

    let clearFlag = false;
    this.showProgress = true;
    this.uploadPercent$.subscribe(value => {
      this.log('percent.subscribe', value);
      this.percent = value;
      !this.cdr['destroyed'] && this.cdr.detectChanges();
    });

    const timer = setInterval(() => {
      this.log('percent', this.percent);
      !this.cdr['destroyed'] && this.cdr.detectChanges();
      if (this.percent == 100 && !clearFlag) {
        clearFlag = true;
        setTimeout(() => {
          clearInterval(timer);
          this.showProgress = false;
          !this.cdr['destroyed'] && this.cdr.detectChanges();
          this.complete.emit({ url: this._image.url });
          this.log('complete');
        }, 1000);
      }
    }, 250);

    !this.cdr['destroyed'] && this.cdr.detectChanges();
  }

  async removeImage() {
    this.log('removeImage', this._image);
    if (!this._image) return;

    if (this._image.url) {
      try {
        await this.afStorage.storage.refFromURL(this._image.url).delete();
        this.log('removeImage', this._image.name);
      }
      catch (error) {
        this.warn('removeImage', error);
      }
    }
    this._image = null;
    this.uploadPercent$ = null;
    !this.cdr['destroyed'] && this.cdr.detectChanges();
    this.remove.emit();
  }
}
