import { Component, Input, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { FMTCorrectiveStandard, FMTCorrectiveStatus, FMTCorrectiveExercises } from '@fitt-lib/models/exercise-test/fmt-corrective-exercises';
import { FmtCorrectiveVideoModal } from './fmt-corrective-video/fmt-corrective-video';

@Component({
  selector: 'ft-fmt-corrective',
  templateUrl: './ft-fmt-corrective.html',
  styleUrls: ['./ft-fmt-corrective.scss'],
})
export class FtFmtCorrectiveComponent extends FtLibBaseComponent implements OnChanges {
  @Input() mobility: Array<string>;
  @Input() stability: Array<string>;
  @Input() motorControl: Array<string>;

  readonly standards: Array<FMTCorrectiveStandard> = ['mobility', 'stability', 'motorControl'];
  readonly status: Array<FMTCorrectiveStatus> = ['BAD', 'NORMAL', 'GOOD'];

  guideVideos: Array<{ imgURl: string, url: string, name: string }> = [];
  recommendedVideos: Array<{ title: string, videos: Array<{ imgURl: string, url: string, name: string, desc: string }> }> = [
    { title: this.translate.instant('건강.가동성'), videos: [] },
    { title: this.translate.instant('건강.안정성'), videos: [] },
    { title: this.translate.instant('건강.운동조절'), videos: [] },
  ];
  mobilityVideos: Array<{ imgURl: string, url: string, name: string }> = [];
  stabilityVideos: Array<{ imgURl: string, url: string, name: string }> = [];
  motorControlVideos: Array<{ imgURl: string, url: string, name: string }> = [];

  constructor(
    private modalCtrl: ModalController,
  ) {
    super();
  }

  ngOnChanges() {
    if (this.guideVideos.length === 0) {
      this.getGuideVideos();
    }
    this.setRecommendedVideos();
  }

  getGuideVideos() {
    const res = FMTCorrectiveExercises.getCorrectiveExerciseList(this.standards, this.status);
    const guideVideos = [];
    for (const corrective of res) {
      guideVideos.push({
        imgURl: 'http://i.ytimg.com/vi/' + corrective.link.split('https://youtu.be/')[1] + '/hqdefault.jpg',
        url: corrective.link,
        name: corrective.name,
        desc: corrective.nameKo
      });
    }
    this.guideVideos = guideVideos;
  }

  setRecommendedVideos() {
    const mobilityVideos = [];
    const stabilityVideos = [];
    const motorControlVideos = [];
    for (const name of this.mobility) {
      mobilityVideos.push(this.guideVideos.find(video => video.name === name));
    }
    for (const name of this.stability) {
      stabilityVideos.push(this.guideVideos.find(video => video.name === name));
    }
    for (const name of this.motorControl) {
      motorControlVideos.push(this.guideVideos.find(video => video.name === name));
    }

    this.recommendedVideos[0].videos = mobilityVideos;
    this.recommendedVideos[1].videos = stabilityVideos;
    this.recommendedVideos[2].videos = motorControlVideos;
  }

  async viewDetail(video: { imgURl: string, url: string, name: string }) {
    if (this.util.isMobileApp()) {
      const modal = await this.modalCtrl.create({
        component: FmtCorrectiveVideoModal,
        mode: 'ios',
        showBackdrop: true,
        cssClass: 'swipeable-modal',
        componentProps: { video: video }
      });
      await modal.present();
    } else {
      const modal = await this.modalCtrl.create({
        component: FmtCorrectiveVideoModal,
        componentProps: { video: video }
      });
      await modal.present();
    }
  }
}
