import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { EndEstimatedData, UserPaymentData, UserStatusRecord } from '@fitt-lib/models/admin-models';
import { BookingState, ScState } from '@fitt-lib/models/booking-models';
import { ScheduleEvent } from '@fitt-lib/models/event-models';
import { LactateItem, PointPosition } from '@fitt-lib/models/execise';
import { FittMember, GymInfoRaw, GymSalarySetting, GymUser, Member, MemberRole, Trainer, User } from '@fitt-lib/models/fitt-models';
import { RefundUserPaymentParam, RefundUserPointParam, UpdateUserPointEndDateParam } from '@fitt-lib/models/param-models';
import { UserPoint, UserPointDetail, UserPointLog, UserPointState, USER_POINT_STATE } from '@fitt-lib/models/point-models';
import { ClassCountRecord, DaySchedule, ExTestCountRecord, ExTestQueryMode, ExTestReturnTotalCount, ExTestReturnTypeCount, gymPlanPaymentParam, LessonSchedule, LessonState, PaymentHistory, Schedule, ScheduleClass, ScheduleType } from '@fitt-lib/models/schedule-models';
import { GymSettings } from '@fitt-lib/models/schedule-types';
import { BaseComponent } from '@shared-lib/base.component';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { Subject } from 'rxjs';
import { FittUtilService } from './fitt-util.service';

export type GymPayemntParam = {
  upgrade: boolean, bSeq: string, type: string, packageSeq: string, userCnt: number,
  trainerCnt: number, schedule: string, tmtFlag: number, fmtFlag: number, smtFlag: number, lacticFlag: number,
  couponsSeq: Array<number>, gSeq: number, groupHead: number, isCurrentLangKo: boolean
};

type AddGxArgs = {
  gSeq: number,
  tSeq: number,
  sType: ScheduleType,
  cName: string,
  cNote?: string,
  cColor: string;
  cQuota?: number,
  sMemo?: string,
  startRepeatDate?: string,
  endRepeatDate?: string,
  hours?: Array<{ day: number, startTime: string, endTime: string }>
};
const maleAvrRecord = [{ age: 60, distance: 644 }, { age: 61, distance: 630 }, { age: 62, distance: 616 }, { age: 63, distance: 607 }, { age: 64, distance: 598 }, { age: 65, distance: 589 }, { age: 66, distance: 582 }, { age: 67, distance: 576 }, { age: 68, distance: 573 }, { age: 69, distance: 570 }, { age: 70, distance: 567 }, { age: 71, distance: 563 }, { age: 72, distance: 560 }, { age: 73, distance: 551 }, { age: 74, distance: 540 }, { age: 75, distance: 527 }, { age: 76, distance: 516 }, { age: 77, distance: 508 }, { age: 78, distance: 503 }, { age: 79, distance: 497 }, { age: 80, distance: 492 }, { age: 81, distance: 486 }, { age: 82, distance: 480 }, { age: 83, distance: 471 }, { age: 84, distance: 462 }, { age: 85, distance: 453 }, { age: 86, distance: 444 }, { age: 87, distance: 434 }, { age: 88, distance: 423 }, { age: 89, distance: 414 }, { age: 90, distance: 402 }];
const femaleAvrRecord = [{ age: 60, distance: 566 }, { age: 61, distance: 558 }, { age: 62, distance: 550 }, { age: 63, distance: 542 }, { age: 64, distance: 534 }, { age: 65, distance: 526 }, { age: 66, distance: 521 }, { age: 67, distance: 518 }, { age: 68, distance: 515 }, { age: 69, distance: 512 }, { age: 70, distance: 509 }, { age: 71, distance: 507 }, { age: 72, distance: 505 }, { age: 73, distance: 496 }, { age: 74, distance: 487 }, { age: 75, distance: 468 }, { age: 76, distance: 479 }, { age: 77, distance: 467 }, { age: 78, distance: 453 }, { age: 79, distance: 447 }, { age: 80, distance: 432 }, { age: 81, distance: 412 }, { age: 82, distance: 403 }, { age: 83, distance: 399 }, { age: 84, distance: 396 }, { age: 85, distance: 393 }, { age: 86, distance: 390 }, { age: 87, distance: 388 }, { age: 88, distance: 385 }, { age: 89, distance: 382 }, { age: 90, distance: 379 }];

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseComponent {

  myInfoQuery: boolean = false;
  myInfo: FittMember;
  myInfoSubject: Subject<FittMember>;

  gyms: any = {};
  gymUsers: Array<GymUser>;
  gymTrainers: Array<Trainer>;

  lessons: Array<LessonSchedule> = [];
  lessonMap: { [tSeq: number]: Array<LessonSchedule> } = {};
  noshowLessonMap: Map<string, string> = new Map<string, string>();
  cancelLessonMap: Map<string, string> = new Map<string, string>();
  showLessonMap: Map<string, string> = new Map<string, string>();

  listenScheduleState: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
    private events: RxEvents,
    private afAuth: AngularFireAuth,
    private afData: AngularFireDatabase,
    private fittUtil: FittUtilService,
  ) {
    super();

    this.fittUtil.setApiService(this);
  }

  clear() {
    this.log('clear');
    this.myInfo = null;
    this.myInfoQuery = false;
  }

  verifyAuth(): Promise<any> {
    this.log('verifyAuth');
    return new Promise<any>((resolve) => {
      const unsubscribe = this.afAuth.auth.onAuthStateChanged(user => {
        this.log('verifyAuth onAuthStateChanged', user ? { uid: user.uid, displayName: user.displayName } : null);
        if (!user) return;
        if (!user.emailVerified) return;

        unsubscribe();
        resolve(user);
      });
    });
  }

  getAuthToken(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.afAuth.auth.currentUser) {
        await this.verifyAuth();
      }
      const idToken = await this.afAuth.auth.currentUser.getIdToken();
      resolve(idToken);
    });
  }

  getRecord(args: { table: string, fields?: Array<string>, where?: any, orderBy?: string, limit?: Array<number> }): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        // const res = await this.fittUtil.remoteApi({
        //   api: 'util/getRecord',
        //   param: args,
        // });
        // resolve(res.record);
        const { table, where, fields, orderBy, limit } = args;
        const q = `
          SELECT
            ${fields ? fields.join(',') : '*'}
          FROM ${table}
          ${where ? `WHERE ${this.util.makeWhereParamters(where)}` : ''}
          ${orderBy ? `ORDER BY ${[].concat(orderBy).join(',')}` : ''}
          ${limit ? `LIMIT ${limit.join(',')}` : ''};
        `;
        this.log('getRecord', q);
        const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
        resolve(res.result);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  setRecord(args: { table: string, record: any, where?: any, syncData?: boolean, syncId?: any }): Promise<any> {
    this.log('setRecord', args);
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'util/setRecord',
          param: args,
        });
        // 운동검사 기록을 삽입,삭제할 경우 기록일자 기준으로 EXERCISE_TEST_Order 재정렬함.
        // 삭제할 경우, Visible 1인 경우만 EXERCISE_TEST_Order 재정렬.
        if (args.table === 'TEXERCISE_TEST') {
          try {
            let whereData = args.record ? args.record : args.where;
            if (args.record && args.record.EXERCISE_TEST_Visible === 0) {
              whereData = args.where;
            }
            console.log("whereData", whereData);
            if (whereData) {
              await this.exerciseApi.sortExerciseTestOrder({
                gSeq: whereData.GYM_Seq,
                uSeq: whereData.USER_Seq,
                testType: whereData.EXERCISE_TEST_Type
              });
            }
          } catch (error) {
            resolve(error);
          }
        }
        resolve(res);
      }
      catch (error) {
        reject(error);
      }
    });
  }
  setRecords(args: { table: string, records: Array<any> }): Promise<any> {
    this.log('setRecords', args);
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'util/setRecords',
          param: args,
        });
        resolve(res);
      }
      catch (error) {
        reject(error);
      }
    });
  }

  public payment = {
    updateGym: (args: {
      gSeq: number, gId: string, gPassword: string, gName: string, gTel: string, gOwnerName: string, gAddress: string,
      gCompanyNumber: string | null, gCompanyNumberLink: string | null, gDepositAccount: string | null, gDepositAccountLink: string | null, gDepositBank: string | null,
      gTimeZone: string, isCurrentLangKo: boolean
    }): Promise<any> => {
      const { gSeq, gId, gPassword, gName, gTel, gOwnerName, gAddress, gCompanyNumber, gCompanyNumberLink, gDepositAccount, gDepositAccountLink, gDepositBank, gTimeZone, isCurrentLangKo } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/updateGym',
            param: {
              tok: await this.getAuthToken(),
              gSeq: gSeq,
              gId: gId,
              gPassword: gPassword,
              gName: gName,
              gTel: gTel,
              gOwnerName: gOwnerName,
              gAddress: gAddress,
              gCompanyNumber: gCompanyNumber,
              gCompanyNumberLink: gCompanyNumberLink,
              gDepositAccount: gDepositAccount,
              gDepositAccountLink: gDepositAccountLink,
              gDepositBank: gDepositBank,
              forPublic: (this.environment.forPublic && this.environment.production) ? true : false,
              gTimeZone: gTimeZone,
              isCurrentLangKo: isCurrentLangKo
            }
          });
          await this.auth.getMyInfo({ refresh: true });
          resolve(res);
        }
        catch (error) {
          this.error('updateGym');
          reject(error);
        }
      });
    },
    gymPayment: (args: {
      upgrade: boolean, bSeq: string, type: string, packageSeq: string, userCnt: number, treadmillCnt: number, trainerCnt: number,
      schedule: string, tmtFlag: number, fmtFlag: number, smtFlag: number, lacticFlag: number, couponsSeq: Array<number>, gSeq: number,
      groupHead: number, isCurrentLangKo: boolean, countryCode: string, pMethodType: string, depositor: string, dName?: string,
      dZipCode?: string, dAddrMain?: string, dAddrSub?: string, dContact?: string, dMemo?: string, dLift?: string
    }): Promise<any> => {
      this.log('gymPayment');
      const { upgrade, bSeq, type, packageSeq, userCnt, treadmillCnt, trainerCnt, tmtFlag, fmtFlag, smtFlag, lacticFlag, couponsSeq, gSeq,
        schedule, groupHead, isCurrentLangKo, countryCode, pMethodType, depositor, dName, dZipCode, dAddrMain, dAddrSub, dContact, dMemo, dLift } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/gymPayment',
            param: {
              tok: await this.getAuthToken(),
              email: localStorage.myEmail,
              upgrade: upgrade,
              bSeq: bSeq,
              type: type,
              packageSeq: packageSeq,
              userCnt: userCnt,
              treadmillCnt: treadmillCnt,
              trainerCnt: trainerCnt,
              schedule: schedule,
              tmtFlag: tmtFlag,
              fmtFlag: fmtFlag,
              smtFlag: smtFlag,
              lacticFlag: lacticFlag,
              couponsSeq: couponsSeq,
              gSeq: gSeq,
              groupHead: groupHead,
              forPublic: (this.environment.forPublic && this.environment.production) ? true : false,
              isCurrentLangKo: isCurrentLangKo,
              countryCode: countryCode,
              pMethodType: pMethodType,
              depositor: depositor,

              dName: dName,
              dZipCode: dZipCode,
              dAddrMain: dAddrMain,
              dAddrSub: dAddrSub,
              dContact: dContact,
              dMemo: dMemo,
              dLift: dLift
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('gymPayment');
          reject(error);
        }
      });
    },
    getPaymentList: (args: {
      email: string
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { email } = args;
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPaymentList',
            param: {
              email: email
            }
          });
          resolve(res.result);
        } catch (error) {
          this.error('getPaymentList');
          reject(error);
        }
      });
    },
    getPaypalPlanDetail: (args: {
      paypalUrl: string, clientId: string, clientSecret: string, planId: string,
    }): Promise<any> => {
      const { paypalUrl, clientId, clientSecret, planId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPaypalPlanDetail',
            param: {
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              planId: planId
            }
          });
          resolve(res);
        } catch (error) {
          this.error('getPaypalPlanlist');
          reject(error);
        }
      });
    },
    getPaypalPlanlist: (args: {
      paypalUrl: string, clientId: string, clientSecret: string, productId: string,
    }): Promise<any> => {
      const { paypalUrl, clientId, clientSecret, productId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPaypalPlanlist',
            param: {
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              productId: productId
            }
          });
          resolve(res);
        } catch (error) {
          this.error('getPaypalPlanlist');
          reject(error);
        }
      });
    },
    cancelPaypalSubscription: (args: {
      paypalUrl: string, clientId: string, clientSecret: string, subsId: string, reason: string
    }): Promise<any> => {
      const { paypalUrl, clientId, clientSecret, subsId, reason } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/cancelPaypalSubscription',
            param: {
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              subsId: subsId,
              reason: reason
            }
          });
          resolve(res);
        } catch (error) {
          this.error('cancelPaypalSubscription');
          reject(error);
        }
      });
    },
    suspendPaypalSubscription: (args: {
      paypalUrl: string, clientId: string, clientSecret: string, subsId: string, reason: string
    }): Promise<any> => {
      const { paypalUrl, clientId, clientSecret, subsId, reason } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/suspendPaypalSubscription',
            param: {
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              subsId: subsId,
              reason: reason
            }
          });
          resolve(res);
        } catch (error) {
          this.error('suspendPaypalSubscription');
          reject(error);
        }
      });
    },
    getPaypalSubscriptionID: (): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPaypalSubscriptionID',
            param: {
              tok: await this.getAuthToken(),
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getPaypalSubsID');
          reject(error);
        }
      })
    },
    getPaypalSubscriptionDetail: (args: {
      paypalUrl: string, clientId: string, clientSecret: string, subsId: string,
    }): Promise<any> => {
      const { paypalUrl, clientId, clientSecret, subsId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPaypalSubscriptionDetail',
            param: {
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              subsId: subsId
            }
          });
          resolve(res);
        } catch (error) {
          this.error('getPaypalPlanlist');
          reject(error);
        }
      });
    },
    creatingPaypalPay: (args: gymPlanPaymentParam): Promise<any> => {
      const cppParam: gymPlanPaymentParam = args;
      // const { upgrade, bSeq, type, packageSeq, userCnt, trainerCnt, tmtFlag, fmtFlag, smtFlag, lacticFlag, couponsSeq, gSeq, schedule, groupHead, isCurrentLangKo, paypalUrl, clientId, clientSecret } = args;
      return new Promise(async (resolve, reject) => {
        try {
          cppParam.tok = await this.getAuthToken();
          cppParam.email = localStorage.myEmail;
          cppParam.forPublic = (this.environment.forPublic && this.environment.production) ? true : false;
          const res = await this.fittUtil.remoteApi({
            api: 'payment/creatingPaypalPay',
            param:
              cppParam

          });
          resolve(res);
        } catch (error) {
          this.error('creatingPaypalPay');
          reject(error);
        }
      });
    },
    creatingPaypalPlan: (args: gymPlanPaymentParam): Promise<any> => {
      const cppParam: gymPlanPaymentParam = args;
      // const { upgrade, bSeq, type, packageSeq, userCnt, trainerCnt, tmtFlag, fmtFlag, smtFlag, lacticFlag, couponsSeq, gSeq, schedule, groupHead, isCurrentLangKo, paypalUrl, clientId, clientSecret } = args;
      return new Promise(async (resolve, reject) => {
        try {
          cppParam.tok = await this.getAuthToken();
          cppParam.email = localStorage.myEmail;
          cppParam.forPublic = (this.environment.forPublic && this.environment.production) ? true : false;
          const res = await this.fittUtil.remoteApi({
            api: 'payment/creatingPaypalPlan',
            param:
              cppParam

          });
          resolve(res);
        } catch (error) {
          this.error('getCreatingPaypalPlanID');
          reject(error);
        }
      });
    },
    completePaypalPayment: (args: gymPlanPaymentParam): Promise<any> => {
      const cppParam: gymPlanPaymentParam = args;
      return new Promise(async (resolve, reject) => {
        try {
          cppParam.tok = await this.getAuthToken();
          cppParam.email = localStorage.myEmail;
          cppParam.forPublic = (this.environment.forPublic && this.environment.production) ? true : false;
          const res = await this.fittUtil.remoteApi({
            api: 'payment/completePaypalPayment',
            param: cppParam

          });
          resolve(res);
        } catch (error) {
          this.error('completePaypalPayment');
          reject(error);
        }
      });
    },
    failPaypalPayment: (args: {
      failReason: string, createSubsRst: string, mSeq: number, paypalUrl: string, clientId: string, clientSecret: string, gymName: string,
      packageSeq: string, gSeq: number, couponsSeq: Array<number>
    }): Promise<any> => {
      const { failReason, createSubsRst, mSeq, paypalUrl, clientId, clientSecret, gymName, packageSeq, couponsSeq, gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/failPaypalPayment',
            param: {
              failReason: failReason,
              createSubsRst: createSubsRst,
              mSeq: mSeq,
              paypalUrl: paypalUrl,
              clientId: clientId,
              clientSecret: clientSecret,
              gymName: gymName,
              packageSeq: packageSeq,
              couponsSeq: couponsSeq,
              gSeq: gSeq
            }
          });
          resolve(res);
        } catch (error) {
          this.error('failPaypalPayment');
          reject(error);
        }
      });
    },
    digitalSupportGymPayment: (args: {
      upgrade: boolean, bTarget: string, type: string, packageSeq: string, userCnt: number, trainerCnt: number,
      tmtFlag: number, fmtFlag: number, smtFlag: number, lacticFlag: number, gSeq: number,
      pMethodType: string, pDisplayName: string, isPlan: number, period: number
    }): Promise<any> => {
      const { upgrade, bTarget, type, packageSeq, userCnt, trainerCnt, tmtFlag, fmtFlag, smtFlag, lacticFlag, gSeq, pMethodType, pDisplayName, isPlan, period } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/digitalSupportGymPayment',
            param: {
              tok: await this.getAuthToken(),
              email: localStorage.myEmail,
              upgrade: upgrade,
              pMethodType: pMethodType,
              pDisplayName: pDisplayName,
              isPlan: isPlan,
              bTarget: bTarget,
              type: type,
              packageSeq: packageSeq,
              userCnt: userCnt,
              trainerCnt: trainerCnt,
              tmtFlag: tmtFlag,
              fmtFlag: fmtFlag,
              smtFlag: smtFlag,
              lacticFlag: lacticFlag,
              gSeq: gSeq,
              forPublic: (this.environment.forPublic && this.environment.production) ? true : false,
              period: period
            }
          });
          resolve(res);
        } catch (error) {
          this.error('digitalSupportGymPayment');
          reject(error);
        }
      });
    },
    getPrevPayment: (args: {
      gSeq: number,
      isDigitSupport?: boolean
    }): Promise<any> => {
      const { gSeq, isDigitSupport } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getPrevPayment',
            param: {
              gSeq: gSeq,
              isDigitSupport: isDigitSupport ? true : false
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getPrevPayment');
          reject(error);
        }
      })
    },
    getB2BPackageList: (args): Promise<any> => {
      this.log('getB2BPackageList');
      const { forEduResearch, forDigitalSupport, forProduct, countryCode } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getB2BPackageList',
            param: {
              // tok: await this.getAuthToken(),
              countryCode: countryCode || 'kr',
              forProduct: forProduct,
            }
          });
          const result = res.result;
          let packageList;
          if (forEduResearch) {
            packageList = result.filter(item => item.pName.includes('EducationalResearch'));
          } else if (forDigitalSupport) {
            packageList = result.filter(item => item.pName.includes('Digital'));
          } else {
            packageList = result.filter(item => !item.pName.includes('EducationalResearch')).filter(item => !item.pName.includes('Digital'));
          }
          result.packageList = packageList;
          resolve(res);
        }
        catch (error) {
          this.error('getB2BPackageList');
          reject(error);
        }
      })
    },
    getCouponByCode: (args: { code: string, qType: string }): Promise<any> => {
      this.log('getCouponByCode');
      const { code, qType } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getCoupon',
            param: {
              tok: await this.getAuthToken(),
              code: code,
              qType: qType
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getCouponByCode');
          reject(error);
        }
      })
    },
    getCoupon: async (args: { type: string, code?: string, qType?: string, countryCode?: string }): Promise<any> => {
      this.log('getCoupon');
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'coupon/getCoupon',
          param: {
            tok: await this.getAuthToken(),
            type: args.type,
            code: args.code,
            qType: args.qType
          }
        });
        this.log('getCouponApi', res);
        const countryCode = args.countryCode.toUpperCase() || 'KR';
        return res.result.filter(c => c.cCountryCode === countryCode || c.cType === 'GYMPACKAGE_YEAR');
      } catch (error) {
        this.error(error);
        throw error;
      }
    },
    getCardList: (): Promise<any> => {
      this.log('getCardList');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/getBillKeyList',
            param: {
              tok: await this.getAuthToken(),
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getCardList');
          reject(error);
        }
      })
    },

    putBillKey: (args: { ExpMonth: string, ExpYear: string, CardNo: string, IDNo: string, CardPw: string }): Promise<any> => {
      this.log('putBillKey');
      const { ExpMonth, ExpYear, CardNo, IDNo, CardPw } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/putBillKey',
            param: {
              tok: await this.getAuthToken(),
              ExpMonth: ExpMonth,
              ExpYear: ExpYear,
              CardNo: CardNo,
              IDNo: IDNo,
              CardPw: CardPw
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('putBillKey');
          reject(error);
        }
      });
    },

    setGymB2BPayment: (args: {
      pSeq: number,
      userCnt?: number,
      trainerCnt?: number,
      tmtFlag?: boolean,
      fmtFlag?: boolean,
      smtFlag?: boolean,
      lacticFlag?: boolean,
      amount?: number,
      nextAmount?: number,
      refundAmount?: number,
      expiredDate?: string,
      cancelDate?: string,
      state?: string,
      gymPInfo?: any,
      isCurrentLangKo: boolean
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'payment/setGymB2BPayment',
            param: {
              ...args, email: localStorage.myEmail,
              forPublic: (this.environment.forPublic && this.environment.production) ? true : false,
              isCurrentLangKo: this.util.isCurrentLangKo()
            }
          });
          resolve(res);
        } catch (error) {
          this.error('setGymB2BPayment error', error);
          reject(error);
        }
      });
    },

  }

  public userPayment = {
    refundUserPayment: async (args: RefundUserPaymentParam) => {
      this.log('refundUserPayment.args', args);
      return await this.fittUtil.remoteApi(
        {
          api: 'userPayment/refundUserPayment',
          param: args
        }
      );
    },
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    registOrEditSubMall: async (args: {
      mId: string,
      mKey: string,
      dateStr: string,
      smId: string,
      smName: string,
      smCoNo: string,
      accountName: string,
      bankName: string,
      bankAccount: string,
      memo: string,
      isEdit: string,
    }) => {
      this.log('registOrEditSubMall.args', args);
      return await this.fittUtil.remoteApi({
        api: 'userPayment/registOrEditSubMall',
        param: args
      });
    },
    getBalanceCheck: async (args: {
      mId: string,
      mKey: string,
      dateStr: string,
    }) => {
      this.log('getBalanceCheck.args', args);
      return await this.fittUtil.remoteApi({
        api: 'userPayment/getBalanceCheck',
        param: args
      });
    },
    paymentRequest: async (args: {
      mId: string,
      mKey: string,
      dateStr: string,
      smId: string,
      tommorow: string,
      reqAmt: string,
    }) => {
      this.log('paymentRequest.args', args);
      return await this.fittUtil.remoteApi({
        api: 'userPayment/paymentRequest',
        param: args
      });
    },
    cancelPaymentRequest: async (args: {
      mId: string,
      mKey: string,
      dateStr: string,
      smId: string,
      tommorow: string,
      paySeq: string,
    }) => {
      this.log('cancelPaymentRequest.args', args);
      return await this.fittUtil.remoteApi({
        api: 'userPayment/cancelPaymentRequest',
        param: args
      });
    },
    getResultCheck: async (args: {
      mId: string,
      mKey: string,
      dateStr: string,
      smId: string,
      tommorow: string,
    }) => {
      this.log('getResultCheck.args', args);
      return await this.fittUtil.remoteApi({
        api: 'userPayment/getResultCheck',
        param: args
      });
    },
  }

  public migration = {
    decryptSeq: (args: { gSeq: number }): Promise<any> => {

      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/decryptSeq', param: { gSeq: gSeq } });
          resolve(res.result);
        }
        catch (error) {
          this.error('decryptSeq');
          reject(error);
        }
      });
    },
    getPrevPayment: (args: { gSeq: number }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/getPrevPayment', param: { gSeq: gSeq } });
          resolve(res);
        }
        catch (error) {
          this.error('getPrevPayment');
          reject(error);
        }
      });
    },
    isPaid: (args: { gSeq: any }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/isPaid', param: { gSeq: gSeq } });
          resolve(res);
        }
        catch (error) {
          this.error('isPossible', error);
          reject(error);
        }
      });
    },
    isPossible: (args: { gSeq: any, isMigration: boolean }): Promise<any> => {
      const { gSeq, isMigration } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/isPossible', param: { gSeq: gSeq, isMigration: isMigration } });
          resolve(res);
        }
        catch (error) {
          this.error('isPossible', error);
          reject(error);
        }
      });
    },
    isMigrationIng: (args: { mSeq: number }): Promise<any> => {
      const { mSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/isMigrationIng', param: { mSeq: mSeq } });
          resolve(res);
        }
        catch (error) {
          this.error('isMigrationIng');
          reject(error);
        }
      });
    },
    isPossibleGym: (args: { gSeq: any }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/isPossibleGym', param: { gSeq: gSeq } });
          resolve(res);
        }
        catch (error) {
          this.error('isPossible');
          reject(error);
        }
      });
    },
    signup: (args: { email: string, password: string, gSeq: string, cType: number }): Promise<any> => {
      this.log('signup');
      const { email, password, gSeq, cType } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/signup', param: { email: email, password: password, gSeq: gSeq, cType: cType } });

          resolve(res);
        }
        catch (error) {
          this.error('signup');
          reject(error);
        }
      });
    },
    step1: (args: { gSeq: string }): Promise<any> => {
      this.log('step1');
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/first', param: { gSeq: gSeq, tok: await this.getAuthToken(), } });
          resolve(res);
        }
        catch (error) {
          this.error('step1');
          reject(error);
        }
      });
    },
    step2: (args: { gSeq: string }): Promise<any> => {
      this.log('step2');
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/second', param: { gSeq: gSeq, tok: await this.getAuthToken(), } });
          resolve(res);
        }
        catch (error) {
          this.error('step2');
          reject(error);
        }
      });
    },
    step3: (args: { gSeq: string }): Promise<any> => {
      this.log('step3');
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/third', param: { gSeq: gSeq, tok: await this.getAuthToken(), } });
          resolve(res);
        }
        catch (error) {
          this.error('step3');
          reject(error);
        }
      });
    },
    setInterview: (args: { title: string, link: string, type: string }): Promise<any> => {
      const { title, link, type } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = this.util.makeInsertQuery({
            table: 'FITT_DB.TINTERVIEW', fieldValues: {
              'INTERVIEW_Title': title,
              'INTERVIEW_Link': link,
              'INTERVIEW_Type': type
            }
          });
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res);
        }
        catch (error) {
          this.error('getInterview');
          reject(error);
        }
      });
    },
    getInterview: (args: {}): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'migration/interview', param: '' });
          resolve(res.result[0]);
        }
        catch (error) {
          this.error('getInterview', error);
          reject(error);
        }
      });
    },
    deleteInterview: (args: { Seq: number }): Promise<any> => {
      const { Seq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = `DELETE FROM FITT_DB.TINTERVIEW WHERE INTERVIEW_Seq = ${Seq}`;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res);
        } catch (error) {
          this.error('deleteInterview', error);
          reject(error);
        }
      })
    },
    getDetailInterview: (args: { Seq: number }): Promise<any> => {
      const { Seq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = `SELECT * FROM FITT_DB.TINTERVIEW WHERE INTERVIEW_Seq = ${Seq}`;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res.result);
          this.log('getInterview', res);
        }
        catch (error) {
          this.error('getInterview', error);
          reject(error);
        }
      });
    },
    getBroadcast: (args: {}): Promise<any> => {
      const { } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = `SELECT * FROM FITT_DB.TEDU WHERE EDU_Visible = 1 AND EDU_EDUCATEGORY_Seq like "FITT%"`;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res.result);
        }
        catch (error) {
          this.error('getInterview', error);
          reject(error);
        }
      });
    },
    getNews: (args: {}): Promise<any> => {
      const { } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q =
            `
            SELECT *
            FROM FITT_DB.TNOTICE
            WHERE NOTICE_Visible = 1 AND NOTICE_Type = 3
            ORDER BY NOTICE_Date DESC
          `;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res.result);
        }
        catch (error) {
          this.error('getInterview', error);
          reject(error);
        }
      });
    },
  }

  public auth = {
    isAlreadyPayment: (args: { gSeq: number }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/isAlreadyPayment',
            param: {
              gSeq: gSeq,
            }
          });
          resolve(res);
        }
        catch (error) {
          reject(error);
        }
      })
    },
    getGoogleAccessToken: (args: { code: string, googleCLientId: string, googleCLientKey: string, redirect_uri: string }): Promise<any> => {
      const { code, googleCLientId, googleCLientKey, redirect_uri } = args;
      return new Promise((resolve, reject) => {
        this.http.post('https://www.googleapis.com/oauth2/v4/token',
          JSON.stringify({
            'grant_type': 'authorization_code',
            'client_id': googleCLientId,
            'client_secret': googleCLientKey,
            'code': code,
            'redirect_uri': redirect_uri
          })
          , { headers: new HttpHeaders().set('Content-Type', 'application/json') }).subscribe(response => {
            resolve(response);
          }, error => {
            reject(error);
          })
      });
    },
    getGoogleUserId: (args: { token: string }): Promise<any> => {
      const { token } = args;
      return new Promise((resolve, reject) => {
        this.http.get('https://oauth2.googleapis.com/tokeninfo?id_token=' + token).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        })
      });
    },
    getFacebookAccessToken: (args: { code: string, redirect_uri: string }): Promise<any> => {
      const { code, redirect_uri } = args;
      return new Promise((resolve, reject) => {
        this.http.get(
          `https://graph.facebook.com/v2.11/oauth/access_token?client_id=505528553692266&redirect_uri=${redirect_uri}&client_secret=53e82b1a839854a7f901d091d3a8bcbf&code=${code}`
        ).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        })
      });
    },
    getFacebookUesrId: (args: { token: string }): Promise<any> => {
      const { token } = args;
      return new Promise((resolve, reject) => {
        this.http.get(
          `https://graph.facebook.com/me?access_token=${token}`
        ).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        })
      });
    },
    getNaverAccessId: (args: { code: string, naverClientId: string, naverClientSecretKey: string, state: string }): Promise<any> => {
      const { code, naverClientId, naverClientSecretKey, state } = args;
      return new Promise((resolve, reject) => {
        this.http.get(
          `https://nid.naver.com/oauth2.0/token?grant_type=authorization_code&client_id=${naverClientId}&client_secret=${naverClientSecretKey}&code=${code}&state=${state}`
        ).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        })
      });
    },
    reqNaverLogin: (): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/requestNaverLogin',
            param: {
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getNaverUserInfo: (args: { code: string, naverClientId: string, naverClientSecretKey: string, state: string }): Promise<any> => {
      const { code, naverClientId, naverClientSecretKey, state } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/getNaverId',
            param: {
              code,
              naverClientId,
              naverClientSecretKey,
              state
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getKakaoToken: (args: { code: string, redirect_uri: string }): Promise<any> => {
      const { code, redirect_uri } = args;
      const bodyData = {
        'grant_type': 'authorization_code',
        'client_id': 'd9ce5494b08ca571eb5cef76b89f7e77',
        'client_secret': 'rdXB1J29KXLcHw7QAkv4M8uti7hCLNTY',
        'code': code,
        'redirect_uri': redirect_uri
      }
      const queryStringBody = Object.keys(bodyData)
        .map(k => encodeURIComponent(k) + "=" + encodeURI(bodyData[k]))
        .join("&")

      return new Promise((resolve, reject) => {
        this.http.post('https://kauth.kakao.com/oauth/token',
          queryStringBody, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') }
        ).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        })

      });
    },
    // getKakaoUserData: (args: { accessToken: string }): Promise<any> => {
    //   const { accessToken } = args;
    //   const bodyData = {
    //     'property_keys': [

    //     ]
    //   };
    //   return new Promise(async (resolve, reject) => {
    //     try {

    //     } catch (e) {

    //     }
    //   });
    // },
    getKakaoId: (args: { code: string, redirect_uri: string }): Promise<any> => {
      const { code, redirect_uri } = args;

      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/getKakaoId',
            param: {
              code,
              redirect_uri
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getSignUpInfoWithSNS: (args: { snsId: string, snsType: string }): Promise<any> => {
      const { snsId, snsType } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/getSignUpInfoWithSNS',
            param: {
              snsId: snsId,
              snsType: snsType
            }
          });
          resolve(res);
        }
        catch (error) {
          resolve(error.error);
        }
      })
    },
    getSignUpSNSType: (args: { email: string }): Promise<any> => {
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/getSignUpSNSType',
            param: {
              email: email
            }
          });
          resolve(res);
        }
        catch (error) {
          resolve(error.error);
        }
      })
    },
    checkSocialData: (args: { snsId: string }): Promise<any> => {
      const { snsId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/checkSocialData',
            param: {
              snsId: snsId
            }
          });
          resolve(res);
        } catch (e) {
          this.error('checkSocialData');
          reject(e);
        }
      });
    },
    setSocialData: (args: { email: string, snsType: string, snsId: string }): Promise<any> => {
      const { email, snsType, snsId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/setSocialData',
            param: {
              email: email,
              snsType: snsType,
              snsId: snsId
            }
          });
          resolve(res);
        } catch (e) {
          this.error('setSocialData');
          reject(e);
        }
      });
    },
    deleteAllSocialId: (args: { snsId: string, snsType: string }): Promise<any> => {
      const { snsId, snsType } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/deleteAllSocialId',
            param: {
              snsId: snsId,
              snsType: snsType
            }
          });
          resolve(res);
        } catch (e) {
          this.error('deleteAllSocialId');
          reject(e);
        }
      })
    },
    deleteAllSocialData: (args: { email: string }): Promise<any> => {
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/deleteAllSocialData',
            param: {
              email: email
            }
          });
          resolve(res);
        } catch (e) {
          this.error('deleteAllSocialData');
          reject(e);
        }
      })
    },
    getSocialTypes: (args: { email: string }): Promise<any> => {
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/getSocialTypes',
            param: {
              email: email
            }
          });
          resolve(res);
        } catch (e) {
          this.error('getSocialTypes');
          reject(e);
        }
      });
    },
    getAuthEmail: (args: { email: string }): Promise<any> => {
      this.log('getAuthEmail');
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/isAuthEmail',
            param: {
              email: email
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getAuthEmail');
          reject(error);
        }
      })
    },
    setSnsData: (args: { snsId: string, snsType: string, email: string }): Promise<any> => {
      const { snsId, snsType, email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/setSnsData',
            param: {
              snsId: snsId,
              snsType: snsType,
              email: email
            }
          });
          resolve(res);
        } catch (error) {
          this.error('setSnsData');
          reject(error);
        }
      });
    },
    validSignUpEmail: (args: { gId: string, gSeq?: number }): Promise<any> => {
      this.log('validSignUpEmail');
      const { gId, gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/validSignUpEmail',
            param: {
              gId: gId,
              gSeq: gSeq
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('validSignUpEmail');
          reject(error);
        }
      })
    },
    hasPaymentPassword: (): Promise<any> => {
      this.log('hasPaymentPassword');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/hasPaymentPassword',
            param: {
              tok: await this.getAuthToken(),
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('hasPaymentPassword');
          reject(error);
        }
      })
    },
    isPaymentPassword: (args: { pw: string }): Promise<any> => {
      const { pw } = args;
      this.log('isPaymentPassword', pw);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/isPaymentPassword',
            param: {
              tok: await this.getAuthToken(),
              pw: pw
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('isPaymentPassword');
          reject(error);
        }
      })
    },
    putPaymentPassword: (args: { pw: string }): Promise<any> => {
      this.log('putPaymentPassword');
      const { pw } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/putPaymentPassword',
            param: {
              tok: await this.getAuthToken(),
              pw: pw
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('putPaymentPassword');
          reject(error);
        }
      })
    },
    isValidEmail: (args: { email: string }): Promise<any> => {
      this.log('isValidEmail');
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'auth/isValidEmail', param: { email: email } });
          resolve(res);
        }
        catch (error) {
          this.error('isValidToken');
          reject(error);
        }
      });
    },

    isValidPackage: (args: { gSeq: number }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'auth/isValidPackage', param: { gSeq: gSeq } });
          resolve(res.result);
        }
        catch (error) {
          this.error('isValidToken');
          reject(error);
        }
      });
    },
    isValidToken: (args: { token: string }): Promise<any> => {
      this.log('isValidToken');
      const { token } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'auth/isValidToken', param: { token: token } });
          resolve(res);
        }
        catch (error) {
          this.error('isValidToken');
          reject(error);
        }
      });
    },
    isValidGym: (args: { gSeq: any }): Promise<any> => {
      this.log('isValidGym');
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'auth/isValidGym', param: { token: await this.getAuthToken(), gSeq: gSeq } });
          resolve(res);
        }
        catch (error) {
          this.error('isValidGym');
          reject(error);
        }
      });
    },
    signupMember: (args: { email: string, password: string, name: string, tel: string, agreeMarketing: boolean, agreePolicy: boolean, agreeTerms: boolean }): Promise<any> => {
      this.log('signupMember');
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = `SELECT m.MEMBER_Visible FROM FITT_DB.TMEMBER m WHERE m.MEMBER_Email='${email}';`;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res);
        }
        catch (error) {
          this.error('signupMember');
          reject(error);
        }
      });
    },
    signoutMember: (args: { gId?: string }): Promise<any> => {
      this.log('signoutMember');
      const { gId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/signoutMember',
            param: {
              tok: await this.getAuthToken(),
              gId: gId ? gId + `@fitt4internal.kr` : null,
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('signoutMember');
          reject(error);
        }
      });
    },
    existMember: (args: { email: string }): Promise<any> => {
      this.log('existMember');
      const { email } = args;

      return new Promise(async (resolve, reject) => {
        try {
          const q = `SELECT m.MEMBER_Visible FROM FITT_DB.TMEMBER m WHERE m.MEMBER_Email='${email}';`;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res);
        }
        catch (error) {
          this.error('existMember');
          reject(error);
        }
      });
    },

    getMyInfo: async (args?: {
      refresh?: boolean
    }) => {
      try {
        args = args || {};
        const { refresh } = args;
        // this.warn('getMyInfo', { refresh });
        const token = await this.getAuthToken();
        const email = this.afAuth.auth.currentUser.email || localStorage.myEmail;
        this.myInfo = await this.util.getSingleObj({
          method: 'getMyInfo', refresh, reqFunc: async () => {
            const startTime = new Date().getTime();
            const res = await this.fittUtil.remoteApi({
              api: 'auth/getMyInfo',
              param: {
                tok: token, email: email,
                project: this.environment.project
              }
            });
            this.log('getMyInfo', { elapsedTime: new Date().getTime() - startTime, res });
            if (res.code === 1) {
              const values = res.mInfo;
              this.log("getMyInfo.value", values);
              const myInfo = new FittMember({ member: new Member(values), user: new User(values), gyms: res.gList });
              this.fittUtil.setMyInfo(myInfo);
              return myInfo;
            }
          }
        });
        return this.myInfo;
      } catch (error) {
        this.error('getMyInfo');
      }
    },

    getMyInfoFmes: async (args?: {
      refresh?: boolean
    }) => {
      try {
        args = args || {};
        const { refresh } = args;
        // this.warn('getMyInfo', { refresh });
        const token = await this.getAuthToken();
        const email = this.afAuth.auth.currentUser.email || localStorage.myEmail;
        this.myInfo = await this.util.getSingleObj({
          method: 'getMyInfoFmes', refresh, reqFunc: async () => {
            const startTime = new Date().getTime();
            const res = await this.fittUtil.remoteApi({
              api: 'auth/getMyInfoFmes',
              param: { tok: token, email: email }
            });
            this.log('getMyInfoFmes', { elapsedTime: new Date().getTime() - startTime, res });
            if (res.code === 1) {
              const values = res.mInfo;
              this.log("getMyInfoFmes.value", values);
              const myInfo = new FittMember({ member: new Member(values), user: new User(values), gyms: res.gList });
              this.fittUtil.setMyInfo(myInfo);
              return myInfo;
            }
          }
        });
        return this.myInfo;
      } catch (error) {
        this.error('getMyInfoFmes');
      }
    },

    getMyRole: async () => {
      if (this.myInfo) return this.myInfo.getRole();
      (await this.auth.getMyInfo()).getRole();
    },

    getMyUserData: (args: {
      uSeq: number
    }): Promise<any> => {
      this.log('getMyUserData');
      const { uSeq } = args;

      return new Promise(async (resolve, reject) => {
        try {
          const userData = await this.fittUtil.remoteApi({
            api: 'backdoor/getMyUserData',
            param: {
              uSeq: uSeq
            }
          });
          const res = userData.result.map(data => {
            return new User(data);
          })
          resolve(res);
        }
        catch (error) {
          this.error('existMember');
          reject(error);
        }
      });
    },
    updateProfile: (args: {
      user: User,
      isMine?: boolean
    }): Promise<any> => {
      const { user, isMine } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/updateProfile',
            param: {
              user: user,
              isMine: isMine
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

    sendEmailCertifiedCode: (args: {
      email: string,
      isCurrentLangKo: boolean
    }): Promise<any> => {
      const { email, isCurrentLangKo } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/sendEmailCertifiedCode',
            param: {
              email: email,
              isCurrentLangKo: isCurrentLangKo
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
  }

  private makeLessonSchedule(lSchedule: any): LessonSchedule {
    const schedule = new LessonSchedule(lSchedule);
    const mapKey = this.fittUtil.schedule.getScheduleKey(lSchedule);
    const myRole = this.myInfo.getRole();

    schedule.cellStyle = this.fittUtil.fitt.getCellStyle(schedule, this.myInfo);
    if (this.cancelLessonMap[mapKey] && !this.fittUtil.schedule.isCheckedSchedule('cancel', schedule, myRole) && myRole != 'user') {
      schedule.cellStyle.backgroundColor = '#FFCCDC';
    }
    if (this.noshowLessonMap[mapKey]) {
      if (schedule.sType == 'PT' && (schedule.isOpen() || !BookingState.NoShow.includes(schedule.sState))) {
        schedule.tooltipColor = 'var(--ft-color-caution)';
      }
      else if (['OT', 'TEST', 'MEMO'].includes(schedule.sType)) {
        schedule.tooltipColor = 'var(--ft-color-caution)';
      }
    }

    return schedule;
  }

  private setLessonMap(lesson: LessonSchedule, addOrDelete: boolean = true, lStates?: Array<string>) {
    if (!lesson) {
      this.warn('setLessonMap', 'lesson is null');
      return;
    }
    if (!this.lessonMap[lesson.tSeq]) this.lessonMap[lesson.tSeq] = new Array<LessonSchedule>();
    const lessons: Array<LessonSchedule> = this.lessonMap[lesson.tSeq];
    if (addOrDelete) {
      if (lesson.sType == 'PT') {
        if (lesson.uSeq && BookingState.Reserved.includes(lesson.sState) ||
          BookingState.NoShow.includes(lesson.sState) ||
          ScState.RegisterVirtual == lesson.sState) {
          lessons.push(lesson);
          this.lessons.push(lesson);
        } else if (lStates && lStates.includes(lesson.lState)) {
          lessons.push(lesson);
          this.lessons.push(lesson);
        }
      }
      else if (['OT', 'TEST', 'GX'].includes(lesson.sType)) {
        lessons.push(lesson);
        this.lessons.push(lesson);
      } else if (lesson.sType === 'MEMO' && !lesson.sAllDay) {
        lessons.push(lesson);
      }
    }
    else {
      let index;
      index = lessons.findIndex(l => l.sStartDate == lesson.sStartDate);
      if (index >= 0) lessons.splice(index, 1);

      index = this.lessons.findIndex(l => l.sStartDate == lesson.sStartDate);
      if (index >= 0) this.lessons.splice(index, 1);
    }
  }

  private isOverlapped(lesson: LessonSchedule): boolean {
    const lessons: Array<LessonSchedule> = this.lessonMap[lesson.tSeq];

    if (lessons) {
      return ['PT', 'OT', 'TEST'].includes(lesson.sType) && !lesson.uSeq
        && lessons.some(l => this.util.isOverlapRange({ start: new Date(l.sStartDate), end: new Date(l.sEndDate) }, { start: new Date(lesson.sStartDate), end: new Date(lesson.sEndDate) }, false));
    }
    return true;
  }

  public member = {
    getMemberDetail: (args: { mSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { mSeq } = args;
        try {
          const res = await this.fittUtil.remoteApi({ api: 'member/getMemberDetail', param: { mSeq: mSeq } });
          resolve(res.result)
        } catch (error) {
          reject(error)
        }
      });
    },
    setMemberDetail: (args: { mSeq: number, data: any }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { mSeq, data } = args;
        try {
          this.log('data', mSeq, data);
          const res = await this.fittUtil.remoteApi({ api: 'member/setMemberDetail', param: { mSeq: mSeq, data: data } });
          resolve(res)
        } catch (error) {
          reject(error)
        }
      });
    },
    updateMemberDetail: (args: { mSeq: number, data: any }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { mSeq, data } = args;
        try {
          this.log('data', mSeq, data);
          const res = await this.fittUtil.remoteApi({ api: 'member/updateMemberDetail', param: { mSeq: mSeq, data: data } });
          resolve(res)
        } catch (error) {
          reject(error)
        }
      });
    },
    setMember: async (args: { mSeq, uTel, isEmergencyTel }): Promise<any> => {
      const { mSeq, uTel, isEmergencyTel } = args;
      try {
        this.log('uTel', mSeq, uTel);
        const res = await this.fittUtil.remoteApi({ api: 'member/setMember', param: { mSeq: mSeq, uTel: uTel, isEmergencyTel: isEmergencyTel } });
        return res
      } catch (error) {
        this.error(error)
      }
    },
    getMemberDelivery: (args: { mSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { mSeq } = args;
        try {
          const [rec] = await this.getRecord({
            table: 'FITT_DB.TMEMBER_DELIVERY',
            fields: ['MEMBER_DELIVERY_Recipient AS mdRecipient',
              'MEMBER_DELIVERY_Zip_Code AS mdZip_Code',
              'MEMBER_DELIVERY_Address_Main AS mdAddress_Main',
              'MEMBER_DELIVERY_Address_Sub AS mdAddress_Sub',
              'MEMBER_DELIVERY_Contact AS mdContact',
              'MEMBER_DELIVERY_Memo AS mdMemo'],
            where: { MEMBER_Seq: mSeq },
            orderBy: 'MEMBER_DELIVERY_Seq DESC',
            limit: [1]
          });
          resolve(rec)
        } catch (error) {
          reject(error)
        }
      });
    },
    setMemberDelivery: (args: {
      mpSeq: number, dName: string, dZipCode: string,
      dAddrMain: string, dAddrSub: string, dContact: string,
      dMemo: string, dLift: string
    }):
      Promise<any> => {
      console.log('setMemberDelivery args', args);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.setRecord({
            table: 'FITT_DB.TMEMBER_DELIVERY',
            record: {
              PAYMENT_Seq: args.mpSeq,
              MEMBER_DELIVERY_Recipient: args.dName,
              MEMBER_DELIVERY_Zip_Code: args.dZipCode,
              MEMBER_DELIVERY_Address_Main: args.dAddrMain,
              MEMBER_DELIVERY_Address_Sub: args.dAddrSub,
              MEMBER_DELIVERY_Contact: args.dContact,
              MEMBER_DELIVERY_Lift: args.dLift,
              MEMBER_DELIVERY_Memo: args.dMemo,
            }
          });
          resolve(res)
        } catch (error) {
          reject(error)
        }
      });
    },
  }

  public b2b = {
    alreadyChangedProfile: (args: { email: string }): Promise<any> => {
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/user/alreadyChangedProfile',
            param: {
              email: email,
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    // etcc
    getThumbnail: (args: { id: string }): Promise<any> => {
      const { id } = args;
      return new Promise(async (resolve, reject) => {
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', 'bearer db00f504623e3d6ccc0d6cdecd0ba4f8');
        headers = headers.set('Accept', 'application/vnd.vimeo.*+json;version=3.4');
        const url = 'https://api.vimeo.com/videos/' + id;
        this.http.get(url, { responseType: 'json', headers: headers }).subscribe(data => {
          resolve(data);
        }, err => {
          reject('error');
        });
      });
    },

    getGymsB2BPayment: (args: { email: string }): Promise<any> => {
      this.log('getGymsB2BPayment');
      const { email } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymsB2BPayment',
            param: { email: email }
          });
          res.result.map(payment => {
            payment.pAmountCurrency = payment.pAmount ? payment.pAmount.currencyFormat() : 0;
            payment.pNextAmountCurrency = payment.pNextAmount ? payment.pNextAmount.currencyFormat() : 0;
            return payment;
          })
          resolve(res);
        }
        catch (error) {
          this.error('getGymsB2BPayment');
          reject(error);
        }
      });
    },

    // getGyms - 트레드밀 관리
    getGymsForAdmin: (args: {
      includeFitt3?: boolean,
      includeFitt4?: boolean,
      ggHeadSeq?: number,
      includeExpired?: boolean,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          let ret;
          const { code, result, } = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymsForAdmin',
            param: args,
          });
          if (code === 1) {
            const { Fitt3: fitt3GymList, Fitt4: fitt4GymList }: { Fitt3: any[], Fitt4: any[] } = result;
            const gymList = [...fitt4GymList];
            const isFitt4Gym: { [gSeq: number]: boolean } = {};

            fitt4GymList.forEach(({ gSeq }) => isFitt4Gym[gSeq] = true);
            fitt3GymList.forEach((fitt3Gym) => !isFitt4Gym[fitt3Gym.gSeq] && gymList.push(fitt3Gym));
            ret = gymList;
          } else {
            ret = result;
          }
          resolve(ret);
        } catch (error) {
          this.error('getGymsForAdmin error', error);
          reject(error);
        }
      });
    },

    // gym
    updateGymInfo: (args: {
      gSeq: number, gId: string, gPassword: string, gName?: string, gOwnerName?: string, gTel?: string, gInfo?: string, gAddress: string, gCompanyNumber: string, gCompanyNumberLink: string, gDepositBank: string, gDepositAccount: string, gDepositAccountLink: string, gTimeZone, gMemo?: string
    }): Promise<any> => {
      this.log('updateGymInfo');

      const { gSeq, gId, gPassword, gName, gOwnerName, gTel, gInfo, gAddress, gCompanyNumber, gCompanyNumberLink, gDepositBank, gDepositAccount, gDepositAccountLink, gTimeZone, gMemo } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/updateGymInfo',
            param: {
              gSeq: gSeq,
              gId: gId + `@fitt4internal.kr`,
              gPassword: gPassword,
              gName: gName,
              gOwnerName: gOwnerName,
              gTel: gTel,
              gEmail: localStorage.myEmail,
              gInfo: gInfo,
              gAddress: gAddress,
              gCompanyNumber: gCompanyNumber,
              gCompanyNumberLink: gCompanyNumberLink,
              gDepositBank: gDepositBank,
              gDepositAccount: gDepositAccount,
              gDepositAccountLink: gDepositAccountLink,
              gTimeZone: gTimeZone,
              gMemo: gMemo,
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('gymPayment');
          reject(error);
        }
      });
    },
    checkOverUserLimit: (args: {
      gSeq: number
    }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const limit = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getLimitCount',
            param: {
              gSeq: gSeq
            }
          });
          const uLimit = limit.result.uCnt;
          const [rec] = await this.getRecord({
            table: 'FITT_DB.TUSER',
            fields: ['count(*) AS uCnt'],
            where: { GYM_Seq: gSeq, USER_Visible: 1, User_State: 1 },
          });

          this.log("checkOverUserLimit", limit, rec);
          const res = {
            over: rec.uCnt > uLimit,
            full: !(rec.uCnt < uLimit),
            data: { limit: uLimit, count: rec.uCnt }
          };
          resolve(res);
        }
        catch (error) {
          this.error('error');
          reject(error);
        }
      });
    },

    checkOverTrainerLimit: (args: {
      gSeq: number
    }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const limit = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getLimitCount',
            param: {
              gSeq: gSeq
            }
          });
          const tLimit = limit.result.tCnt;
          let trainers = await this.getRecord({
            table: 'TTRAINER',
            fields: ['TRAINER_Seq AS tSeq', 'TRAINER_Retire AS tRetire', 'TRAINER_Auth AS tAuth'],
            where: {
              GYM_Seq: gSeq,
              TRAINER_Visible: 1,
            },
          });
          trainers = trainers ? trainers.filter(t => t.tRetire === null && t.tAuth != 'COMMON') : [];
          const trainerCount = trainers.length;
          const res = {
            over: trainerCount > tLimit,
            full: !(trainerCount < tLimit),
            data: { limit: tLimit, count: trainerCount }
          };
          resolve(res);
        }
        catch (error) {
          this.error('error');
          reject(error);
        }
      });
    },

    getAddress: (args: { keyword: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getAddress');
        const { keyword } = args;
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getAddress',
            param: {
              keyword: keyword
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },
    getSearchData: (args: { txt: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getSearchData');
        const { txt } = args;
        try {
          const q = `
            SELECT
              *
            FROM
              FITT.TGYM g
                LEFT JOIN FITT.TGYM_DETAIL gd ON g.GYM_Seq = gd.GYM_DETAIL_GYM_Seq
                LEFT JOIN FITT.TPAY_RESERVATION pr ON g.GYM_Seq = pr.PAY_RESERVATION_GYM_Seq
                LEFT JOIN FITT.TPAY_INFORMATION pi ON pr.PAY_RESERVATION_Seq = pi.PAY_INFORMATION_PAY_RESERVATION_Seq
            WHERE
              pi.PAY_INFORMATION_ExpiryDate > NOW()
                AND
                g.GYM_Visible = 1
                AND
                gd.GYM_DETAIL_Location is not null
                AND
                gd.GYM_DETAIL_Name LIKE "%${txt}%"
                ORDER BY
                length(gd.GYM_DETAIL_Name),gd.GYM_DETAIL_Name;
          `;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },
    getMapData: (args: {}): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getMapData');
        try {
          const q = `
            SELECT
              *
            FROM
              FITT.TGYM g
                LEFT JOIN FITT.TGYM_DETAIL gd ON g.GYM_Seq = gd.GYM_DETAIL_GYM_Seq
                LEFT JOIN FITT.TPAY_RESERVATION pr ON g.GYM_Seq = pr.PAY_RESERVATION_GYM_Seq
                LEFT JOIN FITT.TPAY_INFORMATION pi ON pr.PAY_RESERVATION_Seq = pi.PAY_INFORMATION_PAY_RESERVATION_Seq
            WHERE
              pi.PAY_INFORMATION_ExpiryDate > NOW()
                AND
                g.GYM_Visible = 1
                AND
                gd.GYM_DETAIL_Location is not null
                ORDER BY
                length(gd.GYM_DETAIL_Name),gd.GYM_DETAIL_Name;
          `;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getInternalPw: (args: { gId: string }): Promise<any> => {
      const { gId } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getInternalPw',
            param: {
              gId: gId
            }
          });
          this.log('getInternalPw', res);
          resolve(res);
        }
        catch (error) {
          this.error('getGymInfo', error);
          reject(error);
        }
      });
    },
    getGymInfo: (args: { gSeq?: number, gId?: string }): Promise<GymInfoRaw> => {
      this.log('getGymInfo', args);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymInfo',
            param: args
          });
          const [gymInfoRaw] = res.result[0];
          resolve(gymInfoRaw);
        }
        catch (error) {
          this.error('getGymInfo');
          reject(error);
        }
      });
    },

    // payroll / sales
    getPayrollModalData: (args: {
      gSeq?: number,
      tSeq?: number,
      type: string,
      pkgType: string,
      year: number,
      month: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await this.fittUtil.remoteApi({ api: 'b2b/gym/getPayrollModalData', param: args });
          this.log('getPayrollModalData', args, result);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPayrollSettings: (args: { gSeq: number }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        this.log('getPayrollSettings');
        try {
          const gymSalarySetting = new GymSalarySetting();

          const promiseArray: Array<Promise<any>> = [];
          promiseArray.push((async () => {
            const gymSetting = await this.fittUtil.remoteApi({ api: 'b2b/gym/getGymPayrollSettings', param: { gSeq: gSeq, type: 'gym' } });
            gymSalarySetting.setGymSetting(gymSetting.result[0]);
          })());
          promiseArray.push((async () => {
            const trainerSetting = await this.fittUtil.remoteApi({ api: 'b2b/gym/getGymPayrollSettings', param: { gSeq: gSeq, type: 'trainer' } });
            gymSalarySetting.trainerSalary = trainerSetting.result;
          })());
          promiseArray.push((async () => {
            const gymUsers = await this.fittUtil.remoteApi({ api: 'b2b/gym/getGymPayrollSettings', param: { gSeq: gSeq, type: 'user' } });
            gymSalarySetting.userFeePerPT = gymUsers.result;
          })());
          promiseArray.push((async () => {
            const classSalary = await this.fittUtil.remoteApi({ api: 'b2b/gym/getGymPayrollSettings', param: { gSeq: gSeq, type: 'class' } });
            gymSalarySetting.GXSalaryPerClass = classSalary.result;
          })());
          promiseArray.push((async () => {
            const gxCountSalary = await this.fittUtil.remoteApi({ api: 'b2b/gym/getGymPayrollSettings', param: { gSeq: gSeq, type: 'gxCount' } });
            gymSalarySetting.GXSalaryPerCount = gxCountSalary.result;
          })());
          await Promise.all(promiseArray);

          resolve(gymSalarySetting);
        } catch (error) {
          reject(error);
        }
      });
    },
    getTrainerPayrollBasicInfo: (args: { gSeq: number, date: Date }): Promise<any> => {
      const { gSeq, date } = args;
      const param = { gSeq: gSeq, year: date.getFullYear(), month: (date.getMonth() + 1) };
      this.log('getTrainerPayrollBasicInfo');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'b2b/gym/getTrainerPayrollBasicInfo', param: param });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    savePayrollSetting: (args: { gSeq: number, setting: GymSalarySetting }): Promise<any> => {
      const { gSeq, setting } = args;
      const param = { gSeq: gSeq, settings: setting };
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'b2b/gym/setGymPayrollSettings', param: param });
          this.log('setGymPayrollSetting res : ', res);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getSalesData: (args: {
      gSeq?: number,
      ggHeadSeq?: number,
      queryStartDate?: Date,
      queryEndDate?: Date,
      queryType?: 'payment' | 'activate'
    }): Promise<UserPaymentData[]> => {
      this.log('getSalesData', args);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getSalesData',
            param: args
          });
          let result;
          if (res.result) {
            result = res.result.map(r => new UserPaymentData(r));
          }
          resolve(result);
        } catch (error) {
          reject(error);
        }
      })
    },
    getSalesDataByFilter: (args: {
      gSeq: number,
      date: string,
      tSeq?: number,
      paSeqs?: Array<number>
    }): Promise<any> => {
      this.log('getSalesDataByFilter', args);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getSalesDataByFilter',
            param: args
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getSalesRankingDatas: (args: {
      gSeq: number,
      date: string,
      tSeq?: number,
      paSeqs?: Array<number>
    }): Promise<any> => {
      const { gSeq, date, tSeq, paSeqs } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getSalesRankingDatas',
            param: {
              gSeq: gSeq,
              date: date,
              tSeq: tSeq,
              paSeqs: paSeqs
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getDailySalesDetailData: (args: {
      date: string,
      gSeq: number
    }): Promise<any> => {
      const { date, gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getDailySalesDetailData',
            param: {
              gSeq,
              date
            }
          });
          const weekDay = ['일', '월', '화', '수', '목', '금', '토'];
          const ptSales = Number(res.result.ptSales);
          const gxSales = Number(res.result.gxSales);
          const healthSales = Number(res.result.healthSales);
          const pointSales = Number(res.result.pointSales);
          const etcSales = Number(res.result.etcSales);
          const dailySales = ptSales + gxSales + healthSales + pointSales + etcSales;
          const paymentHistoryList = Array.from(res.result.paymentList, (payment) => new PaymentHistory(payment))

          const allList = paymentHistoryList.map(item => {
            const d = new Date(item.pInDate);
            item.pInDate = this.translate.instant('스케줄._월 _일 _요일', {
              month: this.translate.instant(`스케줄.${d.getMonth() + 1}월`),
              date: d.getDate(),
              day: this.translate.instant(`요일.${weekDay[d.getDay()]}`)
            }).concat(` ${d.format('HH:mm')}`);
            item.amountText = `${item.pAmount.currencyFormat()}${this.translate.instant('일반.원')}`;
            item.tName = item.tName ? item.tName : this.translate.instant('담당 없음')
            return item;
          });
          this.log('getDailySalesDetailData', { res }, { allList })
          const ptList = allList.filter(item => item.paType === 'PT');
          const gxList = allList.filter(item => item.paType === 'GX');
          const healthList = allList.filter(item => item.paType === 'HEALTH');
          const pointList = allList.filter(item => item.paType === 'POINT');
          const etcList = allList.filter(item => ['ETC', 'OT', 'TEST'].includes(item.paType));
          resolve({
            ptSales,
            gxSales,
            healthSales,
            pointSales,
            etcSales,
            dailySales,
            allList,
            ptList,
            gxList,
            healthList,
            pointList,
            etcList
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    getEndEstimatedData: (args: {
      gSeq?: number,
      date: Date,
    }): Promise<any> => {
      const { gSeq, date } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getEndEstimatedData',
            param: {
              gSeq: gSeq || this.myInfo.getGymSeq(),
              date: date.format('yyyy-MM-dd 00:00:00')
            }
          });

          const result = res.result;
          const thisMonth = result.thisMonth
            .filter(item => (item.pPeriod > 0) || (item.pPeriod === 0 && Number(item.usedPercent) >= 80))
            .map(item => {
              const d = new Date(item.endDate);
              item.paTypeText = this.translate.instant(`packageType.${item.paType}`);
              item.endDate = (item.pPeriod === 0) ? '-' : d.format('yy.MM.dd');
              switch (item.isRepayment) {
                case 'REPAYMENT': item.isRepaymentText = this.translate.instant('회원관리.재등록');
                  item.dotColor = 'var(--ft-color-sub1)'; item.expAmountColor = 'var(--ft-color-black1)'; break;
                case 'EXPECTED': item.isRepaymentText = this.translate.instant('웹짐.결제 예상');
                  item.dotColor = 'var(--ft-color-caution)'; item.expAmountColor = 'var(--ft-color-black1)'; break;
                case 'END': item.isRepaymentText = this.translate.instant('웹짐.종료 예정');
                  item.dotColor = 'var(--ft-color-gray4)'; item.expAmountColor = 'var(--ft-color-gray4)'; break;
              }
              item.realAmount = item.realAmount || '-';
              item.expectedAmount = item.expectedAmount || '-';
              return item;
            });
          const nextMonth = result.nextMonth
            .filter(item => (item.pPeriod > 0) || (item.pPeriod === 0 && Number(item.usedPercent) >= 80))
            .map(item => {
              const d = new Date(item.endDate);
              item.paTypeText = this.translate.instant(`packageType.${item.paType}`);
              item.endDate = (item.pPeriod === 0) ? '-' : d.format('yy.MM.dd');
              switch (item.isRepayment) {
                case 'REPAYMENT': item.isRepaymentText = this.translate.instant('회원관리.재등록');
                  item.dotColor = 'var(--ft-color-sub1)'; item.expAmountColor = 'var(--ft-color-black1)'; break;
                case 'EXPECTED': item.isRepaymentText = this.translate.instant('웹짐.결제 예상');
                  item.dotColor = 'var(--ft-color-caution)'; item.expAmountColor = 'var(--ft-color-black1)'; break;
                case 'END': item.isRepaymentText = this.translate.instant('웹짐.종료 예정');
                  item.dotColor = 'var(--ft-color-gray4)'; item.expAmountColor = 'var(--ft-color-gray4)'; break;
              }
              item.realAmount = item.realAmount || '-';
              item.expectedAmount = item.expectedAmount || '-';
              return item;
            });
          result.thisMonth = thisMonth;
          result.nextMonth = nextMonth;
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getEndEstimatedData2: (args: {
      gSeq: number,
      queryStartDate: Date,
      queryEndDate: Date,
    }): Promise<EndEstimatedData[]> => {
      const { gSeq, queryStartDate, queryEndDate } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getEndEstimatedData2',
            param: {
              gSeq: gSeq,
              qLocalStartDate: queryStartDate.format('yyyy-MM-dd'), qLocalEndDate: queryEndDate.format('yyyy-MM-dd')
            }
          });
          const result = res.result
            .filter(item => (item.pPeriod > 0) || (item.pPeriod === 0 && Number(item.usedPercent) >= 80))
            .map(item => new EndEstimatedData(item));
          // this.log('getEndEstimatedData2', result);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },

    getExerciseTestRanking: (args: {
      gSeq?: number, maxCount?: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getExerciseTestRanking',
            param: args
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

    getPackageClasses: (args: { gSeq?: number, paSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getPackageClasses', param: { token: this.getAuthToken(), ...args }
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    // trainer / user
    setRetired: (args: { gSeq: number, tSeq: number, retire: boolean }): Promise<any> => {
      const { gSeq, tSeq, retire } = args;
      this.log('setRetired');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'b2b/gym/setRetired', param: { gSeq, tSeq, retire } });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    updateTrainersOrder: (args: {
      trainers: Array<Trainer>,
    }): Promise<any> => {
      const { trainers } = args;
      this.log('updateTrainersOrder');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/updateTrainersOrder',
            param: { trainers }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    setTrainer: (args: {
      gSeq: number,
      tSeq?: number,
      name?: string,
      birth?: any,
      gender?: string,
      tel?: string,
      tel2?: string,
      email?: string,
      weight?: string,
      height?: string,
      // career: any,
      entry?: any,
      profile?: string,
      auth?: string,
      visible?: boolean,
      connect?: boolean
    }): Promise<any> => {
      this.log('setTrainer');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/setTrainer',
            param: {
              ...args, token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateTrainer: (args: {
      gSeq: number,
      tSeq?: number,
      name?: string,
      birth?: any,
      gender?: string,
      tel?: string,
      tel2?: string,
      weight?: string,
      height?: string,
      // career: any,
      entry?: any,
      profile?: string,
      auth?: string,
      visible?: boolean
    }): Promise<any> => {
      this.log('updateTrainer');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/updateTrainer',
            param: {
              ...args, token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    setMigrationTrainer: (args: { gSeq: number, tSeq: number, selectTseq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/setMigrationTrainer',
            param: {
              ...args,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getAvailableTrainer: (args: { uSeq: number, allowDuplicate?: boolean }): Promise<any> => {
      const { uSeq, allowDuplicate } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getAvailableTrainer',
            param: {
              uSeq, allowDuplicate,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getGymTrainers: async (args: { gSeq: number, meFirst?: boolean, refresh?: boolean, retire?: boolean, separately?: boolean }): Promise<Array<Trainer>> => {
      const { gSeq, retire, separately } = args;
      const meFirst = args.meFirst !== undefined ? args.meFirst : true;
      const refresh = args.refresh !== undefined ? args.refresh : false;

      // admin 등에서 조회할 때 내가 로그인한 짐의 정보와 조회하려는 곳과 충돌이 발생한다. admin 등에서는 별도로 조회해야함.
      if (separately) {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getGymTrainers',
          param: {
            gSeq: gSeq,
            token: await this.getAuthToken(),
          }
        });
        // this.log('trainers res : ', res.trainerList);
        const trainers = [];
        for (const data of res.trainerList) {
          const trainer = new Trainer(data);
          if (!trainer.tName || trainer.tName.length == 0) continue;
          trainers.push(trainer);
        }
        trainers.sort((a, b) => {
          if ((a.tOrder || 10000) < (b.tOrder || 10000)) return -1;
          else if ((a.tOrder || 10000) > (b.tOrder || 10000)) return 1;
          else return a.tName < b.tName ? 1 : a.tName > b.tName ? -1 : 0;
        });
        return trainers;
      }

      this.gymTrainers = await this.util.getSingleObj({
        method: 'getGymTrainers', refresh, reqFunc: async () => {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymTrainers',
            param: {
              gSeq: gSeq,
              token: await this.getAuthToken(),
            }
          });
          // this.log('trainers res : ', res.trainerList);
          const trainers = [];
          for (const data of res.trainerList) {
            const trainer = new Trainer(data);
            if (!trainer.tName || trainer.tName.length == 0) continue;
            trainers.push(trainer);
          }
          trainers.sort((a, b) => {
            if ((a.tOrder || 10000) < (b.tOrder || 10000)) return -1;
            else if ((a.tOrder || 10000) > (b.tOrder || 10000)) return 1;
            else return a.tName < b.tName ? 1 : a.tName > b.tName ? -1 : 0;
          });
          return trainers;
        }
      });

      const trainers = retire ? this.gymTrainers.map(t => t) : this.gymTrainers.filter(t => !t.tRetire).map(t => t);
      if (this.myInfo && meFirst) {
        const index = trainers.findIndex(trainer => trainer.tSeq == this.myInfo.getTrainerSeq());
        if (index >= 0) {
          trainers.unshift(trainers.splice(index, 1)[0]);
        }
      }

      return trainers;

      // return new Promise(async (resolve, reject) => {
      //   try {
      //     if (!this.gymTrainers || refresh) {
      //       const res = await this.fittUtil.remoteApi({
      //         api: 'b2b/gym/getGymTrainers',
      //         param: {
      //           gSeq: gSeq,
      //           token: await this.getAuthToken(),
      //         }
      //       });
      //       // this.log('trainers res : ', res.trainerList);
      //       const trainers = [];
      //       for (const data of res.trainerList) {
      //         const trainer = new Trainer(data);
      //         if (!trainer.tName || trainer.tName.length == 0) continue;
      //         trainers.push(trainer);
      //       }
      //       trainers.sort((a, b) => {
      //         if ((a.tOrder || 10000) < (b.tOrder || 10000)) {
      //           return -1;
      //         } else if ((a.tOrder || 10000) > (b.tOrder || 10000)) {
      //           return 1;
      //         } else {
      //           return a.tName < b.tName ? 1 : a.tName > b.tName ? -1 : 0;
      //         }
      //       });
      //       this.gymTrainers = trainers;
      //     }

      //     const trainers = retire ? this.gymTrainers.map(t => t) : this.gymTrainers.filter(t => !t.tRetire).map(t => t);
      //     if (meFirst) {
      //       const index = trainers.findIndex(trainer => trainer.tSeq == this.myInfo.getTrainerSeq());
      //       if (index >= 0) {
      //         trainers.unshift(trainers.splice(index, 1)[0]);
      //       }
      //     }
      //     resolve(trainers);
      //   }
      //   catch (error) {
      //     this.error('getGymTrainers');
      //     reject(error);
      //   }
      // });
    },
    getGymUsers: (args: {
      gSeq?: number,
      tSeq?: number,
      uSeq?: number,
      refresh?: boolean,
      includeInvisible?: boolean,
      separately?: boolean,
    }): Promise<any> => {
      const { tSeq, uSeq, refresh, includeInvisible, separately } = args;
      const gSeq = args.gSeq || this.myInfo.getGymSeq();
      return new Promise(async (resolve, reject) => {
        try {
          if (separately) {
            const res = await this.fittUtil.remoteApi({
              api: 'b2b/gym/getGymUsers',
              param: { tok: await this.getAuthToken(), gSeq, tSeq, uSeq, includeInvisible }
            });
            if (res.code == 1) {
              const users: Array<GymUser> = [];
              for (const record of res.result) {
                users.push(new GymUser({
                  user: record.uSeq ? new User(record) : undefined,
                  member: record.mSeq ? new Member(record) : undefined,
                  trainer: record.tSeq ? new Trainer(record) : undefined
                }));
              }
              res.result = users;
            }
            resolve(res);
            return;
          }

          if (this.gymUsers && !uSeq && !tSeq && !refresh) {
            this.log('getGymUsers cache');
            resolve({ code: 1, result: this.gymUsers });
            return;
          }
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymUsers',
            param: {
              tok: await this.getAuthToken(),
              gSeq, tSeq, uSeq
            }
          });
          if (res.code == 1) {
            const users: Array<GymUser> = [];
            for (const record of res.result) {
              users.push(new GymUser({
                user: record.uSeq ? new User(record) : undefined,
                member: record.mSeq ? new Member(record) : undefined,
                trainer: record.tSeq ? new Trainer(record) : undefined
              }));
            }
            if (!uSeq && !tSeq) {
              this.gymUsers = users;
            }
            res.result = users;
          }
          resolve(res);
        }
        catch (error) {
          this.error('getGymUsers');
          reject(error);
        }
      });
    },
    getGymUserStatus: (args: {
      gSeq: number,
      tSeq?: number,
      queryStartDate?: Date,
      queryEndDate?: Date,
    }): Promise<UserStatusRecord[]> => {
      const { gSeq, tSeq, queryStartDate, queryEndDate } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymUserStatus',
            param: { tok: await this.getAuthToken(), gSeq, tSeq, qLocalStartDate: queryStartDate.format('yyyy-MM-dd'), qLocalEndDate: queryEndDate.format('yyyy-MM-dd') }
          });
          const { result } = res;
          resolve(result);
        }
        catch (error) {
          this.error('getGymUserStatus');
          reject(error);
        }
      });
    },

    getUserCountPerTrainer: (args: {
      gSeq: number,
      tSeq?: number,
      month?: string,
      uState?: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getUserCountPerTrainer',
            param: {
              tok: await this.getAuthToken(), ...args
            }
          });
          resolve(res);
        }
        catch (error) {
          this.error('getUserCountPerTrainer');
          reject(error);
        }
      });
    },

    addGymUser: (args: {
      user: User
    }): Promise<any> => {
      this.log('addGymUser', args);
      const { user } = args;
      return new Promise(async (resolve, reject) => {
        try {
          if (!user.gSeq) {
            user.gSeq = (await this.auth.getMyInfo()).getGymSeq();
          }

          let fieldValues: any = {
            GYM_Seq: user.gSeq,
            USER_Name: user.uName,
            USER_Gender: user.uGender,
            USER_Birth: user.uBirth ? user.uBirth.format('yyyy-MM-dd') : null,
            USER_Tel: user.uTel,
            USER_Tel2: user.uTel2, //비상연락처
            USER_Email: user.uEmail,
            USER_State: user.uState,
            USER_Profile: user.uProfile,
            USER_Height: user.uHeight,
            USER_Weight: user.uWeight,
            USER_Trainer_Seq: user.tSeq,
            USER_Connect: 0,
            USER_Friend_Seq: user.uFriendSeq,
          };

          if (user.uInDate) {
            fieldValues['USER_InDate'] = user.uInDate.format('yyyy-MM-dd HH:mm:ss');
          }

          const isMigratedGym = await this.fittUtil.remoteApi({ api: 'backdoor/isMigratedGym', param: { gSeq: user.gSeq } });
          this.log('isMigratedGym', isMigratedGym);

          if (isMigratedGym.result) {
            const qFitt3 = this.util.makeInsertQuery({
              table: 'FITT.TUSER',
              fieldValues: {
                USER_GYM_Seq: user.gSeq,
                USER_Trainer_Seq: user.tSeq,
                USER_Name: user.uName,
                USER_Sex: user.uGender === 'male' ? 'man' : 'woman',
                USER_Birth: user.uBirth.format('yyyy-MM-dd'),
                USER_Tel: user.uTel,
                USER_Tel2: user.uTel2,
                USER_Address: user.uEmail
              }
            });
            this.log('setFitt3User', qFitt3);
            const resFitt3 = await this.fittUtil.remoteApi({ api: 'backdoor/setFitt3User', param: { q: qFitt3 } });
            this.log('setFitt3User res', resFitt3);

            const resFitt3Physical = await this.fittUtil.remoteApi({
              api: 'backdoor/setFitt3UserPhysical',
              param: {
                uSeq: resFitt3.result.insertId,
                uWeight: user.uWeight,
                uHeight: user.uHeight
              }
            });
            this.log('setFitt3UserPhysical res', resFitt3Physical);

            fieldValues = {
              USER_Seq: resFitt3.result.insertId,
              ...fieldValues
            }
          }

          const [record] = await this.getRecord({
            table: 'FITT_DB.TMEMBER', fields: ['MEMBER_Visible'],
            where: { MEMBER_Email: user.uEmail }
          });
          const existMember = record ? record.MEMBER_Visible : false;
          if (existMember) {
            fieldValues['USER_Connect'] = 1;
          }
          const res = await this.setRecord({ table: 'FITT_DB.TUSER', record: fieldValues, syncData: true });

          this.log('addGymUser res', res);

          if (res.code == 1) {
            if (this.gymUsers) {
              const resUser = await this.fittUtil.remoteApi({
                api: 'b2b/gym/getGymUsers',
                param: {
                  tok: await this.getAuthToken(),
                  gSeq: user.gSeq, uSeq: res.insertId
                }
              });
              this.log('addGymUser resUser', resUser);
              if (resUser.code == 1) {
                const record = resUser.result[0];
                this.gymUsers.push(new GymUser({
                  user: record.uSeq ? new User(record) : undefined,
                  member: record.mSeq ? new Member(record) : undefined,
                  trainer: record.tSeq ? new Trainer(record) : undefined
                }));
                this.gymUsers.sort((a: GymUser, b: GymUser) => {
                  return a.user.uName > b.user.uName ? 1 : a.user.uName < b.user.uName ? -1 : 0;
                });
              }
            }
            this.events.publish('member-list:event', { gymUsers: this.gymUsers });
          }
          resolve(res);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    deleteGymUser: (args: {
      user?: User,
      seq?: number
    }): Promise<any> => {
      this.log('deleteGymUser', args);
      const { user, seq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const uSeq = user.uSeq || seq;
          const res = await this.fittUtil.remoteApi({ api: 'b2b/gym/deleteGymUser', param: { gSeq: user.gSeq, uSeq: uSeq } });
          const code = res.code;
          resolve(res);
          if (code == 1) {
            if (this.gymUsers) {
              const index = this.gymUsers.findIndex(gymUser => gymUser.user.uSeq == uSeq);
              this.log('deleteGymUser', index, this.gymUsers[index]);
              this.gymUsers[index].isDeleted = true;
              if (index !== undefined) {
                this.gymUsers.splice(index, 1);
              }
            }
            this.events.publish('member-list:event', { gymUsers: this.gymUsers });
          }
          resolve(res);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    updateGymUser: (args: {
      user: User,
    }): Promise<any> => {
      this.log('updateGymUser', args);
      const { user } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const fieldValues = {
            GYM_Seq: user.gSeq,
            USER_Name: user.uName,
            USER_Gender: user.uGender,
            USER_Birth: user.uBirth.format('yyyy-MM-dd'),
            USER_Tel: user.uTel,
            USER_Tel2: user.uTel2, //비상연락처
            USER_Email: user.uEmail,
            USER_State: user.uState,
            USER_Profile: user.uProfile,
            USER_Height: user.uHeight,
            USER_Weight: user.uWeight,
            USER_Trainer_Seq: user.tSeq,
            USER_Connect: user.uConnect,
            USER_Friend_Seq: user.uFriendSeq,
          };

          const res = await this.setRecord({ table: 'FITT_DB.TUSER', record: fieldValues, where: { USER_Seq: user.uSeq }, syncData: true, syncId: user.uSeq });
          if (res.code == 1) {
            const res = await this.fittUtil.remoteApi({
              api: 'b2b/gym/getGymUsers',
              param: {
                tok: await this.getAuthToken(),
                uSeq: user.uSeq
              }
            });
            if (res.code == 1 && this.gymUsers) {
              const userInfo = this.gymUsers.find(u => u.user.uSeq == user.uSeq);
              if (userInfo) {
                const record = res.result[0];
                userInfo.user = record.uSeq ? new User(record) : undefined;
                userInfo.member = record.mSeq ? new Member(record) : undefined;
                userInfo.trainer = record.tSeq ? new Trainer(record) : undefined;
              }
              this.events.publish('member-list:event', { gymUsers: this.gymUsers });
            }
          }
          resolve(res);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    getRetireList: (args: {
      gSeq: number,
      queryStartDate: Date,
      queryEndDate: Date,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getRetireList',
            param: args,
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

    // pt-report
    getPTReportList: (args: { gSeq?: number, tSeq?: number, uSeq?: number, type: 'all' | 'complete' | 'none', offset: number, contentsPerPage: number }): Promise<any> => {
      const { uSeq, gSeq, type } = args;
      this.log('getPTReportList', args);
      return new Promise(async (resolve, reject) => {
        const whereConditions = [];

        gSeq && whereConditions.push(`GYM_Seq = ${gSeq}`);
        uSeq && whereConditions.push(`USER_Seq = ${uSeq}`);
        let stateCondition;
        switch (type) {
          case 'complete': stateCondition = 'SCHEDULE_ReportKey IS NOT NULL'; break;
          case 'none': stateCondition = 'SCHEDULE_ReportKey IS NULL'; break;
        }
        stateCondition && whereConditions.push(stateCondition);

        try {
          // const q = `
          //         SELECT schedule.SCHEDULE_Seq            as sSeq,
          //                trainer.TRAINER_Seq              as tSeq,
          //                user.USER_Seq                    as uSeq,
          //                payment.USER_PAYMENT_Seq         as pSeq,
          //                schedule.GYM_Seq                 as gSeq,
          //                user.USER_Name                   as uName,
          //                schedule.SCHEDULE_StartDate      as sStartDate,
          //                schedule.SCHEDULE_EndDate        as sEndDate,
          //                schedule.SCHEDULE_SessionOrder   as sOrder,
          //                payment.USER_PAYMENT_Session     as pSession,
          //                payment.USER_PAYMENT_MAX_Session as pMaxSession,
          //                schedule.SCHEDULE_ReportKey      as sReportKey,
          //                trainer.TRAINER_Name             as tName
          //         FROM FITT_DB.TSCHEDULE as schedule JOIN
          //              FITT_DB.TUSER_PAYMENT as payment ON schedule.USER_PAYMENT_Seq = payment.USER_PAYMENT_Seq JOIN
          //              FITT_DB.TUSER as user ON schedule.USER_Seq = user.USER_Seq JOIN
          //              FITT_DB.TTRAINER as trainer ON schedule.TRAINER_Seq = trainer.TRAINER_Seq
          //         WHERE ${whereConditions.join(' AND ') + (whereConditions.length > 0 ? ' AND' : '')}
          //               schedule.SCHEDULE_Type = 'PT' AND
          //               schedule.SCHEDULE_StartDate BETWEEN DATE_ADD(NOW(), INTERVAL -30 DAY ) AND NOW() AND
          //               (schedule.SCHEDULE_State = 2 OR
          //                schedule.SCHEDULE_State = 4 OR
          //                schedule.SCHEDULE_State = 6 OR
          //                schedule.SCHEDULE_State = 7 OR
          //                schedule.SCHEDULE_State = 11 OR
          //                schedule.SCHEDULE_State = 12 OR
          //                schedule.SCHEDULE_State = 13 OR
          //                schedule.SCHEDULE_State = 15 OR
          //                schedule.SCHEDULE_State = 5) AND schedule.SCHEDULE_Visible = 1
          //         ORDER BY schedule.SCHEDULE_StartDate DESC
          //         LIMIT ${offset}, ${contentsPerPage};
          // `;
          const q1 = `
                  SELECT schedule.SCHEDULE_Seq            as sSeq,
                         trainer.TRAINER_Seq              as tSeq,
                         user.USER_Seq                    as uSeq,
                         payment.USER_PAYMENT_Seq         as pSeq,
                         schedule.GYM_Seq                 as gSeq,
                         user.USER_Name                   as uName,
                         schedule.SCHEDULE_StartDate      as sStartDate,
                         schedule.SCHEDULE_EndDate        as sEndDate,
                         schedule.SCHEDULE_SessionOrder   as sOrder,
                         payment.USER_PAYMENT_Session     as pSession,
                         payment.USER_PAYMENT_MAX_Session as pMaxSession,
                         schedule.SCHEDULE_ReportKey      as sReportKey,
                         trainer.TRAINER_Name             as tName
                  FROM (
                        SELECT * FROM FITT_DB.TSCHEDULE
                        WHERE ${whereConditions.join(' AND ') + (whereConditions.length > 0 ? ' AND' : '')}
                            SCHEDULE_Type = 'PT' AND
                            SCHEDULE_StartDate BETWEEN DATE_ADD(NOW(), INTERVAL -30 DAY ) AND NOW() AND
                            SCHEDULE_State IN (2,4,5,6,7,11,12,13,15) AND
                            SCHEDULE_Visible = 1
                        ORDER BY SCHEDULE_StartDate DESC
                        LIMIT 0, 50
                        ) as schedule LEFT JOIN FITT_DB.TUSER_PAYMENT as payment ON schedule.USER_PAYMENT_Seq = payment.USER_PAYMENT_Seq
                      JOIN FITT_DB.TUSER as user ON schedule.USER_Seq = user.USER_Seq
                      JOIN FITT_DB.TTRAINER as trainer ON schedule.TRAINER_Seq = trainer.TRAINER_Seq;
          `;

          this.log('getPTReportList.q', q1);
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q1 } });
          this.log('getPTReportList.res', res);
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },

    // schedule
    countSchedule: (args: { gSeq: number, tSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { gSeq, tSeq } = args;
        const conditions: Array<string> = [];
        gSeq && conditions.push(`GYM_Seq=${gSeq}`);
        tSeq && conditions.push(`TRAINER_Seq=${tSeq}`);
        try {
          const q = `
            SELECT count(*) as count FROM FITT_DB.TSCHEDULE WHERE ${conditions.join(' AND ')}
          `;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          const [{ count }] = res.result;
          this.log('countSchedule', q, res, count);
          resolve(count);
        } catch (error) {
          reject(error);
        }
      });
    },
    getManageBookingData: (args: {
      type: string, gSeq?: number, tSeq?: number, uSeq?: number, state?: string, offset?: number, contentsPerPage?: number,
      order?: { [key: string]: 'asc' | 'desc' }, sSeq?: number,
    }): Promise<Array<LessonSchedule>> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getManageBookingData',
            param: args
          });
          this.log('getManageBookingData', { res });
          resolve(res.result.map(d => {
            if (d.etcData) {
              const noShowDatas = d.etcData.indexOf(',') > 0 ? d.etcData.split(',') : [d.etcData];

              let addingResult;
              if (noShowDatas.length === 1) {
                addingResult = { numerator: 1, denominator: noShowDatas[0] };
              }
              for (let i = 1; i < noShowDatas.length; i++) {
                addingResult = i === 1 ? this.util.addingFraction({ numerator: 1, denominator: noShowDatas[0] }, { numerator: 1, denominator: noShowDatas[1] }) : this.util.addingFraction({ numerator: addingResult.numerator, denominator: addingResult.denominator }, { numerator: 1, denominator: noShowDatas[i] });
              }
              d.etcData = addingResult;
            }
            const schedule = new LessonSchedule(d);
            const age = this.util.getAge(schedule.uBirth);
            const tVal = this.translate.instant(['컴포넌트.남', '컴포넌트.여', '컴포넌트.만 _세'], { age: age });
            schedule['subText'] = `${schedule.uGender == 'male' ? tVal['컴포넌트.남'] : tVal['컴포넌트.여']}/${tVal['컴포넌트.만 _세']}`;
            schedule['startDate'] = new Date(schedule.sStartDate);
            schedule['inDate'] = new Date(schedule.sInDate);
            return schedule;
          }));
        } catch (error) {
          reject(error);
        }
      });
    },
    getGXDetail: (args: { gSeq?: number, uSeq?: number, cSeq: number, hSeq: number, startDate: string, endDate?: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGXDetail',
            param: {
              ...args,
              tSeq: this.myInfo.getTrainerSeq(),
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGXParticipantsDetail: (args: { hSeq: number, date: string, gSeq: number, cSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          this.log('getGXParticipantsDetail');
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGXParticipantsDetail',
            param: {
              ...args,
              tSeq: this.myInfo.getTrainerSeq(),
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGXParticipants: (args: { cSeq: number, date: string, onlyConfirm?: boolean }): Promise<any> => {
      const { cSeq, date, onlyConfirm } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = `
          SELECT
            schedule.SCHEDULE_Seq as sSeq,
            schedule.GYM_Seq as gSeq,
            schedule.TRAINER_Seq as tSeq,
            schedule.USER_Seq as uSeq,
            schedule.USER_PAYMENT_Seq as pSeq,
            schedule.SCHEDULE_BundleSeq as sBundleSeq,
            schedule.SCHEDULE_Type as sType,
            schedule.SCHEDULE_State as sState,
            schedule.SCHEDULE_StartDate as sStartDate,
            schedule.SCHEDULE_EndDate as sEndDate,
            schedule.SCHEDULE_MemoTitle as sMemoTitle,
            schedule.SCHEDULE_Memo as sMemo,
            schedule.SCHEDULE_Reason as sReason,
            schedule.SCHEDULE_Session as sSession,
            schedule.SCHEDULE_InDate as sInDate,
            schedule.SCHEDULE_ModifyDate as sModifyDate,
            schedule.SCHEDULE_SessionOrder as sOrder,
            schedule.SCHEDULE_SessionMax as sSessionMax,
            package.PACKAGE_Name as pName,
            user.USER_Name as uName,
            user.USER_Profile as uProfile
          FROM  FITT_DB.TCLASS as class JOIN
                FITT_DB.TCLASS_HOUR as hour on class.CLASS_Seq = hour.CLASS_Seq JOIN
                FITT_DB.TSCHEDULE as schedule on class.CLASS_Seq = schedule.CLASS_Seq AND schedule.SCHEDULE_StartDate = '${date}' JOIN
                FITT_DB.TUSER_PAYMENT as payment on schedule.USER_PAYMENT_Seq = payment.USER_PAYMENT_Seq JOIN
                FITT_DB.TPACKAGE as package on payment.PACKAGE_Seq = package.PACKAGE_Seq JOIN FITT_DB.TUSER as user ON schedule.USER_Seq = user.USER_Seq
          WHERE class.CLASS_Seq = ${cSeq} AND class.CLASS_Visible = 1 ${onlyConfirm ? 'AND (schedule.SCHEDULE_State = 6 OR schedule.SCHEDULE_State = 7 OR schedule.SCHEDULE_State = 4)' : ''};
          `
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          this.log('getGXParticipants', { q, res });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    insertSchedule: (args: {
      gymSeq: number,
      trainerSeq: number,
      userSeq: any,
      writerEmail?: string,
      userPaymentSeq: string,
      paSeq?: string,
      scheduleType: string,
      scheduleState: number,
      scheduleStartDateList: string,
      scheduleEndDateList: string,
      scheduleMemo: string,
      scheduleSession: number,
      scheduleTrainerCheckDate: string,
      scheduleMemoTitle: string,
      scheduleReason: string,
      scheduleUserCheckDate: string,
      scheduleMemoAllDay: number,
      isEdit?: boolean,
      sSeq?: number,
      isCurrentLangKo?: boolean
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const htSeq = (await this.auth.getMyInfo()).getTrainerSeq();
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/insertSchedule',
            param: {
              ...args,
              token: await this.getAuthToken(),
              uid: localStorage.uid,
              htSeq: htSeq,
              isCurrentLangKo: this.util.isCurrentLangKo()
            }
          });

          if (res.code === 1 && !this.listenScheduleState) {
            const { newSchedules } = res;
            for (let idx = 0; idx < newSchedules.length; idx++) {
              const newSchedule = newSchedules[idx];
              this.setLessonMap(newSchedule);

              if (idx === 0) {
                this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(newSchedule), pSeq: newSchedule.pSeq });
              } else {
                this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(newSchedule), });
              }
            }
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

    getRecentSchedules: (args: { sSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getRecentSchedules',
            param: args
          });
          if (res.code == 1) {
            res.pastSchedules = res.pastSchedules.map(s => new Schedule(s));
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getChangeApplyTime: (args: { sSeq: number }): Promise<any> => {
      const { sSeq } = args;
      return new Promise(async (resolve, reject) => {
        const param = this.myInfo.getTrainerSeq() ? {
          sSeq: sSeq,
          token: await this.getAuthToken(),
          tSeq: this.myInfo.getTrainerSeq()
        } : {
            sSeq: sSeq,
            token: await this.getAuthToken(),
            uSeq: this.myInfo.getUserSeq(true)
          };
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getChangeApplyTime',
            param: param
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getScheduleLogByTime: (args: { startDate?: string, endDate?: string, tSeq?: number, uSeq?: number, sSeq?: number, sAllDay?: boolean, }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getScheduleLogByTime',
            param: args
          });
          this.log('getScheduleLogByTime.res', res);
          const scheduleLogArray: Array<Schedule> = [];
          for (const schedule of res.result) {
            const s = new Schedule(schedule);

            if (s.shouldBeCompleted()) {
              const sComplete = new Schedule(schedule);
              sComplete.sState = ScState.Complete;
              sComplete.lInDate = s.sEndDate;
              scheduleLogArray.push(sComplete);
            }
            scheduleLogArray.push(s);
          }
          res.scheduleLogArray = scheduleLogArray;
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymScheduleSettings: (args: {}): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const myInfo = await this.auth.getMyInfo();
          const param = {
            gSeq: myInfo.getGymSeq(),
            tSeq: myInfo.getTrainerSeq(),
            token: await this.getAuthToken()
          };
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGymScheduleSettings',
            param: param
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    insertGymScheduleSettings: (settings: GymSettings): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const myInfo = await this.auth.getMyInfo();
          const param = {
            ...settings,
            gSeq: myInfo.getGymSeq(),
            tSeq: myInfo.getTrainerSeq(),
            token: await this.getAuthToken()
          };
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/insertGymScheduleSetting',
            param: param
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    applyPT: (args: {
      gSeq: number, tSeq: number, uSeq: number, pSeq?: number, cSeq?: number, hSeq?: number, paSeq?: number, sSession: number, sStartDate: string, sEndDate: string, sType: string, isCurrentLangKo: boolean
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/applyPT',
            param: { ...args, uid: localStorage.uid, writerEmail: localStorage.myEmail, token: await this.getAuthToken() }
          });
          this.log('applyPT.res', res);
          if (res.code === 1 && !this.listenScheduleState) {
            const { newSchedule } = res;
            this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(newSchedule), });
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    applyGX: (args: { gSeq: number, uSeq: number, tSeq: number, hSeq: number, upSeq: number, startDate: string, isTrainer: boolean }): Promise<any> => {
      const { gSeq, uSeq, hSeq, upSeq, startDate, isTrainer } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const param = {
            token: await this.getAuthToken(),
            gSeq, uSeq, hSeq, upSeq, startDate,
            isTrainer: isTrainer ? 1 : 0,
            tSeq: isTrainer ? this.myInfo.getTrainerSeq() : undefined,
            htSeq: isTrainer ? this.myInfo.getTrainerSeq() : undefined,
          };
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/applyGX',
            param: param
          });
          this.log('applyGX', res);
          if (res.code === 1 && !this.listenScheduleState) {
            const { lessonSchedule } = res;
            this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(lessonSchedule), });
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    checkDuplicateContainGX: (args: { uSeq: number, hSeq: number, date: string }): Promise<any> => {
      const { uSeq, hSeq, date } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/checkDuplicateContainGX',
            param: {
              token: await this.getAuthToken(),
              uSeq: uSeq,
              hSeq: hSeq,
              date: date
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentSchedules: (args: { pSeq: number }): Promise<any> => {
      const { pSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getPaymentSchedules',
            param: {
              pSeq: pSeq,
              tSeq: this.myInfo.getTrainerSeq(),
              token: await this.getAuthToken()
            }
          });
          const sArray = [];
          for (const s of res.result) {
            const schedule = new Schedule(s);
            sArray.push(schedule);
          }
          res.result = sArray;
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    removeVirtual: (args: { sSeq: number }): Promise<any> => {
      const { sSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'b2b/schedule/removeVirtual', param: { sSeq, htSeq: this.myInfo.getTrainerSeq() } });
          resolve(JSON.parse(res.code[0].result));
        } catch (error) {
          reject(error);
        }
      });
    },
    changeVirtualToReal: (args: { sSeq: number }): Promise<any> => {
      const { sSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/changeVirtualToReal',
            param: {
              sSeq, htSeq: this.myInfo.getTrainerSeq(),
              writerEmail: localStorage.myEmail,
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getPastVirtualClassCount: (args: { gSeq: number, tSeq?: number }): Promise<number> => {
      const { gSeq, tSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const conditions: Array<string> = [];
          conditions.push(`schedule.GYM_Seq = ${gSeq}`);
          tSeq && conditions.push(`schedule.TRAINER_Seq = ${tSeq}`);
          conditions.push(`schedule.SCHEDULE_State = 14`);
          conditions.push(`schedule.SCHEDULE_EndDate <= NOW()`);
          conditions.push(`schedule.SCHEDULE_Visible = 1`);
          const q = `
            SELECT count(*) as result
            FROM FITT_DB.TSCHEDULE as schedule
              JOIN FITT_DB.TUSER as user on schedule.USER_Seq = user.USER_Seq
              JOIN FITT_DB.TTRAINER as trainer on schedule.TRAINER_Seq = trainer.TRAINER_Seq
            WHERE ${conditions.join(' AND ')}
            ORDER BY schedule.SCHEDULE_StartDate DESC;
          `;
          // this.log('getPastVirtualClassCount', q);
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          const { result } = res.result[0];
          this.log('getPastVirtualClassCount', result);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateSchedule: (args: {
      sSeq: number,
      beforeState?: number,
      sState?: number,
      startDate?: Date,
      endDate?: Date,
      changeStartDate?: Date,
      sMemoTitle?: string,
      sMemo?: string,
      sMemoAllDay?: boolean,
      userCheck?: boolean,
      trainerCheck?: boolean,
      noshowSession?: number,
      sBundleSeq?: number,
      pSeq?: number,
      prevSstate?: number,
      uptuSeq?: number,
      paSeq?: number,
      isCurrentLangKo?: boolean

    }): Promise<any> => {
      const { sSeq, sState, startDate, endDate, changeStartDate, sMemoTitle, sMemo, sMemoAllDay, userCheck, trainerCheck, noshowSession, sBundleSeq, pSeq, prevSstate, uptuSeq, paSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const myRole = this.myInfo.getRole();
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateSchedule',
            param: {
              sSeq,
              sState,
              startDate: startDate && startDate.format('yyyy-MM-dd HH:mm:ss'),
              endDate: endDate && endDate.format('yyyy-MM-dd HH:mm:ss'),
              changeStartDate: changeStartDate && changeStartDate.format('yyyy-MM-dd HH:mm:ss'),
              sMemoTitle,
              sMemo,
              sMemoAllDay,
              userCheck,
              trainerCheck,
              isTrainer: myRole === 'trainer',
              token: await this.getAuthToken(),
              uSeq: this.myInfo.getUserSeq(true),
              htSeq: this.myInfo.getTrainerSeq(),
              noshowSession,
              sBundleSeq,
              prevSstate,
              writerEmail: localStorage.myEmail,
              pSeq,
              uptuSeq,
              paSeq,
              isCurrentLangKo: this.util.isCurrentLangKo()
            }
          });
          this.log('updateSchedule', { sSeq, sState, res, pSeq });
          if (res.code === 1) {
            const { lessonSchedules, pSeq } = res;
            if (BookingState.Cancel.includes(sState) || ScState.Delete == sState) {
              const lessonSchedule = this.lessons && this.lessons.find(lesson => lesson.sSeq == sSeq);
              this.log('updateSchedule before lesson', { lessons: this.lessons, lessonSchedule, lessonSchedules, listenScheduleState: this.listenScheduleState });
              this.setLessonMap(lessonSchedule, false);
            }

            if (!this.listenScheduleState) {
              for (let lessonSchedule of lessonSchedules) {
                if (!lessonSchedule) {
                  continue;
                }
                const getRemoveStateList: { [role in MemberRole]: () => ScState[] } = {
                  'trainer': () => [ScState.Delete],
                  'user': () => [ScState.Delete, ScState.CancelRequest, ScState.Cancel],
                  'none': () => [ScState.Delete],
                };
                const removeStateList = getRemoveStateList[myRole]();
                if (removeStateList.includes(sState)) {
                  this.setLessonMap(lessonSchedule, false);
                }
                if (this.isOverlapped(lessonSchedule)) continue;

                const mapKey = this.fittUtil.schedule.getScheduleKey(lessonSchedule);
                if (lessonSchedule.sType == 'PT' && BookingState.NoShow.includes(lessonSchedule.sState)) {
                  this.noshowLessonMap[mapKey] = true;
                }

                if (this.fittUtil.schedule.isRedBackground(lessonSchedule, myRole)) {
                  this.cancelLessonMap[mapKey] = true;
                }
                lessonSchedule = this.makeLessonSchedule(lessonSchedule);
                this.events.publish('refreshScheduleItem', { schedule: lessonSchedule, pSeq });
              }
            }
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    listenSchedules: async (state: boolean) => {
      if (this.listenScheduleState == state) return;

      const myInfo = await this.auth.getMyInfo();
      const gSeq = myInfo.getGymSeq();
      if (state) {
        const refPath = `gym-events/${gSeq}/Schedule`;
        const recentVal = await (await this.afData.database.ref(refPath).limitToLast(1).once('value')).val();
        const lastKey = recentVal && Object.keys(recentVal).length > 0 ? Object.keys(recentVal)[0] : null;
        const lastNextKey = lastKey ? this.util.keyEncode(this.util.keyDecode(lastKey) + 1) : null;
        const query = lastNextKey == null ? this.afData.database.ref(refPath).orderByKey() : this.afData.database.ref(refPath).orderByKey().startAt(lastNextKey);

        query.on('child_added', async (snapshot) => {
          const scheduleEvent: ScheduleEvent = snapshot.val();
          const { scheduleData, orgPaymentSeq: pSeq } = scheduleEvent;
          let schedule: LessonSchedule;

          // ! 기존 이벤트와 호환용. 1.1.10 상용 배포 후 제거
          if (scheduleData) {
            schedule = scheduleData;
          } else if (typeof scheduleEvent === 'object') {
            schedule = new LessonSchedule(scheduleEvent);
          } else {
            this.error('listenSchedules.child_added invalid schedule');
            return;
          }
          this.setLessonMap(schedule, schedule.sState != ScState.Delete);

          const mapKey = this.fittUtil.schedule.getScheduleKey(schedule);
          this.noshowLessonMap[mapKey] = schedule.sType == 'PT' && BookingState.NoShow.includes(schedule.sState);
          this.cancelLessonMap[mapKey] = this.fittUtil.schedule.isRedBackground(schedule, this.fittUtil.getMyRole());

          this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(schedule), pSeq });
        });
      }
      else {
        this.afData.database.ref(`gym-events/${gSeq}/TSCHEDULE`).off();
      }
      this.listenScheduleState = state;
    },
    getLessonSchedules: (args: {
      gSeq: number,
      uSeq?: number,
      sType?: string,
      queryStartDate: Date,
      queryEndDate: Date,
      tSeq?: number,
      lStates?: Array<string>,
      schedules?: any,
      dateArrange?: boolean
    }): Promise<any> => {
      const { gSeq, uSeq, tSeq, sType, queryStartDate, queryEndDate, lStates, schedules } = args;
      const dateArrange = args.dateArrange !== undefined ? args.dateArrange : true;
      return new Promise(async (resolve, reject) => {
        try {
          if (schedules) {
            schedules.queryStartDate = schedules.queryStartDate && schedules.queryStartDate.format('yyyy-MM-dd HH:mm:ss');
            schedules.queryEndDate = schedules.queryEndDate && schedules.queryEndDate.format('yyyy-MM-dd HH:mm:ss');
          }
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getLessonSchedules',
            param: {
              gSeq, uSeq, tSeq, sType, lStates, schedules,
              queryStartDate: queryStartDate && queryStartDate.format('yyyy-MM-dd HH:mm:ss'),
              queryEndDate: queryEndDate && queryEndDate.format('yyyy-MM-dd HH:mm:ss'),
              isTrainer: this.myInfo.getRole() == 'trainer',
              token: await this.getAuthToken(),
            }
          });
          // res.lessons = res.lessons.map(lesson => {
          //   for (const key of Object.keys(lesson)) {
          //     if (!key.includes('Date') || !lesson[key]) continue;
          //     lesson[key] = lesson[key].replace('+09:00', 'Z');
          //   }
          //   return lesson;
          // });

          // this.log('getLessonSchedules.res', res);
          // this.log('getLessonSchedules.filter trainer', res.lessons.filter(l => l.tSeq == 1000001826));
          // this.log('getLessonSchedules.filter user', res.lessons.filter(l => l.uSeq == 1000002184));
          // this.log('getLessonSchedules.filter gx', res.lessons.filter(l => l.sType == 'GX'));
          // this.log('getLessonSchedules.filter seq', res.lessons.filter(l => l.sSeq == 1000777701));

          const result: any = {};
          if (res.code == 1) {
            this.lessonMap = {};
            this.lessons = [];
            const now = new Date();
            const myRole = this.myInfo.getRole();
            const lessons = res.lessons
              .map(lesson => this.makeLessonSchedule(lesson))
              .filter(lesson => {
                const mapKey = this.fittUtil.schedule.getScheduleKey(lesson);
                if (lesson.sNoShowConfirmDate && BookingState.NoShow.includes(lesson.sState)) {
                  // PT만 노란 툴팁
                  lesson.sType === 'PT' && (this.noshowLessonMap[mapKey] = true);
                  if ([ScState.NoShowByTrainer, ScState.NoShowReset].includes(lesson.sState)) return false;
                }
                // 출력하지 않는 lessonSchedule filter.
                if (this.fittUtil.schedule.isRedBackground(lesson, myRole)) {
                  this.cancelLessonMap[mapKey] = true;
                }

                if (myRole === 'trainer') {
                  if ([ScState.CancelByTrainer, ScState.Reject].includes(lesson.sState)) {
                    return false;
                  }
                } else {  // user
                  if (lesson.sType == 'MEMO') return false;
                  if ([ScState.CancelByUser, ScState.RegisterVirtual, ScState.CancelRequest].includes(lesson.sState)) {
                    return false;
                  }
                }

                // 등록된 수업
                BookingState.RedWithCancel.includes(lesson.sState) && (this.showLessonMap[mapKey] = true);
                this.setLessonMap(lesson, true, lStates);
                return true;
              })
              .filter((lesson: LessonSchedule) => {
                // 겹치는 open/close 정리
                if (!lStates && this.isOverlapped(lesson)) {
                  lesson.tSeq == 1000000019 && this.log('getLessonSchedules.filter false', lesson);
                  return false;
                }

                if (myRole === 'user' && lesson.isOpen() && lesson.sStartDate < now) {
                  return false;
                }

                // sNoShowConfirmDate가 존재하는 노쇼 스케줄은 화면에 출력하지 않고 해당 시간의 lesson에 툴팁을 처리한다.
                if (BookingState.NoShow.includes(lesson.sState) && lesson.sNoShowConfirmDate) return false;
                const mapKey = this.fittUtil.schedule.getScheduleKey(lesson);

                const isCheckedCancel = this.fittUtil.schedule.isCheckedSchedule('cancel', lesson, myRole);
                if (isCheckedCancel) return false;

                const isDuplicate = !isCheckedCancel && this.showLessonMap[mapKey];
                const hideList = myRole === 'trainer' ? BookingState.HideTrainerCheck : BookingState.HideUserCheck;
                if (isDuplicate && hideList.includes(lesson.sState)) return false;
                lesson.sState === ScState.CancelByUser && this.log('getLessonSchedules cu', { lesson, thisLesson: this.lessons.map(ele => ele) });
                if (BookingState.HideTrainerCheck.includes(lesson.sState)) this.setLessonMap(lesson, false);
                lesson.sState === ScState.CancelByUser && this.log('getLessonSchedules cu', { lesson, thisLesson: this.lessons });

                return true;
              })
              .sort((a, b) => {
                return a.sAllDay && !b.sAllDay ? -1 : !a.sAllDay && b.sAllDay ? 1 :
                  a.sStartDate < b.sStartDate ? -1 : a.sStartDate > b.sStartDate ? 1 : 0
              });

            // this.log('getLessonSchedules', { lessons });
            if (dateArrange) {
              for (const lesson of lessons) {
                for (let date = lesson.sStartDate; date.format('yyyy-MM-dd') <= lesson.sEndDate.format('yyyy-MM-dd'); date = date.getNewDate('date', 1)) {
                  const dayKey = date.format('yyyy-MM-dd');
                  if (!result[dayKey]) result[dayKey] = new DaySchedule(dayKey);
                  const daySchedule: DaySchedule = result[dayKey];
                  this.fittUtil.schedule.countSchedules(daySchedule.count, lesson);
                  daySchedule.schedules.push(lesson);
                }
              }
            }
            else {
              result.result = lessons;
            }
          }
          resolve(result);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    setClass: (args: AddGxArgs | {
      cSeq: number,
      tSeq?: number,
      cNote?: string,
      cColor?: string;
      cQuota?: number,
      startRepeatDate?: string,
      endRepeatDate?: string,
      hours?: Array<{ day: number, startTime: string, endTime: string }>
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { hours, } = args;
          const hourString = hours.map(h => `${h.day},\\'${h.startTime}\\',\\'${h.endTime}\\'`).join('/');
          const param = {
            ...args, hourString,
            endRepeatDate: new Date(args.endRepeatDate).getNewDate('date', 1).format('yyyy-MM-dd'),
            token: await this.getAuthToken(),
          };
          this.log('param', param);

          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/setClass',
            param: param,
          });

          if (args.hasOwnProperty('sMemo') && args['sMemo']) {
            const { gSeq, sMemo, tSeq, startRepeatDate, endRepeatDate, } = args as AddGxArgs;
            const dotToDash = (str: string) => str.replace(/\./g, '-');
            const scheduleTimeMap: { [startTime: string]: string } = {};
            const getTimeKey = (startTime: string, day: number) => `${day}-${startTime}`;
            hours.forEach(({ startTime, endTime, day }) => scheduleTimeMap[getTimeKey(startTime, day)] = endTime);

            const gxList = await this.b2b.getLessonSchedules({
              gSeq, tSeq, sType: 'GX',
              queryStartDate: new Date(`${dotToDash(startRepeatDate)}T00:00:00Z`),
              queryEndDate: new Date(`${dotToDash(endRepeatDate)}T23:59:59Z`),
            });
            for (const date of Object.values(gxList)) {
              const promiseArray: Array<Promise<any>> = [];

              Array.isArray(date['schedules']) && date['schedules'].forEach(async (schedule: LessonSchedule) => {
                const { cSeq, sStartDate, sEndDate } = schedule;
                const day = sStartDate.getDay();
                const startTime = sStartDate.format('HH:mm');
                const endTime = sEndDate.format('HH:mm');

                scheduleTimeMap[getTimeKey(startTime, day)] === endTime && promiseArray.push(
                  this.b2b.updateMemo({
                    cSeq: cSeq, sSeq: undefined,
                    type: 'GX', sMemo: sMemo,
                    sStartDate: sStartDate.format('yyyy-MM-dd HH:mm:ss'),
                    sEndDate: sEndDate.format('yyyy-MM-dd HH:mm:ss'),
                  })
                );
              });
              await Promise.all(promiseArray);
            }
          }
          if (res.code === 1) {
            const result = [];
            res.result = result;
          }
          resolve(res);
        }
        catch (error) {
          this.error('setClass');
          reject(error);
        }
      });
    },
    setClassHourOpen: (args: {
      hSeq?: number,
      date: string,
      tSeq: number,
      sSeq?: number,
      sState?: number,
      openState: boolean,
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const date = new Date(args.date.replace(' ', 'T')); // 사파리에서 setClassHourOpen 안되는 현상 수정
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/setClassHourOpen',
            param: {
              hSeq: args.hSeq,
              date: date.format('yyyy-MM-ddTHH:mm'),
              tSeq: args.tSeq,
              sSeq: args.sSeq,
              sState: args.sState,
              openState: args.openState,
              token: await this.getAuthToken()
            }
          });
          const code = res.code;

          if (res.code === 1 && !this.listenScheduleState) {
            const { resLesson } = res;
            if (resLesson) {
              this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(resLesson), });
            }
          }
          resolve(code);
        } catch (error) {
          reject(error);
        }
      })
    },
    getClasses: (args: {
      gSeq: number,
      tSeq?: number,
      cType?: string,                  // 2: pt, 3: ot, 4: gx, 5: 운동검사
      queryStartDate?: Date,           // queryEndDate가 없으면 queryStartDate로 일간 조회만한다.
      queryEndDate?: Date,            // queryEndDate가 있으면 start~end 조회를 한다.
      cSeq?: number,
      hSeq?: number,
      mergeByClass?: boolean,
      isCurrentLangKo?: boolean
    }): Promise<any> => {
      // this.log('getClasses');
      const { gSeq, tSeq, cType, queryStartDate, queryEndDate, cSeq, hSeq, mergeByClass } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getClasses',
            param: {
              gSeq, tSeq, cType, cSeq, hSeq,
              queryStartDate: queryStartDate.format('yyyy-MM-dd HH:mm:ss'),
              queryEndDate: queryEndDate.format('yyyy-MM-dd HH:mm:ss'),
              token: await this.getAuthToken(), authTSeq: this.myInfo.getTrainerSeq(),
              isCurrentLangKo: this.util.isCurrentLangKo()
            }
          });
          // this.log('getClasses.result', res.result);
          if (res.code == 1) {
            if (mergeByClass) {
              const result = [];
              for (const record of res.result) {
                let classInfo: ScheduleClass = result.find(c => c.cSeq == record.cSeq);
                if (!classInfo) {
                  classInfo = new ScheduleClass(record);
                  result.push(classInfo);
                }
                classInfo.hHours.push({
                  day: record.hDay,
                  startTime: record.hStartTime,
                  endTime: record.hEndTime,
                  hSeq: record.hSeq,
                  isOpen: record.isOpen,
                  openDate: record.openDate ? new Date(record.openDate) : undefined,
                  sSessionParty: record.sSessionParty || 0
                });
              }
              res.result = result;
            }
          }
          resolve(res);
        }
        catch (error) {
          this.error('getClasses');
          reject(error);
        }
      });
    },
    getGXClass: (args: {
      cSeq: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGXClass',
            param: {
              cSeq: args.cSeq,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getSchedules: (args: {
      gSeq: number,
      tSeq?: number,
      uSeq?: number,
      sSeq?: number,
      sType?: string,
      sState?: ScState,
      sStates?: Array<number>,
      queryStartDate?: Date,
      queryEndDate?: Date,
      dateArrange?: boolean
    }): Promise<any> => {
      const { gSeq, tSeq, uSeq, sSeq, sType, sState, sStates, queryStartDate, queryEndDate } = args;
      const dateArrange = args.dateArrange !== undefined ? args.dateArrange : true;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getSchedules/',
            param: {
              gSeq,
              tSeq,
              sSeq,
              uSeq,
              sType,
              sState,
              sStates,
              queryStartDate: queryStartDate && queryStartDate.format('yyyy-MM-dd HH:mm:ss'),
              queryEndDate: queryEndDate && queryEndDate.format('yyyy-MM-dd HH:mm:ss'),
              token: await this.getAuthToken(),
            }
          });
          // this.log('getSchedules.res', res);
          if (res.code == 1) {
            if (!dateArrange) {
              res.result = res.result.map(record => this.makeLessonSchedule(record));
            }
            else {
              const result = {};
              for (const record of res.result) {
                // DaySchedule
                const schedule = this.makeLessonSchedule(record);
                const sStartDate = schedule.sStartDate;
                const sEndDate = schedule.sEndDate;
                let dayKeys = [];
                if (sStartDate.format('yyyy-MM-dd') !== sEndDate.format('yyyy-MM-dd') && sEndDate.format('HH:mm:ss') !== '00:00:00') {
                  const sStartDateClone = new Date(sStartDate.format('yyyy-MM-dd'));

                  let beforeMonth;
                  do {
                    dayKeys.push(sStartDateClone.format('yyyy-MM-dd'));
                    beforeMonth = sStartDateClone.getMonth();
                    sStartDateClone.setDate(sStartDateClone.getDate() + 1);
                  }
                  while (beforeMonth !== sStartDateClone.getMonth() || (new Date(sStartDateClone.format('yyyy-MM-dd')).getTime() > (new Date(sEndDate.format('yyyy-MM-dd')).getTime())));
                } else {
                  dayKeys = [`${sStartDate.format('yyyy-MM-dd')}`];
                }
                res.dayKeys = dayKeys;
                for (const dayKey of dayKeys) {
                  if (!result[dayKey]) result[dayKey] = new DaySchedule(dayKey);
                  const daySchedule: DaySchedule = result[dayKey];
                  this.fittUtil.schedule.countSchedules(daySchedule.count, schedule);
                  if (schedule.sNoShowSession === 1) {
                    daySchedule.count.reserved++;
                  }

                  switch (schedule.sState) {
                    case ScState.ReservationRequest:
                    case ScState.RequestChange:
                      daySchedule.borderColor = '#206ff6';
                      break;
                    case ScState.AutoConfirm:
                      if (!schedule.sTrainerCheckDate) daySchedule.borderColor = '#206ff6';
                      break;
                    case ScState.NoShowByUser:
                      if (!schedule.sTrainerCheckDate) daySchedule.borderColor = '#f7ba02';
                      break;
                    case ScState.CancelByUser:
                      if (!schedule.sTrainerCheckDate) daySchedule.borderColor = '#ff2d55'; else continue;
                      break;
                    case ScState.CancelRequest:
                      if (!schedule.sTrainerCheckDate) daySchedule.borderColor = '#ff2d55'; else continue;
                      break;
                    case ScState.CancelByTrainer:
                      continue;
                  }
                  schedule.sNoShowConfirmDate ? daySchedule.confirmedNoShowSchedules.push(schedule) : daySchedule.schedules.push(schedule);
                }
              }
              res.result = result;
            }
          }
          resolve(res);
        }
        catch (error) {
          this.error('getSchedule');
          reject(error);
        }
      });
    },
    getGXCancelList: (args: {
      gSeq: number,
      tSeq?: number,
      repeatStartDate: string,
      repeatEndDate?: string,
      isCurrentLangKo?: boolean
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { gSeq, tSeq, repeatStartDate, repeatEndDate } = args;
          const res: { code: number, result: Array<{ cSeq: number, cancelStartDate: string, cancelEndDate: string }> } = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGXCancelList',
            param: {
              gSeq,
              tSeq,
              repeatStartDate,
              repeatEndDate,
              token: await this.getAuthToken()
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGXMemoList: (args: {
      gSeq: number,
      tSeq?: number,
      repeatStartDate: string,
      repeatEndDate?: string,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { gSeq, tSeq, repeatStartDate, repeatEndDate } = args;
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGXMemoList',
            param: {
              gSeq,
              tSeq,
              repeatStartDate,
              repeatEndDate,
              token: await this.getAuthToken()
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getScheduleSituation: (args: {
      date: string
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          await this.auth.getMyInfo();
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getScheduleSituation',
            param: {
              tSeq: this.myInfo.getTrainerSeq(),
              token: await this.getAuthToken(),
              date: args.date
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getScheduleSituationUser: (): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          await this.verifyAuth()
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getScheduleSituationUser',
            param: {
              uSeq: this.myInfo.getUserSeq(true),
              token: await this.getAuthToken()
            }
          });
          this.log('getScheduleSituationUser', res);
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getReservationHistoryUser: (args: {
      uSeq: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getReservationHistoryUser',
            param: {
              token: await this.getAuthToken(),
              tSeq: this.myInfo.getTrainerSeq(),
              uSeq: args.uSeq
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getClassHours: (args: {
      tSeq: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getClassHours',
            param: {
              token: await this.getAuthToken(),
              tSeq: args.tSeq
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateClassHours: (args: {
      tSeq: number,
      addHours: Array<any>,
      deleteHours: Array<any>
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateClassHours',
            param: {
              token: await this.getAuthToken(),
              tSeq: args.tSeq,
              gSeq: this.myInfo.getGymSeq(),
              addHours: args.addHours,
              deleteHours: args.deleteHours
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGXList: (args: {
      gSeq: number
    }): Promise<any> => {
      const gSeq = args.gSeq;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/getGXList',
            param: {
              gSeq
            }
          });
          const gxList: Array<any> = [];
          for (const gx of res.result) {
            const g = new ScheduleClass(gx);
            gxList.push({ class: g, checked: false });
          }
          res.result = gxList;
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateMemo: (args: {
      sSeq: number, sMemo: string, type: string, sStartDate: string, sEndDate: string, cSeq: number, sMemoTitle?: string
    }): Promise<any> => {
      const { sSeq, sMemo, sMemoTitle, type, sStartDate, sEndDate, cSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          this.log('updateMemo.args', args);
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateMemo',
            param: {
              sSeq, sMemo, type, sStartDate, sEndDate, cSeq, sMemoTitle
            }
          });
          this.log('updateMemo.res', res);
          if (res.code === 1 && !this.listenScheduleState) {
            const { newSchedule } = res;
            if (newSchedule) {
              this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(newSchedule), });
            }
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getExistGXHours: (args: {
      tSeq: number,
      repeatStartDate?: string,
      repeatEndDate?: string
    }): Promise<any> => {
      const { tSeq, repeatStartDate, repeatEndDate } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getExistGXHours',
            param: {
              tSeq,
              repeatStartDate,
              repeatEndDate
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    cancelGX: (args: {
      cSeq: number,
      startDate: string,
      endDate: string,
      schedule: Schedule,
    }): Promise<any> => {
      const { cSeq, startDate, endDate, schedule } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const htSeq = this.myInfo.getTrainerSeq();
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/cancelGX',
            param: { cSeq, startDate, endDate, htSeq, },
          })
          if (res.code === 1) {
            schedule.sState = ScState.Cancel;
            this.events.publish('refreshScheduleItem', { schedule: this.makeLessonSchedule(schedule), });
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    resumeGX: (args: {
      cSeq: number,
      startDate: string,
      endDate: string
      schedule: Schedule,
    }): Promise<any> => {
      const { cSeq, startDate, endDate, schedule, } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/resumeGX',
            param: { cSeq, startDate, endDate, },
          });
          if (res.code === 1) {
            schedule.sState = undefined;
            this.events.publish('refreshScheduleItem', { schedule: schedule, });
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateUserCheckDate: (args: {
      sSeq: number
    }): Promise<any> => {
      const { sSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateUserCheckDate',
            param: {
              sSeq
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    setFittSettingSchedule: (args: {}): Promise<any> => { // 시연용 셋팅 함수
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/settingPresentationSchedule',
            param: {

            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateGXClass: (args: {
      cSeq: number,
      classInfo: any,
      cancelPlusDiffer: Array<any>,
      cancelMinusDiffer: Array<any>
    }): Promise<any> => {
      const { cSeq, classInfo, cancelPlusDiffer, cancelMinusDiffer } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateGXClass',
            param: {
              cSeq,
              classInfo,
              cancelPlusDiffer,
              cancelMinusDiffer
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymClassCount: (args: {
      gSeq: number,
      queryStartDate: string,
      queryEndDate: string,
      type: 'month' | 'monthDayily' | 'weekDayily' | 'day',
      tSeq?: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGymClassCount',
            param: args,
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymClassCount2: (args: {
      gSeq: number, tSeq?: number, queryStartDate: Date, queryEndDate: Date, qType?: 'daily' | 'monthly', includeByUser?: boolean
    }): Promise<ClassCountRecord[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { gSeq, tSeq, queryStartDate, queryEndDate, qType, includeByUser } = args;
          const { result } = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getGymClassCount2',
            param: {
              gSeq, tSeq, qLocalStartDate: queryStartDate.format('yyyy-MM-dd'), qLocalEndDate: queryEndDate.format('yyyy-MM-dd'), qType, includeByUser
            },
          });
          this.log('getGymClassCount2', result);
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymPackageList: (args: { gSeq: number, paType?: string, sortOrder?: string, isAll?: boolean }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getGymPackageList');
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getGymPackageList',
          param: {
            ...args,
            tSeq: this.myInfo && this.myInfo.getTrainerSeq(),
            token: await this.getAuthToken()
          }
        });
        resolve(res);
      });
    },
    transferUserPayment: (args: {
      startDate: string,
      endDate: string,
      upSeq: number,
      newUserSeq: number,
      tSeq: number,
      htSeq: number,
      upType: string,
      transferFee: number,
      memo: string
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/transferUserPayment',
          param: {
            ...args
          }
        });
        resolve(res);
      });
    },
    insertUserPayment: (args: {
      tSeq?: number,
      uSeq: number,
      gSeq: number,
      paSeq: number,
      paType: string,
      paName: string,
      upType: string,
      upPeriod: number,
      upSession: number,
      upMemo: string,
      amount: number,
      sDate: string,
      eDate: string,
      writerEmail: string,
      reqStatus: string,
    }): Promise<any> => {
      this.log('insertUserPayment', { args });
      return new Promise(async (resolve, reject) => {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/insertUserPayment',
          param: {
            ...args,
            token: await this.getAuthToken()
          }
        });
        resolve(res);
      });
    },
    updateUserPaymentStatus: (args: {
      uSeq: number,
      tSeq: number,
      gSeq: number,
      pSeq: number,
      paSeq: number,
      writerEmail: string,
      paType: string,
      paName: string,
      amount: number,
      upType?: string,
      reqStatus?: string
    }): Promise<any> => {
      this.log('updateUserPaymentStatus', { args });
      return new Promise(async (resolve, reject) => {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/updateUserPaymentStatus',
          param: {
            ...args,
            token: await this.getAuthToken()
          }
        });
        resolve(res);
      });
    },
    requestAgainUserPayment: (args: {
      uSeq: number,
      gSeq: number,
      pSeq: number,
      writerEmail: string,
      paType: string,
      paName: string,
      amount: number,
      upType?: string,
      reqStatus?: string
    }): Promise<any> => {
      this.log('requestAgainUserPayment', { args });
      return new Promise(async (resolve, reject) => {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/requestAgainUserPayment',
          param: {
            ...args,
            token: await this.getAuthToken()
          }
        });
        resolve(res);
      });
    },
    deleteUserPayment: (args: {
      gSeq: number,
      tSeq: number,
      uSeq: number,
      pSeq: number,
    }): Promise<any> => {
      this.log('deleteUserPayment', { args });
      return new Promise(async (resolve, reject) => {
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/deleteUserPayment',
          param: {
            ...args,
            token: await this.getAuthToken()
          }
        });
        resolve(res);
      });
    },
    getUserPayment: (args: { pSeq: number }): Promise<any> => {
      const { pSeq } = args;
      return new Promise(async (resolve, reject) => {
        this.log('getUserPayment', {
          pSeq: pSeq,
          token: await this.getAuthToken(),
          uSeq: this.myInfo.getUserSeq(true)
        });
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getUserPayment',
            param: {
              pSeq: pSeq,
              token: await this.getAuthToken(),
              uSeq: this.myInfo.getUserSeq(true)
            }
          });
          this.log('getUserPayment res : ', res);
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      });
    },
    getUserPaymentList: (args: { gSeq: number, uSeq: number, sType?: string, queryStartDate?: Date, queryEndDate?: Date, active?: boolean }): Promise<any> => {
      const { gSeq, uSeq, sType, queryStartDate, queryEndDate, active } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getUserPaymentList',
            param: {
              gSeq, sType, uSeq, active,
              qLocalStartDate: queryStartDate && queryStartDate.format('yyyy-MM-dd HH:mm'),
              qLocalEndDate: queryEndDate && queryEndDate.format('yyyy-MM-dd HH:mm'),
              tSeq: this.myInfo.getTrainerSeq(),
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentTickets: (args: { uSeq: number, }): Promise<any> => {
      const { uSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getPaymentTickets',
            param: {
              uSeq: uSeq,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentRequestTickets: (args: { uSeq: number, }): Promise<any> => {
      const { uSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getPaymentRequestTickets',
            param: {
              uSeq: uSeq,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getUsageHistory: (uSeq: number): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getUsageHistory',
            param: {
              uSeq: uSeq,
              token: await this.getAuthToken()
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentPassDetail: (args: {
      upSeq: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getPaymentPassDetail',
            param: {
              token: await this.getAuthToken(),
              tSeq: this.myInfo.getTrainerSeq(),
              upSeq: args.upSeq
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateUserPayment: (args: {
      session: number,
      startDate: string,
      endDate: string,
      pauseDates: Array<{ state: string, pStartDate: string, pEndDate: string }>,
      upSeq: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          this.log('updateUserPayment', args);
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/updateUserPayment',
            param: {
              token: await this.getAuthToken(),
              ...args
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getPaymentHistory: (args: {
      gSeq: number,
      uSeq?: number,
      type?: string,
      typeList?: string[],
      state?: string, // all, paid, cancelled
      tSeq?: number,
      upSeq?: number,
      startInDate?: string,
      endInDate?: string,
      startExpireDate?: string,
      endExpireDate?: string,
      progressRateMin?: number,
      progressRateMax?: number,
      offset?: number,
      reqState?: string, // all, not, completion
      contentsPerPage?: number
    }): Promise<PaymentHistory[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          const paymentHistoryList: PaymentHistory[] = [];
          const { result } = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/getPaymentHistory',
            param: args
          });

          result.forEach((item) => paymentHistoryList.push(new PaymentHistory(item)))
          resolve(paymentHistoryList);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateIsRepayment: (args: {
      pSeq: number,
      key: string
    }): Promise<any> => {
      const { pSeq, key } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/updateIsRepayment',
            param: {
              pSeq, key
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getUserPaymentLogs: (args: {
      upSeq: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getUserPaymentLogs',
            param: {
              upSeq: args.upSeq
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getUsageDetails: (args: {
      gSeq: number,
      uSeq: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/schedule/getUsageDetails',
            param: args,
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },

    // exercise
    getUserExercise: (args: {
      gSeq: number
    }): Promise<any> => {
      const { gSeq } = args;
      const q = `
      SELECT USER_Seq uSeq, PACKAGE_Type pType
      FROM FITT_DB.TUSER_PAYMENT
      WHERE GYM_Seq=${gSeq}
      GROUP BY USER_Seq, PACKAGE_Type;
      `;
      this.log('getUserExercise.query', q);
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          // this.log('getUserExercise.query.result', res);
          resolve(res);
        }
        catch (error) {
          this.error('getUserExercise');
          reject(error);
        }
      });
    },

    // short-cut
    getTrainerShortCut: (args: {
      gSeq: number,
      tSeq: number,
      exceptRetire?: boolean,
    }): Promise<any> => {
      const { gSeq, tSeq, exceptRetire, } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/getTrainerShortCut',
            param: {
              gSeq,
              tSeq,
            }
          });
          if (exceptRetire) {
            res.trainers = res.trainers.filter((t: Trainer) => !t.tRetire)
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    insertTrainerShortCuts: (args: {
      tSeq: number,
      shortcuts: Array<any>
    }): Promise<any> => {
      const { tSeq, shortcuts } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/insertTrainerShortCut',
            param: {
              shortcuts,
              tSeq
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    getUserShortCut: (args: {
      gSeq: number,
      uSeq: number,
      exceptRetire?: boolean,
    }): Promise<any> => {
      const { gSeq, uSeq, exceptRetire, } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/getUserShortCut',
            param: { gSeq, uSeq, },
          });
          // this.log('getUserShortCut', { exceptRetire, res, });
          if (exceptRetire) {
            res.trainers = res.trainers.filter((t: Trainer) => !t.tRetire)
          }
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },
    insertUserShortCuts: (args: {
      uSeq: number,
      shortcuts: Array<any>
    }): Promise<any> => {
      const { uSeq, shortcuts } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: '/b2b/schedule/insertUserShortCut',
            param: {
              shortcuts,
              uSeq
            }
          });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      })
    },

    // gym-locker
    insertGymLocker: (args: {
      gSeq: number,
      lDivision: string,
      lTag: string,
      lClass: string,
      lMemo: string
    }): Promise<any> => {
      const {
        gSeq,
        lDivision,
        lTag,
        lClass,
        lMemo
      } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/insertGymLocker',
            param: {
              gSeq,
              lDivision,
              lTag,
              lClass,
              lMemo
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymLockers: (args: {
      gSeq?: number,
    }): Promise<any> => {
      const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymLockers',
            param: {
              gSeq: gSeq,
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateGymLocker: (args: {
      lSeq: number,
      gSeq: number,
      lDivision: string,
      lTag: string,
      lClass: string,
      lMemo: string
    }): Promise<any> => {
      const {
        lSeq,
        gSeq,
        lDivision,
        lTag,
        lClass,
        lMemo
      } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/updateGymLocker',
            param: {
              lSeq,
              gSeq,
              lDivision,
              lTag,
              lClass,
              lMemo
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteGymLocker: (args: {
      lSeq: number,
      gSeq: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({ api: 'b2b/gym/deleteGymLocker', param: args });
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    setGymLockerLog: (args: {
      gSeq: number,
      lSeq: number,
      uSeq: number,
      llStartDate?: string,
      llEndDate?: string,
      llSeq?: number,
      llVisible?: boolean,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/setGymLockerLog',
            param: args
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getGymLockerLog: (args: {
      gSeq: number,
      lSeq?: number,
      uSeq?: number,
      fromEndDate?: string,
      toEndDate?: string,
    }): Promise<any> => {
      const { lSeq, uSeq, fromEndDate, toEndDate } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getGymLockerLog',
            param: {
              lSeq: lSeq,
              uSeq: uSeq,
              fromEndDate: fromEndDate,
              toEndDate: toEndDate
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getUserGymLockerLog: (args: {
      gSeq: number,
      uSeq: number,
    }): Promise<any> => {
      const { gSeq, uSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/getUserGymLockerLog',
            param: {
              gSeq: gSeq,
              uSeq: uSeq
            }
          })
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    getRecentUsePayment: async (args: {
      uSeq: number,
      date: string,
    }): Promise<any> => {
      try {
        const { uSeq, date, } = args;
        const res = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getRecentUsePayment',
          param: {
            uSeq: uSeq,
            date: date,
          }
        })
        return res;
      } catch (error) {
        this.error(error);
        return error;
      }
    },
    insertUserPoint: async (args: {
      upSeq: number,
      tSeq: number,
      user: User,
      uptAmount: number,
      uptStartDate: string,
      uptEndDate: string
    }): Promise<any> => {
      try {
        const { upSeq, tSeq, user, uptAmount, uptStartDate, uptEndDate } = args;
        const { gSeq, uSeq } = user;
        const result = await this.fittUtil.remoteApi({
          api: 'b2b/gym/insertUserPoint',
          param: {
            upSeq, gSeq, tSeq, uSeq,
            htSeq: this.myInfo.getTrainerSeq(),
            uptAmount, uptStartDate, uptEndDate,
          }
        });

        result.code === 1 && this.events.publish(UserPoint.UPDATE_EVENT);
        return result;
      } catch (error) {
        this.error(error);
        throw new Error(error);
      }
    },
    getUserPoint: async (args: {
      user: Pick<User, 'gSeq' | 'uSeq'>,
      upSeq?: number,
      queryStartDate?: Date,
      expired?: boolean,
    }): Promise<UserPoint[]> => {
      try {
        const { user, upSeq, queryStartDate, expired } = args;
        const { gSeq, uSeq } = user;
        if (!uSeq) {
          throw new Error(`uSeq is undefined ${args}`);
        }
        const { result: uptList } = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getUserPoint',
          param: {
            gSeq, uSeq, upSeq,
            queryStartDate: queryStartDate && queryStartDate.format('yyyy-MM-dd'),
            expired
          }
        });

        return uptList.map((upt: Object) => new UserPoint(upt));
      } catch (error) {
        this.error(error);
        throw new Error(error);
      }
    },
    getUserPointLog: async (args: {
      user: User,
      upSeq?: number,
    }): Promise<UserPointLog[]> => {
      try {
        const { user: { gSeq, uSeq }, upSeq } = args;
        const { result: uptuList } = await this.fittUtil.remoteApi({
          api: 'b2b/gym/getUserPointLog',
          param: { gSeq, uSeq, upSeq }
        });

        return uptuList.map(uptu => new UserPointLog(uptu));
      } catch (error) {
        this.error(error);
        throw new Error(error);
      }
    },
    updateUserPoint: async (args: UpdateUserPointEndDateParam | RefundUserPointParam):
      Promise<{ code: number, message: string, result: UserPointDetail[] }> => {
      try {
        this.log('updateUserPoint', { args });
        const result = await this.fittUtil.remoteApi({
          api: 'b2b/gym/updateUserPoint',
          param: {
            ...args,
            htSeq: this.myInfo.getTrainerSeq(),
          }
        });

        this.log('updateUserPoint', { result });
        result.code === 1 && this.events.publish(UserPoint.UPDATE_EVENT);
        return result;
      } catch (error) {
        this.error(JSON.stringify(error));
        throw new Error(error);
      }
    },
    updateUserPointBalance: async (args: {
      gSeq: number,
      tSeq: number,
      uSeq: number,
      uptSeq?: number,
      uptuSeq?: number,
      uptuState: Extract<UserPointState, '금액 차증' | '금액 차감' | '사용 취소'>,
      uptuAmount: number,
      publishEvent: boolean,
    }): Promise<{ code: number, message: string, result: UserPointDetail[] }> => {
      try {
        const { gSeq, tSeq, uSeq, uptSeq, uptuSeq, uptuState, uptuAmount, publishEvent } = args;

        this.log('updateUserPointBalance', { args });
        if (!USER_POINT_STATE.guard(args.uptuState)) {
          throw new Error(`invalid uptuState(${args.uptuState})`);
        }
        const result = await this.fittUtil.remoteApi({
          api: 'b2b/gym/updateUserPointBalance',
          param: {
            gSeq, tSeq, uSeq, uptSeq, uptuSeq, uptuState, uptuAmount,
            htSeq: this.myInfo.getTrainerSeq(),
          }
        });

        publishEvent && result.code === 1 && this.events.publish(UserPoint.UPDATE_EVENT);
        return result;
      } catch (error) {
        this.error(JSON.stringify(error));
        throw new Error(error);
      }
    },
    applyJoinGroup: async (args: {
      groupCode: string,
      gSeq: number,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { groupCode, gSeq } = args;
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/applyJoinGroup',
            param: {
              groupCode: groupCode,
              gSeq: gSeq,
            }
          });
          resolve(res);
        } catch (error) {
          this.error(error);
          reject(error);
        }
      });
    },
    /**
     * sequence를 -1로 보내면 해당 부분은 확인하지 않는다.
     */
    checkScheduleAvailable: async (args: {
      tSeq: number,
      startDate: Date,
      endDate: Date,
      sSeq: number,
      uSeq: number,
    }): Promise<boolean> => {
      const { tSeq, startDate, endDate, sSeq, uSeq } = args;
      const { code, result } = await this.fittUtil.remoteApi({
        api: 'b2b/schedule/checkScheduleAvailable',
        param: {
          tSeq: tSeq,
          startDate: startDate.format('yyyy-MM-ddTHH:mm:ss'),
          endDate: endDate.format('yyyy-MM-ddTHH:mm:ss'),
          sSeq: sSeq,
          uSeq: uSeq,
        }
      });

      return code === 1 && result === true;
    },
    setMultipleClassHourOpen: async (args: {
      gSeq: number,
      queryStartDate: Date,
      queryEndDate: Date,
      classDefault: LessonState
      tSeq?: number,
      day?: number
    }): Promise<boolean> => {
      const { queryStartDate, queryEndDate } = args;
      const { code, result } = await this.fittUtil.remoteApi({
        api: 'b2b/schedule/setMultipleClassHourOpen',
        param: {
          ...args,
          queryStartDate: queryStartDate.format('yyyy-MM-dd'),
          queryEndDate: queryEndDate.format('yyyy-MM-dd'),
        }
      });

      return code === 1 && result === true;
    },
  }
  public backdoor = {

    /**
     * [backdoor] 백도어 쿼리 날리기
     * @param
     * @result
     */
    query: (args: {
      query: string,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('query:', args.query);

        try {
          const res = await this.fittUtil.remoteApi({
            api: 'backdoor/', param: { q: args.query }
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: String(JSON.stringify(res.result))
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },
  }

  public exerciseApi = {
    getB2BExerciseList: (args: { gSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getB2BExerciseList',
            param: {
              gSeq: args.gSeq
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },
    /**
     * [EXERCISEAPI] 운동검사 API
     * @param
     * @result
     */
    sendEmail: (args: { email: string, name: string, subject: string, contents: string, images: Array<any>, isCurrentLangKo: boolean }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('sendEmail.query', args.email);
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/sendMail', param: args
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        } catch (error) {
          this.error('query');
          reject(error);
        }
      })
    },
    getCheckupAge: (args: { gender: String, type: String, value: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { gender, type, value } = args
        try {

          this.log('getCheckupAge', gender, type, value);
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getCheckupAge',
            param: {
              gender: gender,
              type: type,
              value: value
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },
    getReference: (args: { gender: String, age: Number, type: String }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { gender, age, type } = args
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getReference',
            param: {
              gender: gender,
              age: age,
              type: type
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },
    getTestAvgReference: (args: { gender: String, age: Number, type: String }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const { gender, age, type } = args
        try {
          this.log('refVeriable', {
            gender: gender,
            age: age,
            type: type
          })
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getTestAvgReference',
            param: {
              gender: gender,
              age: age,
              type: type
            }
          });
          resolve(res.result);
        } catch (error) {
          reject(error);
        }
      })
    },


    test: (args: {
      token: string
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {

        const { token } = args;
        this.log('toke====', token);
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'auth/test', param: { token: token }
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },
    getLastVO2Max: (args: {
      uSeq: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getLastVO2Max',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },
    getAvrVO2Max: (args: {
      gender: string,
      age: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getAvrVO2Max.query:', args);

        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getAvrVO2Max', param: args
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },
    getTMTbyVO2Max: (args: {
      gender: Gender,
      age: number,
      weight: number,
      VO2Max: number,
      type?: '2.4km' | '12min' | '5min' | 'bruce',
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('getTMTbyVO2Max.query:', args);

        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getTMTbyVO2Max', param: args
          });

          if (args.type === 'bruce') {
            let bruceRes = await this.exerciseApi.getBruceByVO2Max(res.result.avrVO2Max, args.age, args.gender);
            bruceRes = Math.floor(bruceRes.result)
            const minutes = Math.floor(bruceRes / 60);
            const seconds = Math.floor(bruceRes - minutes * 60);
            res.result.avrTime = `${minutes}:${seconds}`;
          } else {
            res.result.avrTime = this.exerciseApi.minSecByTime((this.exerciseApi.getTimeByVO2Max(res.result.avrVO2Max)));
          }

          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },

    getVO2Max: (args: {
      gender: string,
      age: number,
      distance: any,
      min?: any,
      sec?: any,
      height: any,
      weight: any,
      type: string
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        // gender, age, VO2Max (-1: avrVO2Max, 0>: VO2Max)
        this.log('getVO2Max.query:', args);

        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getVO2Max', param: args
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },
    getVO2MaxByMinSec: (
      min: number,
      sec: number): number => {
      // 2.4km 달리기에서 소요된 시간을 입력.
      const elapsedMin = Number(min) + Number(sec) / 60.0;
      return elapsedMin ? Number((483.0 / elapsedMin + 3.5).toFixed(1)) : 0;
    },
    getTMTAtRowing: (args: {
      min: number,
      sec: number,
      age: number,
      gender: string,
      weight: number
    }): Promise<any> => {

      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getTMTAtRowing', param: args
          });
          this.log('getTMTAtRowing res', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        } catch (error) {
          this.error('getTMTAtRowing');
          reject(error);
        }
      });

    },
    getBruceByVO2Max: (
      VO2Max: number,
      age: number,
      gender: Gender
    ): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getBruceByVO2Max', param: { VO2Max, age, gender }
          });
          resolve(res);
        } catch (error) {
          this.error('getBruceByVO2Max');
          reject(error);
        }
      });
    },
    getVO2MaxByDistance: (
      type: '12min' | '5min' | '6min',
      distance: number,
      user?: User): number => {

      // type: '12min' | '5min' | '6min'
      // distance: meter

      let value_a;
      let value_b;
      let value_c;

      switch (type) {
        case '12min':
          value_a = (Number(distance) - 504.9) / 44.73;
          value_b = (Number(distance) * 0.0268) - 11.3;
          return Number(((value_a + value_b) / 2).toFixed(1));
        case '5min':
          value_a = (Number(distance) / 10000.0 * 3.23 + 0.123) * 100;
          value_b = Number(distance) * 0.0402 + 3.5;
          value_c = Math.abs(value_a - value_b) * 0.2;
          return Number(distance) < 1114 ? value_b + value_c : value_a + value_c;
        case '6min':
          if (!user) {
            throw new Error('User is not defined.');
          } else if (!user.uBirth || !user.uHeight || !user.uWeight) {
            switch (user.uGender) {
              case 'male':
                return 0.52 * 0.062 * distance;
              case 'female':
                return (0.07 * distance) - 7.62;
            }
          } else {
            const age = this.util.getAge(user.uBirth);
            switch (user.uGender) {
              case 'male':
                return 16.34 + (0.06 * distance) + (0.12 * age) + (-0.02 * user.uHeight) + (-0.04 * user.uWeight);
              case 'female':
                return -9.48 + (0.06 * distance) + (-0.09 * age) + (0.03 * user.uHeight) + (0.1 * user.uWeight);
            }
          }
      }
    },

    getVO2MaxByBruce: (args: {
      min: number,
      sec: number,
      age: number,
      gender: Gender
    }): Promise<any> => {
      this.log('getVO2MaxByBruce');
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getVO2MaxByBruce',
            param: args
          });
          this.log('res:', res);
          resolve(res);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },

    getPaceByVO2Max: (
      VO2Max: number) => {

      return Number(((Number(VO2Max) - 3.5) * 5 / 26.8 * 1.6).toFixed(1));
    },
    getTimeByVO2Max: (
      VO2Max: number) => {

      return (483.0 / (Number(VO2Max) - 3.5)).toFixed(2);
    },
    getDistanceByVO2Max: (
      type: '2.4km' | '12min' | '5min',
      VO2Max: number) => {

      switch (type) {
        case '12min':
          return (VO2Max * 89.46 + 1010.3) / 2198.8;
        case '5min':
          return (VO2Max < 48.3 ? (VO2Max - 5.26) / 0.03906 : (VO2Max - 10.54) / 0.03388).toFixed(0);
        default:
          return 0;
      }
    },
    getPercentListByAgeGender: (args: {
      gender: string,
      age: number
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        this.log('query:', args);

        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getPercentList', param: args
          });
          this.log('res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },
    getPercentByVO2Max: (
      VO2Max: number,
      percentList: Array<any>) => {

      return percentList.filter(function (el) { return el.vo2max >= VO2Max; })[0].percent;
    },
    getVO2MaxDistribution: async (args: {
      age: number,
      gender: string,
      maxVO2?: number
    }): Promise<any> => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'exerciseTest/getVO2MaxDistribution',
          param: args
        });
        this.log('test!', res);
        return res;
      } catch (error) {
        this.error(error);
        throw error;
      }
    },
    getCardioAge: (
      maxVO2: number,
      gender: Gender) => {

      const male_maxVO2 = 44.65;
      const male_minVO2 = 27.5;
      const female_maxVO2 = 38.5;
      const female_minVO2 = 24.05;

      const male_maxVO2_avr = [43.9, 42.4, 40.4, 36.7, 33.1, 29.4];
      const female_maxVO2_avr = [37.4, 35.2, 33.3, 30.2, 27.5, 25.1];

      let cardioAge = 80;

      switch (gender) {
        case 'male':


          // 80세 이상
          if (male_minVO2 > Number(maxVO2))
            return cardioAge;

          // 20세 ~ 25세
          if (male_maxVO2_avr[0] <= Number(maxVO2)) {
            cardioAge = 20;

            if (male_maxVO2 > Number(maxVO2)) {
              const rate1 = (male_maxVO2 - male_maxVO2_avr[0]) / 5;
              for (let i = 0; i <= 5; i++) {
                if (Number(maxVO2) >= male_maxVO2 - rate1 * i) break;
                cardioAge++;
              }
            }
            return cardioAge;
          }
          else {
            // 25세 ~ 75세
            cardioAge = 25;
            for (let i = 0; i < male_maxVO2_avr.length - 1; i++) {
              if (male_maxVO2_avr[i] > Number(maxVO2) && male_maxVO2_avr[i + 1] <= Number(maxVO2)) {
                const rate2 = (male_maxVO2_avr[i] - male_maxVO2_avr[i + 1]) / 10;
                for (let j = 0; j < 10; j++) {
                  if (Number(maxVO2) >= male_maxVO2_avr[i] - rate2 * j) break;
                  cardioAge++;
                }
                return cardioAge;
              }
              cardioAge += 10;
            }

            // 75세 ~ 80세
            const rate3 = (male_maxVO2_avr[5] - male_minVO2) / 5;
            for (let i = 0; i < 5; i++) {
              if (Number(maxVO2) >= male_maxVO2_avr[5] - rate3 * i)
                return cardioAge;
              cardioAge++;
            }
            return 80;
          }
        case 'female':

          // 80세 이상
          if (female_minVO2 > Number(maxVO2))
            return cardioAge;

          // 20세 ~ 25세
          if (female_maxVO2_avr[0] <= Number(maxVO2)) {
            cardioAge = 20;

            if (female_maxVO2 > Number(maxVO2)) {
              const rate1 = (female_maxVO2 - female_maxVO2_avr[0]) / 5;
              for (let i = 0; i <= 5; i++) {
                if (Number(maxVO2) >= female_maxVO2 - rate1 * i) break;
                cardioAge++;
              }
            }
            return cardioAge;
          }
          else {
            // 25세 ~ 75세
            cardioAge = 25;
            for (let i = 0; i < female_maxVO2_avr.length - 1; i++) {
              if (female_maxVO2_avr[i] > Number(maxVO2) && female_maxVO2_avr[i + 1] <= Number(maxVO2)) {
                const rate2 = (female_maxVO2_avr[i] - female_maxVO2_avr[i + 1]) / 10;
                for (let j = 0; j < 10; j++) {
                  if (Number(maxVO2) >= female_maxVO2_avr[i] - rate2 * j) break;
                  cardioAge++;
                }
                return cardioAge;
              }
              cardioAge += 10;
            }

            // 75세 ~ 80세
            const rate3 = (female_maxVO2_avr[5] - female_minVO2) / 5;
            for (let i = 0; i < 5; i++) {
              if (Number(maxVO2) >= female_maxVO2_avr[5] - rate3 * i)
                return cardioAge;
              cardioAge++;
            }
            return 80;
          }
      }
    },
    minSecByTime: (
      time: string) => {
      const min = ('0' + time.split('.')[0]).substr(-2);
      const sec = ('0' + (Number(time.split('.')[1]) * 60 / 100).toString().split('.')[0]).substr(-2);
      this.log('minSecByTime', { min, })
      return min + ':' + sec;
    },
    getRating: (
      percent: number) => {

      if (Number(percent) >= 95) return "Superior";
      else if (Number(percent) >= 80) return "Excellent";
      else if (Number(percent) >= 60) return "Good";
      else if (Number(percent) >= 40) return "Fair";
      else if (Number(percent) >= 20) return "Poor";
      else return "Very poor";
    },
    getLacateThresholdStandard(_lactateThreshold: Array<LactateItem>): Array<LactateItem> {
      let lacTholdStd: Array<LactateItem> = [{ lactate: 2, speed: undefined }, { lactate: 4, speed: undefined }];
      const lactateThreshold: Array<LactateItem> = _lactateThreshold.filter(ele => !!ele.lactate);
      const isDone = lactateThreshold.some((thrEle, thrEleIdx) => {
        if (thrEleIdx === 0) return false;

        lacTholdStd.map((stdEle, stdEleIdx) => {
          if (lactateThreshold[thrEleIdx - 1].lactate <= stdEle.lactate && stdEle.lactate <= thrEle.lactate) {
            lacTholdStd[stdEleIdx].speed = +this.getEquationAnswer(stdEle.lactate,
              new PointPosition({ y: lactateThreshold[thrEleIdx - 1].speed, x: lactateThreshold[thrEleIdx - 1].lactate }),
              new PointPosition({ y: thrEle.speed, x: thrEle.lactate })).toFixed(1);
          }
        });
        return lacTholdStd.reduce((acc, cur) => acc && !!cur.speed, true);
      });

      if (!isDone) {
        lacTholdStd = lacTholdStd.map(ele => {
          if (ele.speed) return ele;
          const lastIdx = lactateThreshold.length - 1;

          if (ele < lactateThreshold[0]) {
            ele.speed = +this.getEquationAnswer(ele.lactate,
              new PointPosition({ y: lactateThreshold[0].speed, x: lactateThreshold[0].lactate }),
              new PointPosition({ y: lactateThreshold[1].speed, x: lactateThreshold[1].lactate }),
              new PointPosition({ y: lactateThreshold[2].speed, x: lactateThreshold[2].lactate })).toFixed(1);
            return ele;
          } else if (ele > lactateThreshold[lastIdx]) {
            ele.speed = +this.getEquationAnswer(ele.lactate,
              new PointPosition({ y: lactateThreshold[0].speed, x: lactateThreshold[lastIdx - 2].lactate }),
              new PointPosition({ y: lactateThreshold[1].speed, x: lactateThreshold[lastIdx - 1].lactate }),
              new PointPosition({ y: lactateThreshold[2].speed, x: lactateThreshold[lastIdx].lactate })).toFixed(1);
            return ele;
          }
        });
      }
      return lacTholdStd;
    },
    getMaxLactateProdRate(_lactateProductionRate: Array<LactateItem>, _restingItem: LactateItem, _interval: number): number {
      let maxLactate = 0;
      const lactateProductionRate: Array<LactateItem> = _lactateProductionRate.filter(ele => !!ele.lactate);

      lactateProductionRate.forEach(ele => maxLactate < ele.lactate && (maxLactate = ele.lactate));
      return Math.abs(this.getExactDecimal(maxLactate - _restingItem.lactate, 1)) / (10 - _interval);
    },
    getMaxHR(gender: Gender, age: number): number {
      switch (gender) {
        case 'male':
          return 214 - (0.8 * age);
        case 'female':
          return 209 - (0.7 * age);
      }
    },
    getAvrDistanceByAge(gender: Gender, age: number): number {
      let avrDistance: number = undefined;

      age = age < 60 ? 60 : age > 90 ? 90 : age;
      switch (gender) {
        case 'male':
          maleAvrRecord.some(ele => age === ele.age && !!(avrDistance = ele.distance));
          break;
        case 'female':
          femaleAvrRecord.some(ele => age === ele.age && !!(avrDistance = ele.distance));
          break;
      }
      return avrDistance;
    },
    getAvrAgeByDistance(gender: Gender, distance: number): number {
      let avrAge: number = undefined;

      switch (gender) {
        case 'male':
          maleAvrRecord.some(ele => distance >= ele.distance && !!(avrAge = ele.age));
          break;
        case 'female':
          femaleAvrRecord.some(ele => distance >= ele.distance && !!(avrAge = ele.age));
          break;
      }
      return !avrAge ? 90 : avrAge;
    },

    calcHRA: (gender: Gender, age: number, VO2Max: number): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/calcHRA', param: { gender, age, VO2Max }
          });

          this.log('calcHRA res:', res);
          const model = {
            code: Number(res.code),
            message: String(res.message),
            result: res.result
          };
          resolve(model);
        }
        catch (error) {
          this.error('query');
          reject(error);
        }
      });
    },

    migrateFromFitt3: async (args: { uSeq: number }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const resTmt = await this.fittUtil.remoteApi({
            api: 'exerciseTest/migrateTmtFromFitt3',
            param: args
          });
          const resSmt = await this.fittUtil.remoteApi({
            api: 'exerciseTest/migrateSmtFromFitt3',
            param: args
          });
          const resFmt = await this.fittUtil.remoteApi({
            api: 'exerciseTest/migrateFmtFromFitt3',
            param: args
          });
          resolve({ tmt: resTmt, smt: resSmt, fmt: resFmt });
        } catch (error) {
          this.error(error);
          reject(error);
        }
      });
    },

    sortExerciseTestOrder: async (args: { gSeq: number, uSeq: number, testType: string }): Promise<any> => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'exerciseTest/sortExerciseTestOrder',
          param: args
        });
        return res;
      } catch (error) {
        this.error(error);
        throw error;
      }
    },

    sortExerciseTestOrderForMigration: async (args: { gSeq: number, uSeq: number }): Promise<any> => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'exerciseTest/sortExerciseTestOrderForMigration',
          param: args
        });
        return res;
      } catch (error) {
        this.error(error);
        throw error;
      }
    },

    getUserExerciseRecords: async (args: { uSeq: number, isAllData: boolean, isConnected: boolean }): Promise<any> => {
      try {
        const res = await this.fittUtil.remoteApi({
          api: 'exerciseTest/getUserExerciseRecords',
          param: args
        });
        return res;
      } catch (error) {
        this.error(error);
        throw error;
      }
    },

    getSmt3Rank: async (args: { uSeq: number, gSeq: number, subType: string, inDate: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getSmt3Rank',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getSmt4Rank: async (args: { uSeq: number, gSeq: number, subType: string, inDate: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getSmt4Rank',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getSmtRecords: async (args: { uSeq: number, type: string, subType: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getSmtRecords',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getOptProgramFrom1rm: async (args: { uSeq: number, type: string, subType: string, inDate: string }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getOptProgramFrom1rm',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getExerciseTestCount: (args: {
      gSeq?: number, queryStartDate?: Date, queryEndDate?: Date, tSeq?: number, exType?: string, qMode: ExTestQueryMode
    }): Promise<any | ExTestCountRecord[] | ExTestReturnTotalCount | ExTestReturnTypeCount[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { gSeq, tSeq, queryStartDate, queryEndDate, exType, qMode } = args;
          const { result } = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getExerciseTestCount',
            param: {
              gSeq, tSeq, exType, qMode,
              qLocalStartDate: queryStartDate && queryStartDate.format('yyyy-MM-dd'),
              qLocalEndDate: queryEndDate && queryEndDate.format('yyyy-MM-dd'),
            },
          });
          switch (qMode) {
            case 'stats': resolve(result); break;
            case 'countAll': {
              const [count] = result;
              resolve(count);
              break;
            }
            case 'countExType': resolve(result); break;
          }
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getLastAllSmtRecords: async (args: { uSeq: number, isAllData: boolean, isConnected: boolean }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getLastAllSmtRecords',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getLastSmtRecords: async (args: { uSeq: number, type: 'smt3' | 'smt4' | 'smt5', isAllData: boolean, isConnected: boolean }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getLastSmtRecords',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    },

    getFittRunningRecords: async (args: { uSeq: number, type: 'day' | 'week' }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'exerciseTest/getFittRunningRecords',
            param: args,
          });
          resolve(res.result);
        }
        catch (error) {
          reject(error);
        }
      });
    }
  }

  public treadmill = {
    // 트레드밀 관리 - 트레드밀 조회
    getTreadmills: (args: {
      fromFitt: number,
      gSeq: number,
    }): Promise<any> => {
      const { fromFitt, gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'treadmill/getTreadmills',
            param: { fromFitt: fromFitt, gSeq: gSeq }
          });
          if (res.code == 1) {
            let order = 1;
            const trStatus = new Map<String, String>([
              ['INUSE', this.translate.instant('마이페이지.사용중')],
              ['UNTAKEN', this.translate.instant('마이페이지.비활성')],
            ]);
            const treadmills = res.result.treadmills.map(tr => {
              tr['trStatus'] = trStatus.get(tr.trStatus);
              tr['trOrder'] = order;
              order += 1;
              tr['deleteButton'] = 'trash';
              return tr;
            });
            res.result.treadmills = treadmills;
          }
          resolve(res)
        } catch (error) {
          this.error('getTreadmills error', error);
          reject(error);
        }
      });
    },
    // 트레드밀 관리 - 해당 짐의 트레드밀 코드 정보 수정
    updateTreadmillCode: (args: {
      fromFitt: number,
      type?: string,
      tgrSeq?: number,
      gSeq: number,
      tgrSession?: number,
      tgrState?: number,
      tgrModifyDate: string,
      tgrMemo?: string,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'treadmill/updateTreadmillCode',
            param: { ...args }
          });
          resolve(res);
        } catch (error) {
          this.error('updateTreadmillCode error', error);
          reject(error);
        }
      });
    },
    // 트레드밀 관리 - 짐의 트레드밀 수정
    updateTreadmill: (args: {
      trSeq: number,
      trStatus?: string,
      trModifyDate: string,
      trVisible?: boolean,
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'treadmill/updateTreadmill',
            param: { ...args }
          });
          resolve(res);
        } catch (error) {
          this.error('updateTreadmill error', error);
          reject(error);
        }
      });
    },
    // 트레드밀 QR 로그인
    loginWithQRCode: (args: {
      uuid: string,
      expiredDate: Date,
      scannedDate: Date,
      userInfo: {
        uSeq: number,
        uHeight: number,
        uWeight: number
      }
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await this.fittUtil.remoteApi({
            api: 'treadmill/loginWithQRCode',
            param: { ...args }
          });
          resolve(res);
        } catch (error) {
          this.error('loginWithQRCode error', error);
          reject(error);
        }
      })
    }
  }

  public fmes = {
    setFmesVirtualGym: (args: {
      mEmail, mSeq
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        try {
          const { mEmail, mSeq } = args;
          const res = await this.fittUtil.remoteApi({
            api: 'b2b/gym/setFmesVirtualGym',
            param: {
              mEmail: mEmail,
              mSeq: mSeq
            }
          });
          console.log("res", res);
          resolve(res);
        } catch (error) {
          this.error('setFmesVirtualGym error', error);
          reject(error);
        }
      })
    }
  }


  public templete = {
    /**
     * [TEMPLETE] API 생성 템플릿
     * @param
     * @result
     */
    apiTemplete: (
      // args: {} // input parameter
    ): Promise<any> => {
      this.log('apiTemplete');
      // const { gSeq } = args;
      return new Promise(async (resolve, reject) => {
        try {
          const q = ``;
          const res = await this.fittUtil.remoteApi({ api: 'backdoor/', param: { q: q } });
          resolve(res);
        }
        catch (error) {
          this.error('apiTemplete');
          reject(error);
        }
      });
    },
  }


}
