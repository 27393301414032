import { Component } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import BackgroundMode from 'cordova-plugin-advanced-background-mode';
import { FmesBaseComponent } from "./fitt-fmes-base.component";
import { NavService } from '@shared-lib/services/nav.service';
import { StatusBar, Style } from '@capacitor/status-bar';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { FittMember, MemberRole } from '@fitt-lib/models/fitt-models';
import { AlertController, LoadingController, MenuController, ModalController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { UserInfoModal } from './modals/user-info/user-info';
import { FireService } from '@fitt-lib/services/fire.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { FmesSettingsMainModal } from 'src/app/modals/fmes-settings-main/fmes-settings-main';
import { FmesSubscribeMainModal } from 'src/app/modals/fmes-subscribe-main/fmes-subscribe-main';
import { FmesFmtGuideModal } from 'src/app/modals/fmes-fmt-guide/fmes-fmt-guide'



@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent extends FmesBaseComponent {
  myInfo: FittMember;
  myRole: MemberRole = 'none';
  gymNotiBadge: boolean = false;
  isSubscribe: boolean = true;
  paymentDueDate: Date = new Date();
  constructor(
    private title: Title,
    private platform: Platform,
    private fire: FireService,
    private navSvc: NavService,
    private router: Router,
    private afAuth: AngularFireAuth,
    private afData: AngularFireDatabase,
    private events: RxEvents,
    private popoverCtrl: PopoverController,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
  ) {
    super();
    this.title.setTitle('FMES');
    this.navSvc.loadRouting();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.platform.ready().then(() => {

      this.initializeApp();
      if (this.platform.is('capacitor')) {
        BackgroundMode.enable();
        BackgroundMode.setDefaults({ silent: true });
      }

      this.platform.resume.subscribe(() => {
        this.afData.database.goOnline();
        // if (this.util.isMobile()) {
        //   const user = this.gApi.myInfo.getUser();
        //   this.fittUtil.remoteApi({
        //     api: 'b2b/entrance/setVisitLog',
        //     param: { gSeq: this.gApi.myInfo.getGymSeq(), date: new Date().format('yyyy-MM-dd'), type: 'mobile', uSeq: this.gApi.myInfo.getUserSeq(), uid: localStorage.uid, name: user.uName, }
        //   });
        // }
      });
    });


    this.events.subscribe('userInfoModal:create', async (user: any) => {
      this.log('userInfoModal:create', user);
      let gymUser;
      if (!user) {
        await this.modalCtrl.dismiss();
        const modal = await this.modalCtrl.create({ component: UserInfoModal, componentProps: { newMode: true } });
        await modal.present();
        const result: any = await modal.onDidDismiss();
        return this.events.publish('userInfoChange', result.data.gymUser);
      }
      else if (typeof user == 'number') {
        const loading = await this.loadingCtrl.create({ message: 'Please wait...', });
        try {
          await loading.present();
          const res = await this.gApi.b2b.getGymUsers({ uSeq: user });
          if (res.result) {
            gymUser = res.result[0];
          }
        }
        catch (error) {
          this.error(error);
          return;
        }
        finally {
          loading.dismiss();
        }
      }
      else {
        gymUser = user;
      }

      const modal = await this.modalCtrl.create({ component: UserInfoModal, componentProps: { gymUser: gymUser } });
      await modal.present();
      const result: any = await modal.onDidDismiss();
      if (!result.data) return;
      this.log('userInfoChange', result.data.gymUser);
      this.events.publish('userInfoChange', result.data.gymUser);
    });
  }




  async initializeApp() {
    this.log('initializeApp');

    const gymNoticeArray = ['gymNoticeNewCount'];
    this.fittUtil.noticeMgr.subscribe(gymNoticeArray, (noticeName, value) => {
      this.gymNotiBadge = value > 0;
    });

    this.afAuth.auth.onAuthStateChanged(async user => {
      this.log('onAuthStateChanged', user ? { uid: user.uid, displayName: user.displayName, emailVerified: user.emailVerified } : null);
      if (!user) return;
      if (!user.emailVerified) return;

      this.myInfo = await this.gApi.auth.getMyInfo();
      this.myRole = this.fittUtil.getMyRole();
      await this.gApi.b2b.getGymTrainers({ gSeq: this.myInfo.getGymSeq() });

      // TODO 구독 확인 로직
      this.log('onAuthStateChanged', { myInfo: this.myInfo });

    });


    this.platform.ready().then(() => {
      // fitt-note: platform dependency가 있는 작업은 반드시 ready 이후 작업.

      if (this.platform.is('capacitor')) {
        StatusBar.setStyle({ style: Style.Default });
      }

      // mobile에서는 popup context menu 제한
      if (this.util.isMobileApp()) {
        window.oncontextmenu = function () { return false; };
      }


      if (this.platform.is('capacitor') && this.platform.is('android')) {
        this.platform.backButton.subscribeWithPriority(9999, this.onAndroidBackButton);
        this.events.subscribe('simulate:back-button', this.onAndroidBackButton);
      }

    });


    /*
    * URI scheme deeplink 처리 -> fittapp:// 링크로 앱 열릴 떄
    */
    // App.addListener('appUrlOpen', (data: any) => {
    //   this.log('appUrlOpen', data);
    //   this.zone.run(() => {
    //     this.router.navigate(['/login']);
    //   });
    // });    
  }


  isShowToast: boolean = false;
  onAndroidBackButton = async () => {
    this.log('onAndroidBackButton');
    const isRoot = this.navSvc.isRoot();  // 반드시 여기서 먼저 해야 한다.

    const qrScanner = document.getElementById('qrScanner');
    if (qrScanner) {
      this.log('backkey qrscanner.stop');
      this.events.publish('qrscanner:stop');
      return;
    }
    const popover = await this.popoverCtrl.getTop();
    if (popover) {
      this.log('backkey popover.dismiss');
      popover.dismiss();
      return;
    }
    const alert = await this.alertCtrl.getTop();
    if (alert) {
      this.log('backkey alert.dismiss');
      alert.dismiss();
      return;
    }
    const isMenuOpened = await this.menuCtrl.isOpen();
    if (isMenuOpened) {
      this.log('backkey menuCtrl.close');
      this.menuCtrl.close();
      return;
    }
    const modal: HTMLIonModalElement = await this.modalCtrl.getTop();
    if (modal) {
      this.log('onAndroidBackButton', modal.classList);
      if (modal.classList.contains('no-android-backbutton')) {
        this.events.publish('android:back-button');
        return;
      }
      this.log('backkey modal.dismiss');
      modal.dismiss();
      return;
    }
    if (!isRoot) {
      this.log('backkey not isRoot');
      this.navSvc.pop();
      return;
    }

    this.log('backkey', { isShowToast: this.isShowToast });
    if (!this.isShowToast) {
      this.isShowToast = true;
      const toast = await this.toastCtrl.create({ mode: 'md', message: this.translate.instant('한번 더 누르시면 종료합니다'), duration: 2000, position: 'bottom' });
      await toast.present();
      // const result = await toast.onDidDismiss(); // 이거 쓰면 안됨. toast 떠 있는 동안 backbutton에 대한 callback이 호출되지 않음.
      toast.onDidDismiss().then(result => {
        this.log('toast onDidDismiss', result);
        this.isShowToast = false;
      });
      return;
    }
    this.platform.is('capacitor') && navigator['app'].exitApp();
  };

  ionDidOpen(menuId) {
    this.events.publish('menu:didOpen', menuId);
    this.util.menuId = menuId;
  }

  ionDidClose() {
    this.events.publish('menu:didClose');
    this.util.menuId = null;
  }

  async ionWillOpen(menuId) {

  }

  async logout() {
    this.log('clickLogout');
    this.util.menuClose();
    try {
      await this.fire.logout(false);
      await this.navSvc.goPage('/login', null, 'root');
    }
    catch (error) {
      this.error(error);
    }
  }

  async popupModal(type) {
    this.log('popupModal', type);
    this.util.menuClose();
    switch (type) {
      case 'settings-main': {
        const modal = await this.modalCtrl.create({ component: FmesSettingsMainModal });
        await modal.present();
        break;
      }
      case 'subscribe-main': {
        const modal = await this.modalCtrl.create({ component: FmesSubscribeMainModal });
        await modal.present();
        break;
      }
      case 'fmt-guide': {
        const modal = await this.modalCtrl.create({ component: FmesFmtGuideModal });
        await modal.present();
        break;
      }


    }
  }

  async changePassword() {
    const sendEmail = localStorage.myEmail;
    // const sendEmail = 'im2908@fitt.kr';
    try {
      await this.util.showLoadingPopup(true);
      this.fire.resetPasswordInit(sendEmail);
      await this.util.showLoadingPopup(false);
      const message = this.translate.instant('인증.가입하신 이메일로 비밀번호 재설정 메일이 전송되었습니다. 인증 메일은 발송 후 24시간동안 유효합니다.');

      const alert = await this.util.alertCreate({
        title: '비밀번호 변경', message: message, buttons: [{ text: '확인', role: 'ok', }]
      });

      await alert.present();
      await alert.onDidDismiss();
      this.logout();
    }
    catch (error) {
      this.util.errorPopup(error);
    }
    finally {
      await this.util.showLoadingPopup(false);
    }
  }
}
