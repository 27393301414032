import { Component, Input, OnInit } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { FittMember, Trainer } from '@fitt-lib/models/fitt-models';

@Component({
  selector: 'ft-trainer-item',
  templateUrl: './ft-trainer-item.component.html',
  styleUrls: ['./ft-trainer-item.component.scss'],
})
export class TrainerItemComponent extends FtLibBaseComponent implements OnInit {

  @Input() fittMember: FittMember;
  trainer: Trainer | any;

  constructor(
  ) {
    super({ enableLog: false });
  }

  async ngOnInit() {
    this.log('ngOnInit', this.fittMember);
    this.trainer = this.fittMember.getTrainer() || {};
  }
}
