import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'newDate' })
export class NewDatePipe implements PipeTransform {
  constructor(
  ) { }
  transform(value: number | string, param: 'string' | 'second' | 'minute' | 'hour' | 'month' | 'year'): number {
    if (param === 'string') {
      return new Date(value).getTime();
    } else {
      value = Number(value);
      if (param === 'hour') {
        param = 'minute';
        value = value * 60;
      }
      if (param === 'minute') {
        param = 'second';
        value = value * 60;
      } 
      const date = new Date(0).getNewDate(param, Number(value));
      return date.getTime();
    }
  }
}
