import { Injectable } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { AngularFireDatabase } from '@angular/fire/database';
import { Platform } from '@ionic/angular';
import { FCM } from '@capacitor-community/fcm';
import { PushNotificationSchema, PushNotificationToken, PushNotifications } from '@capacitor/push-notifications';
import { FirebasePush, TokenResult } from 'capacitor-firebase-push';
import { LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { LocalNotifications as NativeLocalNotifications } from '@ionic-native/local-notifications/ngx';
import { TransLocService } from '@shared-lib/services/trans-loc.service';


@Injectable({
  providedIn: 'root'
})
export class FcmService extends FtLibBaseComponent {

  constructor(
    private afData: AngularFireDatabase,
    private platform: Platform,
    // private debugScreenSvc: DebugScreenService,
    private nativeLocalNotifications: NativeLocalNotifications,
    private transLocSvc: TransLocService,
  ) {
    super({ enableLog: false });
  }

  async init() {
    // this.debugScreenSvc.start();
    await this.fcmInit();
  }

  fcmInit() {
    FirebasePush.requestPermissions().then(async result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        FirebasePush.register();
        await this.localPushInit();
      } else {
        // 안드로이드 스튜디오에서 현재 console.log로 로그 찍힘
        console.log('FirebasePush.requestPermissions', result);
      }
    });

    FirebasePush.addListener('token', (result: TokenResult) => {
      console.log('FirebasePush token : ' + JSON.stringify(result.token));
      localStorage.setItem('push_token', result.token);
    });

    PushNotifications.addListener('registration', (token: PushNotificationToken) => {
      console.log('PushNotifications registration token: ' + JSON.stringify(token));
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('PushNotifications : ' + JSON.stringify(error));
    });
    if (this.platform.is('android')) {
      // 안드로이드 앱 포어그라운드 실행시 푸시 알림
      PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
        console.log('Push received: ' + JSON.stringify(notification));

        const titleLocArgs: string[] = [];
        const bodyLocArgs: string[] = [];
        if (notification.data && notification.data.titleLocArgsLength) {
          for (let idx = 0; idx < Number(notification.data.titleLocArgsLength); idx++) {
            titleLocArgs.push(notification.data["titleLocArgs_" + idx]);
          }
        }
        if (notification.data && notification.data.bodyLocArgsLength) {
          for (let idx = 0; idx < Number(notification.data.bodyLocArgsLength); idx++) {
            bodyLocArgs.push(notification.data["bodyLocArgs_" + idx]);
          }
        }

        this.sendLocalPush(Date.now(),
          await this.transLocSvc.getTranslateFromNotification(notification.data.titleLocKey, titleLocArgs),
          await this.transLocSvc.getTranslateFromNotification(notification.data.bodyLocKey, bodyLocArgs),
          notification.data.notiType);
      });
    }

    // android background notification Click Event
    // PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
    //   console.log("notification", JSON.stringify(notification));

    //   this.openNotice(notification.notification.data.notiType);
    // });


    // Ios 포그라운드 메시지 받았을 경우 Listener
    FirebasePush.addListener('message', async (message: any) => {
      console.log('message received: ' + JSON.stringify(message));
      // 앱 종료 & 백그라운드 실행시 푸시 알림
      if (this.platform.is('ios')) {
        console.log('message received - title-loc-key & loc-key:  ' + message.aps.alert['title-loc-key'] + message.aps.alert['loc-key']);
        if (message.tap !== 'background') {
          this.sendLocalPush(
            Date.now(),
            await this.transLocSvc.getTranslateFromNotification(message.aps.alert['title-loc-key'], message.aps.alert['title-loc-args']),
            await this.transLocSvc.getTranslateFromNotification(message.aps.alert['loc-key'], message.aps.alert['loc-args']),
            message.aps.click_action);
        }
      }
    });
  }


  localPushInit() {
    LocalNotifications.requestPermissions().then(result => {
      console.log('Local Push Permissions granted: ' + JSON.stringify(result));
    });
  }


  // Ios 알림 클릭시 해당 탭으로 이동 X,
  sendLocalPush(id: number, title: string, text: string, notiType: string): void {
    if (this.platform.is('ios')) {
      this.nativeLocalNotifications.schedule({
        id: id, title: title, text: text, sound: 'default', data: notiType, foreground: true
      });
    }
    else {
      const notifications: Array<LocalNotificationSchema> = [];
      notifications.push({ id: id, title: title, body: text, sound: 'default', extra: notiType });
      LocalNotifications.schedule({
        notifications
      }).then(res => console.log('sendLocalPush', JSON.stringify(res)));
    }
  }

  getToken(uid: string): Promise<string> {
    return new Promise<any>(async (resolve) => {
      const push_token = (await this.afData.database.ref(`/members/${uid}/push_key`).once('value')).val();
      console.log('getToken: ', push_token);
      resolve(push_token);
    });
  }

  saveToken(uid: string, push_key: string): Promise<any> {
    console.log('saveToken', JSON.stringify({ uid: uid, token: push_key }));
    if (!push_key) return;
    return new Promise<any>(async (resolve) => {
      await this.afData.database.ref(`/members/${uid}/push_key`).set(push_key);
      console.log('saveToken complete');
      resolve(undefined);
    });
  }


  deleteToken(uid: string): Promise<any> {
    console.log('deleteToken', JSON.stringify({ uid: uid }));
    if (!uid) return;
    return new Promise<any>(async (resolve) => {
      await this.afData.database.ref(`/members/${uid}/push_key`).set(null);
      console.log('deleteToken complete');
      resolve(undefined);
    });
  }

  subscribeToTopic(args: { topics: Array<string> }) {
    for (const topic of args.topics) {
      if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
        FCM.subscribeTo({ topic: topic })
          .then((message) => console.log('subscribeToTopic Message', message))
          .catch((err) => console.log(err));
      }
    }
  }

  unsubscribeFromTopic(args: { topics: Array<string> }) {
    for (const topic of args.topics) {
      if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
        FCM.unsubscribeFrom({ topic: topic })
          .then((message) => console.log('unsubscribeFromTopic Message', JSON.stringify(message)))
          .catch((err) => console.log(err));
      }
    }
  }

  async unregister() {
    if (this.platform.is('capacitor') && (this.platform.is('android') || this.platform.is('ios'))) {
      await FirebasePush.unregister();
      await FirebasePush.removeAllListeners();
      await PushNotifications.removeAllListeners();
      await LocalNotifications.removeAllListeners();
    }
  }

}
