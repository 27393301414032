import { Component, OnInit, ElementRef } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'fmt-corrective-video',
  templateUrl: './fmt-corrective-video.html',
  styleUrls: ['./fmt-corrective-video.scss']
})
export class FmtCorrectiveVideoModal extends FtLibBaseComponent implements OnInit {
  video: { imgURl: string, url: string, name: string };
  src: string = 'https://www.youtube.com/embed/';

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private elementRef: ElementRef,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.util.isMobileApp()) {
      this.util.parentElementSize(this.elementRef, '880px', '600px');
    }
    this.video = this.navParams.get('video');
    this.src += this.video.url.split('https://youtu.be/')[1];
    this.log('ngOnInit', this.video, this.src);
  }

  async close() {
    await this.modalCtrl.dismiss();
  }
}
