import { Component, Input, ViewChild, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { ModalController, IonSlides, ToastController, NavParams } from '@ionic/angular';
import { FmtReport, FMT, FmtItem } from '@fitt-lib/models/exercise-test/fmt';
import { FmtSurvey } from '@fitt-lib/models/exercise-test/fmt-survey';
import { GymUser, FittMember, User } from '@fitt-lib/models/fitt-models';

@Component({
  selector: 'mobile-fmt-result',
  templateUrl: './mobile-fmt-result.html',
  styleUrls: ['./mobile-fmt-result.scss']
})
export class MobileFmtResultModal extends FtLibBaseComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('reportRef', { read: ElementRef }) reportRef: ElementRef;

  @Input() record: any;
  records: Array<any>;

  report: FmtReport;
  reportComponenets: Array<{ key: string, title: string }> = [
    { key: 'details', title: '건강.평가 상세' },
    { key: 'report', title: '건강.평가 리포트' },
    { key: 'analysis', title: '건강.평가 분석' },
    { key: 'corrective', title: '건강.추천 교정운동' }
  ];
  fmtItemList: Array<FmtItem> = FMT.getFmtItemList();

  activateIndex: number = 0;

  myInfo: FittMember;
  users: Array<User>;
  gymUser: GymUser;

  gymId: string;
  isTrainer: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private navParams: NavParams,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  async ngOnInit() {
    if (this.record) {
      await this.initUsers();
      await this.initAllRecords();
      await this.setReport();
      this.isTrainer = this.navParams.get('isTrainer');
      this.cdr.detectChanges();
    }
  }

  async initUsers() {
    this.myInfo = await this.gApi.auth.getMyInfo();
    this.users = await this.gApi.auth.getMyUserData({
      uSeq: this.record.USER_Seq
    });
  }

  async initAllRecords() {
    const isAllData = this.users.find(u => u.uSeq === this.myInfo.getUserSeq()) !== undefined;
    this.records = (await this.gApi.exerciseApi.getUserExerciseRecords({
      uSeq: this.record.USER_Seq,
      isAllData: isAllData,
      isConnected: false // TODO: 회원의 GYM 건강 정보 연동 기능 구현 후 값 설정 필요
    })).result
      .filter(record => record.EXERCISE_TEST_Type === 'fmt2')
      .reverse();
  }

  async setReport() {
    this.log('record', this.record);
    this.gymId = this.record.GYM_Seq ? (await this.gApi.b2b.getGymInfo({ gSeq: this.record.GYM_Seq })).GYM_Id : '';

    const user = this.users.find(u => u.uSeq = this.record.USER_Seq);
    this.gymUser = new GymUser({ user: user });

    const answerList = this.record.EXERCISE_TEST_Data.answerList.map(row => row.map(val => Number(val)));
    const fmtSurvey: FmtSurvey = new FmtSurvey(answerList);
    const [weight, height, inDate] = [this.record.USER_Weight, this.record.USER_Height, this.record.EXERCISE_TEST_InDate];
    this.report = FMT.calcFmt(this.gymUser, weight, height, inDate, fmtSurvey);
  }

  async ionSlideDidChange() {
    this.activateIndex = await this.slides.getActiveIndex();
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async onDelete() {
    this.log('onDelete');
    await this.util.showLoadingPopup(true);
    if (this.record && this.record.EXERCISE_TEST_Seq) {
      this.record.EXERCISE_TEST_Visible = 0;
      this.record.EXERCISE_TEST_Order = 'null';
      await this.gApi.setRecord({
        table: 'TEXERCISE_TEST',
        record: this.record,
        where: {
          EXERCISE_TEST_Seq: this.record.EXERCISE_TEST_Seq,
          USER_Seq: this.record.USER_Seq,
          EXERCISE_TEST_Type: this.record.EXERCISE_TEST_Type,
          GYM_Seq: this.record.GYM_Seq
        }
      });
    }
    await this.util.showLoadingPopup(false);
    this.modalCtrl.dismiss({
      remove: true,
      exerciseTestSeq: this.record.EXERCISE_TEST_Seq,
    });
    const toast = await this.toastCtrl.create({
      message: this.translate.instant('모달.기록을 삭제하였습니다.'),
      duration: 2000
    });
    toast.present();
  }
}
