import { Component, OnInit, Input, ElementRef, Renderer2 } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-rolling-label',
  templateUrl: './ft-rolling-label.component.html',
  styleUrls: ['./ft-rolling-label.component.scss'],
})
export class FtRollingLabelComponent extends BaseComponent implements OnInit {
  @Input() activeIndex: number = 0;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    super({ enableLog: false });
    this.log('constructor', this.elementRef, this.elementRef.nativeElement.childElementCount);
  }

  ngOnInit() {
    this.log('ngOnInit', this.elementRef, this.elementRef.nativeElement.childElementCount);
    this.setActiveChild(this.activeIndex);

    this.elementRef.nativeElement.addEventListener('click', () => {
      this.setActiveChild();
    });
  }

  setActiveChild(index?: number) {
    this.activeIndex = index !== undefined ? index : ++this.activeIndex;
    this.activeIndex = this.activeIndex % this.elementRef.nativeElement.childElementCount;
    this.log('setActiveChild', this.activeIndex);
    for (let i = 0; i < this.elementRef.nativeElement.childElementCount; i++) {
      const child = this.elementRef.nativeElement.children[i];
      this.renderer.setStyle(child, 'display', i == this.activeIndex ? 'block' : 'none');
    }
  }
}
