<ng-container *ngIf="!util.isMobileApp()">
  <ion-row>
    <ion-col *ngFor="let fmtItem of fmtItemList; index as idx;" size="1.5" class="tab web" (click)="activateFmtItem(idx)">
      <div class="container" [ngClass]="{'active': fmtItem.img.isActive}">
        <img *ngIf="hasPainInSurvey(idx)" class="pain-icon" src="/assets/fitt-lib/img/fmt-pain-icon.png"
          [ngStyle]="{'opacity' : fmtItem.img.isActive ? '1' : '0.5'}">
        <img class="tab-img" [src]="fmtItem.img.isActive ? fmtItem.img.normal : fmtItem.img.deactive">
        <ion-label [color]="fmtItem.img.isActive ? 'main' : 'gray6'" class="tab-title">{{idx + 1}}. {{'fmt2.' + fmtSubjects[idx] | translate}}
        </ion-label>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" style="align-items: center;">
      <div class="item-container web">
        <img [src]="srcActiveFmtItemImg">
        <ion-label color="main">{{activatedIndex + 1}}. {{fmtItemList[activatedIndex].subject|translate}}</ion-label>
        <ion-text>{{fmtItemList[activatedIndex].description|translate}}</ion-text>
      </div>
    </ion-col>
    <ion-col size="6">
      <ion-row class="multiple-answers" style="margin: 0 24px 0 0;">
        <ion-col *ngFor="let survey of getOptionSurveys(); index as idx;">
          <ion-label color="black1">{{survey}}</ion-label>
          <ion-text color="gray4">{{getOptionsEnTitle()[idx]}}</ion-text>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let survey of getBooleanSurveys(); index as idx;" class="boolean-answers web">
        <ion-text class="question">
          <span class="index">{{idx + 1}}.</span>{{getBooleanQuestions()[idx].title}}
        </ion-text>
        <ion-text class="answer" [color]="getSurveyTextColor(idx, survey)">{{getSurveyText(survey)}}</ion-text>
      </ion-row>
    </ion-col>
  </ion-row>
</ng-container>
<ng-container *ngIf="util.isMobileApp()">
  <ion-row>
    <ion-col *ngFor="let fmtItem of fmtItemList; index as idx;" size="3" class="tab mobile" (click)="activateFmtItem(idx)">
      <div class="container" [ngClass]="{'active': fmtItem.img.isActive}">
        <img *ngIf="hasPainInSurvey(idx)" class="pain-icon" src="/assets/fitt-lib/img/fmt-pain-icon.png"
          [ngStyle]="{'opacity' : fmtItem.img.isActive ? '1' : '0.5'}">
        <img class="tab-img" [src]="fmtItem.img.isActive ? fmtItem.img.normal : fmtItem.img.deactive">
        <ion-label [color]="fmtItem.img.isActive ? 'main' : 'gray6'" class="tab-title">{{'fmt2.' + fmtSubjects[idx] | translate}}</ion-label>
      </div>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col class="item-container mobile">
      <img [src]="srcActiveFmtItemImg">
      <ion-label color="main">{{activatedIndex + 1}}. {{'fmt2.' + fmtItemList[activatedIndex].subject|translate}}</ion-label>
      <ion-text color="gray5">{{'fmt2.' + fmtItemList[activatedIndex].description|translate}}</ion-text>
    </ion-col>
  </ion-row>
  <ion-row class="multiple-answers mobile">
    <ion-col *ngFor="let survey of getOptionSurveys(); index as idx;">
      <ion-label color="black1">{{survey}}</ion-label>
      <ion-text color="gray4">{{getOptionsEnTitle()[idx]}}</ion-text>
    </ion-col>
  </ion-row>
  <ion-row *ngFor="let survey of getBooleanSurveys(); index as idx;" class="boolean-answers mobile">
    <ion-text class="question">
      <span class="index">{{idx + 1}}.</span>
      <span class="text">{{'fmt2.' + getBooleanQuestions()[idx].title|translate}}</span>
    </ion-text>
    <ion-text class="answer" [color]="getSurveyTextColor(idx, survey)">{{getSurveyText(survey)}}</ion-text>
  </ion-row>
</ng-container>
