import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

export class TabButton<T = string> {
  id?: T;
  name: string;
  notYet?: boolean;
}
@Component({
  selector: 'ft-tab-buttons',
  templateUrl: './ft-tab-buttons.html',
  styleUrls: ['./ft-tab-buttons.scss'],
})
export class FtTabButtonsComponent extends BaseComponent implements OnInit {
  @Input() buttons: Array<TabButton>;
  @Input() selected: string;
  @Output() selectedChange: EventEmitter<string> = new EventEmitter<any>();
  @Input() direction: string = 'row';
  @Input() size: string = '16px';
  @Input() isLastData: boolean = false;
  @Input() unselectedColor = 'gray3';
  private isClicked: boolean = false;
  constructor(
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // this.log('ngOnChanges', changes);
    if (this.isClicked) {
      this.isClicked = false;
      return;
    }

    if (changes.selected && !changes.selected.firstChange && changes.selected.previousValue != changes.selected.currentValue) {
      this.selectedChange.emit(this.selected);
    }
  }

  onClick(button: TabButton) {
    this.isClicked = true;
    this.log('onClick', button, this.selected);
    this.selected = button.id || button.name;
    this.selectedChange.emit(this.selected);
  }
}
