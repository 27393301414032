import { Component, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { User } from '@fitt-lib/models/fitt-models';

@Component({
  selector: 'ft-user-profile-name',
  templateUrl: './ft-user-profile-name.html',
  styleUrls: ['./ft-user-profile-name.scss'],
})
export class FtUserProfileNameComponent extends FtLibBaseComponent {
  @Input() user: User;

  constructor(
  ) {
    super();
  }
}
