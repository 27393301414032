import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-digit-label',
  templateUrl: './ft-digit-label.component.html',
  styleUrls: ['./ft-digit-label.component.scss'],
})
export class FtDigitLabelComponent extends BaseComponent implements OnInit {

  @Input() value: string = 'value';
  @Input() text: string = 'text';
  @Input() unit: string = '';
  @Input() showText: boolean = true;
  @Input() reverse: boolean = false;
  @Input() valueSize: number = 24;
  @Input() textSize: number = 12;
  @Input() textMargin = 0;
  @Input() set size(size) {
    this.valueSize = size;
    this.textSize = Math.floor(size / 2);
  }
  @Input() space?: string = 'pre-line';

  constructor(
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    this.log('ngOnInit', this.value, this.text, this.valueSize, this.textSize, this.reverse);
  }

}
