import { Component, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';

@Component({
  selector: 'fmes-settings-main',
  templateUrl: './fmes-settings-main.html',
  styleUrls: ['./fmes-settings-main.scss'],
})
export class FmesSettingsMainModal extends FmesBaseComponent implements OnInit {
  systemNoticeBadge: string;
  versionDisplay: string;
  selectedLanguage: 'device' | 'en' | 'ko';
  supportLanguages = ['device', 'en', 'ko'];
  displayUnit: 'km' | 'mi';
  supportUnit = ['km', 'mi'];
  constructor(
    private modalCtrl: ModalController,
    public platform: Platform,
  ) {
    super({ enableLog: false });
  }

  async ngOnInit() {
    this.selectedLanguage = localStorage.selectedLanguage || 'device';
    this.displayUnit = this.util.isDisplayUnitKm() ? 'km' : 'mi';
    this.versionDisplay = (await this.fittUtil.getVersionInfo()).versionDisplay;
  }
  changeLanguage(language) {
    this.log('changeLanguage', 'language=' + language, 'localStorage.selectedLanguage=' + localStorage.selectedLanguage, 'getDefaultLang=' + this.translate.getDefaultLang());
    if (language != 'device') {
      this.util.setUseLanguage(language);
    }
    else {
      delete localStorage.selectedLanguage;
      const language = this.util.getLanguageCode(navigator.language);
      this.util.setUseLanguage(language);
    }
    this.log('changeLanguage' + this.translate.currentLang);
    location.reload();
  }

  changeDisplayUnit(unit) {
    this.log('changeDisplayUnit', 'unit=' + unit, 'localStorage.displayUnit=' + localStorage.displayUnit);
    delete localStorage.displayUnit;
    localStorage.displayUnit = unit;

    this.log('changeLanguage' + localStorage.displayUnit);
    location.reload();
  }

  async close() {
    this.modalCtrl.dismiss();
  }
}
