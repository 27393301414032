import { NgModule } from '@angular/core';
import { DatePickerModal } from './date-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DatePickerModal,
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  entryComponents: [
    DatePickerModal,
  ],
  exports: [
  ],
})
export class DatePickerModalModule { }
