import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

export type ToggleGroupButtons = {
  style?: { fontSize: string, buttonStyle: 'clear' | 'outline' | 'solid', buttonWidth: string },
  buttons: GroupButton[]
};

export type GroupButton = {
  key: string,
  title: string;
  value: boolean;
};

@Component({
  selector: 'ft-toggle-button-group',
  templateUrl: './ft-toggle-button-group.html',
  styleUrls: ['./ft-toggle-button-group.scss'],
})
export class FtToggleButtonGroupComponent extends BaseComponent implements OnInit {
  @Input() groupButtons: ToggleGroupButtons = {
    buttons: [{ key: 'default', title: '버튼', value: true }]
  };  // default value

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  onValueChange() {
    this.onChange && this.onChange.emit({ buttons: this.groupButtons.buttons });
  }

  getButtonStyle() {
    return this.groupButtons.style ? this.groupButtons.style.buttonStyle : 'outline';
  }

  getButtonWidth() {
    return { width: this.groupButtons.style ? this.groupButtons.style.buttonWidth : 'unset' };
  }
}
