import { Component, OnInit, Input, forwardRef, ViewChild, ElementRef, Output, EventEmitter, Renderer2, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IonInput, IonLabel } from '@ionic/angular';

@Component({
  selector: 'ft-input',
  templateUrl: './ft-input.component.html',
  styleUrls: ['./ft-input.component.scss'],
  providers: [{   // <================================================ ADD THIS
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => InputComponent), multi: true
  }],
  // viewProviders: [
  //   {
  //     provide: ControlContainer,
  //     useFactory: (container: ControlContainer) => container,
  //     deps: [[new SkipSelf(), ControlContainer]]
  //   },
  // ]
})
export class InputComponent extends BaseComponent implements OnInit, ControlValueAccessor {
  // @ViewChild('input', { static: true }) inputRef: ElementRef;
  @ViewChild('label', { static: true }) label: IonLabel;
  @ViewChild('container', { static: true }) container: ElementRef;
  @ViewChild('input', { static: true }) input: IonInput;

  @Input() title: string;
  @Input() position: string;

  @Input() type: string;
  @Input() inputmode: string;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() value?: string;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  isFocused: boolean;
  @Input() disabled: boolean = false;
  @Input() hasLabel: any = false;
  @Input() hasClose: boolean = false;
  @Input() unitLabel: string;
  @Input() containerHeight: string;
  @Input() inputBorder: string;
  @Input() isDisabledValue?: boolean;
  @Input() mask?: string;

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
  ) {
    super({ enableLog: false });
  }

  // The internal data model
  public innerValue: any = '';

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onChangeCallback: any;

  // implements ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.onChangeCallback && this.onChangeCallback(value);
      this.log('writeValue', value);
      this.innerValue = value;
    }
  }
  // implements ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // implements ControlValueAccessor interface - not used, used for touch input
  registerOnTouched() { }

  // change events from the textarea
  onChangeInternal() {
    const input = <HTMLInputElement>this.input['el'];
    // get value from text area
    const newValue = input.value;
    this.log('onChangeInternal', { innerValue: this.innerValue, newValue });
    if (this.innerValue === newValue) {
      return;
    }
    this.innerValue = newValue;

    if (newValue && newValue.length > 0) {
      this.renderer.addClass(this.element.nativeElement, 'has-value');
    }
    else {
      this.renderer.removeClass(this.element.nativeElement, 'has-value');
    }

    // update the form
    this.onChangeCallback && this.onChangeCallback(newValue);
    this.onChange.emit({ eventType: 'change', value: input.value });
    this.valueChange.emit(this.input.value);
  }
  async ngOnInit() {
    this.log('ngOnInit', { label: this.label, container: this.container, input: this.input, inputBorder: this.inputBorder, value: this.value });

    const input = <HTMLInputElement>this.input['el'];
    if (input) {
      input.onchange = () => this.onChangeInternal();
      input.onkeyup = () => this.onChangeInternal();
    }
    if (this.container) {
      this.containerHeight && this.renderer.setStyle(this.container.nativeElement, 'height', this.containerHeight);
      this.inputBorder == 'false' && this.renderer.setStyle(this.container.nativeElement, 'border', 'none');
      // this.renderer.addClass(this.el.nativeElement, 'wild');
    }

    if (this.value) {
      this.input.value = this.value;
      this.writeValue(this.value);

      if (this.value.length > 0) {
        this.renderer.addClass(this.element.nativeElement, 'has-value');
      }
    }
    this.log('ngOnInit', this);
  }

  ngAfterViewInit() {
    this.log('ngAfterViewInit', this);
    if (this.position == 'stacked' && this.label) {
      this.renderer.addClass(this.label['el'], 'label-stacked');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.log('ngOnChanges', changes, this.value);
    if (changes.value) {
      this.input.value = this.value;
      if (this.value && this.value.length > 0) {
        this.renderer.addClass(this.element.nativeElement, 'has-value');
      }
      else {
        this.renderer.removeClass(this.element.nativeElement, 'has-value');
      }
    }
  }

  clear() {
    this.log('clear', this.input);
    this.input.value = '';
    this.writeValue('');
    this.renderer.removeClass(this.element.nativeElement, 'has-value');
    this.input.setFocus();
    this.onChange.emit({ eventType: 'change', value: this.input.value });
    this.valueChange.emit(this.input.value);
  }

  inputFocus($event) {
    this.log('inputFocus', $event);
    this.renderer.addClass(this.element.nativeElement, 'has-focus');
    this.renderer.addClass(this.container.nativeElement, 'has-focus');
    this.isFocused = true;
  }

  inputBlur($event) {
    this.log('inputBlur', $event, document.activeElement);
    this.renderer.removeClass(this.element.nativeElement, 'has-focus');
    this.renderer.removeClass(this.container.nativeElement, 'has-focus');
    this.isFocused = false;
  }

  setFocus() {
    setTimeout(() => this.input.setFocus(), 100);
  }
}
