<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="black2" (click)="tapModalTitle()">
      {{'대체.움직임능력검사'|translate}}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" [disabled]="isDisabled()" (click)="save()">
        <ion-text color="main">{{'마이페이지.제출'|translate}}</ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-item lines="full" class="fmt-toolbar">
    <ion-text color="black2">
      {{'대체.움직임능력검사'|translate}} {{curOrder ? curOrder : '-'}}{{'모달.회차'|translate}}
    </ion-text>
    <ion-button fill="clear" (click)="loadRecentRecord()" [disabled]="!this.records || !this.records.length">
      <ion-label color="main">{{'건강.최근 내역 불러오기'|translate}}</ion-label>
    </ion-button>
  </ion-item>
  <ion-item lines="full" class="fmt-toolbar" (click)="editTestInDate()">
    <ion-text color="black2">{{testInDate|ftdate:'yyyy. MM. dd (E) A/P HH:mm'}}</ion-text>
    <ion-text color="gray3" class="text-button">{{'모달.편집'|translate}}</ion-text>
  </ion-item>
  <ion-row class="tabs">
    <ion-col *ngFor="let fmtCheck of fmtItemList; index as fmtCheckIndex;" size="6" class="tab-container" (click)="openFmtDetail(fmtCheckIndex)">
      <img class="tab-image" [src]="isFmtItemAnswered(fmtCheckIndex) ? fmtCheck.img.normal : fmtCheck.img.deactive">
      <ion-text class="tab-title" [color]="isFmtItemAnswered(fmtCheckIndex) ? 'main' : 'gray6'">
        {{fmtCheckIndex + 1}}. {{'fmt2.' + fmtCheck.subject|translate}}
      </ion-text>
      <div class="dots">
        <span *ngFor="let item of fmtCheck.children; index as itemIndex;"
          [ngClass]="isAnswered(fmtCheckIndex, itemIndex) ? 'active-main' : 'inactive'"></span>
      </div>
      <div class="pain-answers">
        <ion-text color="gray6">
          <span class="bold" *ngFor="let text of fmtCheck.position; index as idx;">
            {{text}} {{surveyItems.answerList[fmtCheckIndex][idx]}}
            <span class="bold" *ngIf="idx < (fmtCheck.position.length - 1)"> / </span>
          </span>
        </ion-text>
        <img *ngIf="hasPainInSurvey(fmtCheckIndex)" class="pain-icon" src="/assets/fitt-lib/img/fmt-pain-icon.png">
      </div>
    </ion-col>
  </ion-row>
</ion-content>
