import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { FtInfomationModal } from '@fitt-lib/modals/ft-infomation/ft-infomation';

@Component({
  selector: 'fmes-subscribe-main',
  templateUrl: './fmes-subscribe-main.html',
  styleUrls: ['./fmes-subscribe-main.scss']
})
export class FmesSubscribeMainModal extends FmesBaseComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) {
    super();
  }

  // 구독 이스터 에그 관련
  tappingTimer: NodeJS.Timeout | undefined = undefined;
  numberOfTappings: number = 0;
  isSubscribe: boolean = true;

  ngOnInit(): void {
  }

  async close() {
    this.modalCtrl.dismiss();
  }

  async openPaymentCancelInfo() {
    const modal = await this.modalCtrl.create({
      component: FtInfomationModal,
      // swipeToClose: true, // ionic multitouch error
      mode: 'ios',
      showBackdrop: true,
      cssClass: 'swipeable-modal',
      componentProps: {
        infoTitleFontBold: true,
        title: '결제 / 해지 안내',
        infomationItems: [
          {
            title: '결제 안내',
            content: [
              '부가가치세 10%가 포함된 가격입니다.',
              '매월 정해진 정기결제일에 결제가 발생하며, 인앱결제의 경우 앱 마켓에서 정한 날짜에 정기결제가 이루어질 수 있습니다.',
              '미성년자인 회원이 법정대리인 동의없이 상품을 구매하는 경우 본인 또는 법정대리인이 이를 취소할 수 있습니다.'
            ],
          },
          {
            title: '결제 해지 안내',
            content: [
              '결제 후 계약내용에 관한 서면을 교부받은 날(이하 ‘구매일’)로부터 7일 이내에 청약철회할 수 있습니다. 매월 결제일 기준 7일 이내 이용내역이 없는 경우, 청약철회 신청이 가능하며 전액 환불됩니다. 단, 이용내역이 있을 경우 결제 금액에서 이용대금을 제외한 금액이 환불됩니다.',
              '위 청약철회와 별개로 회원은 언제든 이용 계약을 해지할 수 있습니다. 이 때 회사는 회원이 서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환불합니다. 청약철회 및 계약 해지는 구독 페이지에서 할 수 있으며, 청약철회일로부터 3 영업일 이내에 지급받은 대금의 환급을 정당한 사유없이 지연하는 경우 회원은 지연기간에 대하여 연15%의 지연 배상금을 회사에 청구할 수 있습니다. 다만 인앱 결제는 앱마켓의 정책에 따른 결제 취소 및 환불만 가능할 수 있으므로, 이러한 경우 당해 앱마켓 고객센터로 문의하여 주시기 바랍니다.',
              '회사는 회원이 이용한 앱마켓 결제에 대하여 해당 앱마켓에 대금지급의 정지∙취소 요청 등 전자상거래 등에서의 소비자 보호에 관한 법률에 따른 관련 조치를 요청할 수 있습니다. 다만, 해당 앱마켓의 위와 같은 회사의 요청에 따른 조치에 대하여는 해당 앱마켓의 정책을 참조하여 주시기 바랍니다.',
              '그 외 청약철회 및 계약 해지와 환불 관련 사항은 FMT 유료서비스 이용약관의 기준에 따르며, 피해보상 및 불만 처리 등 필요한 사항은 [고객센터]로 연락 주시기 바랍니다.'
            ]
          }
        ]
      }

    });
    await modal.present();

  }

  cancelSubscribe() {

  }



  /*
* 모달 타이틀을 5초 안에 연달아 5번 터치하면 샘플 데이터가 채워지는 이스터 에그
*/
  tapModalTitle() {
    if (!this.tappingTimer) {
      this.tappingTimer = setTimeout(() => {
        this.numberOfTappings = 0;
      }, 5000);
    } else {
      this.tappingTimer = undefined;
    }

    this.numberOfTappings++;
    if (this.numberOfTappings >= 5) {
      this.isSubscribe = !this.isSubscribe;
    }
  }
}
