import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { BookingState, ScState } from '@fitt-lib/models/booking-models';
import { FittMember, GymSalarySettingUnsetState, GymUser, MemberRole, PlanPermission, Trainer, User } from '@fitt-lib/models/fitt-models';
import { PackageStatus } from '@fitt-lib/models/package-models';
import { DispClassCount, DispClassCountRecord, LessonSchedule, MonthlyScheduleType, Schedule, ScheduleClass, ScheduleType } from '@fitt-lib/models/schedule-models';
import { GymSettings } from '@fitt-lib/models/schedule-types';
import { ApiService } from '@fitt-lib/services/api.service';
import { pcSchedule } from '@fitt-lib/theme/cell-color.json';
import { IsDebug } from '@ionic-native/is-debug/ngx';
import { Platform } from '@ionic/angular';
import { StringUnion } from '@shared-lib/utils/string-union';
import accountJson from '../assets/account/account.json';
import planPermissionJson from '../assets/plan-permission/plan-permission.json';
import { BaseComponent } from '@shared-lib/base.component';

const PERMISSION_METHOD = StringUnion(
  '스케줄.스케줄 보기', '스케줄.스케줄 읽기', '웹헤더.운영데이터', '회원 삭제',
  '회원관리.진행/휴면 전환', '회원관리.결제정보 수정', '회원관리.공지 보내기', '회원관리.회원목록 다운로드', '회원관리.결제 분석',
  '센터관리.회사정보 수정', '센터관리.패키지 삭제', '회원관리.결제 분석', '센터관리.패키지 추가', '센터관리.CS 삭제',
  '마이페이지.정기 결제 관리', '마이페이지.트레드밀 구매', '마이페이지.환경설정', '마이페이지.어드민', '마이페이지.트레드밀 관리', '마이페이지.GYM 관리', '마이페이지.트레드밀 코드',
  '스케줄.예약 관리', '스케줄.GX 수업', '어드민.어드민 페이지', '어드민.그룹짐 페이지', '모바일.회원 관리', '마이페이지.내 비밀번호 변경', '회원관리.결제'
);
type PermissionMethod = typeof PERMISSION_METHOD.type;
const PLAN_PERMISSION_METHOD = StringUnion(
  '웹헤더.공지 보내기', '웹헤더.사물함 관리', '웹헤더.스케줄', '웹헤더.센터관리', '웹헤더.운영데이터', '웹헤더.급여정산', '웹헤더.매출 관리', '웹헤더.이용 분석',
  '회원관리.상세 필터', '회원관리.구분 탭', '회원관리.결제', '회원관리.회원 결제 현황',
  '스케줄.스케줄러 타입', '스케줄.스케줄러 PT', '스케줄.스케줄러 GX', '스케줄.가상 예약',
  '건강.심폐능력검사(TMT)', '건강.근력측정평가(SMT)', '건강.움직임능력검사(FMT)', '건강.젖산검사(선수평가)'
);
type PlanPermissionMethod = typeof PLAN_PERMISSION_METHOD.type;
const CHECK_STATE = StringUnion('confirm', 'autoConfirm', 'cancel');
type CheckState = typeof CHECK_STATE.type;


@Injectable({
  providedIn: 'root'
})
export class FittUtilService extends BaseComponent {
  gApi: ApiService;
  supportedBrowser: boolean = true;
  versionInfo: any;
  ScState = ScState;
  BookingState = BookingState;
  version: string;

  color = {
    main: '#206ff6',
    sub1: '#01c2b2',
    sub2: '#d4fe42',
    sub3: '#c35bf5',
    sub4: '#ff2d55',
    error: '#ff2d55',
    caution: '#f7ba02',
  };
  readonly maleUrl: string = 'https://firebasestorage.googleapis.com/v0/b/fitt-4-real.appspot.com/o/profile%2FFITT_profile_default_image_male.png?alt=media&token=be25c2e6-b44f-4282-be12-41e189b1a071';
  readonly femaleUrl: string = 'https://firebasestorage.googleapis.com/v0/b/fitt-4-real.appspot.com/o/profile%2FFITT_profile_default_image_female.png?alt=media&token=a1e3c9a5-8841-4277-a8b7-31c8a2e5c58c';


  constructor(
    private http: HttpClient,
    private platform: Platform,
    private isDebug: IsDebug,
    @Inject('environment') public environment: any,
    @Inject('packageJson') private packageJson: any,
    // private util: UtilService,
    // private translate: TranslateService,
  ) {
    super({ enableLog: false });
    this.version = this.version || `${packageJson.version}${packageJson['subVersion'] && packageJson['subVersion'] != '0' ? '.' + packageJson['subVersion'] : ''}`;
  }
  setApiService(gApi: ApiService) {
    this.gApi = gApi;
  }

  assets(path, ext?): string {
    return ext ? `assets/fitt-lib/${path}${ext}` : `assets/fitt-lib/${path}`;
  }

  schedule = {
    getSessionState: (schedule: Schedule): string => {  // search-user-ticket, schedule-history 등에서 과거 세션 설명용으로 사용
      switch (schedule.sState) {
        case ScState.Reject:
          return this.translate.instant('스케줄.예약반려');
        case ScState.CancelByUser:
        case ScState.CancelByTrainer:
        case ScState.Cancel:
        case ScState.CancelRequest:
          return this.translate.instant('취소');
        case ScState.NoShowByUser:
        case ScState.NoShowByTrainer:
        case ScState.NoShowReset:
          return `${this.translate.instant('스케줄.노쇼')} ${this.schedule.getUsedSessionText(schedule)}`;
        case ScState.RegisterVirtual:
          return this.translate.instant('스케줄.가상예약');
        default:
          return '';
      }
    },

    getTitle: (schedule: LessonSchedule, showMode: 'calendar' | 'item', role: MemberRole = 'none'): string => {
      const myInfo = this.gApi.myInfo;

      if (showMode == 'calendar') { // 캘린더 형태의 공간에 출력. 넓이가 좁음.
        switch (schedule.sType) {
          case 'GX': {
            if (ScState.Cancel == schedule.sState) return this.translate.instant('스케줄.state16 title');
            if (BookingState.Reserved.includes(schedule.sState)) return schedule.cName;
            // if (ScState.ReservationRequest == schedule.sState) return this.translate.instant(`스케줄.state${schedule.sState} titleGX`);
            if (ScState.ReservationRequest == schedule.sState) return this.translate.instant('스케줄.대기');
            if (BookingState.NoShow.includes(schedule.sState)) return this.translate.instant('스케줄.state17 title');

            return schedule.cName;
          }
          case 'POINT':
          case 'PT': {
            if (BookingState.Reserved.includes(schedule.sState) || ScState.RegisterVirtual == schedule.sState) return myInfo.getRole() == 'user' ? schedule.tName : schedule.uName;
            if (!schedule.sState || BookingState.OpenClose.includes(schedule.sState)) return schedule.lState === 'open' ? 'OPEN' : 'CLOSE';
            if (BookingState.NoShow.includes(schedule.sState)) return this.translate.instant('스케줄.state17 title');
            if (BookingState.Cancel.includes(schedule.sState)) return this.translate.instant('취소');
            return this.translate.instant(`스케줄.state${schedule.sState} title`);
          }
          case 'OT':
          case 'TEST': {
            if (BookingState.Reserved.includes(schedule.sState)) return myInfo.getRole() == 'user' ? schedule.tName : schedule.uName;
            if (BookingState.NoShow.includes(schedule.sState)) return this.translate.instant('스케줄.state17 title');
            return this.translate.instant(`스케줄.state${schedule.sState} title`);
          }
          case 'MEMO': {
            return schedule.sMemoTitle;
          }
        }
      }
      else if (showMode == 'item') {  // 아이템 형태의 공간에 출력. 넓이가 조금 있음. ft-mobile-schedule
        switch (schedule.sType) {
          case 'GX': {
            const preState = (() => {
              if (BookingState.Reserved.includes(schedule.sState)) return '';
              if (!BookingState.isValid(schedule.sState)) return '';
              // if (ScState.ReservationRequest == schedule.sState) return `[${this.translate.instant(`스케줄.state${schedule.sState} titleGX`)}] `;
              if (ScState.ReservationRequest == schedule.sState) return `[${this.translate.instant('스케줄.대기')}] `;
              if (BookingState.NoShow.includes(schedule.sState)) return `[${this.translate.instant('스케줄.state17 title')}] `;
              return `[${this.translate.instant(`스케줄.state${schedule.sState} title`)}] `;
            })();
            return `${preState}${schedule.cName}`;
          }
          case 'POINT': {
            const sTypeText = this.translate.instant('회원관리.적립금');
            if (BookingState.Confirm.includes(schedule.sState) && ScState.AutoConfirm != schedule.sState) return sTypeText;
            else if (BookingState.NoShow.includes(schedule.sState)) return `[${this.translate.instant('스케줄.state17 title')}] ${sTypeText}`;
            else if (this.schedule.isCheckedSchedule('autoConfirm', schedule, role)) return sTypeText;
            else return `[${this.translate.instant(`스케줄.state${schedule.sState} title`)}] ${sTypeText}`;
          }
          case 'PT': {
            if (BookingState.Confirm.includes(schedule.sState) && ScState.AutoConfirm != schedule.sState) return 'PT';
            else if (!schedule.sState || BookingState.OpenClose.includes(schedule.sState)) return schedule.lState === 'open' ? 'OPEN' : 'CLOSE';
            else if (BookingState.NoShow.includes(schedule.sState)) return `[${this.translate.instant('스케줄.state17 title')}] PT`;
            else if (this.schedule.isCheckedSchedule('autoConfirm', schedule, role)) return 'PT';
            else return `[${this.translate.instant(`스케줄.state${schedule.sState} title`)}] PT`;
          }
          case 'OT':
          case 'TEST': {
            const preState = (() => {
              if (BookingState.Reserved.includes(schedule.sState)) return '';
              if (BookingState.NoShow.includes(schedule.sState)) return `[${this.translate.instant('스케줄.state17 title')}] `;
              return `[${this.translate.instant(`스케줄.state${schedule.sState} title`)}] `;
            })();
            return `${preState}${this.schedule.getClassName(schedule.sType)}`;
          }
          case 'MEMO': {
            return schedule.sMemoTitle;
          }
        }
      }

      return this.translate.instant(`스케줄.state${schedule.sState} title`);
    },
    getStateTitle: (schedule: Schedule): string => {
      return schedule.sType == 'MEMO' ? schedule.sMemoTitle : `${schedule.uName} ${this.translate.instant('모달.회원님')}`;
    },
    getStateText: (schedule: Schedule): string => {
      if (schedule && BookingState.isValid(schedule.sState)) {
        if (schedule.sType === 'MEMO') {
          if (schedule.sState === ScState.Memo) return this.translate.instant(`스케줄.일정 등록`);
          if (schedule.sState === ScState.Delete) return this.translate.instant(`스케줄.일정 삭제`);
        }
        if (schedule.sType === 'GX' && schedule.sState === ScState.ReservationRequest) {
          return this.translate.instant(`스케줄.state${ScState.ReservationRequest} titleGX`);
        }
        return this.translate.instant(`스케줄.state${schedule.sState} title`);
      }
      return '';
    },
    getStateColor: (schedule: Schedule): string => {
      return BookingState.Red.includes(schedule.sState) ? 'var(--ft-color-error)' : 'var(--ft-color-main)';
    },

    getClassName: (sType: ScheduleType, name?: string): string => {
      if (['PT', 'OT'].includes(sType)) {
        return sType;
      } else if (sType === 'TEST') {
        return this.translate.instant('건강.운동검사');
      } else if (sType === 'MEMO') {
        // return '메모';
        return '';
      } else {
        return (name || '');
      }
    },

    getScheduleDate: (schedule: Schedule): string => {
      const { sMemoAllDay, sAllDay, lInDate, sInDate, sStartDate, } = schedule;
      const isAllDay = Boolean(sMemoAllDay || sAllDay);
      const formatType = `NN DD (E) ${isAllDay ? this.translate.instant('컴포넌트.종일') : 'HH:mm'}`;
      let date = new Date();

      if (sStartDate) {
        date = sStartDate;
      } else if (lInDate) {
        date = lInDate;
      } else if (sInDate) {
        date = sInDate;
      } else {
        console.error('getScheduleDate error', schedule);
      }
      return date.format(formatType);
    },

    // TODO: 조형상. 아래 함수 정리 필요.
    getExplain: (schedule: Schedule, option: 'ALL' | 'simpleGX' = 'ALL') => { // 'state4': '{{sStartDate}} {{tName}}T {{cName}} 수업 완료',
      const { sState, sType, uName } = schedule;
      const cName = this.schedule.getClassName(sType, schedule.cName || schedule.paName);
      const tName = schedule.tName + 'T';
      const htName = schedule.htAuth === 'COMMON' ? schedule.gName : (schedule.htName || schedule.tName) + 'T';
      // const sStartDate = (schedule.sState != ScState.Complete ? schedule.sStartDate : schedule.sEndDate).format('NN DD (E) HH:mm');
      const scheduleDate = this.schedule.getScheduleDate(schedule);
      const sNoShowSession = this.schedule.getUsedSessionText(schedule);
      const params = { sState, sType, uName, cName, tName, htName, sStartDate: scheduleDate, sNoShowSession };

      if (sType === 'MEMO') {
        const getMemoState = (sState: ScState) => {
          switch (sState) {
            case ScState.Memo:
              return this.translate.instant('스케줄.일정 등록');
            case ScState.Delete:
              return this.translate.instant('스케줄.일정 삭제');
            default:
              return '';
          }
        };
        const memoState = getMemoState(sState);

        return `${scheduleDate} ${tName} ${memoState} (${htName})`;
      } else if (option === 'simpleGX') {
        return this.translate.instant(`예약관리모달.GX.state${sState}`, params);
      } else if (sType === 'GX' && sState === ScState.Confirm) {
        return this.translate.instant(`스케줄.state${sState} GX`, params);
      }
      return this.translate.instant(`스케줄.state${sState}`, params);
    },

    getSessionStatus: (schedule: Schedule) => {
      switch (schedule.sState) {
        case ScState.CancelByUser:
        case ScState.CancelByTrainer:
        case ScState.Cancel:
          return this.translate.instant('스케줄.예약이 취소되었습니다.');
        case ScState.CancelRequest:
          return this.translate.instant('스케줄.예약 신청이 취소되었습니다.')
        case ScState.NoShowByUser:
        case ScState.NoShowByTrainer:
        case ScState.NoShowReset:
          return this.translate.instant('스케줄.예약이 노쇼되었습니다.');
        case ScState.Reject:
          return this.translate.instant('스케줄.예약 신청이 반려되었습니다.');
        case ScState.RejectChange:
          return this.translate.instant('스케줄._회 중 _번째 예약 변경 신청이 반려되었습니다.', { totalSession: schedule.pSession, currentSession: schedule.sOrder + schedule.trSession });
        case ScState.RequestChange:
          return this.translate.instant('스케줄._회 중 _번째 예약에 대한 변경 신청입니다.', { totalSession: schedule.pSession, currentSession: schedule.sOrder + schedule.trSession });
        case ScState.RegisterVirtual:
          if ((schedule.sEndDate || schedule.sStartDate) < new Date()) return this.translate.instant('스케줄.지난 가상예약입니다.');
          else return this.translate.instant('스케줄.가상예약입니다.');
        case ScState.ReservationRequest: {
          const getRequestMessage: { [sType: string]: () => string } = {
            'PT': () => '스케줄._회 중 _번째 예약 신청입니다.',
            'GX': () => '스케줄._회 중 _번째 예약 대기 중입니다.',
          };
          return this.translate.instant(getRequestMessage[schedule.sType](), {
            totalSession: schedule.pSession,
            currentSession: schedule.sOrder + schedule.trSession
          });
        }
        case ScState.AutoConfirm:
        case ScState.Complete:
        case ScState.Confirm:
        case ScState.ReservationByTrainer:
        case ScState.ConfirmChange:
        case ScState.RegisterConfirm:
          if (!schedule.pSession) {
            return this.translate.instant('컴포넌트._번째 예약입니다.', { currentSession: schedule.sOrder + schedule.trSession });
          }
          else {
            return this.translate.instant('컴포넌트._회 중 _번째 예약입니다.', { totalSession: schedule.pSession, currentSession: schedule.sOrder + schedule.trSession });
          }
        case ScState.Delete:
          return this.translate.instant('삭제된 가상 예약입니다.');
      }
    },

    // getNoShowText: (sNoShowSession: number): string => {
    //   return sNoShowSession > 1 ? `1/${sNoShowSession}` : `${(sNoShowSession || 0)}`;
    // },

    getUsedSessionText: (schedule: Schedule): string => {
      if (ScState.Adding === schedule.sState || !schedule.sState) {
        return `${schedule.sSession || 1}${this.translate.instant('일반.회')}`;
      } else if (BookingState.ShowOnScheduler.includes(schedule.sState)) {
        return `${schedule.sSession || 1}${this.translate.instant('일반.회')}`;
      } else if (BookingState.NoShow.includes(schedule.sState)) {
        const amount = schedule.sNoShowSession || 0;
        const unit = this.translate.instant('일반.' + (schedule.sType === 'POINT' ? '원' : '회'));

        return `${amount.currencyFormat()}${unit}`;
      } else if (!BookingState.Reserved.includes(schedule.sState) && !BookingState.Wait.includes(schedule.sState)) {
        return `0${this.translate.instant('일반.회')}`;
      } else {
        return '';
      }
    },

    getSessionDesc: (schedule: any): string => {
      const pSession = schedule.pSession || schedule.upSession || 0;
      return `${schedule.sOrder + schedule.trSession || 0}` + (pSession ? `/${pSession}` : '') + this.translate.instant('일반.회');
    },

    getEnableTrainerList: (trainerList: Array<Trainer>): Array<Trainer> => {
      const myInfo = this.gApi.myInfo;
      const tSeq: number = myInfo.getTrainerSeq();

      if (myInfo.isManager() || myInfo.isCommon()) {
        return trainerList;
      } else {
        const enableTrainerList: Array<Trainer> = [];
        const trainer = trainerList.find(trainer => trainer.tSeq === tSeq);

        trainer && enableTrainerList.push(trainer);
        return enableTrainerList;
      }
    },

    getEnableUserList: (userList: Array<GymUser>, gymSettings: GymSettings): Array<GymUser> => {
      const myInfo = this.gApi.myInfo;
      const tSeq: number = myInfo.getTrainerSeq();

      if (myInfo.isManager() || myInfo.isCommon()) {
        return userList;
      } else {
        let enableUserList: Array<GymUser> = [];

        if (gymSettings.crossTrainer === 1) {
          enableUserList = Array.from(userList, gymUser => gymUser);
        } else {
          const userListInCharge = userList.filter(gymUser => {
            const { user } = gymUser;

            if (user) {
              return !user.tSeq || user.tSeq === tSeq;
            } else {
              return false;
            }
          });
          enableUserList = userListInCharge;
        }
        return enableUserList;
      }
    },

    syncDate: (user: User): Date => {
      if (user && user.mInDate) {
        return user.mInDate > user.uInDate ? user.mInDate : user.uInDate;
      }
      return null;
    },

    getPaTypeText: (paType: string, shortText: boolean = false) => {
      switch (paType) {
        case 'PRODUCT': return this.translate.instant('컴포넌트.상품');
        case 'HEALTH': return this.translate.instant('웹짐.헬스');
        case 'TEST': return shortText ? this.translate.instant('대체.운동검사') : this.translate.instant('모달.운동검사');
        case 'ETC': return this.translate.instant('모달.기타');
      }
      return paType;
    },
    getClassHours: (gxClass: ScheduleClass) => {
      const hourString = [];
      for (const hour of gxClass.hHours) {
        hourString.push(`${this.util.getDayOfWeek(hour.day, true)} ${hour.startTime}~${hour.endTime}`);
      }
      return hourString.join('\n');
    },
    getGXNotice: (schedule: LessonSchedule): string => {
      return schedule.lMemo ? schedule.lMemo : this.translate.instant('스케줄.수업 공지가 없습니다.');
    },
    getGXNoticeColor: (schedule: LessonSchedule): string => {
      return schedule.lMemo ? 'black1' : 'gray4';
    },
    isPastVirtualSchedule: (schedule: Schedule): boolean => {
      return schedule.sState === ScState.RegisterVirtual && schedule.sStartDate < new Date();
    },
    countSchedules: (countObj: any, s: Schedule | LessonSchedule) => {
      switch (s.sType) {
        case 'MEMO': countObj.memo++; break;
        case 'PT':
          if (BookingState.Reserved.includes(s.sState)) {
            countObj.pt++;
            countObj.reserved++;
          }
          else if (s.sState === ScState.RegisterVirtual) {
            countObj.virtual++;
          }
          break;
        case 'OT':
          if (BookingState.Reserved.includes(s.sState)) {
            countObj.ot++;
            countObj.reserved++;
          }
          break;
        case 'GX':
          countObj.gx++;
          countObj.reserved++;
          break;
        case 'TEST':
          if (BookingState.Reserved.includes(s.sState)) {
            countObj.ch++;
            countObj.reserved++;
          }
          break;
      }
      if (s instanceof LessonSchedule) {
        switch (s.lState) {
          case 'OPEN': countObj.open++; break;
          case 'CLOSE': countObj.close++; break;
        }
      }
    },
    getClassCountForm: (classCount: DispClassCount): string => {
      return `${(classCount.pastDayClassCount || 0)}(${(classCount.futureDayClassCount || 0)})/${classCount.pastMonthClassCount || 0}`;
    },
    getTotalDailyTrainerInfo: (trainerClassCount: DispClassCountRecord, countType?: MonthlyScheduleType) => {
      const classCount: DispClassCount = {
        pastDayClassCount: 0,
        futureDayClassCount: 0,
        pastMonthClassCount: 0,
      };

      const classCountForms: Record<MonthlyScheduleType | 'default', () => DispClassCount> = {
        'PT': () => trainerClassCount['PT'],
        'GX': () => trainerClassCount['GX'],
        'ETC': () => {
          for (const [sType, info] of Object.entries(trainerClassCount)) {
            if (['OT', 'TEST'].includes(sType)) {
              classCount.pastDayClassCount += info.pastDayClassCount;
              classCount.futureDayClassCount += info.futureDayClassCount;
              classCount.pastMonthClassCount += info.pastMonthClassCount;
            }
          }
          return classCount;
        },
        'POINT': () => trainerClassCount['POINT'],
        'default': () => {
          for (const info of Object.values(trainerClassCount)) {
            classCount.pastDayClassCount += info.pastDayClassCount;
            classCount.futureDayClassCount += info.futureDayClassCount;
            classCount.pastMonthClassCount += info.pastMonthClassCount;
          }
          return classCount;
        }
      };

      return (classCountForms[countType] || classCountForms['default'])();
    },
    getTotalClassCount: (trainerClassCount: DispClassCountRecord, countType?: MonthlyScheduleType): string => {
      if (!trainerClassCount || typeof trainerClassCount !== 'object') {
        return '0(0)/0';
      }
      const dailyTrainerInfo: DispClassCount = this.schedule.getTotalDailyTrainerInfo(trainerClassCount, countType);
      return this.schedule.getClassCountForm(dailyTrainerInfo);
    },
    getUsedSession: (uSession: number, trSession: number, futureCount: number, pSession: number) => {
      return `${uSession + trSession || 0}(${futureCount || 0})/${pSession === 0 ? this.translate.instant('모달.무제한') : (pSession || 0)}`;
    },
    filterScheduleLog: (schedule: Schedule): boolean => {
      const { myInfo } = this.gApi;
      const uSeq = myInfo.getUserSeq(true);
      const isUser = myInfo.getRole() === 'user';

      return (!isUser || (schedule.uSeq === uSeq && schedule.sState !== ScState.RegisterVirtual));
    },
    getSchedulerText: (role: MemberRole): string => {
      const schedulerText = role === 'trainer' ? '스케줄.스케줄' : '스케줄.캘린더';
      return this.translate.instant(schedulerText);
    },
    isCheckedSchedule: (checkState: CheckState, schedule: Schedule | LessonSchedule, role: MemberRole = 'none'): boolean => {
      const { sTrainerCheckDate, sUserCheckDate, sState, } = schedule;
      const isChecked: Record<MemberRole, () => boolean> = {
        none: () => false,
        trainer: () => Boolean(sTrainerCheckDate),
        user: () => Boolean(sUserCheckDate),
      };
      const validState: Record<CheckState, () => boolean> = {
        confirm: (): boolean => BookingState.TrainerCheck.includes(sState),
        autoConfirm: () => sState === ScState.AutoConfirm,
        cancel: () => {
          if (role === 'trainer') {
            return BookingState.TrainerCheck.includes(sState) && BookingState.Cancel.includes(sState);
          } else if (role === 'user') {
            return BookingState.UserCheck.includes(sState) && BookingState.Cancel.includes(sState);
          } else {
            return false;
          }
        },
      };
      return isChecked[role]() && validState[checkState]();
    },
    canEditMemo: (schedule: LessonSchedule): boolean => {
      const myInfo = this.gApi.myInfo;
      if (!myInfo) return false;

      const myRole = myInfo.getRole();
      const canBeEditedState = schedule.isOpenClose() || BookingState.ShowMemo.includes(schedule.sState);
      return canBeEditedState && myRole === 'trainer';
    },
    getScheduleKey: (lSchedule: LessonSchedule | any): string => {
      const { tSeq, sStartDate, } = lSchedule;

      // '2021-10-19T23:00'
      if (typeof sStartDate.getDate === 'function') {
        return `${tSeq}/${(sStartDate as Date).format('yyyy-MM-ddTHH:mm')}`;
      } else if (typeof sStartDate === 'string') {
        return `${tSeq}/${sStartDate.substr(0, 16)}`;
      }
      return '';
    },
    isRedBackground: (lSchedule: LessonSchedule | any, role: MemberRole): boolean => {
      const isCheckedCancel = this.schedule.isCheckedSchedule('cancel', lSchedule, role);
      const hideList = role === 'trainer' ? BookingState.HideTrainerCheck : BookingState.HideUserCheck;

      return hideList.includes(lSchedule.sState) && !isCheckedCancel;
    },
    /**
     * @param  {Date} goal
     * 목표 연월일
     *
     * @description
     * 목표 연월일의 최대 시간(23)과 최대 분(59)을 가진 Date 객체를 반환한다.
     */
    getValidEndDate(sStartDate: Date, sEndDate: Date): Date {
      const endOfDay = new Date(sStartDate).resetDate('full', 'hour').resetDate('full', 'minute');

      return sStartDate.format('yyyyMMdd') === sEndDate.format('yyyyMMdd') ? sEndDate : endOfDay;
    },

    getPaEndDate(sStartDate: Date, sEndDate: Date, paSession: number): Date {
      const tmpEndDate = new Date(sEndDate);

      paSession !== 0 && tmpEndDate.setTime(sStartDate.getTime() + (paSession * 1000 * 60));
      return this.getValidEndDate(sStartDate, tmpEndDate);
    }
  };

  payroll = {
    getPtCommission: (trainer: any, gymSalarySetting, gymMonthlyPtAmount, trainerBasicInfo): number => {
      let ptCommission = 0;
      const ptAmount = Number(trainer.ptAmount);

      switch (gymSalarySetting.ptCommissionSettingType) {
        case 'OVER':
          if (ptAmount >= gymSalarySetting.ptCommissionOverTrainerAmount) {
            ptCommission = Math.floor((ptAmount - gymSalarySetting.ptCommissionOverTrainerAmount) * (gymSalarySetting.ptCommissionOverTrainerRatio / 100));
          }
          break;
        case 'TOTAL':
          if (gymMonthlyPtAmount >= gymSalarySetting.ptCommissionOverTotalAmount) {
            if (gymSalarySetting.ptCommissionOverTotalDistributionIndex === 0) {
              let count = 0;
              for (const t of trainerBasicInfo) {
                if (Number(t.ptCount) > 0) count++;
              }
              const commissionAmount = Math.floor(gymMonthlyPtAmount * (gymSalarySetting.ptCommissionOverTotalRatio / 100));
              ptCommission = Math.floor(commissionAmount / count);
            } else {
              let classCount = 0;
              for (const t of trainerBasicInfo) {
                classCount = classCount + Number(t.ptCount);
              }
              const commissionAmount = Math.floor(gymMonthlyPtAmount * (gymSalarySetting.ptCommissionOverTotalRatio / 100));
              const classCommissionUnit = commissionAmount / classCount;
              ptCommission = Math.floor(classCommissionUnit * Number(trainer.ptCount));
            }
          }
          break;
      }
      return ptCommission;
    },

    getGxCommission: (trainer: any, gymSalarySetting, gymMonthlyGxAmount, trainerBasicInfo): number => {
      let gxCommission = 0;
      const gxAmount = Number(trainer.gxAmount);

      switch (gymSalarySetting.gxCommissionSettingType) {
        case 'OVER':
          if (gxAmount >= gymSalarySetting.gxCommissionOverTrainerAmount) {
            gxCommission = Math.floor((gxAmount - gymSalarySetting.gxCommissionOverTrainerAmount) * (gymSalarySetting.gxCommissionOverTrainerRatio / 100));
          }
          break;
        case 'TOTAL':
          if (gymMonthlyGxAmount >= gymSalarySetting.gxCommissionOverTotalAmount) {
            if (gymSalarySetting.gxCommissionOverTotalDistributionIndex === 0) {
              let count = 0;
              for (const t of trainerBasicInfo) {
                if (t.gxCount > 0) count++;
              }
              const commissionAmount = Math.floor(gymMonthlyGxAmount * (gymSalarySetting.gxCommissionOverTotalRatio / 100));
              gxCommission = Math.floor(commissionAmount / count);
            } else {
              let classCount = 0;
              for (const t of trainerBasicInfo) {
                classCount = classCount + t.gxCount;
              }
              const commissionAmount = Math.floor(gymMonthlyGxAmount * (gymSalarySetting.gxCommissionOverTotalRatio / 100));
              const classCommissionUnit = commissionAmount / classCount;
              gxCommission = Math.floor(classCommissionUnit * Number(trainer.gxCount));
            }
          }
          break;
      }
      return gxCommission;
    },
    checkPtSalarySetting: (trainer: any, gymSalarySetting): boolean => {
      let isSetting = false;
      switch (gymSalarySetting.PTSalarySettingType) {
        case 'TOTAL':
        case 'USER':
          isSetting = true;
          break;
        case 'TRAINER':
          if (isSetting = trainer.PTSalaryType === 'FIXED') {
            isSetting = trainer.PTFixedAmount && trainer.PTFixedAmount > 0
          } else if (trainer.PTSalaryType === 'RATIO') {
            isSetting = trainer.PTRatioAmount && trainer.PTRatioAmount > 0
          }
          break;
        default:
          isSetting = false;
          break;
      }
      return isSetting;
    },
    getPTClassSalary: (trainer: any, gymSalarySetting): number => {
      let ptSalary: number;
      const ptCount = Number(trainer.ptCount)
      let overPtCnt = ptCount - gymSalarySetting.PTStandardCount;
      overPtCnt = overPtCnt < 0 ? 0 : overPtCnt;
      switch (gymSalarySetting.PTSalarySettingType) {
        case 'TOTAL':
          if (gymSalarySetting.usePTStandard && gymSalarySetting.PTStandardCount > 0) {
            ptSalary = gymSalarySetting.PTTotalSalary * overPtCnt;
          } else {
            ptSalary = gymSalarySetting.PTTotalSalary * ptCount;
          }
          break;
        case 'TRAINER':
          if (gymSalarySetting.usePTStandard && gymSalarySetting.PTStandardCount > 0) {
            ptSalary = trainer.PTSalaryType === 'FIXED' ? trainer.PTFixedAmount * overPtCnt :
              (trainer.PTSalaryType === 'RATIO' && trainer.ptOneSession && overPtCnt > 0) ? this.payroll.getPtAmountRatio(trainer) : 0;
          } else {
            ptSalary = trainer.PTSalaryType === 'FIXED' ? trainer.PTFixedAmount * ptCount :
              trainer.PTSalaryType === 'RATIO' && trainer.ptOneSession ? this.payroll.getPtAmountRatio(trainer) : 0;
          }
          break;
        case 'USER':
          if (gymSalarySetting.usePTStandard && gymSalarySetting.PTStandardCount > 0) {
            ptSalary = 0;
            let index = 0, ptAmount = 0;
            const userSalarysArr = trainer.ptSalaryInfo;
            userSalarysArr.sort((a, b) => a.userSalary - b.userSalary);
            for (const salary of userSalarysArr) {
              if (gymSalarySetting.usePTStandard && gymSalarySetting.PTStandardCount > 0) {
                if (index < gymSalarySetting.PTStandardCount) {
                  index++;
                  continue;
                }
              }
              ptAmount += salary.userSalary;
            }
            ptSalary = ptAmount;

          } else {
            ptSalary = trainer.userSalary;
          }
          break;
        default:
          ptSalary = 0;
          break;
      }
      return ptSalary;
    },
    getPtAmountRatio: (trainer: any): number => {
      let ptAmount = 0;
      const ptOneSession: Array<string> = trainer.ptOneSession.split(',');
      for (const cost of ptOneSession) {
        ptAmount += Math.floor(Number(cost) * (trainer.PTRatioAmount / 100))
      }
      return ptAmount;
    },
    checkGxSalarySetting: (trainer: any, gymSalarySetting): boolean => {
      let isSetting = false;
      switch (gymSalarySetting.GXSalarySettingType) {//'TOTAL' | 'TRAINER' | 'CLASS';
        case 'TOTAL':
          isSetting = gymSalarySetting.GXTotalSalary && gymSalarySetting.GXTotalSalary > 0 ? true : false;
          break;
        case 'CLASS':
        case 'COUNT':
          isSetting = true;
          break;
        case 'TRAINER':
          isSetting = trainer.GXFixedAmount && trainer.GXFixedAmount > 0 ? true : false;
          break;
        default:
          isSetting = false;
      }
      return isSetting;
    },
    getGXClassSalary: (trainer: any, gymSalarySetting): number => {    // 요기@@@@ 버그
      let salary: number = 0;
      let gxCount = 0;
      for (const gx of trainer.gxSalaryInfo) {
        gxCount += gx.count;
      }
      // if (gymSalarySetting.useGXStandard && gymSalarySetting.useGXOverCount && gymSalarySetting.GXOverCount > 0) {
      if (gymSalarySetting.useGXStandard && gymSalarySetting.GXOverCount > 0) {
        gxCount = gxCount - gymSalarySetting.GXOverCount;
        gxCount = gxCount > 0 ? gxCount : 0;
      }
      switch (gymSalarySetting.GXSalarySettingType) {//'TOTAL' | 'TRAINER' | 'CLASS';
        case 'TOTAL': {
          salary = gymSalarySetting.GXTotalSalary * gxCount;
          break;
        }
        case 'TRAINER': {
          salary = trainer.GXFixedAmount * gxCount;
          break;
        }
        case 'CLASS': {
          let gxAmount = 0;
          const gxArr = [];
          for (const gx of trainer.gxSalaryInfo) {
            for (let i = 0; i < gx.count; i++) {
              gxArr.push(gx.gxSalaryPerClass);
            }
          }
          let index = 0;
          gxArr.sort((a, b) => a - b);
          for (const salary of gxArr) {
            // if (gymSalarySetting.useGXStandard && gymSalarySetting.useGXOverCount && gymSalarySetting.GXOverCount > 0) {
            if (gymSalarySetting.useGXStandard && gymSalarySetting.GXOverCount > 0) {
              if (index < gymSalarySetting.GXOverCount) {
                index++;
                continue;
              }
            }
            gxAmount += salary;
            index++;
          }
          salary = gxAmount;
          break;
        }
        case 'COUNT': {
          for (const peopleCount of trainer.gxPeopleCounts) {
            const maxCntSalary = { count: 0, salary: 0 };
            for (const countSalary of gymSalarySetting.GXSalaryPerCount) {
              if (peopleCount >= countSalary.count && countSalary.count > maxCntSalary.count) {
                // GX 수업수 최저기준 설정 사용
                if (gymSalarySetting.useGXStandard && gymSalarySetting.GXOverCount && gymSalarySetting.GXOverCount > 0) {
                  if (gxCount < gymSalarySetting.GXOverCount) {
                    continue;
                  }
                }
                maxCntSalary.salary = countSalary.salary;
              }
            }
            salary += maxCntSalary.salary;
          }
          break;
        }
        default:
          salary = 0;
      }
      return salary;
    },

    getPTDesc: (trainer: any, gymSalarySetting): Array<string> => {
      const ptDesc: Array<string> = [];
      let ptCountReal = Number(trainer.ptCount) - (gymSalarySetting.usePTStandard ? (Number(gymSalarySetting.PTStandardCount) || 0) : 0);
      ptCountReal = ptCountReal < 0 ? 0 : ptCountReal;
      let ptRealCountDesc = '';
      let method = '';
      switch (gymSalarySetting.PTSalarySettingType) {
        case 'TOTAL':
          method = this.translate.instant('웹짐.고정 수업료 적용');
          ptRealCountDesc = `${this.translate.instant('웹짐.고정 수업료')}(${gymSalarySetting.PTTotalSalary.currencyFormat()}) x ${ptCountReal}${this.translate.instant('일반.회')}`;
          break;
        case 'TRAINER':
          if (trainer.PTSalaryType === 'FIXED') {
            ptRealCountDesc = `${this.translate.instant('웹짐.트레이너별 수업료')}(${trainer.PTFixedAmount.currencyFormat()}) x ${ptCountReal}${this.translate.instant('일반.회')}`;
            method = this.translate.instant('웹짐.트레이너별 수업료 적용');
          } else if (trainer.PTSalaryType === 'RATIO') {
            ptRealCountDesc = this.payroll.getPtCountByPkg(trainer);
            method = this.translate.instant('트레이너별 수업료 적용(회당 세션 금액)');
          }
          break;
        case 'USER':
          ptRealCountDesc = `${this.translate.instant('웹짐.회원별 PT 회당 수업료(별도)')} x ${ptCountReal}${this.translate.instant('일반.회')}`;
          method = this.translate.instant('웹짐.회원별 PT 회당 수업료 적용');
          break;
      }
      if (ptRealCountDesc) {
        ptDesc.push(ptRealCountDesc);
      }
      ptDesc.push(method);
      if (gymSalarySetting.usePTStandard && gymSalarySetting.PTStandardCount) {
        ptDesc.push(this.translate.instant('웹짐.최저 수업수 _회 적용', { count: gymSalarySetting.PTStandardCount }));
      }
      return ptDesc;
    },
    getPtCountByPkg: (trainer: any): string => {
      const ptOneSession: Array<string> = trainer.ptOneSession ? trainer.ptOneSession.split(',') : [];
      const countPerPkg = trainer.countPerPkg ? trainer.countPerPkg.split(",") : [];
      const pkgName = trainer.pkgName ? trainer.pkgName.split(",") : [];
      let idx = 0;
      let i, j, temp;
      const pkgCounts = {}
      // console.log("getPtCountByPkg countPerPkg", trainer.tName, countPerPkg, pkgName,ptOneSession);
      for (i = 0, j = ptOneSession.length; i < j; i += Number(countPerPkg[idx - 1])) {
        temp = ptOneSession.slice(i, i + Number(countPerPkg[idx]));
        const counts = temp.reduce((map, val) => { map[Number(val)] = (map[Number(val)] || 0) + 1; return map }, {});
        // console.log(' counts', temp, idx, i + Number(countPerPkg[idx]), counts)
        pkgCounts[pkgName[idx]] = counts;
        idx += 1;
      }
      // console.log("getPtCountByPkg pkgCounts", pkgCounts);
      let eachPtDesc = '';
      let ptRealCountDesc = '';
      for (const pkgName in pkgCounts) {
        const costCounts = pkgCounts[pkgName]
        for (const cost in costCounts) {
          eachPtDesc = `[${pkgName}] ${this.translate.instant('급여정산.회당 세션 금액(_원)', { ptOneSessionCost: cost.currencyFormat() })} x ${costCounts[cost]}${this.translate.instant('일반.회')} x ${this.translate.instant('급여정산.세션금액 비율')} ${trainer.PTRatioAmount}%\n`
          ptRealCountDesc += eachPtDesc;
        }
      }
      return ptRealCountDesc;
    },

    getGXDesc: (trainer: any, gymSalarySetting): Array<string> => {     // 오류 확인해봐야 함 - 확인된 오류는 gx 수업료 계산이 제대로 안되어 있어
      const gxDesc: Array<string> = [];
      let gxCountReal = trainer.gxCount;
      // for (const gx of trainer.gxSalaryInfo) {
      //   gxCountReal += gx.count;
      // }
      if (!gxCountReal) {
        const gxCount = gymSalarySetting.useGXStandard ? gymSalarySetting.useGXStandard : 0;
        const desc = this.translate.instant('웹짐.최저 수업수 _회 적용', { count: gxCount });
        gxDesc.push(desc);
        return gxDesc;
      }
      // if (gymSalarySetting.useGXStandard && gymSalarySetting.useGXOverCount && gymSalarySetting.GXOverCount > 0) {
      if (gymSalarySetting.useGXStandard && gymSalarySetting.GXOverCount > 0) {
        gxCountReal = gxCountReal - gymSalarySetting.GXOverCount;
        gxCountReal = gxCountReal > 0 ? gxCountReal : 0;
      }

      let gxRealCountDesc = '';
      let method = '';
      switch (gymSalarySetting.GXSalarySettingType) {
        case 'TOTAL': {
          gxRealCountDesc = `${this.translate.instant('웹짐.고정 수업료')}(${gymSalarySetting.GXTotalSalary.currencyFormat()}) x ${gxCountReal}${this.translate.instant('일반.회')}`;
          method = this.translate.instant('웹짐.고정 수업료 적용');
          break;
        }
        case 'TRAINER': {
          gxRealCountDesc = `${this.translate.instant('웹짐.트레이너별 수업료')}(${trainer.GXFixedAmount ? trainer.GXFixedAmount.currencyFormat() : 0}) x ${gxCountReal}${this.translate.instant('일반.회')}`;
          method = this.translate.instant('웹짐.트레이너별 수업료 적용');
          break;
        }
        case 'CLASS': {
          gxRealCountDesc = `${this.translate.instant('웹짐.수업별 수업료(별도)')} x ${gxCountReal}${this.translate.instant('일반.회')}`;
          method = this.translate.instant('웹짐.수업별 수업료 적용');
          break;
        }
        case 'COUNT': {
          gxRealCountDesc = `${this.translate.instant('급여정산.참여인원별 GX 회당 수업료(별도)')} x ${gxCountReal}${this.translate.instant('일반.회')}`;
          method = this.translate.instant('급여정산.참여인원별 GX 회당 수업료');
          break;
        }
      }
      gxDesc.push(gxRealCountDesc);
      gxDesc.push(method);
      if (gymSalarySetting.useGXStandard) {
        gxDesc.push(this.translate.instant('웹짐.최저 수업수 _회 적용', { count: gymSalarySetting.GXOverCount }));
      }
      // if (gymSalarySetting.useGXStandard && gymSalarySetting.useGXOverCount) {
      //   gxDesc.push(this.translate.instant('웹짐.최저 수업수 _회 적용', { count: gymSalarySetting.GXOverCount }));
      // }
      // if (gymSalarySetting.useGXStandard && gymSalarySetting.useGXOverPeople) {
      //   gxDesc.push(this.translate.instant('웹짐.최저 인원수 _명 적용', { count: gymSalarySetting.GXOverPeople }));
      // }
      return gxDesc;
    },

    getETCDesc: (trainer: any, gymSalarySetting): string => {
      if (!trainer.otCount && !trainer.testCount) return '-';
      const otTotalSalary = gymSalarySetting.OTTotalSalary.currencyFormat();
      const otDesc = gymSalarySetting.useOTSalarySetting ? `${this.translate.instant('웹짐.OT 고정 수업료')}(${otTotalSalary}${this.translate.instant('일반.원')}) x ${trainer.otCount || 0}${this.translate.instant('일반.회')}\n` : '';
      const testTotalSalary = gymSalarySetting.TESTTotalSalary.currencyFormat();
      const testDesc = gymSalarySetting.useTESTSalarySetting ? `${this.translate.instant('웹짐.운동검사 고정 수업료')}(${testTotalSalary}${this.translate.instant('일반.원')}) x ${trainer.testCount || 0}${this.translate.instant('일반.회')}` : '';
      return otDesc.concat(testDesc);
    },

    getCommissionDesc: (trainer: any, gymSalarySetting): string => {
      let commissionDesc = '';
      const tNone = `${this.translate.instant('급여정산.매출 커미션')} ${this.translate.instant('모달.미사용')} \n\n`;

      const myPlanPermission = this.getPlanPermission();
      if (myPlanPermission.schedulerType !== 'GX') {
        commissionDesc += gymSalarySetting.usePtCommissionSetting ? this.payroll.getPtCommissionDesc(trainer, gymSalarySetting) : 'PT ' + tNone;
      }

      if (myPlanPermission.schedulerType !== 'PT') {
        commissionDesc += gymSalarySetting.useGxCommissionSetting ? this.payroll.getGxCommissionDesc(trainer, gymSalarySetting) : 'GX ' + tNone;
      }
      return commissionDesc;
    },

    getPtCommissionDesc: (trainer: any, gymSalarySetting): string => {
      let ptCommissionDesc = '';
      const ptCount = Number(trainer.ptCount);
      const ptAmount = Number(trainer.ptAmount);

      if (ptCount <= 0) return ptCommissionDesc;
      // swith 문으로 수정하기
      if (gymSalarySetting.ptCommissionSettingType === 'TOTAL') {
        let optionText = '';
        if (gymSalarySetting.ptCommissionOverTotalDistributionIndex === 0)
          optionText = this.translate.instant('급여정산.균등배분(1/_ 진행한 트레이너 수)', { pkgName: 'PT' });
        else
          optionText = this.translate.instant('급여정산.차등배분(개별 트레이너 수업 수/전체 수업 수)');
        ptCommissionDesc = this.translate.instant('급여정산.PT 전체 매출 _원 초과 시,\nPT 전체 매출액의 _%를\n_ 보너스로 지급한다.\n\n', {
          total: gymSalarySetting.ptCommissionOverTotalAmount.currencyFormat(),
          percent: gymSalarySetting.ptCommissionOverTotalRatio, option: optionText
        });
      } else if (gymSalarySetting.ptCommissionSettingType === 'OVER') {
        const exceedTotal = (ptAmount - gymSalarySetting.ptCommissionOverTrainerAmount) < 0 ? 0 : ptAmount - gymSalarySetting.ptCommissionOverTrainerAmount;
        ptCommissionDesc = `${this.translate.instant('급여정산.개별 트레이너 _ 매출', { pkgName: 'PT' })} ${this.translate.instant('급여정산._ 초과 시,\n초과 금액(_)의 _%를\n보너스로 지급한다.\n\n', {
          trainerTotal: `${gymSalarySetting.ptCommissionOverTrainerAmount.currencyFormat()} ${this.translate.instant('일반.원')}`,
          percent: gymSalarySetting.ptCommissionOverTrainerRatio, exceedTotal: `${exceedTotal.currencyFormat()} ${this.translate.instant('일반.원')}`
        })}`;
      }
      return ptCommissionDesc;
    },

    getGxCommissionDesc: (trainer: any, gymSalarySetting): string => {
      const gxAmount = Number(trainer.gxAmount);
      let gxCommissionDesc = '';
      if (Number(trainer.gxCount) <= 0) return gxCommissionDesc;

      if (gymSalarySetting.gxCommissionSettingType === 'TOTAL') {
        let optionText = '';
        if (gymSalarySetting.gxCommissionOverTotalDistributionIndex === 0)
          optionText = this.translate.instant('급여정산.균등배분(1/_ 진행한 트레이너 수)', { pkgName: 'GX' });
        else
          optionText = this.translate.instant('급여정산.차등배분(개별 트레이너 수업 수/전체 수업 수)');
        gxCommissionDesc = this.translate.instant('급여정산.GX 전체 매출 _원 초과 시,\nGX 전체 매출액의 _%를\n_ 보너스로 지급한다.\n', {
          total: gymSalarySetting.gxCommissionOverTotalAmount.currencyFormat(),
          percent: gymSalarySetting.gxCommissionOverTotalRatio, option: optionText
        });
      } else if (gymSalarySetting.gxCommissionSettingType === 'OVER') {
        const exceedTotal = (gxAmount - gymSalarySetting.gxCommissionOverTrainerAmount) < 0 ? 0 : gxAmount - gymSalarySetting.gxCommissionOverTrainerAmount;

        gxCommissionDesc = `${this.translate.instant('급여정산.개별 트레이너 _ 매출', { pkgName: 'GX' })} ${this.translate.instant('급여정산._ 초과 시,\n초과 금액(_)의 _%를\n보너스로 지급한다.\n\n', {
          trainerTotal: `${gymSalarySetting.gxCommissionOverTrainerAmount.currencyFormat()} ${this.translate.instant('일반.원')}`,
          percent: gymSalarySetting.gxCommissionOverTrainerRatio, exceedTotal: `${exceedTotal.currencyFormat()} ${this.translate.instant('일반.원')}`
        })}`;
      }
      return gxCommissionDesc;
    },

    unsetStateInit: (gymSalarySetting): GymSalarySettingUnsetState => {
      const unsetState = new GymSalarySettingUnsetState();
      if (gymSalarySetting.useBasicSalarySetting) {
        unsetState.unsetBasicSalary = gymSalarySetting.trainerSalary.some(t => t.basicSalary === null);
      }
      if (gymSalarySetting.PTSalarySettingType === 'TOTAL') {
        unsetState.unsetPTTotalSalary = (gymSalarySetting.PTTotalSalary === null) ? true : false;
      } else if (gymSalarySetting.PTSalarySettingType === 'TRAINER') {
        unsetState.unsetPTAmountPerTrainer = gymSalarySetting.trainerSalary.some(t => t.PTFixedAmount === null && t.PTRatioAmount === null);
      } else if (gymSalarySetting.PTSalarySettingType === 'USER') {
        unsetState.unsetUserFeePerPT = gymSalarySetting.userFeePerPT.some(u => u.salary === null);
      }
      if (gymSalarySetting.useGXSalarySetting) {
        if (gymSalarySetting.GXSalarySettingType === null) {
          unsetState.unsetGXSalarySetting = true;
        } else if (gymSalarySetting.GXSalarySettingType === 'TOTAL') {
          unsetState.unsetGXTotalSalary = (gymSalarySetting.GXTotalSalary === null) ? true : false;
        } else if (gymSalarySetting.GXSalarySettingType === 'TRAINER') {
          unsetState.unsetGXAmountPerTrainer = gymSalarySetting.trainerSalary.some(t => t.GXFixedAmount === null);
        } else if (gymSalarySetting.GXSalarySettingType === 'CLASS') {
          unsetState.unsetGXAmountPerClass = gymSalarySetting.GXSalaryPerClass.some(c => c.amount === null);
        } else if (gymSalarySetting.GXSalarySettingType === 'COUNT') {
          if (gymSalarySetting.GXSalaryPerCount.length === 0) {
            unsetState.unsetGXSalaryPerCount = true
          } else {
            unsetState.unsetGXSalaryPerCount = gymSalarySetting.GXSalaryPerCount.some(c => c.count === null || c.salary === null);
          }
        }
      }
      if (gymSalarySetting.useOTSalarySetting) {
        unsetState.unsetOTTotalSalary = (gymSalarySetting.OTTotalSalary === null) ? true : false;
      }
      if (gymSalarySetting.useTESTSalarySetting) {
        unsetState.unsetTESTTotalSalary = (gymSalarySetting.TESTTotalSalary === null) ? true : false;
      }
      if (gymSalarySetting.usePtCommissionSetting) {
        if (gymSalarySetting.ptCommissionSettingType === null) {
          unsetState.unsetPtCommissionSetting = true;
        } else if (gymSalarySetting.ptCommissionSettingType === 'TOTAL') {
          unsetState.unsetPTCommissionTotal = (gymSalarySetting.ptCommissionOverTotalAmount === 0 || gymSalarySetting.ptCommissionOverTotalRatio === 0) ? true : false;
        } else if (gymSalarySetting.ptCommissionSettingType === 'OVER') {
          unsetState.unsetPTCommissionOver = (gymSalarySetting.ptCommissionOverTrainerAmount === 0 || gymSalarySetting.ptCommissionOverTrainerRatio === 0) ? true : false;
        }
      }
      if (gymSalarySetting.useGxCommissionSetting) {
        if (gymSalarySetting.gxCommissionSettingType === null) {
          unsetState.unsetGxCommissionSetting = true;
        } else if (gymSalarySetting.gxCommissionSettingType === 'TOTAL') {
          unsetState.unsetGXCommissionTotal = (gymSalarySetting.gxCommissionOverTotalAmount === 0 || gymSalarySetting.gxCommissionOverTotalRatio === 0) ? true : false;
        } else if (gymSalarySetting.gxCommissionSettingType === 'OVER') {
          unsetState.unsetGXCommissionOver = (gymSalarySetting.gxCommissionOverTrainerAmount === 0 || gymSalarySetting.gxCommissionOverTrainerRatio === 0) ? true : false;
        }
      }
      if (gymSalarySetting.usePTStandard) {
        unsetState.unsetPTStandardCount = (gymSalarySetting.PTStandardCount === null) ? true : false;
      }
      if (gymSalarySetting.useGXStandard) {
        unsetState.unsetGXOverCount = (gymSalarySetting.GXOverCount === null) ? true : false;
      }
      return unsetState;
    }
  };

  fitt = {
    getText_Type: (sType: number) => {
      switch (sType) {
        case 0: return 'MEMO';
        case 1: return 'HEALTH';
        case 2: return 'PT';
        case 3: return 'OT';
        case 4: return 'GX';
        case 5: return '운동검사';
        default: return 'unknown';
      }
    },
    getType_State: (sState: number) => {
      switch (sState) {
        case 0: return 'CLOSE';          // w
        case 1: return 'OPEN';           // w
        case 2: return '자동확정';        // b1
        case 4: return '수업완료';        // g1
        case 5: return '예약신청';        // b1
        case 6: return '예약확정';        // g
        case 7: return '예약등록';        // b1
        case 8: return '예약반려';        // r1
        case 9: return '취소';            // r1
        case 10: return '트레이너취소';   // r1
        case 11: return '예약변경신청';   // b1
        case 12: return '예약변경승인';   // b1
        case 13: return '예약변경반려';   // r1
        case 14: return '가상예약';       // w
        case 15: return '확정예약';       // b1
        case 16: return '수업취소';       // r1
        case 17: return '노쇼';          // y1
        case 18: return '노쇼';          // y1
        case 19: return '노쇼';          // y1
        case 20: return '일정';          // g
        default: return 'unknown';       // g
      }
    },

    getCellStyle: (schedule: Schedule | LessonSchedule, myInfo: FittMember) => {
      if (schedule.sType == 'MEMO') {
        return { color: 'var(--ft-color-black1)', backgroundColor: 'rgba(var(--ft-color-gray3-rgb), 1)' };
      } else if (schedule.sType == 'GX') {
        if (myInfo.getRole() === 'trainer') {
          return { color: schedule.cColor, backgroundColor: `${schedule.cColor}20` };
        }
        else {
          if (BookingState.GxConfirm.includes(schedule.sState)) {
            return { color: schedule.cColor, backgroundColor: `${schedule.cColor}20` };
          }
          else if (schedule instanceof LessonSchedule && schedule.isJoined(myInfo.getUserSeq(true))) {
            return { color: schedule.cColor, backgroundColor: `${schedule.cColor}20` };
          }
          return { color: schedule.cColor, backgroundColor: 'var(--ft-color-white)' };
        }
      } else {
        const { LIGHT_GRAY, LIGHT_MAIN, LIGHT_SUB2, LIGHT_SUB4, LIGHT_CAUTION } = pcSchedule;

        if (schedule instanceof LessonSchedule && schedule.isOpenClose()) {
          const color = schedule.lState == 'close' ? 'rgba(176, 186, 188, 0.2)' : 'var(--ft-color-black1)'
          return { color: color, backgroundColor: 'var(--ft-color-white)' };
        }
        if (myInfo.getRole() === 'user' && BookingState.Confirm.includes(schedule.sState)) {
          return { color: 'var(--ft-color-black1)', backgroundColor: LIGHT_MAIN, };
        }

        switch (schedule.sState) {
          case ScState.AutoConfirm:
          case ScState.Complete:
          case ScState.Confirm:
          case ScState.ReservationByTrainer:
          case ScState.RequestChange:
          case ScState.ConfirmChange:
          case ScState.RegisterConfirm: {
            const mySchedule = myInfo.getTrainerSeq() === schedule.tSeq;
            const confirmedByMe = [myInfo.getTrainerSeq(), undefined, null].includes(schedule.htSeq);
            const checkedSchedule = this.schedule.isCheckedSchedule('confirm', schedule, myInfo.getRole());
            return {
              color: 'var(--ft-color-black1)',
              backgroundColor: schedule['existUncheckedCanceled'] ? LIGHT_SUB4 :
                !confirmedByMe && !checkedSchedule && mySchedule ? LIGHT_MAIN :
                  LIGHT_GRAY,
            };
          }
          case ScState.RegisterVirtual:
            return { color: 'rgba(var(--ft-color-black1-rgb), 0.4)', backgroundColor: 'var(--ft-color-white)' };
          case ScState.ReservationRequest:
            return { color: 'var(--ft-color-black1)', backgroundColor: LIGHT_SUB2 };
          case ScState.Reject:
          case ScState.CancelByUser:
          case ScState.CancelByTrainer:
          case ScState.RejectChange:
          case ScState.Cancel:
          case ScState.CancelRequest:
            return { color: 'var(--ft-color-black1)', backgroundColor: LIGHT_SUB4 };

          case ScState.NoShowByUser:
          case ScState.NoShowByTrainer:
          case ScState.NoShowReset:
            return { color: 'var(--ft-color-black1)', backgroundColor: LIGHT_CAUTION };

          case ScState.Memo:
            return { color: 'var(--ft-color-black1)', backgroundColor: 'rgba(var(--ft-color-gray3-rgb), 1)' };
        }
      }
      return { color: 'var(--ft-color-black1)', backgroundColor: 'var(--ft-color-white)' };
    },

    getPaymentName: (item: any): string => {
      const pAmount: number = item.pAmount || 0;
      // item.paName = this.getPaName(item);
      return item.paType === 'POINT' ? `${pAmount.currencyFormat()}${this.translate.instant('회원관리.원 충전')}` : item.paName;
    },
  };

  permission = {
    check: (method: PermissionMethod, tSeq?: number, uSeq?: number): boolean => {
      const myInfo = this.gApi.myInfo;
      if (!myInfo) return false;

      switch (method) {
        case '스케줄.스케줄 보기': return this.canLookSchedule(tSeq, uSeq);
        case '스케줄.스케줄 읽기': return myInfo.isManager() || myInfo.isCommon() || this.canLookSchedule(tSeq, uSeq);
        case '스케줄.예약 관리': return myInfo.isManager() || myInfo.isCommon();
        case '스케줄.GX 수업': return myInfo.isManager();
        case '웹헤더.운영데이터': return myInfo.isSupervisor() || !myInfo.isHeadGroupGym() && myInfo.isOwner();
        case '회원 삭제': return myInfo.isManager();
        case '회원관리.결제': return myInfo.isManager() || myInfo.isTrainer() || myInfo.isCommon();
        case '회원관리.진행/휴면 전환': return myInfo.isManager() || tSeq === myInfo.getTrainerSeq();
        case '회원관리.결제정보 수정': return myInfo.isManager();
        case '회원관리.공지 보내기': return myInfo.isManager() || myInfo.isTrainer() || myInfo.isCommon();
        case '회원관리.회원목록 다운로드': return this.isUrbanfit();
        case '회원관리.결제 분석': return myInfo.isOwner();
        case '센터관리.회사정보 수정': return myInfo.isManager();
        case '센터관리.패키지 삭제': return myInfo.isManager();
        case '센터관리.패키지 추가': return myInfo.isManager();
        case '센터관리.CS 삭제': return myInfo.isManager();
        case '마이페이지.내 비밀번호 변경': return !myInfo.isCommon();
        case '마이페이지.정기 결제 관리': return myInfo.isManager() || myInfo.isTrainer();
        case '마이페이지.트레드밀 구매': return myInfo.isManager();
        case '마이페이지.환경설정': return !myInfo.isCommon();
        case '마이페이지.어드민': return this.canLookAdmin();
        case '마이페이지.트레드밀 코드': return myInfo.isManager() || myInfo.isCommon() || myInfo.isTrainer();
        case '마이페이지.트레드밀 관리': return this.canLookAdmin();
        case '마이페이지.GYM 관리': return myInfo.isManager() || myInfo.isCommon() || myInfo.isTrainer();
        case '어드민.어드민 페이지': return this.canLookAdmin();
        case '어드민.그룹짐 페이지': return this.canLookGroupGym();
        case '모바일.회원 관리': return myInfo.isManager() || myInfo.isCommon() || myInfo.isTrainer();
      }
    },
  };

  package: {
    getStatusText: (packageStatus: PackageStatus) => string,
    readonly statusToText: Map<PackageStatus, string>,
  } = {
      statusToText: new Map<PackageStatus, string>([
        ['using', this.translate.instant('모달.사용 가능')],
        ['expired', this.translate.instant('모달.사용 완료')],
      ]),
      getStatusText: (packageStatus: PackageStatus): string => {
        const text = this.package.statusToText.get(packageStatus);
        return this.translate.instant(text);
      }
    };


  /**
   * 주식회사 피트 내부 계정인지 확인
   * @returns boolean
   */
  canLookAdmin(): boolean {
    const email = localStorage.myEmail;
    return email ? accountJson.fitt4.admin[email] : false;
  }

  isTester(): boolean {
    const email = localStorage.myEmail;
    return email ? accountJson.fitt4.tester[email] : false;
  }

  private canLookGroupGym(): boolean {
    const myInfo = this.gApi.myInfo;
    return myInfo.isSupervisor();
  }

  private canLookSchedule(tSeq?: number, uSeq?: number): boolean {
    const myInfo = this.gApi.myInfo;

    if (!myInfo) return false;
    switch (myInfo.getRole()) {
      case 'trainer':
        return myInfo.isManager() || tSeq === myInfo.getTrainerSeq();
      case 'user':
        return uSeq === myInfo.getUserSeq(true);
      default:
        return false;
    }
  }

  private isUrbanfit(): boolean {
    const email = localStorage.myEmail;
    return email ? accountJson.fitt4.urbanfit[email] : false;
  }

  private getPlanPermission(): PlanPermission {
    const myInfo = this.gApi.myInfo;
    try {
      const gSeq = myInfo.getGymSeq();
      const [gym] = myInfo.gyms.filter(gym => gym.gSeq === gSeq);
      const planType = gym.pkName.split('_')[0];
      const permission = planPermissionJson.plan[planType] ? planPermissionJson.plan[planType] : planPermissionJson.plan['Pro'];
      const myPlanPermision = new PlanPermission(permission);
      if (planType === 'Business') { myPlanPermision.schedulerType = gym.schedulerType; }
      if (!(['Platinum', 'Test'].includes(planType))) {
        ['tmtFlag', 'smtFlag', 'fmtFlag', 'lacticFlag'].forEach(flag => {
          const key = `p${flag.charAt(0).toUpperCase()}${flag.slice(1)}`;
          myPlanPermision[flag] = Number(gym[key]) === 1;
        });
      }
      return myPlanPermision;
    } catch (e) {
      return new PlanPermission(planPermissionJson.plan['Pro']);
    }

  }

  planPermission = {
    check: (method: PlanPermissionMethod): any => {
      const myPlanPermission = this.getPlanPermission();

      switch (method) {
        case '웹헤더.공지 보내기': return myPlanPermission.gymNoticeMgt;
        case '웹헤더.사물함 관리': return myPlanPermission.gymLockerMgt;
        case '웹헤더.스케줄': return myPlanPermission.scheduler;
        case '웹헤더.센터관리': return myPlanPermission.centerMgt;
        case '웹헤더.운영데이터': return myPlanPermission.gymData;
        case '웹헤더.급여정산': return myPlanPermission.payrollMgt;
        case '웹헤더.매출 관리': return myPlanPermission.salesMgt;
        case '웹헤더.이용 분석': return myPlanPermission.salesMgt;
        case '회원관리.상세 필터': return myPlanPermission.gymUserFilter;
        case '회원관리.구분 탭': return myPlanPermission.scheduler;
        case '회원관리.결제': return myPlanPermission.gymUserPayment;
        case '스케줄.스케줄러 타입': return myPlanPermission.schedulerType;
        case '스케줄.스케줄러 PT': return ['ALL', 'PT'].includes(myPlanPermission.schedulerType);
        case '스케줄.스케줄러 GX': return ['ALL', 'GX'].includes(myPlanPermission.schedulerType);
        case '스케줄.가상 예약': return myPlanPermission.schedulerType;
        case '건강.심폐능력검사(TMT)': return myPlanPermission.tmtFlag;
        case '건강.근력측정평가(SMT)': return myPlanPermission.smtFlag;
        case '건강.움직임능력검사(FMT)': return myPlanPermission.fmtFlag;
        case '건강.젖산검사(선수평가)': return myPlanPermission.lacticFlag;
      }
    }
  }

  remoteApi(args: { api: string, param: any }): Promise<any> {
    const { api, param } = args;
    const paths: Array<string> = api.split('/').filter(s => s.length > 0);
    return new Promise((resolve, reject) => {
      const { environment, packageJson } = this;
      this.log(`${environment.remoteServerUrl}/${paths.join('/')}`, param);

      const callTime = new Date();
      const version = packageJson.version;
      const versionUrl = `V${version.replace(/\./gi, '_')}`;
      const requestUrl = `${environment.remoteServerUrl}/${versionUrl}/${paths.join('/')}`;
      this.http.post(`${requestUrl}`, param ? JSON.stringify(param) : null, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .subscribe(response => {
          this.log(`remoteApi.${api} success`, `${new Date().getTime() - callTime.getTime()} elapsed`);
          resolve(response);
        }, error => {
          this.error(`${requestUrl} error`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  myInfo: FittMember;
  setMyInfo(myInfo: FittMember) {
    this.myInfo = myInfo;
    for (const gym of myInfo.gyms) {
      if (gym.tAuth) {
        gym.uConnect = 1;
      }
    }
    localStorage.gymTimeZone = this.myInfo.getGym() ? this.myInfo.getGym().gTimeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  getMyRole(): MemberRole {
    if (this.myInfo) return this.myInfo.getRole();
    return 'none';
  }

  async requestAuthNumber(receiver) {
    const authNumber = String('0000' + Math.floor(Math.random() * 9999)).slice(-4);
    // const body = {
    //   sender: '01096629177', receiver: this.inputData.tel, msg: `[FITT]인증번호[${authNumber}]를 입력해 주세요`, msg_type: 'SMS', testmode_yn: 'Y'
    // }

    const httpParams = new HttpParams()
      .append('sender', '01096629177')
      .append('receiver', receiver)
      .append('msg', this.util.isCurrentLangKo() ? `[FITT]인증번호[${authNumber}]를 입력해 주세요` : `[FITT]Enter authentication number [${authNumber}]`)
      .append('msg_type', 'SMS')
      // .append('testmode_yn', 'Y')
      ;
    const res = await new Promise<any>((resolve, reject) => {
      this.http.post('https://payment.fitt.kr/sms/send', httpParams, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
    this.log('sendSms', res, authNumber);
    if (res.body.result_code == '1') {
      return authNumber;
    }
    return null;
  }

  async get_uuid() {
    if (this.platform.is('capacitor')) {
      return (await Device.getId()).uuid; // not android.os.Build.SERIAL
    }
    else if (this.util.isDebugMode()) {
      // 개발용 가상 uuid;
      if (!localStorage.virtual_uuid) {
        localStorage.virtual_uuid = `vuuid-${Math.random().toString(36).substr(2, 11)}`;
      }
      return localStorage.virtual_uuid;
    }
    return null;
  }

  isSupportedBrowser(): Promise<boolean> {
    return new Promise(async resolve => {
      if (this.supportedBrowser) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  }

  clear(allClear: boolean = true) {
    this.log('clear');
    if (allClear) {
      localStorage.clear();
    } else {
      const webUuid = localStorage.getItem('web_uuid');
      localStorage.clear();
      localStorage.setItem('web_uuid', webUuid);
    }
    window.sessionStorage.clear();
  }

  registrationWithSNS(snsType, args) {
    if (args == undefined || snsType == undefined) {
      alert(this.translate.instant('인증.계정 인증에 실패했습니다.\n잠시 후 다시 시도해주세요.'));
      return '';
    }
    let signupWithParam = `signup?snstype=${snsType}`;
    let userSnsId = args.id;
    if (userSnsId == undefined || userSnsId == '') {
      userSnsId = args.user;
      if (userSnsId == undefined || userSnsId == '') {
        userSnsId = args.sub;
      }
    }
    if (userSnsId == undefined || userSnsId == '') {
      alert(this.translate.instant('인증.계정 인증에 실패했습니다.\n잠시 후 다시 시도해주세요.'));
      return '';
    } else {
      signupWithParam = signupWithParam + `&snsid=${userSnsId}`;
    }
    let userName = (args.name != undefined) ? args.name : '';
    if (snsType == 'apple' && args.fullName != undefined) {
      if (args.fullName.familyName != undefined || args.fullName.middleName != undefined || args.fullName.givenName != undefined) {
        userName = ((args.fullName.givenName != undefined) ? args.fullName.familyName : '') + ((args.fullName.middleName != undefined) ? args.fullName.middleName : '') + ((args.fullName.givenName != undefined) ? args.fullName.givenName : '');
      }
      else {
        if (args.fullName.nickname != undefined) {
          userName = args.fullName.nickname;
        }
      }
    }
    if (userName != undefined && userName != '') {
      signupWithParam = signupWithParam + `&name=${userName}`;
    }
    if (args.email === undefined) {
      args.email = '';
    }
    const userEmail = args.email.includes('privaterelay.appleid.com') ? '' : args.email;
    if (userEmail != undefined) {
      if (args.email.includes('@hanmail.net') || (args.email.includes('@daum.net')) ? '' : args.email)
        signupWithParam = signupWithParam + `&email=${userEmail}`;
    }
    const userPhone = args.mobile;
    if (userPhone != undefined) {
      signupWithParam = signupWithParam + `&phone=${userPhone}`;
    }
    const userBirth = args.birthyear + '-' + args.birthday;
    if (args.birthyear != undefined && args.birthday != undefined) {
      signupWithParam = signupWithParam + `&birth=${userBirth}`;
    }
    if (args.gender != undefined) {
      let userGender = '1';
      if (args.gender == 'M') {
        userGender = '0';
      }
      signupWithParam = signupWithParam + `&gender=${userGender}`;
    }
    return signupWithParam;
  }

  setMyEmail(args: { email: string, uid: string }) {
    const { email, uid } = args;
    localStorage.myEmail = email;
    localStorage.uid = uid;
  }

  getVersionInfo(): Promise<any> {
    return new Promise<any>(async resolve => {
      if (this.versionInfo) {
        resolve(this.versionInfo);
        return;
      }

      if (this.platform.is('capacitor')) {
        await this.platform.ready();
        const isDebug = await this.isDebug.getIsDebug();
        this.versionInfo = {
          version: this.version, debug: isDebug,
          versionDisplay: `${this.version}${isDebug ? 'D' : ''}`,
          appVersion: (await App.getInfo()).version
        };
      }
      else {
        this.versionInfo = {
          version: this.version, debug: this.util.isDebugMode(),
          versionDisplay: `${this.version}${this.util.isDebugMode() ? 'D' : ''}`,
        };
      }
      resolve(this.versionInfo);
    });
  }

  private noticeData: { [key: string]: { value?: any, subscribers: Array<Function> } } = {};
  noticeMgr = {
    setNotice: (noticeName: string, value: any) => {
      if (!this.noticeData[noticeName]) { this.noticeData[noticeName] = { subscribers: [] }; }
      this.noticeData[noticeName].value = value;
      for (const callback of this.noticeData[noticeName].subscribers) {
        callback && callback(noticeName, value);
      }
    },
    getNotice: (noticeName: string): any => {
      return this.noticeData[noticeName] && this.noticeData[noticeName].value;
    },
    subscribe: (noticeNames: Array<string>, callback: Function) => {
      for (const noticeName of noticeNames) {
        if (!this.noticeData[noticeName]) { this.noticeData[noticeName] = { subscribers: [] }; }
        if (!this.noticeData[noticeName].subscribers.find(s => s == callback)) {
          this.noticeData[noticeName].subscribers.push(callback);
        }
        setTimeout(() => {
          for (const noticeName of noticeNames) {
            if (this.noticeData[noticeName]) {
              callback && callback(noticeName, this.noticeData[noticeName].value);
            }
          }
        });
      }
      return {
        unsubscribe: () => {
          for (const noticeName of noticeNames) {
            const index = this.noticeData[noticeName].subscribers.findIndex(s => s == callback);
            if (index >= 0) {
              this.noticeData[noticeName].subscribers.splice(index, 1);
            }
          }
        }
      }
    }
  }

  getNoticeQueryParam() {
    return {
      noticeKey: 'type',
      noticeValue: 'notice'
    };
  }

  getDefaultUrl(gender?: Gender): string {
    return gender === 'female' ?
      'https://firebasestorage.googleapis.com/v0/b/fitt-4-real.appspot.com/o/profile%2FFITT_profile_default_image_female.png?alt=media&token=a1e3c9a5-8841-4277-a8b7-31c8a2e5c58c'
      : 'https://firebasestorage.googleapis.com/v0/b/fitt-4-real.appspot.com/o/profile%2FFITT_profile_default_image_male.png?alt=media&token=be25c2e6-b44f-4282-be12-41e189b1a071';
  }

  async sendDownloadLink(receiver) {
    // const body = {
    //   sender: '01096629177', receiver: this.inputData.tel, msg: `[FITT]인증번호[${authNumber}]를 입력해 주세요`, msg_type: 'SMS', testmode_yn: 'Y'
    // }
    const appstore = this.util.getSNSLink('appstore');
    const playstore = this.util.getSNSLink('playstore');
    let smsMsg = `환영합니다.
세상 모두의 건강을 위한 피트로 초대합니다.

★피트 모바일 서비스 출시★
운동검사부터 1:1 PT, 필라테스, 요가, 그룹수업 예약까지 하나의 어플로 만나보세요!
-
▶ 구글플레이


${playstore}

▶ 앱스토어
${appstore}
-
운동할 땐, 피트!`;

    if (!this.util.isCurrentLangKo()) {
      smsMsg = `Welcome aboard.
Invite you to FITT for the health of all the people in the world.

★FITT Mobile Services Launch★
From exercise tests to 1:1 PT, pilates, yoga, and group class reservations, meet them in one application!
-
▶ Google Play
${playstore}

▶ App Store
${appstore}
-
When you work out, FITT!`
    }
    const httpParams = new HttpParams()
      .append('sender', '01096629177')
      .append('receiver', receiver)
      .append('msg', smsMsg)
      .append('msg_type', 'LMS')
      // .append('testmode_yn', 'Y')
      ;
    const res = await new Promise<any>((resolve, reject) => {
      this.http.post('https://payment.fitt.kr/sms/send', httpParams, { headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded') })
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });

    if (res.body.result_code == '1') {
      return true;
    }
    return null;
  }

}

