import { Injectable } from '@angular/core';
import * as domToImage from 'dom-to-image';
import _ from 'lodash';
import { BaseComponent } from '@shared-lib/base.component';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DomToImageService extends BaseComponent {
  constructor(
    private platform: Platform,
  ) {
    super({ enableLog: true });
  }

  async domToBase64(domElement: HTMLElement, scale: number = 2): Promise<string> {
    const domToImageOption = {
      height: domElement.offsetHeight * scale,
      width: domElement.offsetWidth * scale,
      style: {
        transform: "scale(" + scale + ")",
        transformOrigin: "top left",
        width: domElement.offsetWidth * scale + "px",
        height: domElement.offsetHeight * scale + "px"
      }
    }
    // eslint-disable-next-line no-async-promise-executor
    const base64: Promise<string> = new Promise(async (resolve, reject) => {
      try {
        this.log('domToBase64', '1');
        const dataUrl = await domToImage.toPng(domElement, domToImageOption);
        this.log('domToBase64', '2');
        if (this.platform.is('ios') || this.platform.is('iphone') || this.platform.is('ipad')) {
          const secondDataUrl = await domToImage.toPng(domElement, domToImageOption)
          this.log('domToBase64', '3');
          resolve(secondDataUrl);
        } else {  // not ios env
          resolve(dataUrl);
        }
      }
      catch (error) {
        this.error('domToImage.toPng error', error);
        reject(error);
      }
      // domToImage.toPng(domElement, domToImageOption)
      //   .then((dataUrl) => {
      //     // Safari 환경에서 domToImage issue https://github.com/tsayen/dom-to-image/issues/343
      //     if (this.platform.is('ios') || this.platform.is('iphone') || this.platform.is('ipad')) {
      //       domToImage.toPng(domElement, domToImageOption)
      //         .then((secondDataUrl) => {
      //           resolve(secondDataUrl);
      //         })
      //     } else {  // not ios env
      //       resolve(dataUrl);
      //     }
      //   })
      //   .catch((error) => {
      //     this.error('domToImage.toPng error', error);
      //     reject(error);
      //   });
    });
    return base64;
  }

  async domToBase64WidthElements(domElement: HTMLElement, elements: Array<any>, scale: number = 2): Promise<string> {
    for (const element of elements) {
      const tag = document.createElement(element.tagName);
      tag.setAttribute('style', element.style);
      if (element.text) {
        tag.textContent = element.text;
      }
      if (element.src) {
        tag.src = element.src;
      }
      domElement.appendChild(tag);
    }

    return this.domToBase64(domElement as HTMLElement, scale);
  }
}
