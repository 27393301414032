import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';
import { TriState } from '../ft-tri-button/ft-tri-button';

export type TriGroupButtons = {
  style?: { fontSize: string, buttonStyle: 'clear' | 'outline' | 'solid', buttonWidth: string },
  buttons: TriGroupButton[]
};

export type TriGroupButton = {
  key: string,
  title: string;
  value: TriState;
};

@Component({
  selector: 'ft-tri-button-group',
  templateUrl: './ft-tri-button-group.html',
  styleUrls: ['./ft-tri-button-group.scss'],
})
export class FtTriButtonGroupComponent extends BaseComponent implements OnInit {
  @Input() groupButtons: TriGroupButtons = {
    buttons: [{ key: 'default', title: '버튼', value: 'on' }]
  };  // default value

  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  protected onValueChange() {
    this.onChange && this.onChange.emit({ buttons: this.groupButtons.buttons });
  }

  protected getButtonStyle() {
    return this.groupButtons.style ? this.groupButtons.style.buttonStyle : 'outline';
  }

  protected getButtonWidth() {
    return { width: this.groupButtons.style ? this.groupButtons.style.buttonWidth : 'unset' };
  }

  setButtonState(key, value) {
    const button = this.groupButtons.buttons.find(b => b.key == key);
    if (button) {
      button.value = value;
    }
  }
}
