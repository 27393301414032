<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> {{title}} </ion-title>
    <ng-content select="[endButtons]"></ng-content>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!isLoaded" class="content-loading">
  <ion-spinner></ion-spinner>
</ion-content>

<ng-content select="[content]" *ngIf="isLoaded"></ng-content>