import { NgModule } from '@angular/core';
import { FtUserSearchItemComponent } from './ft-user-search-item';
import { OverlayModule } from '@angular/cdk/overlay';
import { SharedModule } from '@shared-lib';

@NgModule({
  declarations: [FtUserSearchItemComponent],
  imports: [
    SharedModule,
    OverlayModule,
  ],
  exports: [FtUserSearchItemComponent],
})
export class FtUserSearchItemCompModule { }
