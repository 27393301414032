import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { FmesUtilService } from './services/fmes-util.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('src/app/pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'signup', loadChildren: () => import('src/app/pages/signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'mobile-main', loadChildren: () => import('src/app/pages/mobile-main/mobile-main.module').then(m => m.MobileMainPageModule), canActivate: [FmesUtilService] },
  { path: 'find-password', loadChildren: () => import('@fitt-lib/pages/find-password/find-password.module').then(m => m.FindPasswordPageModule) },
  { path: 'myinfo', loadChildren: () => import('./pages/myinfo-fmes/myinfo-fmes.module').then(m => m.MyinfoFmesModule), canActivate: [FmesUtilService] },
];
routes.push({ path: '**', redirectTo: 'login' });

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
