import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib';
import { FtDateRangeCompModule } from '@shared-lib/components/ft-date-range/ft-date-range.module';
import { FtUserSearchItemCompModule } from '@fitt-lib/components/ft-user-search-item/ft-user-search-item.module';
import { AddPaymentInfo } from './add-payment-info';

@NgModule({
  declarations: [
    AddPaymentInfo,
  ],
  imports: [
    SharedModule,
    FtUserSearchItemCompModule,
    FtDateRangeCompModule,
  ],
  entryComponents: [
    AddPaymentInfo,
  ],
})
export class AddPaymentInfoModule { }
