import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib';
import { FtDateRange } from './ft-date-range';

@NgModule({
  declarations: [FtDateRange],
  imports: [
    SharedModule,
  ],
  exports: [FtDateRange],
})
export class FtDateRangeCompModule { }
