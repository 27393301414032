import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-date-range',
  templateUrl: './ft-date-range.html',
  styleUrls: ['./ft-date-range.scss'],
})
export class FtDateRange extends BaseComponent implements OnInit {
  @Input() startDate: string;
  @Input() months: number;
  @Input() periodList: number[] = [];
  @Output() monthsChange = new EventEmitter<number>();


  constructor() {
    super();
  }

  ngOnInit() {
    this.initVariable();
  }

  initVariable() {
    if (this.startDate === undefined) {
      this.startDate = new Date().format('yyyy.MM.dd');
    }
    if (this.months === undefined) {
      this.months = 0;
      this.onMonthsChange();
    }
  }

  onMonthsChange() {
    this.monthsChange.emit(this.months);
  }

  getEndDate() {
    return this.months > 0 ? new Date(this.startDate).getNewDate('month', this.months).getNewDate('date', -1).format('yyyy.MM.dd') : this.translate.instant('모달.제한없음');
  }
}
