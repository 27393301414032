<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'설정.환경 설정'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="full">
    <ion-label> {{'language.setting'|translate}} </ion-label>
    <ion-select [(ngModel)]="selectedLanguage" (ionChange)="changeLanguage(selectedLanguage)" cancelText="{{'cancel'|translate}}"
      okText="{{'ok'|translate}}">
      <ion-select-option value="{{lang}}" *ngFor="let lang of supportLanguages;">{{'language.' + lang|translate}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item lines="full">
    <ion-label> {{'unit.setting'|translate}} </ion-label>
    <ion-select [(ngModel)]="displayUnit" (ionChange)="changeDisplayUnit(displayUnit)" cancelText="{{'cancel'|translate}}"
      okText="{{'ok'|translate}}">
      <ion-select-option value="{{unit}}" *ngFor="let unit of supportUnit;">{{'unit.' + unit | translate}}</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item lines="full">
    <ion-label>{{'설정.버전 정보'|translate}}</ion-label>
    <ion-note slot="end">ver {{versionDisplay}}</ion-note>
  </ion-item>
</ion-content>
