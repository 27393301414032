import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { FMT } from '@fitt-lib/models/exercise-test/fmt'
import { FtInfomationModal } from '@fitt-lib/modals/ft-infomation/ft-infomation';


@Component({
  selector: 'fmes-fmt-guide',
  templateUrl: './fmes-fmt-guide.html',
  styleUrls: ['./fmes-fmt-guide.scss']
})


export class FmesFmtGuideModal extends FmesBaseComponent {
  thumbnailPrefix = 'https://www.youtube.com/embed/';
  guideVideos: Array<{ id: string, url: string, name: string, desc: string }>;
  src: string;
  constructor(
    private modalCtrl: ModalController
  ) {
    super();
  }

  ngOnInit(): void {
    this.guideVideos = FMT.getGuideVideos();
    this.guideVideos = this.util.instantAll(this.guideVideos, 'fmt1', false);

  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async openPointAndGuideRule() {
    const modal = await this.modalCtrl.create({
      component: FtInfomationModal,
      // swipeToClose: true, // ionic multitouch error
      mode: 'ios',
      showBackdrop: true,
      cssClass: 'swipeable-modal',
      componentProps: {
        infoTitleFontBold: true,
        title: '점수 가이드 및 규칙',
        infomationItems: [
          {
            title: '점수 가이드',
            content: [
              '0점: 통증이 발생합니다.',
              '1점: 패턴이 없고 포지션을 잡지 못합니다.',
              '2점: 패턴은 괜찮지만, 보상작용이 나타납니다.',
              '3점: 동작을 완벽하게 재연합니다.'
            ],
          },
          {
            title: '검사 규칙',
            content: [
              '규칙 1: 검사 전, 워밍업을 하지 않습니다.',
              '규칙 2: 신발은 벗고 실시합니다.',
              '규칙 3: 엄격하게 관찰합니다.',
              '규칙 4: 각 검사는 2회 실시하고 그 중 높은 점수로 산출합니다.'
            ]
          }
        ]
      }

    });
    await modal.present();
  }
}



