import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { BaseComponent } from '@shared-lib/base.component';

@Injectable({
  providedIn: 'root'
})
export class NavService extends BaseComponent {
  private dataMap: any = {};

  readyState;
  currentIndex = -1;
  historyStack = [];

  refreshTimerID;
  constructor(
    private title: Title,
    private navCtrl: NavController,
    private platform: Platform,
    private router: Router,
    private events: RxEvents,
  ) {
    super({ enableLog: false });

    this.events.subscribe('nav:event', this.navEventHandler);
  }

  navEventHandler = args => {
    this.log('navEventHandler', args);
    this.goPage(args.url, args.param, args.method);
  }


  loadRouting(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.log('router', this.currentIndex, this.readyState, event);
        if (event.restoredState == null) {
          const type = event.url == (this.readyState && this.readyState.url) ? this.readyState.type : 'root';
          if (this.currentIndex >= 0 && this.currentIndex == this.historyStack.length - 1) {
            if (this.historyStack[this.currentIndex].url == event.url) {
              this.historyStack.splice(-1);
            }
          }
          else {
            this.historyStack.splice(this.currentIndex + 1);
          }
          this.historyStack.push({ id: event.id, url: event.url, type: type });
          this.currentIndex = this.historyStack.length - 1;
        }
        else {
          const index = this.historyStack.findIndex(info => info.id == event.restoredState.navigationId);
          if (index >= 0) {
            this.historyStack[index].id = event.id;
            this.currentIndex = index;
          }
        }
      }
      else if (event instanceof NavigationEnd) {
        this.log('router', event);
        this.readyState = null;
        const urlAfterRedirects = event.urlAfterRedirects;
        this.log('loadRouting', this.currentIndex, this.historyStack);

        if (this.util.isDebugMode()) {
          this.title.setTitle(`Fitt4(dev) ${urlAfterRedirects}`);
        }
      }
    });
  }

  getCurrent() {
    return this.router.url
  }
  isRoot() {
    const isRoot = !(this.historyStack[this.currentIndex].type == 'push');
    this.log('isRoot', isRoot, this.currentIndex, this.historyStack[this.currentIndex]);
    return isRoot;
  }

  root(url: string, data?: any) {
    url = url[0] != '/' ? '/' + url : url;
    this.log('root', url, data);
    this.dataMap[url] = data;

    this.readyState = { url: url, type: 'root' };
    return this.navCtrl.navigateRoot(url, data || {}).then(result => this.log('root', url, result));
  }

  push(url: string, data?: any) {
    url = url[0] != '/' ? '/' + url : url;
    this.log('push', url, data);
    this.dataMap[url] = data;

    this.readyState = { url: url, type: 'push' };
    return this.platform.width() < 768 ? this.navCtrl.navigateForward(url, data || {}) : this.navCtrl.navigateRoot(url, data || {});
  }

  pop(url?) {
    url = url && url[0] != '/' ? '/' + url : url;
    this.log('pop', url);
    // return this.navCtrl.back();
    return this.navCtrl.pop();
  }

  get(url: string) {
    url = url[0] != '/' ? '/' + url : url;
    const data = this.dataMap[url] || {};
    this.log('get', url, data);
    return data;
  }

  goPage(pageName: string, param?: any, method?: string): Promise<any> {
    // this.performanceStamp(`goPage ${pageName}`);
    this.log('goPage', { pageName, param, method });

    method = method || (this.util.isMobileApp() ? 'push' : 'root');
    if (method === 'root') {
      localStorage.lastPage = pageName;
      if (param) {
        localStorage.lastPageParam = JSON.stringify(param);
      }
    }

    const goPagePromise: Promise<any> = method == 'root' ? this.root(pageName, param) : this.push(pageName, param);
    goPagePromise.catch(error => {
      console.warn('goPage error', error, { pageName: pageName, param: param, method: method });
    });
    return goPagePromise;
  }
}
