<ion-header mode="ios" no-border>
  <ion-toolbar mode="ios">
    <ion-title color="black2">{{'건강.검사방법 영상'|translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="mobile-video-container">
    <iframe [src]="src|safe" frameborder="0" allowfullscreen class="mobile-video-frame"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
    </iframe>
  </div>
  <ion-text class="title" color="black1">
    {{fmtCheckIndex + 1}}. {{('fmt2.' + fmtItemList[fmtCheckIndex].subject)|translate}}
  </ion-text>
  <ion-text>
    <!-- TODO: 설명 삽입 -->
  </ion-text>
  <ion-text>
    Copyright ⓒ FITT TV  All Rights Reserved.<br>
    #피트 #FITT #운동처방 #헬스케어스타트업
  </ion-text>
  <ion-text class="sns-desc">
    👇 피트 SNS에서 더 많은 정보들을 보실 수 있습니다 👇
  </ion-text>
  <div class="sns-buttons">
    <ion-button fill="clear" slot="icon-only" color="sub4" (click)="clickSNS('youtube')">
      <ion-icon name="logo-youtube"></ion-icon>
      <!-- <ion-label>FITT TV</ion-label> -->
    </ion-button>
    <ion-button fill="clear" slot="icon-only" color="tertiary" (click)="clickSNS('instagram')">
      <ion-icon name="logo-instagram"></ion-icon>
      <!-- <ion-label>인스타그램</ion-label> -->
    </ion-button>
    <ion-button fill="clear" slot="icon-only" color="main" (click)="clickSNS('facebook')">
      <ion-icon name="logo-facebook"></ion-icon>
      <!-- <ion-label>페이스북</ion-label> -->
    </ion-button>
  </div>
</ion-content>