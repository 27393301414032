import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ft-badge-button',
  templateUrl: './ft-badge-button.component.html',
  styleUrls: ['./ft-badge-button.component.scss'],
})
export class FtBadgeButtonComponent implements OnInit {

  @Input() fill: string;
  @Input() badgeText: string;

  constructor(
  ) {
  }

  ngOnInit() {

  }

}
