import { ScState as ScStateModels } from './models/booking-models';

export * from './fitt-lib.module';

declare global {
  type Gender = 'male' | 'female';
  type ScState = ScStateModels;
  type Visible = 0 | 1;
  type LoadState = 'none' | 'loading' | 'loaded';
}
