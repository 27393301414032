import { ElementRef, Injectable, isDevMode, NgZone } from '@angular/core';
import { DatePicker, DatePickerMode, DatePickerOptions } from '@capacitor-community/date-picker';
import { Camera } from '@ionic-native/camera/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ActionSheetController, AlertController, LoadingController, MenuController, ModalController, Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerModal } from '@shared-lib/modals/date-picker/date-picker';
import { MenuPopOver } from '@shared-lib/pop-over/menu-pop-over/menu-pop-over';
import { getTimeZones } from "@vvo/tzdb";
import * as CryptoJS from 'crypto-js';
import $ from 'jquery';
import { Subject } from 'rxjs';

Date.prototype.toString = function () {
  return this.format('[yyyy-MM-dd HH:mm:ss]');
}
Date.prototype.format = function (f, utc?: boolean): string {
  if (!this.valueOf()) {
    // console.log('format', this.valueOf());
    return ' ';
  }

  this.getUTCFullYear();
  const ordinalSuffixOf = (value: number): string => {
    const remainder10 = value % 10;
    const remainder100 = value % 100;

    if (remainder10 == 1 && remainder100 != 11) {
      return value + "st";
    }
    if (remainder10 == 2 && remainder100 != 12) {
      return value + "nd";
    }
    if (remainder10 == 3 && remainder100 != 13) {
      return value + "rd";
    }
    return value + "th";
  }
  const d = this;
  let h;
  if (utc) {
    return f.replace(/(yyyy|yy|MM|M|dd|d|E|YYYY|NN|DD|HH|hh|mm|ss|a\/p|fff|T)/gi, function ($1) {
      switch ($1) {
        case 'yyyy': return d.getUTCFullYear();
        case 'yy': return (d.getUTCFullYear() % 1000).pad(2);
        case 'MM': return (d.getUTCMonth() + 1).pad(2); case 'M': return (d.getUTCMonth() + 1);
        case 'dd': return d.getUTCDate().pad(2); case 'd': return d.getUTCDate();
        case 'E': {
          const weekName = UtilService.currentLan == 'ko' ? ['일', '월', '화', '수', '목', '금', '토'] : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
          return weekName[d.getUTCDay()];
        }
        case 'YYYY': {
          if (UtilService.currentLan == 'ko') {
            return `${d.getUTCFullYear()}년`;
          } else {
            return `${d.getUTCFullYear()}`;
          }
        }
        case 'NN': {
          if (UtilService.currentLan == 'ko') {
            return `${(d.getUTCMonth() + 1).pad(2)}월`;
          }
          else {
            return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getUTCMonth()];
          }
        }
        case 'DD': {
          if (UtilService.currentLan == 'ko') {
            return `${d.getUTCDate().pad(2)}일`;
          }
          else {
            return ordinalSuffixOf(d.getUTCDate());
          }
        }
        case 'HH': return d.getUTCHours().pad(2);
        case 'hh': return ((h = d.getUTCHours() % 12) ? h : 12).pad(2);
        case 'mm': return d.getUTCMinutes().pad(2);
        case 'ss': return d.getUTCSeconds().pad(2);
        case 'A/P': return d.getUTCHours() < 12 ? (UtilService.currentLan == 'ko' ? '오전' : 'AM') : (UtilService.currentLan == 'ko' ? '오후' : 'PM');
        case 'a/p': return d.getUTCHours() < 12 ? 'am' : 'pm';
        case 'fff': return d.getUTCMilliseconds().pad(3);
        case 'T': return 'T';
        case 'Z': return 'Z';
        default: return $1;
      }
    });
  }
  else {
    return f.replace(/(yyyy|yy|MM|M|dd|d|E|YYYY|NN|DD|HH|hh|mm|ss|a\/p|fff|T)/gi, function ($1) {
      switch ($1) {
        case 'yyyy': return d.getFullYear();
        case 'yy': return (d.getFullYear() % 1000).pad(2);
        case 'MM': return (d.getMonth() + 1).pad(2); case 'M': return (d.getMonth() + 1);
        case 'dd': return d.getDate().pad(2); case 'd': return d.getDate();
        case 'E': {
          const weekName = UtilService.currentLan == 'ko' ? ['일', '월', '화', '수', '목', '금', '토'] : ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
          return weekName[d.getDay()];
        }
        case 'YYYY': {
          if (UtilService.currentLan == 'ko') {
            return `${d.getUTCFullYear()}년`;
          } else {
            return `${d.getUTCFullYear()}`;
          }
        }
        case 'NN': {
          if (UtilService.currentLan == 'ko') {
            return `${(d.getMonth() + 1).pad(2)}월`;
          }
          else {
            return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()];
          }
        }
        case 'DD': {
          if (UtilService.currentLan == 'ko') {
            return `${d.getDate().pad(2)}일`;
          }
          else {
            return ordinalSuffixOf(d.getDate());
          }
        }
        case 'HH': return d.getHours().pad(2);
        case 'hh': return ((h = d.getHours() % 12) ? h : 12).pad(2);
        case 'mm': return d.getMinutes().pad(2);
        case 'ss': return d.getSeconds().pad(2);
        case 'A/P': return d.getHours() < 12 ? (UtilService.currentLan == 'ko' ? '오전' : 'AM') : (UtilService.currentLan == 'ko' ? '오후' : 'PM');
        case 'a/p': return d.getHours() < 12 ? 'am' : 'pm';
        case 'fff': return d.getMilliseconds().pad(3);
        case 'T': return 'T';
        default: return $1;
      }
    });
  }
};
Date.prototype.compare = function (type: 'second' | 'minute' | 'hour' | 'date' | 'month', value: number = 0, operand?: Date): number {
  const date = operand ? new Date(operand) : new Date();
  switch (type) {
    case 'second': date.setSeconds(date.getSeconds() + value); break;
    case 'minute': date.setMinutes(date.getMinutes() + value); break;
    case 'hour': date.setHours(date.getHours() + value); break;
    case 'date': date.setDate(date.getDate() + value); break;
    case 'month': date.setMonth(date.getMonth() + value); break;
  }
  // console.log('compare', type, value, this < date ? -1 : this > date ? 1 : 0);
  return this < date ? -1 : this > date ? 1 : 0;
}
// 주의: operand와 범위 비교를 하는 것으로 생일 체크시에는 within(')
Date.prototype.within = function (type: 'hour' | 'date' | 'week' | '2week' | 'month', value: number = 0, operand?: Date, noYearCompare?: boolean): boolean {
  const date = operand ? new Date(operand) : new Date();
  let sDate, eDate;
  switch (type) {
    case 'hour':
      sDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate(), date.getHours() + value);
      eDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate(), date.getHours() + value + 1, 0, -1);
      break;
    case 'date':
      sDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() + value);
      eDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() + value + 1, 0, 0, -1);
      break;
    case 'week':
      sDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() - date.getDay() + value);
      eDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7 + value, 0, 0, -1);
      break;
    case '2week':
      sDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() - date.getDay() + value);
      eDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 14 + value, 0, 0, -1);
      break;
    case 'month':
      sDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth() + value);
      eDate = new Date((noYearCompare ? this : date).getFullYear(), date.getMonth() + value + 1, 0, 0, 0, -1);
      break;
    default: return false;
  }
  const result = sDate.getTime() <= this.getTime() && this.getTime() <= eDate.getTime();
  // console.log('within', sDate.format('yyyy-MM-dd'), this.format('yyyy-MM-dd'), eDate.format('yyyy-MM-dd'), result);
  return result;
}
Date.prototype.difference = function (type: 'second' | 'minute' | 'hour' | 'date' | 'month', operand?: Date): number {
  const date = operand ? new Date(operand) : new Date();
  const differ = date.getTime() - this.getTime();
  switch (type) {
    case 'second': return Math.floor(differ / 1000);
    case 'minute': return Math.floor(differ / (60 * 1000));
    case 'hour': return Math.floor(differ / (3600 * 1000));
    case 'date': return Math.floor(differ / (24 * 3600 * 1000));
    case 'month': return Math.floor(differ / (30 * 24 * 3600 * 1000));
  }
}
// 파라미터 만큼 시간을 더해서 새로운 Date 객체를 return
Date.prototype.getNewDate = function (type: 'second' | 'minute' | 'hour' | 'date' | 'month' | 'year', value: number): Date {
  const date = new Date(this);
  switch (type) {
    case 'second': date.setSeconds(date.getSeconds() + value); break;
    case 'minute': date.setMinutes(date.getMinutes() + value); break;
    case 'hour': date.setHours(date.getHours() + value); break;
    case 'date': date.setDate(date.getDate() + value); break;
    case 'month': date.addMonths(value); break;
    case 'year': date.setFullYear(date.getFullYear() + value); break;
  }
  return date;
}
Date.prototype.zeroHour = function () {
  this.setHours(0);
  this.setMinutes(0);
  this.setSeconds(0);
  this.setMilliseconds(0);
  return this;
}
Date.prototype.resetDate = function (mode: 'reset' | 'full', type: 'milisecond' | 'second' | 'minute' | 'hour' | 'date' | 'month') {
  switch (type) {
    case 'month': mode == 'reset' ? this.setMonth(0) : this.setMonth(11);
    case 'date': {
      if (mode == 'reset') {
        this.setDate(1);
      }
      else {
        this.addMonths(1);
        this.setDate(0);
      }
    }
    case 'hour': mode == 'reset' ? this.setHours(0) : this.setHours(23);
    case 'minute': mode == 'reset' ? this.setMinutes(0) : this.setMinutes(59);
    case 'second': mode == 'reset' ? this.setSeconds(0) : this.setSeconds(59);
    case 'milisecond': mode == 'reset' ? this.setMilliseconds(0) : this.setMilliseconds(999);
  }
  return this;
}
Date.prototype.isLeapYear = function (): boolean {
  const year = this.getFullYear();
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};
Date.prototype.getDaysInMonth = function (): number {
  return [31, (this.isLeapYear() ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][this.getMonth()];
};
Date.prototype.addMonths = function (value: number): Date {
  const date = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(date, this.getDaysInMonth()));
  return this;
};

// var bind = Function.bind;
// var unbind = bind.bind(bind);

// function instantiate(constructor, args) {
//   return new (unbind(constructor, null).apply(null, args));
// }

// Date = function (Date) {
//   // copy date methods - this is a pain in the butt because they're mostly nonenumerable
//   // Get all own props, even nonenumerable ones
//   var names = Object.getOwnPropertyNames(Date);
//   // Loop through them
//   for (var i = 0; i < names.length; i++) {
//     // Skip props already in the MyDateConstructor object
//     if (names[i] in MyDateConstructor) continue;
//     // Get property description from o
//     var desc = Object.getOwnPropertyDescriptor(Date, names[i]);
//     // Use it to create property on MyDateConstructor
//     Object.defineProperty(MyDateConstructor, names[i], desc);
//   }
//   return MyDateConstructor;

//   function MyDateConstructor() {
//     // we only care about modifying the constructor if a datestring is passed in
//     let arg0, convert;
//     if (arguments.length === 1 && arguments[0] !== undefined && typeof (arguments[0]) === 'string') {
//       // if you're adding other date transformations, add them here

//       // // match dates of format m-d-yyyy and convert them to cross-browser-friendly m/d/yyyy
//       // var mdyyyyDashRegex = /(\d{1,2})-(\d{1,2})-(\d{4})/g;
//       // arguments[0] = arguments[0].replace(mdyyyyDashRegex, function (match, p1, p2, p3) {
//       //     return p1 + "/" + p2 + "/" + p3;
//       // });

//       // convert = true;
//       // arg0 = arguments[0];
//       // arguments[0] = arguments[0].replace(' ', 'T');
//     }

//     // call the original Date constructor with whatever arguments are passed in here
//     const date = instantiate(Date, arguments);
//     if (convert && isNaN(date.valueOf())) {
//       console.error('MyDateConstructor', arg0, arguments[0]);
//     }
//     return date;
//   }
// }(Date);


String.prototype.pad = function (len: any) { return `${'0'.repeat(len)}${this}`.slice(-len) };
String.prototype.padRight = function (len: any) { return `${this}${'0'.repeat(len)}`.slice(0, len) };
String.prototype.currencyFormat = function () {
  const num = parseFloat(this);
  if (isNaN(num)) return '0';
  return num.currencyFormat();
};
String.prototype.phoneFormat = function (hideMid?: boolean) {
  const num = this;
  let formatNum = '';
  if (num.length == 11) {
    if (hideMid) formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    else formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (num.length == 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') == 0) {
      if (hideMid) formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      else formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
    } else {
      if (hideMid) formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      else formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  }
  return formatNum;
};
String.prototype.splice = function (index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = this.length + index;
    if (index < 0) {
      index = 0;
    }
  }

  return this.slice(0, index) + (add || "") + this.slice(index + count);
}


Number.prototype.pad = function (len: any) { return this.toString().pad(len); };
Number.prototype.padRight = function (len: any) { return this.toString().padRight(len); };
Number.prototype.currencyFormat = function () {
  if (this == 0) return '0';
  const reg = /(^[+-]?\d+)(\d{3})/;
  let n: string = (this + '');
  while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
  return n;
};
Number.prototype.toTimeString = function (format?: string) {
  const totalSec = Math.round(this);
  if (format) {
    const hour = Math.floor(totalSec / 3600);
    const min = Math.floor((totalSec % 3600) / 60);
    const sec = totalSec % 60;
    let result = format.replace(/(HH|hh|mm|ss)/gi, function ($1) {
      switch ($1) {
        case 'HH': return hour.pad(2);
        case 'hh': return hour > 0 ? '' + hour : '';
        case 'mm': return min.pad(2);
        case 'ss': return sec.pad(2);
        default: return $1;
      }
    });
    if (result[0] == ':') result = result.substring(1);
    return result;
  } else {
    if (!this) {
      return '00:00';
    }
    else if (this < 3600) {
      return `${Math.floor(totalSec / 60).pad(2)}:${Math.round(totalSec % 60).pad(2)}`;
    } else {
      return `${Math.floor(totalSec / 3600)}:${Math.floor(totalSec % 3600 / 60).pad(2)}:${Math.round(totalSec % 60).pad(2)}`;
    }
  }

  // return `${hour > 0 ? hour + ':' : ''}${min > 0 ? min.pad(2) + ':' : ''}${sec.pad(2)}`;
}

Number.prototype.formatInRange = function (min: number, max: number): number {
  if (this < min) { return min; }
  if (this > max) { return max; }
  return this;
};
Number.prototype.toFixedNumber = function (x: number, base?: number) {
  const pow = Math.pow(base || 10, x);
  return Math.round(this * pow) / pow;
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  static currentLan: string = 'en';
  Math = Math;
  EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)+$/i;

  loadingPopup;
  isShowLoadingPopup: boolean = false;
  menuId: string;
  constructor(
    public translate: TranslateService,
    private platform: Platform,
    private zone: NgZone,
    private alertCtrl: AlertController,
    private iab: InAppBrowser,
    private loadingCtrl: LoadingController,
    private menuCtrl: MenuController,
    private actionSheetController: ActionSheetController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private camera: Camera,
  ) {
    // super({
    //   // enableLog: true
    // });

    this.translate.setDefaultLang('en');
    const language = localStorage.selectedLanguage || this.getLanguageCode(navigator.language);
    // console.log('configLanguage', { lang: this.translate.getLangs(), 'navigator.language': navigator.language, selectedLanguage: localStorage.selectedLanguage, language: language });
    this.translate.use(language);
    UtilService.currentLan = language;

    // this.translate = translate;

    // let platformService = this.platform;  // 아래 platformService.is('capacitor')에서 this.platform으로 사용하면 에러 난다.
    // (function () {
    //   var _log = console.log;
    //   var _error = console.error;
    //   var _warn = console.warn;

    //   // if (isDevMode() && mobile == 'a') {
    //   console.log('UtilService.constructor wrap log function');
    //   if (platformService.is('capacitor')) {  // 왠지 모르지만 android만 해당된다. this.platform을 사용하면 오류난다.
    //     console.log('wrap log function');
    //     console.error = function () {
    //       let newMessage = '';
    //       for (let i = 0; i < arguments.length; i++) {
    //         let arg = arguments[i];
    //         // let argStr;
    //         // if (arg instanceof Object) {
    //         //   try { argStr = JSON.stringify(arg); }
    //         //   catch (error) { argStr = arg; }
    //         // }
    //         // else {
    //         //   argStr = arg;
    //         // }
    //         // newMessage += ('' + argStr + ' ');
    //         // newMessage += arg.stack;
    //         newMessage += (arg.stack ? arg.stack : arg.toString()) + ' ';
    //       }
    //       _error.apply(console, [newMessage]);
    //     };
    //     console.log = function () {
    //       let newMessage = '';
    //       for (let i = 0; i < arguments.length; i++) {
    //         let arg = arguments[i];
    //         let argStr;
    //         if (arg instanceof Object) {
    //           try { argStr = JSON.stringify(arg); }
    //           catch (error) { argStr = arg; }
    //         }
    //         else {
    //           argStr = arg;
    //         }
    //         newMessage += ('' + argStr + ' ');
    //       }
    //       _log.apply(console, [newMessage]);
    //     };
    //     console.warn = function () {
    //       let newMessage = '';
    //       for (let i = 0; i < arguments.length; i++) {
    //         let arg = arguments[i];
    //         let argStr;
    //         if (arg instanceof Object) {
    //           try { argStr = JSON.stringify(arg); }
    //           catch (error) { argStr = arg; }
    //         }
    //         else {
    //           argStr = arg;
    //         }
    //         newMessage += ('' + argStr + ' ');
    //       }
    //       _warn.apply(console, [newMessage]);
    //     };
    //   }
    // })();
  }

  setUseLanguage(language) {
    localStorage.selectedLanguage = language;
    this.translate.use(language);
    UtilService.currentLan = language;
  }

  trArg(arg: any) {
    if (arg !== undefined && typeof (arg) === 'string') {
      return arg.replace(' ', 'T');
    }
    return arg;
  }

  // getBrowserInfo() {
  //   const ua = navigator.userAgent;
  //   const browser = /Edge\/\d+/.test(ua) ? 'ed' : /MSIE 9/.test(ua) ? 'ie9' : /MSIE 10/.test(ua) ? 'ie10' : /MSIE 11/.test(ua) ? 'ie11' : /MSIE\s\d/.test(ua) ? 'ie?' : /rv\:11/.test(ua) ? 'ie11' : /Firefox\W\d/.test(ua) ? 'ff' : /Chrome\W\d/.test(ua) ? 'gc' : /Chromium\W\d/.test(ua) ? 'oc' : /\bSafari\W\d/.test(ua) ? 'sa' : /\bOpera\W\d/.test(ua) ? 'op' : /\bOPR\W\d/i.test(ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '';
  //   const os = /Windows NT 10/.test(ua) ? 'win10' : /Windows NT 6\.0/.test(ua) ? 'winvista' : /Windows NT 6\.1/.test(ua) ? 'win7' : /Windows NT 6\.\d/.test(ua) ? 'win8' : /Windows NT 5\.1/.test(ua) ? 'winxp' : /Windows NT [1-5]\./.test(ua) ? 'winnt' : /Mac/.test(ua) ? 'mac' : /Linux/.test(ua) ? 'linux' : /X11/.test(ua) ? 'nix' : '';
  //   const mobile = /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 'o' : '';
  //   const tablet = /Tablet|iPad/i.test(ua);
  //   const touch = 'ontouchstart' in document.documentElement
  //   return { browser: browser, os: os, mobile: mobile, tablet: tablet, touch: touch };
  // }
  isDebugMode() { // 개발환경이면. web만 해당된다. 안드로이드(애뮬포함)에서는 적용되지 않는다.
    // console.log('isDebugMode', { isDevMode: isDevMode(), hostname: window.location.hostname });
    return isDevMode() || (!this.platform.is('capacitor') && window.location.hostname == 'localhost') || (this.platform.is('capacitor') && window.location.hostname.startsWith('192.168.0.'));
  }
  isEmulator(): Promise<any> {
    return new Promise(resolve => {
      if (!this.platform.is('capacitor')) {
        resolve(false);
        return;
      }
      // cordova.plugins.HappyHomeAndroidPlugin.isEmulator(result => {
      //   this.log('isEmulator', result);
      //   resolve(result.emulator);
      //   return;
      // });
    });
  }

  public PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  toKey(str: string) {
    return str.replace(/\./g, ',');
  }
  parseKey(str: string) {
    return str.replace(/,/g, '.');
  }
  // toFormattedTimeString(date: Date, format?: string, utc?: boolean) {
  //   format = format || 'yyyy-MM-dd hh:mm:ss a/p';
  //   var str = new Date(date).format(format, utc);

  //   if (this.translate.currentLang == 'ko') {
  //     str = str.replace('am', '오전');
  //     str = str.replace('pm', '오후');
  //   }
  //   return str;
  // };
  // toDateString(dateStr: string | Date, format?: string, utc?: boolean) {
  //   format = format || 'yyyy-MM-dd hh:mm:ss a/p';
  //   // console.log('toDateString', dateStr);
  //   const date = new Date(dateStr);
  //   // console.log('toDateString', date.format(format, utc));
  //   return date.format(format, utc);
  // }
  toMinutesString(seconds: number) {
    return (~~(seconds / 3600)) + ':' + (~~((seconds % 3600) / 60)).pad(2);
  }
  toYearMonthText(months: number) {
    return `${months >= 12 ? Math.floor(months / 12) + this.translate.instant('일반.년') : ''}${months % 12}${this.translate.instant('일반.개월')}`;
  }

  textSeconds(seconds: number, z: boolean, m: boolean, numberOnly?: boolean) {
    // z: true면 상위자리가 0인경우 표시하지 않음 , m: true면 초단위 표시하지 않음
    let arr = ['', '', ''];
    if (!numberOnly) {
      switch (this.translate.currentLang) {
        case 'ko': arr = ['시', '분', '초']; break;
        default: arr = ['h', 'm', 's']; break;
      }
    }

    let r = true;
    return [seconds / 3600, seconds / 60 % 60, seconds % 60]
      .map((t, i) => {
        r = (r && z && (~~t) == 0) || (m && i == arr.length - 1);
        return r ? '' : (~~t).pad(2) + arr[i];
      })
      .filter(t => t != '')
      .join(!numberOnly ? ' ' : ':');
  }
  keyDecode(key: string) {
    const id = key.substring(0, 8);
    let timestamp = 0;
    for (let i = 0; i < id.length; i++) {
      const c = id.charAt(i);
      timestamp = timestamp * 64 + this.PUSH_CHARS.indexOf(c);
    }
    return timestamp;
  }
  keyEncode(time: number) {
    let result = '';
    while (time > 0) {
      result = this.PUSH_CHARS[time % 64] + result;
      time = parseInt('' + time / 64);
    }
    return '-' + result;
  }

  public CHAR_SET = '0123456789ABCDEFGHIJKLMNPQRSTUVWXYZ';
  genPromotionCode() {
    let timeSeed = new Date().getTime() - new Date(2018, 0, 1).getTime();
    let result = '';
    while (timeSeed > 0) {
      result = this.CHAR_SET[timeSeed % 25] + result;
      timeSeed = parseInt('' + timeSeed / 25);
    }
    return result;
  }
  makeObject(str, value) {
    // str: '/a/b' value: true
    // return: {a: {b: true}}
    const obj = {};
    let point = obj;
    const arr = this.getTokens(str);
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i].length > 0) {
        point[arr[i]] = {};
        point = point[arr[i]];
      }
    }
    if (arr[arr.length - 1].length > 0) {
      point[arr[arr.length - 1]] = value;
    }
    return obj;
  }
  transferObject(updates) {
    // updates: updates[`parent/child`] = 'value'; <= firebase에서 사용하는 형태
    // return: {parent: {child: 'value'}}
    const obj = {};
    for (const key in updates) {
      this.deepObjectExtend(obj, this.makeObject(key, updates[key]));
    }
    return obj;
  }
  getLanguageCode(language: string) {
    let code;
    switch (language.substring(0, 2)) {
      case 'en': code = 'en'; break;
      case 'ko': code = 'ko'; break;
      default: code = language;
    }
    return code;
  }
  objectFilter = function (object: Object, prefix: string): Object {
    const obj = {}
    for (const key of Object.keys(object)) {
      if (key.startsWith(prefix)) obj[key] = object[key];
    }
    return obj;
  }
  getNumberOrdinalSuffix = (value: number): string => {
    const remainder10 = value % 10;
    const remainder100 = value % 100;
    if (this.translate.currentLang == 'ko') {
      return "";
    } else {
      if (remainder10 == 1 && remainder100 != 11) {
        return "st";
      }
      if (remainder10 == 2 && remainder100 != 12) {
        return "nd";
      }
      if (remainder10 == 3 && remainder100 != 13) {
        return "rd";
      }
      return "th";
    }
  }
  compareObject(a: any, b: any, path?: string): boolean {
    const output = false;
    output && console.log('compareObject--', `path=${path}`, a, b);
    path = path || '';
    let result;
    if (a === b) {
      result = true;
    }
    else if (a != null && b != null) {
      if (Array.isArray(a) || typeof a == 'object') {
        if (Array.isArray(b) || typeof b == 'object') {
          for (const key of Object.keys(a)) {
            if (!this.compareObject(a[key], b[key], `${path}/${key}`)) {
              output && console.log('compareObject1', `path=${path}`, a, b);
              result = false;
              break;
            }
          }
          result = result !== undefined ? result : true;
        }
        else {
          output && console.log('compareObject2', `path=${path}`, a, b);
          result = false;
        }
      }
      else {
        result = a == b;
        if (!result) {
          output && console.log('compareObject3', `path=${path}`, a, b);
        }
      }
    }
    else {
      if (!a && !b) {
        result = true;
      }
      else {
        result = false;
        output && console.log('compareObject4', `path=${path}`, a, b);
      }
    }
    return result;
  }
  private internalDeepExtend(target, source, exceptDolor, nullCopy, parentProp?) {
    // this.log(`deepObjectExtend internalDeepExtend(${parentProp})`, target, source);
    if (source == null) {
      target = source;
    }
    else {
      const isArray = Array.isArray(source);
      target = target || (isArray ? [] : {});
      for (const prop in source) {
        if (exceptDolor && prop.startsWith('$')) {
          continue;
        }
        if (typeof source[prop] === 'function') {
          continue;
        }

        // this.log(`deepObjectExtend internalDeepExtend ${prop} ${source[prop]}`, typeof source[prop]);
        if (source[prop] === null || source[prop] === undefined) {
          if (!isArray) {
            if (nullCopy) {
              target[prop] = null;
            }
            else {
              delete target[prop];
            }
          }
        }
        else if (typeof source[prop] === 'object') {
          if (!target[prop] || typeof target[prop] !== 'object') {
            target[prop] = Array.isArray(source[prop]) ? [] : {};
          }
          this.internalDeepExtend(target[prop], source[prop], exceptDolor, nullCopy, prop);
          if (Object.keys(target[prop]).length == 0) {
            delete target[prop];
          }
        }
        else {
          if (isArray) {
            target.push(source[prop]);
          }
          else {
            target[prop] = source[prop];
          }
          // this.log(`deepObjectExtend internalDeepExtend(${parentProp}) copy`, prop, target[prop]);
        }
      }
    }
    return target;
  }
  deepObjectExtend(target, source, exceptDolor?: boolean, nullCopy?: boolean, output?: boolean) {
    exceptDolor = exceptDolor !== undefined ? exceptDolor : true;
    nullCopy = nullCopy !== undefined ? nullCopy : false;
    // output && this.log('deepObjectExtend', target, source, exceptDolor);
    const result = this.internalDeepExtend(target, source, exceptDolor, nullCopy);
    // output && this.log('deepObjectExtend result', JSON.stringify(target));
    return result;
  }
  deepCopy(target: Object, ...source: Object[]): any {
    // this.log('deepCopy', target);
    target = target || {};
    for (let i = 0; i < source.length; i++) {
      this.deepObjectExtend(target, source[i]);
    }
    return target;
  }

  childLength(obj: Object) {
    return obj != null && typeof obj == 'object' ? Object.keys(obj).length : 0;
  }
  generateUUID(): string {
    // function s4() {
    //   return Math.floor((1 + Math.random()) * 0x10000).toString().substring(1);
    // }
    // return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    const uuidFormat = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    const nums = window.crypto.getRandomValues(new Uint8ClampedArray(uuidFormat.split(/[xy]/).length - 1));
    let pointer = 0;
    return uuidFormat.replace(/[xy]/g, function (c) {
      const r = nums[pointer++] % 16,
        v = (c === 'x') ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  getDayNames(fullName: boolean = false, language: string): Array<string> {
    language = language ? language : this.translate.currentLang;
    if (!fullName) {
      return language == 'ko' ? ['일', '월', '화', '수', '목', '금', '토'] : ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    }
    else {
      return language == 'ko' ? ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'] : ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    }
  }
  getDayOfWeek(day: number, fullName: boolean = false, language?: string): string {
    if (day > 6) {
      return '';
    }
    const dayNames = this.getDayNames(fullName, language || this.translate.currentLang);
    return dayNames[day];
  }
  getDayIndex(days: string): number {
    const day = days.split('/')[0] || '';
    return ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'].indexOf(day);
  }

  dateKeyAscOrder = (a, b): number => {
    // this.log('dateKeyAscOrder', a.key, b.key);
    return this.getDayIndex(a.key) - this.getDayIndex(b.key);
  }
  transDayOfWeek(day: string) {
    switch (day) {
      case 'sun': return 'sunday';
      case 'mon': return 'monday';
      case 'tue': return 'tuesday';
      case 'wed': return 'wednesday';
      case 'thu': return 'thursday';
      case 'fri': return 'friday';
      case 'sat': return 'saturday';
    }
    return '';
  }
  dateToYearMonth(date: Date, short?: boolean) {
    if (this.translate.currentLang == 'ko') {
      return `${date.getFullYear()}년 ${(date.getMonth() + 1).pad(2)}월`;
    }
    else {
      const monthNames = !short ?
        ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] :
        ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;
    }
  }
  dateToMonthDayName(date: Date, short?: boolean) {
    if (this.translate.currentLang == 'ko') {
      const dayWeekNames = !short ? ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'] : ['일', '월', '화', '수', '목', '금', '토'];
      return `${date.getMonth() + 1}월 ${date.getDate()}일 ${dayWeekNames[date.getDay()]}`;
    }
    else {
      const dayWeekNames = !short ?
        ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'] :
        ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      const monthNames = !short ?
        ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'] :
        ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return `${dayWeekNames[date.getDay()]} ${date.getDate()} ${monthNames[date.getMonth()]}`;
    }
  }

  secondsToMinutes(seconds: number | string, betweenToken: string = '\'', endToken: string = '"') {
    const secondNumberValue = Number(seconds);
    return `${Math.floor(secondNumberValue / 60)}${betweenToken}${('0' + Math.floor(secondNumberValue % 60)).slice(-2)}${endToken}`;
  }


  distance(lat1, lon1, lat2, lon2) {
    // let R = 6371; // Radius of the earth in km
    // let dLat = (lat2 - lat1) * (Math.PI / 180);  // deg2rad below
    // let dLon = (lon2 - lon1) * (Math.PI / 180);
    // let a =
    //   Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    //   Math.cos(lat1) * (Math.PI / 180) * Math.cos(lat2) * (Math.PI / 180) *
    //   Math.sin(dLon / 2) * Math.sin(dLon / 2);
    // let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    // let d = R * c; // Distance in km
    // return d;
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist * 1000;
  }
  extractDomain(url: string) {
    return url && url.match(/^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^/\n]+)/)[0];
  }
  extractVersion(str) {
    const value = typeof str == 'string' ? str.match(/\d+((\.\d+)+)?/g) : null;
    return value && value.length > 0 ? value[0] : null;
  }
  compareVersion(ver1, ver2) {
    const v1 = typeof ver1 == 'string' ? ver1.split('.').map(n => parseInt(n)) : typeof ver1 == 'number' ? [ver1] : [0];
    const v2 = typeof ver2 == 'string' ? ver2.split('.').map(n => parseInt(n)) : typeof ver2 == 'number' ? [ver2] : [0];

    let result = 0;
    for (let i = 0; i < v1.length; i++) {
      if (v1[i] == v2[i]) {
        continue;
      }
      result = (v1[i] || 0) > (v2[i] || 0) ? 1 : -1;
      break;
    }
    // this.log('compareVersion', ver1, result > 0 ? '>' : result < 0 ? '<' : '=', ver2);
    return result;
  }
  promiseTimeout(promise: Promise<any>, ms: number, successWhenTimeout?: boolean) {
    // Create a promise that rejects in <ms> milliseconds
    const timeout = new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id);
        if (successWhenTimeout) {
          // this.log('promiseTimeout', ms);
          resolve('Timed out in ' + ms + 'ms.');
        }
        else {
          reject('Timed out in ' + ms + 'ms.');
        }
      }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
  }
  getTokens(path: string) {
    path.trim();
    const tokens = path.split('/');
    tokens[0] == '' && tokens.shift();
    tokens[tokens.length - 1] == '' && tokens.pop();
    return tokens;
  }
  toMinutes(hourMinutes) {
    const times = hourMinutes.split(':');
    if (times.length == 2) {
      return parseInt(times[0]) * 60 + parseInt(times[1]);
    }
    else if (times.length == 1) {
      return parseInt(times[0]);
    }
    else {
      return 0;
    }
  }
  checkValue(a, b, v) {
    // a값이 b값으로 변경되면서 v가 됨을 확인. b가 undefined 이면 a가 v임을 확인하면됨.
    return b !== undefined ? b === v : a === v;
  }

  transHyperLink(param: { content: string, loginInfo?: { hid: string, devMode: string }, sender?: { hid: string, devMode: string } }) {
    // this.log('transHyperLink', param.loginInfo);
    // 맨뒤 \s?는 빈칸한개까지 포함
    const URL_REGEXP = /(?:(?:https?|ftp|file|hha):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_[가-힣]+|$]):?/igm;

    return param.content.replace(URL_REGEXP, url => {
      let href = url;
      let text = url;
      let isBold = false;

      const index = url.indexOf('::');
      if (index > 0) {
        href = url.substring(0, index);
        text = url.substring(index + 2);
        text = text.replace(/_/g, ' ');
        if (text[text.length - 1] == ':') {
          text = text.substring(0, text.length - 1);
        }
      }

      if (url.indexOf('hha://') >= 0) {
        isBold = true;
        if (url.indexOf('hha://bold') >= 0) {
          href = null;
        }
      }

      let isHyper = false;
      if (param.loginInfo) {
        // param.me.devMode = 'child';  for test
        // this.log('transHyperLink', param);
        isHyper = param.loginInfo.devMode == 'parent' || (param.sender && param.sender.hid == param.loginInfo.hid && param.sender.devMode == 'parent');
      }
      else {
        isHyper = true;
      }

      text = href && isHyper ? `<a href="${href}" target="_blank" (click)="guideMan.link($event)">${text}</a>` : text;
      return isBold ? `<span class="font-bold">${text}</span>` : text;
    });
  }

  checkTimerMap = {};
  checkTimer(param: { id: string, interval: number, callback?: () => void }) {
    this.zone.runOutsideAngular(() => {
      const timer = this.checkTimerMap[param.id];
      if (timer) {
        clearInterval(timer.timerId);
      }
      if (param.interval > 0) {
        const timerID = param.id;
        this.checkTimerMap[param.id] = {
          id: param.id, callback: param.callback, timerId: setTimeout(() => {
            const timer = this.checkTimerMap[timerID];
            // this.log('checkTimer', timer);
            timer.callback && timer.callback();
            this.checkTimerMap[timerID] = null;
          }, param.interval)
        };
      }
      else {
        delete this.checkTimerMap[param.id];
      }
    });
    // this.zone.runOutsideAngular(() => {
    //   if (!this.checkTimerMap[param.id]) {
    //     this.log('checkTimer create', param.id);
    //     const now = new Date().getTime();
    //     const timer = {
    //       id: param.id, interval: param.interval, callback: param.callback, startTime: now, checkTime: now, timerId: setInterval(() => {
    //         const elapsedTime = new Date().getTime() - timer.checkTime;
    //         // this.log('checkTimer setInterval', timer, elapsedTime);
    //         if (elapsedTime > timer.interval) {
    //           clearInterval(timer.timerId);
    //           timer.callback && timer.callback();
    //           delete this.checkTimerMap[param.id];
    //           // this.log('checkTimer clear', this.checkTimerMap);
    //         }
    //       }, param.interval)
    //     };
    //     this.checkTimerMap[param.id] = timer;
    //   }
    //   else {
    //     const timer = this.checkTimerMap[param.id];
    //     if (param.interval > 0) {
    //       // this.log('checkTimer check', param.id);
    //       timer.checkTime = new Date().getTime();
    //     }
    //     else {
    //       // this.log('checkTimer clear', param.id);
    //       clearInterval(timer.timerId);
    //       delete this.checkTimerMap[param.id];
    //     }
    //   }
    // });
  }

  //ionic4 new functions
  // 다음의 2개 함수는 사용할 일이 없을 듯.
  // directTranslate(key: string | Array<string>, interpolateParams?: Object): Promise<string> {
  //   return new Promise((resolve) => {
  //     const keys = [].concat(key);
  //     this.translate.get(keys, interpolateParams).subscribe(result => {
  //       resolve(keys.map(x => result[x]).join(','));
  //     });
  //   });
  // }
  // getTranslate(key: string | Array<string>, interpolateParams?: Object): Promise<string> {
  //   return new Promise((resolve) => {
  //     this.translate.get(key, interpolateParams).subscribe(result => {
  //       resolve(result);
  //     });
  //   });
  // }
  getGroupTranslate(group: string, key: string | Array<string>, interpolateParams?: Object): Promise<{ [key: string]: string; }> {
    return new Promise((resolve) => {
      const keys = [].concat(key);
      const groupKeys = keys.map(k => `${group}.${k}`);
      this.translate.get(groupKeys, interpolateParams).subscribe(result => {
        const vals = {};
        for (const key of keys) {
          vals[key] = result[`${group}.${key}`];
        }
        resolve(vals);
      });
    });
  }

  convert(d) {
    // Converts the date in d to a date-object. The input can be:
    //   a date object: returned without modification
    //  an array      : Interpreted as [year,month,day]. NOTE: month is 0-11.
    //   a number     : Interpreted as number of milliseconds
    //                  since 1 Jan 1970 (a timestamp)
    //   a string     : Any format supported by the javascript engine, like
    //                  "YYYY/MM/DD", "MM/DD/YYYY", "Jan 31 2009" etc.
    //  an object     : Interpreted as an object with year, month and date
    //                  attributes.  **NOTE** month is 0-11.
    return (
      d.constructor === Date ? d :
        d.constructor === Array ? new Date(d[0], d[1], d[2]) :
          typeof d === 'number' ? new Date(d) :
            typeof d === 'string' ? new Date(d) :
              typeof d === 'object' ? new Date(d.year, d.month, d.date) :
                NaN
    );
  }

  inRange(d, start, end) {
    // Checks if date in d is between dates in start and end.
    // Returns a boolean or NaN:
    //    true  : if d is between start and end (inclusive)
    //    false : if d is before start or after end
    //    NaN   : if one or more of the dates is illegal.
    // NOTE: The code inside isFinite does an assignment (=).
    return (
      isFinite(d = this.convert(d).valueOf()) &&
        isFinite(start = this.convert(start).valueOf()) &&
        isFinite(end = this.convert(end).valueOf()) ?
        start <= d && d <= end :
        NaN
    );
  }

  styleFromElementRef(elementRef: ElementRef) {
    const element = elementRef && (elementRef.nativeElement || elementRef['el']);
    return element && element.style;
  }

  verifyObject(obj: any, interval: number = 100, limit: number = 1000): Promise<any> {
    return new Promise((resolve, reject) => {
      if (obj) {
        resolve(undefined);
        return;
      }
      const startTime = new Date().getTime();
      const timer = setInterval(() => {
        if (obj) {
          resolve(undefined);
          return;
        }
        if ((limit > 0) && (new Date().getTime() - startTime > limit)) {
          clearInterval(timer);
          reject();
        }
      }, interval);
    });
  }

  styleStripeColor(color, lightOpacity = 1, darkOpacity = 0.9): string {
    const lightColor = this.hexToRgb(color, lightOpacity);
    const darkenColor = this.hexToRgb(color, darkOpacity);
    return `repeating-linear-gradient( 45deg, ${lightColor}, ${lightColor} 10px, ${darkenColor} 10px, ${darkenColor} 20px )`;
  }
  colours = {
    "aliceblue": "#f0f8ff", "antiquewhite": "#faebd7", "aqua": "#00ffff", "aquamarine": "#7fffd4", "azure": "#f0ffff",
    "beige": "#f5f5dc", "bisque": "#ffe4c4", "black": "#000000", "blanchedalmond": "#ffebcd", "blue": "#0000ff", "blueviolet": "#8a2be2", "brown": "#a52a2a", "burlywood": "#deb887",
    "cadetblue": "#5f9ea0", "chartreuse": "#7fff00", "chocolate": "#d2691e", "coral": "#ff7f50", "cornflowerblue": "#6495ed", "cornsilk": "#fff8dc", "crimson": "#dc143c", "cyan": "#00ffff",
    "darkblue": "#00008b", "darkcyan": "#008b8b", "darkgoldenrod": "#b8860b", "darkgray": "#a9a9a9", "darkgreen": "#006400", "darkkhaki": "#bdb76b", "darkmagenta": "#8b008b", "darkolivegreen": "#556b2f",
    "darkorange": "#ff8c00", "darkorchid": "#9932cc", "darkred": "#8b0000", "darksalmon": "#e9967a", "darkseagreen": "#8fbc8f", "darkslateblue": "#483d8b", "darkslategray": "#2f4f4f", "darkturquoise": "#00ced1",
    "darkviolet": "#9400d3", "deeppink": "#ff1493", "deepskyblue": "#00bfff", "dimgray": "#696969", "dodgerblue": "#1e90ff",
    "firebrick": "#b22222", "floralwhite": "#fffaf0", "forestgreen": "#228b22", "fuchsia": "#ff00ff",
    "gainsboro": "#dcdcdc", "ghostwhite": "#f8f8ff", "gold": "#ffd700", "goldenrod": "#daa520", "gray": "#808080", "green": "#008000", "greenyellow": "#adff2f",
    "honeydew": "#f0fff0", "hotpink": "#ff69b4",
    "indianred ": "#cd5c5c", "indigo": "#4b0082", "ivory": "#fffff0", "khaki": "#f0e68c",
    "lavender": "#e6e6fa", "lavenderblush": "#fff0f5", "lawngreen": "#7cfc00", "lemonchiffon": "#fffacd", "lightblue": "#add8e6", "lightcoral": "#f08080", "lightcyan": "#e0ffff", "lightgoldenrodyellow": "#fafad2",
    "lightgrey": "#d3d3d3", "lightgreen": "#90ee90", "lightpink": "#ffb6c1", "lightsalmon": "#ffa07a", "lightseagreen": "#20b2aa", "lightskyblue": "#87cefa", "lightslategray": "#778899", "lightsteelblue": "#b0c4de",
    "lightyellow": "#ffffe0", "lime": "#00ff00", "limegreen": "#32cd32", "linen": "#faf0e6",
    "magenta": "#ff00ff", "maroon": "#800000", "mediumaquamarine": "#66cdaa", "mediumblue": "#0000cd", "mediumorchid": "#ba55d3", "mediumpurple": "#9370d8", "mediumseagreen": "#3cb371", "mediumslateblue": "#7b68ee",
    "mediumspringgreen": "#00fa9a", "mediumturquoise": "#48d1cc", "mediumvioletred": "#c71585", "midnightblue": "#191970", "mintcream": "#f5fffa", "mistyrose": "#ffe4e1", "moccasin": "#ffe4b5",
    "navajowhite": "#ffdead", "navy": "#000080",
    "oldlace": "#fdf5e6", "olive": "#808000", "olivedrab": "#6b8e23", "orange": "#ffa500", "orangered": "#ff4500", "orchid": "#da70d6",
    "palegoldenrod": "#eee8aa", "palegreen": "#98fb98", "paleturquoise": "#afeeee", "palevioletred": "#d87093", "papayawhip": "#ffefd5", "peachpuff": "#ffdab9", "peru": "#cd853f", "pink": "#ffc0cb", "plum": "#dda0dd", "powderblue": "#b0e0e6", "purple": "#800080",
    "rebeccapurple": "#663399", "red": "#ff0000", "rosybrown": "#bc8f8f", "royalblue": "#4169e1",
    "saddlebrown": "#8b4513", "salmon": "#fa8072", "sandybrown": "#f4a460", "seagreen": "#2e8b57", "seashell": "#fff5ee", "sienna": "#a0522d", "silver": "#c0c0c0", "skyblue": "#87ceeb", "slateblue": "#6a5acd", "slategray": "#708090", "snow": "#fffafa", "springgreen": "#00ff7f", "steelblue": "#4682b4",
    "tan": "#d2b48c", "teal": "#008080", "thistle": "#d8bfd8", "tomato": "#ff6347", "turquoise": "#40e0d0",
    "violet": "#ee82ee",
    "wheat": "#f5deb3", "white": "#ffffff", "whitesmoke": "#f5f5f5",
    "yellow": "#ffff00", "yellowgreen": "#9acd32"
  };
  hexToRgb(color: string, alpha?: number, mergeBackColor?: string) {
    color = color || 'black';
    // console.log('hexToRgb', color);
    let hex = this.colours[color];
    hex = hex || color;
    if (hex[0] == '#') hex = hex.substr(1);
    const r = parseInt(hex.slice(0, 2), 16), g = parseInt(hex.slice(2, 4), 16), b = parseInt(hex.slice(4, 6), 16);
    // console.log('hexToRgb', { hex, r, g, b });
    if (alpha) {
      if (!mergeBackColor) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      }
      else {
        const br = parseInt(mergeBackColor.slice(1, 3), 16), bg = parseInt(mergeBackColor.slice(3, 5), 16), bb = parseInt(mergeBackColor.slice(5, 7), 16);
        return `rgb(${(1 - alpha) * br + alpha * r}, ${(1 - alpha) * bg + alpha * g}, ${(1 - alpha) * bb + alpha * b})`;
      }
    } else {
      return `rgb(${r}, ${g}, ${b})`;
    }
  }

  trimColor(s) {
    return (s.charAt(0) == '#') ? s.substring(1, 7) : s
  }
  toColorHex(rgb) {
    return '#'
      + ('0' + parseInt(rgb[0]).toString(16)).slice(-2)
      + ('0' + parseInt(rgb[1]).toString(16)).slice(-2)
      + ('0' + parseInt(rgb[2]).toString(16)).slice(-2);
  }
  convertToRGB(hex) {
    const color = [];
    color[0] = parseInt((this.trimColor(hex)).substring(0, 2), 16);
    color[1] = parseInt((this.trimColor(hex)).substring(2, 4), 16);
    color[2] = parseInt((this.trimColor(hex)).substring(4, 6), 16);
    return color;
  }

  generateColor(colorStart, colorEnd, colorCount) {
    const start = this.convertToRGB(colorStart);  // The beginning of your gradient
    const end = this.convertToRGB(colorEnd);      // The end of your gradient

    //Alpha blending amount
    const alphaLevel = colorCount == 1 ? 0 : 1.0 / (colorCount - 1);
    const saida = [];
    for (let i = 0; i < colorCount; i++) {
      const c = [];
      const alpha = i * alphaLevel;

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];
      // this.log('util.generateColor', start, end, c, this.toColorHex(c));
      saida.push(this.toColorHex(c));
    }
    return saida.reverse();
  }

  setTimeout(func, milis?: number, insideAngular?: boolean) {
    insideAngular = insideAngular !== undefined ? insideAngular : true;
    if (insideAngular) {
      this.zone.run(() => setTimeout(func, milis));
    }
    else {
      this.zone.runOutsideAngular(() => setTimeout(func, milis));
    }
  }

  asyncWait(milis: number): Promise<any> {
    return new Promise(resolve => {
      this.setTimeout(() => resolve(undefined), milis);
    });
  }

  debugCount = 0;
  getDebugCount() {
    return this.debugCount++;
  }

  transHourMinutes(date: string) {
    if (date && date.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+/)) {
      const dateTime = new Date(date);
      date = `${dateTime.getHours().pad(2)}:${dateTime.getMinutes().pad(2)}`;
    }
    return date;
  }

  getAge(date: Date) {
    if (!date) return 0;
    const ageDifMs = Date.now() - new Date(date).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  date = {
    max: (a: Date, b: Date) => {
      if (!a) return b;
      if (!b) return a;
      return a.getTime() >= b.getTime() ? a : b;
    },
    min: (a: Date, b: Date) => {
      if (!a) return a;
      if (!b) return b;
      return a.getTime() >= b.getTime() ? b : a;
    }
  }

  isMobileApp() {
    // chrome 모바일 모드는 mobileweb mode 이다. cordova(android, ios) 로 하면 해당되지 않는다.
    // console.log('isMobile', this.platform.platforms());
    return this.platform.is('capacitor') || (this.isDebugMode() && ((this.platform.is('android') || this.platform.is('iphone'))));
  }

  isMobileWeb() {
    return this.platform.is('mobileweb') && !this.platform.is('tablet') && (this.platform.is('android') || this.platform.is('ios'));
  }

  isIosTabletWeb() {
    return this.platform.is('mobileweb') && this.platform.is('tablet') && this.platform.is('ipad') && this.platform.is('ios');
  }

  getInitialText(text) {
    // console.log('getInitialText', text);
    let initialText = '';
    for (let i = 0, n = text.length; i < n; i++) {
      const value = text[i];
      let chosung = '';
      if (value == 'ㄱ' || (value >= '가' && value <= '깋')) chosung = 'ㄱ';
      else if (value == 'ㄲ' || (value >= '까' && value <= '낗')) chosung = 'ㄲ';
      else if (value == 'ㄴ' || (value >= '나' && value <= '닣')) chosung = 'ㄴ';
      else if (value == 'ㄷ' || (value >= '다' && value <= '딯')) chosung = 'ㄷ';
      else if (value == 'ㄸ' || (value >= '따' && value <= '띻')) chosung = 'ㄸ';
      else if (value == 'ㄹ' || (value >= '라' && value <= '맇')) chosung = 'ㄹ';
      else if (value == 'ㅁ' || (value >= '마' && value <= '밓')) chosung = 'ㅁ';
      else if (value == 'ㅂ' || (value >= '바' && value <= '빟')) chosung = 'ㅂ';
      else if (value == 'ㅃ' || (value >= '빠' && value <= '삫')) chosung = 'ㅃ';
      else if (value == 'ㅅ' || (value >= '사' && value <= '싷')) chosung = 'ㅅ';
      else if (value == 'ㅆ' || (value >= '싸' && value <= '앃')) chosung = 'ㅆ';
      else if (value == 'ㅇ' || (value >= '아' && value <= '잏')) chosung = 'ㅇ';
      else if (value == 'ㅈ' || (value >= '자' && value <= '짛')) chosung = 'ㅈ';
      else if (value == 'ㅉ' || (value >= '짜' && value <= '찧')) chosung = 'ㅉ';
      else if (value == 'ㅊ' || (value >= '차' && value <= '칳')) chosung = 'ㅊ';
      else if (value == 'ㅋ' || (value >= '카' && value <= '킿')) chosung = 'ㅋ';
      else if (value == 'ㅌ' || (value >= '타' && value <= '팋')) chosung = 'ㅌ';
      else if (value == 'ㅍ' || (value >= '파' && value <= '핗')) chosung = 'ㅍ';
      else if (value == 'ㅎ' || (value >= '하' && value <= '힣')) chosung = 'ㅎ';
      else {
        chosung = value.toUpperCase();
      }
      initialText += chosung;
    }
    return initialText;
  }

  similarText(targetText: string, searchText: string): boolean {
    if (!targetText || !searchText || typeof targetText !== 'string' || targetText.length < searchText.length) return false;

    const targetInital = this.getInitialText(targetText);
    // console.log('similarText', targetText, searchText, targetInital);
    let searchIndex = 0;
    for (let i = 0; i < targetText.length; i++) {
      if (targetText[i] == searchText[searchIndex] || targetInital[i] == searchText[searchIndex].toUpperCase()) {
        searchIndex++;
        if (searchIndex == searchText.length) return true;
      }
      else {
        searchIndex = 0;
      }
    }
    return false;
  }

  isDBFunction(fnName: string) {
    return ['current_timestamp()'].includes(fnName);
  }
  makeInsertQuery(args: { table: string, fieldValues: { [key: string]: any } }) {
    // this.log('makeInsertQuery', args);
    const { table, fieldValues } = args;
    if (!table || !fieldValues) {
      return '';
    }

    const fields: Array<string> = [];
    for (const field in fieldValues) {
      const value = fieldValues[field];
      if (value === undefined) continue;
      fields.push(field);
    }

    return `INSERT INTO ${table} (${fields.join(', ')}) VALUES (${this.makeInsertParamters({ fieldValues })});`;
  }
  makeUpdateQuery(args: { table: string, fieldValues: { [key: string]: any }, where?: { [key: string]: Array<any> | Date | string | number | null } }) {
    // console.log('makeUpdateQuery', args);
    const { table, fieldValues, where } = args;
    if (!table || !fieldValues || !where) {
      return '';
    }
    const sets: Array<string> = [];
    for (const field in fieldValues) {
      const value = fieldValues[field];
      if (value === undefined) continue;

      if (typeof value === 'string') {
        if (this.isDBFunction(value)) sets.push(`${field}=${value.trim()}`);
        else sets.push(`${field}='${value.trim()}'`);
      }
      else if (typeof value === 'number') {
        sets.push(`${field}=${value}`);
      }
      else if (typeof value === 'boolean') {
        sets.push(`${field}=${value}`);
      }
      else if (value instanceof Date) {
        sets.push(`${field}='${value.format('yyyy-MM-dd HH:mm:ss')}'`);
      }
      else {
        continue;
      }
    }
    return `UPDATE ${table} SET ${sets.join(', ')} WHERE ${this.makeWhereParamters(where)}`;
  }
  makeDeleteQuery(args: { table: string, where: { [key: string]: Date | string | number } }) {
    // this.log('makeInsertQuery', args);
    const { table, where } = args;
    if (!table || !where) {
      return '';
    }
    return `DELETE FROM ${table} WHERE ${this.makeWhereParamters(where)}`;
  }
  makeInsertParamters(args: { fieldValues: { [key: string]: any }, applyNull?: boolean }) {
    // console.log('makeInsertParamters', args);
    const { fieldValues, applyNull } = args;
    if (!fieldValues) {
      return '';
    }

    const values: Array<string> = [];
    for (const field in fieldValues) {
      const value = fieldValues[field];
      if (!applyNull && (value === undefined || value === null)) continue;

      if (value instanceof Date) {
        values.push(`'${value.format('yyyy-MM-dd HH:mm:ss')}'`);
      } else if (value === 'now()' || value === 'NOW()') {
        values.push('NOW()');
      }
      else if (typeof value === 'string') {
        if (this.isDBFunction(value)) values.push(`${value.trim()}`);
        else values.push(`'${value.trim()}'`);
      }
      else if (typeof value === 'number') {
        values.push(`${value}`);
      }
      else if (typeof value === 'boolean') {
        values.push(`${value}`);
      }
      else if (value === undefined || value === null) {
        values.push('NULL');
      }
    }
    // console.log('makeInsertParamters', values.join(', '));
    return values.join(', ');
  }
  makeWhereParamters(where: { [key: string]: Array<any> | Date | string | number | null }) {
    // console.log('makeWhereParamters', where);
    const conditions: Array<string> = [];
    for (const key of Object.keys(where)) {
      const value = where[key];
      if (Array.isArray(value)) {
        conditions.push(`${key} IN (${value.join(',')})`);
      }
      else if (value instanceof Date) {
        conditions.push(`${key}='${value.format('yyyy-MM-dd HH:mm:ss')}'`);
      }
      else if (typeof value === 'string') {
        if (value.startsWith('like')) {
          conditions.push(`${key} ${value}`);
        } else if (value.startsWith('<') || value.startsWith('>')) {
          conditions.push(`${key} ${value}`);
        }
        else {
          conditions.push(`${key}='${value}'`);
        }
      }
      else if (typeof value === 'number') {
        conditions.push(`${key}=${value}`);
      }
    }
    return conditions.join(' AND ');
  }

  static toTimeFormat(seconds: number, format: string) {
    seconds = seconds || 0;
    format = format || 'HH:mm';
    return format.replace(/(HH|mm|ss)/gi, function ($1) {
      switch ($1) {
        case 'HH': return Math.floor(seconds / 3600).pad(2);
        case 'mm': return Math.floor(seconds % 3600 / 60).pad(2);
        case 'ss': return Math.floor(seconds % 60).pad(2);
        default: return $1;
      }
    });
  }

  static toTimeFormatMinute(seconds: number) {
    const minute = Math.floor(seconds / 60);
    const second = Math.floor(seconds % 60).pad(2);
    return `${minute}:${second}`;
  }

  addingFraction(num1: { numerator: number, denominator: number }, num2: { numerator: number, denominator: number }) {
    if (num1.denominator === num2.denominator) {
      const d = num1.denominator;
      const n = num1.numerator + num2.numerator;
      const GCD = this.getGCD(d, n);
      return {
        numerator: n / GCD,
        denominator: d / GCD
      }
    }
    const d = num1.denominator * num2.denominator;
    const n = num1.numerator * num2.denominator + num2.numerator * num1.denominator;
    const GCD = this.getGCD(d, n);
    return {
      numerator: n / GCD,
      denominator: d / GCD
    }
  }

  private getGCD(num1: number, num2: number) {
    const minNum = num1 > num2 ? num2 : num1;
    let result = 1;
    for (let i = 1; i <= minNum; i++) {
      result = num1 % i === 0 && num2 % i === 0 ? i : result;
    }
    return result;
  }

  // 숫자 유무 판단
  isNumeric(value) {
    let testValue: any = new String(value);
    testValue = testValue.replace(/^\s*|\s*$/g, ''); // 좌우 공백 제거
    if (testValue == null || testValue == '' || isNaN(testValue)) return false;
    return true;
    // return /^-?\d+$/.test(value);
  }

  genPath(...args) {
    // console.log('genPath', args);
    let path = [];
    for (const arg of args) {
      path = path.concat(arg.split('/').filter(p => !!p));
    }
    return path.join('/');
  }

  makeUpdates(path, obj) {
    const updates = {};
    for (const key in obj) {
      if (['createAt', 'updateAt'].includes(key) || obj[key] === null || typeof obj[key] !== 'object') {
        updates[`${path}/${key}`] = obj[key];
      }
      else {
        const childUpdates = this.makeUpdates(`${path}/${key}`, obj[key]);
        for (const updateKey in childUpdates) {
          updates[updateKey] = childUpdates[updateKey];
        }
      }
    }
    return updates;
  }

  getWordTokens(value: any, minLength: number = 2): any {
    const result = {};
    if (!value) return result;

    if (typeof value == 'string') {
      const words = value.split(/[`~!@#$%^&*|\\'";:/?.\-[\](){}\s]/);
      for (const word of words) {
        for (let i = minLength; i <= word.length; i++) {
          result[word.substring(0, i)] = true;
        }
      }
    }
    else {
      for (const key in value) {
        Object.assign(result, this.getWordTokens(value[key]));
      }
    }
    return result;
  }

  instantAll(obj: any, prefix?: string, alwaysSetPrefix = true) {
    if (obj) {
      switch (typeof obj) {
        case 'object': {
          if (Array.isArray(obj)) {
            obj = obj.map(child => this.instantAll(child, prefix, alwaysSetPrefix));
          }
          else {
            for (const key in obj) obj[key] = this.instantAll(obj[key], prefix, alwaysSetPrefix);
          }
          break;
        }
        case 'string': {
          const translatedObj = this.translate.instant(prefix ? `${prefix}.${obj}` : obj);
          if (translatedObj !== `${prefix}.${obj}`) {
            obj = translatedObj;
          } else {
            obj = alwaysSetPrefix ? translatedObj : obj;
          }
          break;
        }
      }
    }
    // console.log("reportTextLabel instantAll", obj);

    return obj;
  }

  utcToLocalHourMin(hourmin: string) {
    const hourmins = hourmin.split(':').map(s => parseInt(s));
    const privacyStartTime = new Date(2000, 0, 0);
    privacyStartTime.setUTCHours(hourmins[0]);
    privacyStartTime.setUTCMinutes(hourmins[1]);
    return privacyStartTime.format('HH:mm');
  }
  localToUtcHourMin(hourmin: string) {
    const hourmins = hourmin.split(':').map(s => parseInt(s));
    const privacyStartTime = new Date(2000, 0, 0);
    privacyStartTime.setHours(hourmins[0]);
    privacyStartTime.setMinutes(hourmins[1]);
    return privacyStartTime.format('HH:mm', true);
  }

  getSafeData = (obj: any, path: string, def?: any): any => {
    let value;
    if (!obj) return def;

    value = obj;
    const keys = path.split('/');
    for (const key of keys) {
      value = value[key];
      if (value === undefined) { return def; }
    }
    return value;
  }

  checkChildNode = (obj: any) => {
    if (obj === null || obj === undefined) {
      return false;
    }
    else if (Array.isArray(obj)) {
      for (const child of obj) {
        if (!this.checkChildNode(child)) return false;
      }
    }
    else if (typeof obj == 'string') {
      return obj.length > 0;
    }
    else if (typeof obj == 'object') {
      for (const key of Object.keys(obj)) {
        const child = obj[key];
        if (!this.checkChildNode(child)) return false;
      }
    }
    return true;
  }

  getExactDecimal(x: number, fractionDigits: number) {
    return Math.round(x * Math.pow(10, fractionDigits)) / Math.pow(10, fractionDigits);
  }

  filterFireData(obj: Object) {
    if (!obj) return obj;
    const returnObj: Object = {};
    for (const key of Object.keys(obj)) {
      if (key.startsWith('$')) continue;
      returnObj[key] = obj[key];
    }
    return returnObj;
  }

  getWeekdays(): Array<string> {
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  }

  hasChild(obj: any) {
    for (const _ in obj) return true;
    return false;
  }

  isOverlapRange<T>(lhs: { start: T, end: T }, rhs: { start: T, end: T }, includingTouch: boolean = false): boolean {
    if (includingTouch) {
      // return lhs.start <= rhs.end && lhs.end >= rhs.start;
      return !(lhs.start > rhs.end || rhs.start > lhs.end);
    } else {
      return (lhs.start === rhs.end && lhs.end === rhs.start) || (lhs.start < rhs.end && rhs.start < lhs.end);
    }
  }

  singleValue = {};
  singleSubject = {};
  getSingleObj(args: { method: string, reqFunc: Function, refresh?: boolean }): Promise<any> {
    const { method, reqFunc, refresh } = args;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (this.singleValue[method] && !refresh) {
        resolve(this.singleValue[method]);
        return;
      }

      const singleSubject = this.singleSubject[method] || new Subject();
      singleSubject.subscribe(item => {
        // this.log('myInfoSubject', item);
        resolve(item);
      }, error => {
        // this.log('myInfoSubject.error', error);
        reject(error);
      });
      if (this.singleSubject[method]) return;
      this.singleSubject[method] = singleSubject;

      this.singleValue[method] = await reqFunc();
      this.singleSubject[method].next(this.singleValue[method]);
      this.singleSubject[method].complete();
      this.singleSubject[method] = null;
    });
  }

  currencyFormat(num: any): string {
    return (num || 0).currencyFormat();
  }

  jsonToString(data: any) {
    return JSON.stringify(data);
  }

  unsorted() { } // keyvalue unsorted로 사용
  getUserDivs(user: any) {
    if (user.pDiv) {
      const divs = user.pDiv.split('･');
      return divs.filter(d => ['PT', 'GX', 'HEALTH'].includes(d)).map(d => d == 'HEALTH' ? this.translate.instant('건강.헬스') : d).join('･');
    }
    return '';
  }

  isCurrentLangKo() {
    return this.translate.currentLang == 'ko';
  }

  getCountryCode() {
    const timezone = !localStorage.gymTimeZone ? Intl.DateTimeFormat().resolvedOptions().timeZone : localStorage.gymTimeZone;
    const countryCode = getTimeZones().find((tz) => {
      return tz.name === timezone || tz.group.includes(timezone);
    }).countryCode.toLowerCase();

    return countryCode;
  }

  changeIncludedCountry() {
    const fittServiceCountry = ['kr', 'us'];
    const countryCode = this.getCountryCode();

    return fittServiceCountry.includes(countryCode) ? countryCode : 'kr';
  }

  getTranslateImageRoute(imageName: string, extension = 'png', isOnlyWebImage: boolean = false, libraryAsset?: 'fitt' | 'share') {
    let translateImageRoute: string;
    if (libraryAsset) {
      translateImageRoute = libraryAsset === 'fitt' ? 'assets/fitt-lib' : 'assets/shared-lib'
      translateImageRoute = isOnlyWebImage ? translateImageRoute + "/web/translateImg/" : translateImageRoute + "/img/translateImg/";
      translateImageRoute = (this.isCurrentLangKo() ? translateImageRoute + 'ko/' + imageName : translateImageRoute + 'en/' + imageName) + '.' + extension;
    } else {
      translateImageRoute = isOnlyWebImage ? "assets/web/translateImg/" : "assets/img/translateImg/";
      translateImageRoute = (this.isCurrentLangKo() ? translateImageRoute + 'ko/' + imageName : translateImageRoute + 'en/' + imageName) + '.' + extension;
    }
    return translateImageRoute;
  }

  isDisplayUnitKm() {
    if (localStorage.displayUnit) {
      return localStorage.displayUnit == 'km';
    } else {
      const language = localStorage.selectedLanguage || this.getLanguageCode(navigator.language);
      return language === 'ko' ? true : false;
    }
  }

  getDisplayUnitStr(displayUnitStr: 'km' | 'cm' | 'kg' | 'm' | 'm/ft' | 'km/h' | '2.4km' | '2,000m' | string) {

    const isDisplayUnitStr = ['km', 'cm', 'kg', 'm', 'm/ft', 'km/h', '2.4km', '2,000m'].indexOf(displayUnitStr) < 0;

    if (isDisplayUnitStr) {
      return displayUnitStr;
    } else {
      if (this.isDisplayUnitKm()) {
        if (displayUnitStr === 'm/ft') return 'm'
        return displayUnitStr;
      } else {
        switch (displayUnitStr) {
          case 'km':
          case 'm':
            return 'mi';
          case 'km/h':
            return 'mi/h'
          case 'kg': return 'lbs';
          case 'cm': return 'ft';
          case 'm/ft': return 'ft';
          case '2.4km':
            return '1.5mile'
          case '2,000m':
            return '1.24mile'
        }
      }
    }
  }

  isValidAccountWithPaypalPay(subsDetailRst) {
    let reason = '';
    let pageToGo = '';
    if (subsDetailRst.status === 'ACTIVE' || subsDetailRst.status === 'APPROVED' || subsDetailRst.status === 'SUSPENDED') {
      const billingInfo = subsDetailRst.billing_info;
      // const lastPayTime = new Date(billingInfo.last_payment.time.split('T')[0]);
      const nextPayTime = new Date(billingInfo.next_billing_time.split('T')[0]);
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      const todayDate = yyyy + mm + dd
      const nowTime = new Date(todayDate);
      console.log('isValidAccountWithPaypalPay nextPayTime = ', nextPayTime.getTime());
      console.log('isValidAccountWithPaypalPay nowTime = ', nowTime.getTime());
      if (nextPayTime.getTime() < nowTime.getTime()) {
        reason = `Check on login, it change SUSPENDED to CANCELLED`;
        pageToGo = 'payment-main/info';
      } else {
        reason = '';
        pageToGo = 'web-main/exercise-test/main';
      }
    } else if (subsDetailRst.status === 'CANCELLED' || subsDetailRst.status === 'EXPIRED') {
      reason = `Check on login, it already ${subsDetailRst.status}`;
      pageToGo = 'payment-main/info';
    } else {
      reason = '';
      pageToGo = 'payment-main/info';
    }
    return { reason: reason, pageToGo: pageToGo }
  }

  encryptUsingAES256(data: string) {
    const _key = CryptoJS.enc.Utf8.parse('F!ttD2v2l0p2rS00');
    const _iv = CryptoJS.enc.Utf8.parse('F!ttD2v2l0p2rS00');
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    encrypted = encrypted.toString();

    return encrypted;
  }

  decryptUsingAES256(encrpyData: string) {
    const _key = CryptoJS.enc.Utf8.parse('F!ttD2v2l0p2rS00');
    const _iv = CryptoJS.enc.Utf8.parse('F!ttD2v2l0p2rS00');
    const decrypted = CryptoJS.AES.decrypt(
      encrpyData, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);

    return decrypted;
  }

  async alertCreate(param: any) {
    if (param.title) {
      param.header = param.title;
    }
    param.header && this.translate.get(param.header).subscribe(tVal => param.header = tVal);
    param.subHeader && this.translate.get(param.subHeader).subscribe(tVal => param.subHeader = tVal);
    param.message && this.translate.get(param.message, param.messageParam).subscribe(tVal => param.message = tVal);
    if (param.buttons) {
      for (let button of param.buttons) {
        if (typeof button == 'string') {
          button = { text: button };
        }
        button.text && this.translate.get(button.text).subscribe(tVal => button.text = tVal);
      }
    }
    else {
      param.buttons = [{ role: 'ok', text: 'ok' }];
    }
    if (param.inputs) {
      for (const input of param.inputs) {
        input.label && this.translate.get(input.label).subscribe(tVal => input.label = tVal);
        input.placeholder && this.translate.get(input.placeholder).subscribe(tVal => input.placeholder = tVal);
      }
    }
    param.cssClass = 'alert-popup paragraph';
    param.mode = !this.isMobileApp() || this.platform.is('android') ? 'md' : 'ios';
    const alert = await this.alertCtrl.create(param);
    await alert.present();
    return alert;
  }

  async errorPopup(error): Promise<any> {
    if (!error) {
      console.error('errorPopup', 'error is null');
      return;
    }
    console.warn('errorPopup', error);

    let message;
    if (error.code) {
      this.translate.get(`errorMsg.${error.code}`).subscribe(tVal => message = tVal);
    }
    else if (error.status && error.url && error.error) {  // http response 인경우 depth 하나 아래의 message를 사용한다.
      message = `status: ${error.status}`;
      if (error.error.message) message += `\nmessage: ${error.error.message}`;
    }
    else if (error.message) {
      if (error.message.indexOf('Http failure response') >= 0) {
        error.message = 'noServerResponse';
      }
      this.translate.get(error.message).subscribe(tVal => message = tVal);
    }
    else {
      message = error;
    }

    const alert = await this.alertCreate({
      header: this.translate.instant('error'),
      message: message, buttons: [{ text: this.translate.instant('ok') }]
    });
    return alert.present();
  }

  async showLoadingPopup(show: boolean) {
    if (this.isShowLoadingPopup == show) {
      // console.log('showLoadingPopup show cancel', show);
      return;
    }
    // console.warn('showLoadingPopup', show);

    this.isShowLoadingPopup = show;
    if (show) {
      this.loadingPopup = await this.loadingCtrl.create({ spinner: 'crescent' });
      await this.loadingPopup.present();
      if (!this.isShowLoadingPopup && this.loadingPopup) {
        // console.log('showLoadingPopup dismiss now');
        this.loadingPopup.dismiss();
        this.loadingPopup = null;
      }
    }
    else {
      if (this.loadingPopup) {
        this.loadingPopup.dismiss();
        this.loadingPopup = null;
      }
    }
  }

  linkSNS(name) {
    const open = (url: string) => {
      if (this.platform.is('capacitor') && this.platform.is('ios')) {
        this.iab.create(url, '_target');
      } else {
        window.open(url);
      }
    }

    switch (name) {
      case 'facebook':
        open('https://www.facebook.com/fitt.kr/');
        break;
      case 'instagram':
        this.isCurrentLangKo() ? open('https://www.instagram.com/startup_fitt/?hl=ko') : open('https://www.instagram.com/startup_fitt/?hl=en');
        break;
      case 'youtube':
        open('https://www.youtube.com/channel/UCJbI7GXEEHTk2P9bzL7pfyA');
        break;
      case 'blog':
        open('https://blog.naver.com/startup_fitt');
        break;
      case 'playstore':
        open('https://play.google.com/store/apps/details?id=kr.fitt.fitt4');
        break;
      case 'appstore':
        this.isCurrentLangKo() ? open('https://apps.apple.com/kr/app/id1490531630') : open('https://apps.apple.com/app/apple-store/id1490531630');
        break;
    }
  }

  getSNSLink(name) {
    switch (name) {
      case 'facebook':
        return 'https://www.facebook.com/fitt.kr/'
      case 'instagram':
        return this.isCurrentLangKo() ? 'https://www.instagram.com/startup_fitt/?hl=ko' : 'https://www.instagram.com/startup_fitt/?hl=en'
      case 'youtube':
        return 'https://www.youtube.com/channel/UCJbI7GXEEHTk2P9bzL7pfyA'
      case 'playstore':
        return 'https://play.google.com/store/apps/details?id=kr.fitt.fitt4'
      case 'appstore':
        return this.isCurrentLangKo() ? 'https://apps.apple.com/kr/app/id1490531630' : 'https://apps.apple.com/app/apple-store/id1490531630'
    }
  }

  async menuOpen(menuId: string) {
    await this.menuCtrl.enable(true, menuId);
    await this.menuCtrl.open(menuId);
    // console.log('menuOpen', menuId, await this.menuCtrl.getMenus());
    // const menus = (await this.menuCtrl.getMenus()).filter(menu => menu.attributes['menu-id'].value == menuId);
    // console.log('menuOpen', menus);
    this.menuId = menuId;
  }
  async menuClose(menuId?: string) {
    // console.log('menuClose', this.menuId);
    if (this.menuId || menuId) {
      // await this.menuCtrl.close(this.menuId);
      const menus = (await this.menuCtrl.getMenus()).filter(menu => [this.menuId, menuId].includes(menu.attributes['menu-id'].value));
      menus.forEach(menu => {
        // console.log('menuClose', menu);
        menu.close();
      });
    }
  }
  async menuToggle(menuId: string) {
    // console.log('menuToggle', menuId);
    this.menuCtrl.enable(true, menuId);
    const menus = (await this.menuCtrl.getMenus()).filter(menu => menu.attributes['menu-id'].value == menuId);
    // console.log('menuToggle', menus);
    if (menus.length > 0) {
      const menu = menus[0];
      // const beforeState = await menu.isOpen()
      await menu.isOpen() ? await menu.close() : await menu.open();
      // console.log('menuToggle', `${beforeState} > ${await menu.isOpen()}`);
    }
  }

  parentElementSize(elementRef: ElementRef, width: string, height: string) {
    if (!elementRef || !elementRef.nativeElement || !elementRef.nativeElement.parentElement) return;
    const parentElement = elementRef.nativeElement.parentElement;
    if (width) parentElement.style.width = width;
    if (height) parentElement.style.height = height;
  }

  async moreButtons($event, buttons: Array<any>) {
    // 기획 상 ios 에서는 액션 시트에 취소버튼이 존재하도록 결정됨.
    if (this.platform.is('ios')) {
      buttons.push({ text: this.translate.instant('취소'), role: 'cancel', cssClass: 'red' });
    }

    if (this.isMobileApp()) {
      const actionSheet = await this.actionSheetController.create({ mode: this.platform.is('android') ? 'md' : 'ios', buttons: buttons });
      await actionSheet.present();
      return await actionSheet.onDidDismiss();
    } else {
      const popover = await this.popoverCtrl.create({ component: MenuPopOver, event: $event, componentProps: { buttons }, cssClass: 'scrollable-y' });
      await popover.present();
      const { data } = await popover.onDidDismiss();

      return { ...data };
    }
  }

  selectFile(args?: { accept: string, output?: 'url' | 'buffer' }): Promise<Array<any>> {
    const { accept, output } = args;
    return new Promise(async (resolve, reject) => {
      try {
        const inputNativeElement: any = $('#fileInput')[0];
        inputNativeElement.accept = accept;
        const filesAdded = ($event) => {
          const files = inputNativeElement.files;
          // this.log('selectFile.filesAdded', files.length);
          if (files.length == 0) {
            return;
          }

          const fileCount = files.length;
          const result = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            switch (output) {
              case 'buffer': reader.readAsArrayBuffer(file); break;
              case 'url':
              default: reader.readAsDataURL(file);
                break;
            }
            reader.onloadend = (evt: any) => {
              // this.log('selectFile.filesAdded', 'success name', file.name);
              result.push({ name: file.name, data: evt.target.result });
              if (result.length == fileCount) {
                resolve(result);
              }
            };
          }
          inputNativeElement.removeEventListener('change', filesAdded);
        };
        // Fix for IE ver < 11, that does not clear file inputs
        // Requires a sequence of steps to prevent IE crashing but
        // still allow clearing of the file input.
        if (/MSIE/.test(navigator.userAgent)) {
          // this.log('selectFile.clearFileInput', 'MSIE');
          const $frm1 = inputNativeElement.closest('form');
          if ($frm1.length) { // check if the input is already wrapped in a form
            inputNativeElement.wrap('<form>');
            const $frm2 = inputNativeElement.closest('form'), // the wrapper form
              $tmpEl = $(document.createElement('div')); // a temporary placeholder element
            $frm2.before($tmpEl).after($frm1).trigger('reset');
            inputNativeElement.unwrap().appendTo($tmpEl).unwrap();
          } else { // no parent form exists - just wrap a form element
            inputNativeElement.wrap('<form>').closest('form').trigger('reset').unwrap();
          }
        } else { // normal reset behavior for other sane browsers
          // this.log('selectFile.clearFileInput', 'normal');
          inputNativeElement.value = '';
        }

        inputNativeElement.addEventListener('change', filesAdded);
        inputNativeElement.dispatchEvent(new MouseEvent('click', { bubbles: false }));
      }
      catch (error) {
        // this.log('selectFile', error);
        reject(error);
      }
    });
  }

  selectDate(options: { date: Date, mode: DatePickerMode | 'month', minuteInterval?: number, minDate?: Date, maxDate?: Date }): Promise<Date> {
    // this.log('selectDate', options);
    const { date, mode, minDate, maxDate, } = options;
    // let minuteInterval = options.minuteInterval || 10;
    return new Promise<Date>(async (resolve, reject) => {
      if (this.platform.is('capacitor')) {
        const originDate = new Date(date).resetDate('reset', 'milisecond'); // ios error fix
        const locale = localStorage.selectedLanguage ? localStorage.selectedLanguage : navigator.language;
        // mobile 은 'month'를 지원하지 않는다.
        const options: DatePickerOptions = {
          mode: mode != 'month' ? mode : 'dateAndTime', date: originDate.toISOString(), theme: 'light', locale: locale, doneText: this.translate.instant('확인'), cancelText: this.translate.instant('취소')
        };
        if (minDate) options.min = minDate.toISOString();
        if (maxDate) options.max = maxDate.toISOString();

        DatePicker.present(options)
          .then(result => {
            const value = result.value ? new Date(result.value) : date;
            resolve(value);
          })
          .catch(err => { console.error(err); reject(err); });
      }
      else {
        const pickerType = ((mode) => {
          switch (mode) {
            case 'dateAndTime': return 'both';
            case 'date': return 'calendar';
            case 'month': return 'month';
            default: return 'timer';
          }
        })(mode);
        const modal = await this.modalCtrl.create({
          component: DatePickerModal, cssClass: 'alert-modal', componentProps: {
            pickerType, selectedDate: date, minDate, maxDate,
          }
        });
        await modal.present();
        const result = await modal.onDidDismiss();
        resolve(result && result.data);
      }
    });
  }

  selectPhoto(args?: { mediaType: 'image' | 'video' | 'all' }): Promise<any> {
    const { mediaType } = args;
    return new Promise(async (resolve, reject) => {
      try {
        if (this.platform.is('capacitor')) {
          const imageData = await this.camera.getPicture({
            sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM,
            quality: 100, targetWidth: 800, targetHeight: 800, correctOrientation: true,
            destinationType: this.camera.DestinationType.DATA_URL,
            encodingType: this.camera.EncodingType.JPEG,
            mediaType: mediaType == 'image' ? this.camera.MediaType.PICTURE : mediaType == 'video' ? this.camera.MediaType.VIDEO : this.camera.MediaType.ALLMEDIA
          });
          resolve([{ name: `${new Date().format('yyyyMMddHHmmssfff')}.jpg`, data: `data:image/jpeg;base64,${imageData}` }]);
        }
        else {
          let accept;
          if (mediaType == 'image') {
            accept = 'image/png, image/jpeg, image/gif';
          }
          resolve(await this.selectFile({ accept }));
        }
      }
      catch (error) {
        console.log('selectPhoto', error);
        reject(error);
      }
    });
  }

  takePhoto(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        if (!this.platform.is('capacitor')) {
          throw { message: 'device is not mobile' };
        }

        const imageData = await this.camera.getPicture({
          quality: 100, targetWidth: 800, targetHeight: 800, correctOrientation: true,
          destinationType: this.camera.DestinationType.DATA_URL,
          encodingType: this.camera.EncodingType.JPEG,
          mediaType: this.camera.MediaType.PICTURE
        });
        resolve([{ name: `${new Date().format('yyyyMMddHHmmssfff')}.jpg`, data: `data:image/jpeg;base64,${imageData}` }]);
      }
      catch (error) {
        console.log('selectPhoto', error);
        reject(error);
      }
    });
  }

}
