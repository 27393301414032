import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-step-progress',
  templateUrl: './ft-step-progress.component.html',
  styleUrls: ['./ft-step-progress.component.scss'],
})
export class StepProgressComponent extends BaseComponent implements OnInit {
  @Input() stepIndex: number = 0;
  @Input() text0: string;
  @Input() text1: string;
  @Input() text2: string;
  @Input() text3: string;

  constructor(
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    this.log('ngOnInit');
  }

}
