import { Overlay } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GymSettings } from '@fitt-lib/models/schedule-types';
import { IonContent, IonInput, IonItem } from '@ionic/angular';
import { GymUser, User } from '@fitt-lib/models/fitt-models';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { ShortcutService } from '@shared-lib/services/shortcut.service';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

@Component({
  selector: 'ft-user-search-item',
  templateUrl: './ft-user-search-item.html',
  styleUrls: ['./ft-user-search-item.scss'],
})
export class FtUserSearchItemComponent extends FtLibBaseComponent implements OnInit {
  @ViewChild('userSearchItem') userSearchItem: IonItem;
  @ViewChild('content') content: IonContent;
  @ViewChild('userSearchInput') userSearchInput: IonInput;

  gSeq: number;
  userList: Array<GymUser> = [];
  searchList: Array<GymUser> = [];
  searchText: string = "";
  notFound: boolean = false;
  activateIndex: number = -1;
  scrollTopIndex: number = 0;

  isLoad = false;
  @Input() selectedUser: User;
  @Output() selectedUserChanged: EventEmitter<any> = new EventEmitter<User>();
  @Input() canUserCancel: boolean;
  @Input() autoFocus: boolean = false;
  @Input() seachOnlyActivate: boolean = true;
  @Input() seachOnlyInCharge: boolean = false;

  isInputFocus = false;
  @Output() focusInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectUser: EventEmitter<any> = new EventEmitter<any>();

  windowWidth: number;
  restHeight: number = 0;
  positions: any;
  showSearchList: boolean = false;
  constructor(
    private events: RxEvents,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private shortcutService: ShortcutService,
  ) {
    super({ enableLog: false });
    this.positions = this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions([
      { originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' },
      { originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top', },
    ]).withFlexibleDimensions(true);
    // this.log('constructor', this.elementRef, this.positions);
  }

  ngOnInit() {
    this.log('ngOnInit', this.selectedUser);
    this.gSeq = this.gApi.myInfo.getGymSeq();
    this.loadUser(this.gSeq);

    if (this.autoFocus) {
      setTimeout(() => { this.userSearchInput.setFocus(); }, 500);
    }

    // this.shortcutService.register({
    //   selector: 'ft-user-search-item', type: 'element',
    //   keyEvents: [
    //     {
    //       key: 'Backspace', elementId: 'cancel-user', handler: () => {
    //         console.log('localName', document.activeElement.localName);
    //         return document.activeElement.localName != 'textarea';
    //       }
    //     },
    //   ]
    // });
  }

  ngOnDestroy() {
    this.shortcutService.unregister({
      selector: 'ft-user-search-item', type: 'element',
    });
  }

  async selectUser(user: User) {
    this.selectedUser = new User(user);
    this.isInputFocus = false;
    this.searchText = '';
    this.log('selectUser', user);
    this.selectedUserChanged.emit(this.selectedUser);
    this.onSelectUser.emit({ user: user });
  }

  inputChange(event) {
    const value = event.detail.value;
    if (this.searchText !== '' && value === '') {
      this.showSearchList = false;
    }
    this.filter(value);
  }

  filter(value) {
    this.searchText = value;

    if (!value || value.length == 0) {
      Object.assign(this.searchList, this.userList);
      return;
    }

    this.showSearchList = true;
    const results: Array<GymUser> = [];
    const searchItems = this.userList;
    for (const item of searchItems) {
      if (this.util.similarText(item.user.uName, value)) {
        results.push(item);
      }
    }

    this.searchList = results;
    this.log('filter', results.length);
    this.notFound = this.searchList.length == 0;
    if (!this.windowWidth) {
      this.calcSearchListElement();
      this.showSearchList = true;
    }
  }

  calcSearchListElement() {
    const parentElement = this.elementRef.nativeElement.parentElement;

    const parentRect: DOMRect = parentElement.getBoundingClientRect();
    // const elementRect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.windowWidth = parentRect.width;
    this.restHeight = parentRect.height - (this.elementRef.nativeElement.offsetTop + this.elementRef.nativeElement.offsetHeight);
  }

  inputFocus() {
    if (this.autoFocus) {
      this.autoFocus = false;
      return;
    }

    this.calcSearchListElement();
    this.isInputFocus = true;
    this.showSearchList = true;
    this.focusInput.emit({ focus: true });
  }

  inputBlur() {
    // this.isInputFocus = false;
  }

  async loadUser(gSeq: number) {
    const { result: gymSettings } = await this.gApi.b2b.getGymScheduleSettings({});
    const { result: gymUsers } = await this.gApi.b2b.getGymUsers({ gSeq: gSeq, tSeq: 0, });
    const sl: Array<GymUser> = [];

    this.userList = this.filterUserList(gymUsers, gymSettings);
    Object.assign(sl, this.userList);
    this.searchList = sl;
    this.isLoad = true;
  }

  filterUserList(gymUsers: Array<GymUser>, gymSettings: GymSettings): Array<GymUser> {
    let userList: Array<GymUser> = [];

    Object.assign(userList, gymUsers);
    if (this.seachOnlyActivate) {
      userList = userList.filter(u => u.user.uState == 1);
    }
    if (this.seachOnlyInCharge) {
      userList = this.fittUtil.schedule.getEnableUserList(userList, gymSettings);
    }
    return userList;
  }

  async onClickAddUser() {
    this.events.publish('userInfoModal:create');
  }

  cancelUser() {
    this.log('cancelUser');
    this.selectedUser = null;
    this.showSearchList = false;
    this.onSelectUser.emit({ user: undefined });
    setTimeout(() => { this.userSearchInput.setFocus(); }, 500);
  }

  onKeyDown($event) {
    this.log('onKeyDown', $event, $event.keyCode);
    // if (!this.selectMode) return;
    const { key, isComposing } = $event;
    this.prevEnterIsComposing = key == 'Enter' && $event.isComposing;
    switch (key) {
      case 'Tab': {   // Tab
        if (!!this.content && this.activateIndex >= 0) {
          this.selectUser(this.searchList[this.activateIndex].user);
        }
        this.showSearchList = false;
        break;
      }
      case 'Enter': {  // enter
        if ($event.isComposing) {
          // 조합키를 입력중 enter 시 isComposing: true 로 down-up 이벹트 발생 후 isComposing: false 로 down-up 이벹트 발생됨다.
          // isComposing: false에서만 동작을 진행한다.
          // $event.stopImmediatePropagation();
          $event.stopImmediatePropagation();
        }
        break;
      }
      case 'ArrowUp': {  // up
        if (!isComposing) {
          if (this.activateIndex > 0) this.activateIndex--;
          if (this.activateIndex < this.scrollTopIndex) {
            this.scrollTopIndex--;
            this.content.scrollToPoint(0, this.scrollTopIndex * 64);
          }
        }
        $event.preventDefault();
        break;
      }
      case 'ArrowDown': {  // down
        if (!isComposing) {
          if (!this.content) {
            if (!this.showSearchList) {
              this.calcSearchListElement();
              this.showSearchList = true;
            }
            return;
          }
          if (this.activateIndex < this.searchList.length - 1) this.activateIndex++;
          if (this.activateIndex > this.scrollTopIndex + 4) {
            this.scrollTopIndex++;
            this.content.scrollToPoint(0, this.scrollTopIndex * 79);
          }
          $event.preventDefault();
          break;
        }
      }
    }

    if (this.searchList.length > 0 && (this.activateIndex < 0 || this.searchList.length <= this.activateIndex)) {
      this.activateIndex = 0;
    }
  }

  prevEnterIsComposing: boolean = false;
  onKeyUp($event) {
    this.log('onKeyUp', $event, $event.keyCode);
    switch ($event.key) {
      case 'Enter': {  // enter
        if (this.prevEnterIsComposing) {
          // 조합키를 입력중 enter 시 isComposing: true 로 down-up 이벹트 발생 후 isComposing: false 로 down-up 이벹트 발생됨다.
          // isComposing: false에서만 동작을 진행한다.
          $event.stopImmediatePropagation();
          break;
        }
        if (!!this.content && this.activateIndex >= 0) {
          const gymUser = this.searchList[this.activateIndex];
          gymUser && this.selectUser(gymUser.user);
          $event.stopImmediatePropagation();
          break;
        }
        const text = this.searchText;
        this.searchText = null;
        this.activateIndex = -1;
        this.scrollTopIndex = 0;
        this.cdr.detectChanges();
        this.filter(text);
        break;
      }
      case 'Escape': {  // ESC
        if (this.showSearchList) {
          this.showSearchList = false;
          $event && $event.stopPropagation();
        }
        break;
      }
    }
  }

  onClick() {
    this.log('onClick', this.showSearchList);
    if (!this.showSearchList) {
      this.calcSearchListElement();
      this.showSearchList = true;
    }
  }
}
