<div #fmtReportRef class="report" style="position: relative; background-color:#fff; width: 741px;">
  <img id="reportBackground" [src]="util.getTranslateImageRoute('FITT_Fmt', 'png', false, 'fitt')"
    style="width:741px; height: auto; image-rendering: -webkit-optimize-contrast;" />
  <div id="test_gymid" class="font12" style="top: 33px;" [ngStyle]="{'left': util.isCurrentLangKo() ? '636px' : '658px'}"> {{gymId}}</div>



  <div class="user-info name">{{report.name}}</div>
  <div class="user-info height">{{report.height|unitConv: {from: 'cm', to: 'cm/ft', suffixUnit:true, toFixed: [0,2]} }}</div>
  <div class="user-info gender">{{report.gender === 'male' ? ('남성'|translate) : ('여성'|translate)}}</div>
  <div class="user-info indate">{{report.date|ftdate:'yyyy. MM. dd (E)'}}</div>
  <div class="user-info age">{{report.age}}</div>
  <div class="user-info weight">{{report.weight|unitConv: {from: 'kg', to: 'kg/lbs', suffixUnit:true } }} </div>
  <div class="user-info indate-time">{{report.date|ftdate:'a/p hh:mm'}}</div>
  <div class="user-info tel">{{gymUser.user.uTel ? gymUser.user.uTel.phoneFormat() : '-'}}</div>

  <div id="test_1" class="font15 bold" style="top: 196px; left: 16px; width: 84px;"> {{report.movability}}</div>
  <div id="test_2" class="font15 bold" style="top: 196px; left: 100px; width: 70px;"> {{report.stability}}</div>
  <div id="test_3" class="font15 bold" style="top: 196px; left: 172px; width: 94px;"> {{report.motorControl}}</div>
  <div id="test_4" class="font15 bold" style="top: 196px; left: 266px; width: 98px;"> {{report.asymmetryList.length != 0 ?
    report.asymmetryList.join(', ') : 'No'}}</div>
  <div id="test_5" class="font15 bold" style="top: 196px; left: 364px; width: 104px;"> {{report.shoulderPain}}</div>
  <div id="test_6" class="font15 bold" style="top: 196px; left: 468px; width: 76px;"> {{report.corePain}}</div>
  <div id="test_7" class="font15 bold" style="top: 196px; left: 544px; width: 88px;"> {{report.cervicalPain}}</div>
  <div id="test_8" class="font15 bold" style="top: 196px; left: 632px; width: 94px;"> {{report.fmtScore}}/{{report.maxScore}}</div>

  <div id="test_9" class="font25 bold" style="top: 320px; left: 220px; text-align: left;">
    {{report.shoulderResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px; display: block; margin: -6px 0 0;">
      {{report.shoulderResult.status === 'Pain Bad' ? 'Bad' : report.shoulderResult.status}}
      <span style="font-size: 10px;" *ngIf="report.shoulderResult.status === 'Pain Bad'">(Pain)</span>
    </span>
  </div>
  <div id="test_10" class="font25 bold" style="top: 304px; left: 504px;">
    {{report.cervicalResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px;">
      {{report.cervicalResult.status === 'Pain Bad' ? 'Bad' : report.cervicalResult.status}}
      <span style="font-size: 10px;" *ngIf="report.cervicalResult.status === 'Pain Bad'">(Pain)</span>
    </span>
  </div>
  <div id="test_11" class="font25 bold" style="top: 416px; left: 550px; width: 176px;">
    {{report.motorControlResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px;">{{report.motorControlResult.status}}</span>
  </div>
  <div id="test_12" class="font25 bold" style="top: 576px; left: 16px; width: 176px;">
    {{report.thoracicResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px;">{{report.thoracicResult.status}}</span>
  </div>
  <div id="test_13" class="font25 bold" style="top: 804px; left: 228px; text-align: left;">
    {{report.coreResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px; display: block; margin: -6px 0 0;">
      {{report.coreResult.status === 'Pain Bad' ? 'Bad' : report.coreResult.status}}
      <span style="font-size: 10px;" *ngIf="report.coreResult.status === 'Pain Bad'">(Pain)</span>
    </span>
  </div>
  <div id="test_14" class="font25 bold" style="top: 728px; left: 550px; width: 176px;">
    {{report.pelvisLegResult.percent|number:'0.0-0'}}%
    <span style="font-size: 18px;">{{report.pelvisLegResult.status}}</span>
  </div>
  <div id="test_15" class="font25 bold" style="top: 976px; left: 580px; width: 148px;">
    {{report.fmtScore / report.maxScore * 100|number:'0.0-0'}} {{'건강.점'|translate}}</div>

  <div class="ul-wrapper" style="top: 960px; left: 16px; width: 182px;">
    <ul>
      <li *ngFor="let correctiveExercise of report.correctiveExercises.mobility">{{correctiveExercise}}</li>
    </ul>
  </div>
  <div class="ul-wrapper" style="top: 960px; left: 198px; width: 182px;">
    <ul>
      <li *ngFor="let correctiveExercise of report.correctiveExercises.stability">{{correctiveExercise}}</li>
    </ul>
  </div>
  <div class="ul-wrapper" style="top: 960px; left: 380px; width: 182px;">
    <ul>
      <li *ngFor="let correctiveExercise of report.correctiveExercises.motorControl">{{correctiveExercise}}</li>
    </ul>
  </div>
  <div style="top: 420px; left: 220px; width: 300px; height: 300px;">
    <canvas #radarChartCanvas id="radarChart" width="300" height="300"></canvas>
  </div>
</div>
