import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib';
import { LogoComponent } from './ft-logo/ft-logo.component';
import { FtUserProfileComponent } from './ft-user-profile/ft-user-profile';
import { ImageUploaderCompModule } from '@shared-lib/components/image-uploader/image-uploader.module';
import { TrainerItemComponent } from './ft-trainer-item/ft-trainer-item.component'
import { FtUserProfileNameComponent } from './ft-user-profile-name/ft-user-profile-name';


@NgModule({
  declarations: [
    LogoComponent,
    FtUserProfileComponent,
    TrainerItemComponent,
    FtUserProfileNameComponent
  ],
  imports: [
    SharedModule,
    ImageUploaderCompModule
  ],
  exports: [
    LogoComponent,
    FtUserProfileComponent,
    TrainerItemComponent,
    FtUserProfileNameComponent
  ],
  entryComponents: [
  ],
})
export class CommonFittModules { }
