
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'ftdate' })
export class FtDatePipe implements PipeTransform {
  constructor(
  ) { }
  transform(value, param) {
    if(!value){
      return new Date(value).format(param);
    }
    else if(typeof (value) === 'string'){
      return new Date(value.replace(' ', 'T')).format(param);
    }
    return new Date(value).format(param);
  }
}
