
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'metric' })
export class MetricPipe implements PipeTransform {
  constructor(
  ) { }
  transform(value: number | string, args: { unit: 'distance' | 'kmDistance' | 'speed' | 'pace', isMetric?: boolean, displayUnit?: boolean, toFixed?: number }) {
    const { unit, isMetric = true, displayUnit = false, toFixed = 1 } = args;
    let suffix: string = '';
    value = Number(value);

    switch (unit) {
      case 'distance':
        if (!isMetric) {
          value = this.convertDistanceToMile(value);
          suffix = 'mi';
        } else {
          suffix = 'm';
        }
        break;
      case 'kmDistance':
        if (!isMetric) {
          value = this.convertDistanceToMile(value * 1000);
          suffix = 'mi';
        } else {
          suffix = 'km';
        }
        break;
      case 'speed':
        if (!isMetric) {
          value = this.convertSpeedToMile(value);
          suffix = 'mph'
        } else {
          suffix = 'km/h';
        }
        break;
      case 'pace':
        if (!isMetric) {
          value = this.convertPaceToMile(value);
          suffix = '/mi';
        } else {
          suffix = '/km';
        }
    }

    if (unit === 'pace') {
      if (value < 0) {
        return `-'--"`;
      } else {
        return `${Math.floor(value / 60)}'${Math.floor(value % 60).pad(2)}"${displayUnit ? ' ' + suffix : ''}`
      }
    } else {
      return `${value.toFixed(toFixed)}${displayUnit ? ' ' + suffix : ''}`
    }
  }

  convertSpeedToMile(speed: number) {
    return speed * 0.621371;
  }

  convertDistanceToMile(distance: number) {
    return distance * 0.621371 / 1000;
  }

  convertPaceToMile(pace: number) {
    return pace * 1.609
  }
}
