<div class="wrapper">
  <div style="height: 148px;">
    <p>{{context}}</p>
    <ng-container *ngIf="body">
      <div *ngFor="let row of body" class="sns-type">
        <ion-label>
          <img src="/assets/fitt-lib/img/{{row}}.svg" />
        </ion-label>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <button (click)="close()">{{'확인'|translate}}</button>
  </div>
</div>
