import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { BaseComponent } from '@shared-lib/base.component';
import $ from 'jquery';


export class ShortcutKeyEvent {
  key: string;
  elementId?: string;
  eventType?: 'click' | 'focus';
  handler?: Function;
}

export class ShortcutEvent {
  type: 'element' | 'view';
  selector: string;
  keyEvents?: Array<ShortcutKeyEvent>;
}

@Injectable({
  providedIn: 'root'
})
export class ShortcutService extends BaseComponent {

  shortcutModalMap: { [selector: string]: ShortcutEvent } = {};
  shortcutViewMap: { [selector: string]: ShortcutEvent } = {};

  constructor(
    private popoverCtrl: PopoverController,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private router: Router,
  ) {
    super({ enableLog: false });
  }

  startService() {
    document.addEventListener('keydown', this.onKeyEvent);
    document.addEventListener('keyup', this.onKeyEvent);
  }

  onKeyEvent = async (event: any) => {
    const { key, keyCode, repeat, type } = event;
    const activeElement = document.activeElement;
    this.log('onKeyEvent', event, { type, key, keyCode, activeElement: activeElement && activeElement.tagName.toLowerCase() });

    if (key == 'F1') {
      !repeat && this.shortcutHint(type == 'keydown');
      return;
    }
    if (key === 'F5') {
      window.location.href = window.location.href
      return;
    }

    if (key == 'Escape') {
      if (type == 'keyup') {
        const alert = await this.alertCtrl.getTop();
        if (alert) {
          alert.dismiss();
          return;
        }

        const modal = await this.modalCtrl.getTop();
        if (modal) {
          modal.dismiss();
          return;
        }
      }
    }

    if (key == 'Enter') {
      const alert = await this.alertCtrl.getTop();
      if (type == 'keydown') {
        if (this.enableTriggerEnter(!!alert)) {
          event.stopPropagation();
          event.preventDefault();
          return;
        }
      }
      else if (type == 'keyup') {
        if (this.prevEnableTriggerEnter) {
          event.stopPropagation();

          if (alert) {
            this.log('onKeyEvent.alert', activeElement);
            if (this.enableTriggerEnter()) {
              const okButton = $('.alert-button-group').children('button.alert-button-role-ok');
              this.log('onKeyEvent.okButton', okButton);
              if (okButton.length) {
                okButton.trigger('click');
              }
            }
            else if (activeElement) {
              $(activeElement).trigger('click');
            }
            return;
          }

          const modal = await this.modalCtrl.getTop();
          if (modal) {
            const element = modal.getElementsByClassName('click-on-enter');
            this.log('onKeyEvent.Enter HTML', element);
            if (element.length > 1) {
              this.warn('click-on-enter too much');
            }
            else if (element.length == 1) {
              this.log('onKeyEvent.Enter click-on-enter');
              // TODO: element[0].ariaDisabled => Property 'ariaDisabled' does not exist on type 'Element'
              // if (element && !Boolean(element[0].ariaDisabled)) {
              if (element) {
                const elements: HTMLElement = element[0] as HTMLElement;
                elements.click();
              }
            }
            return;
          }
        }
      }
    }

    // input에서 키입력 중이면 단축키를 적용하지 않는다.
    if (['input', 'textarea'].includes(activeElement.tagName.toLowerCase())) {
      return;
    }

    // 그외 일반 키
    if (type == 'keyup') {
      const modal = await this.modalCtrl.getTop();
      const keyEventInfos = this.getKeyEventInfos(modal);
      for (const keyEvent of keyEventInfos) {
        const result = keyEvent.handler && keyEvent.handler(event);
        this.log('onKeyEvent.trigger', { elementId: keyEvent.elementId, result });
        if (result !== false && keyEvent.elementId) {
          const element = $(`#${keyEvent.elementId}`);
          element.each((index, element) => {
            this.log(`onKeyEvent.trigger ${keyEvent.elementId} ${keyEvent.eventType}`, element);
            if (element.classList.contains('click-on-enter')) {
              this.warn(`id=${keyEvent.elementId}은 기본동작(click-on-enter) 이 정의되어 있습니다. 필요 시 다른 id를 사용하세요.`);
            }
            else {
              if (keyEvent.eventType == 'focus') {
                if (element.tagName.startsWith('ION')) {
                  const ionInput: any = element;
                  ionInput.setFocus();
                }
                else {
                  element.focus();
                }
              }
              else {
                element.click();
              }
            }
          });
        }
      }
    }
  };

  getKeyEventInfos(element: Element) {
    const keyEventInfos = [];
    if (element) {
      const localNames = this.enumLocalName(element);
      this.log('getKeyEventInfos.localNames', localNames);
      for (const selector of Object.keys(this.shortcutModalMap)) {
        if (localNames[selector]) {
          const keyEvents = this.shortcutModalMap[selector].keyEvents;
          const keyEvent = this.checkKeyEvents(keyEvents, event);
          keyEvent && keyEventInfos.push(keyEvent);
        }
      }
    }
    else { // view
      this.log('getKeyEventInfos', this.router.url);
      const keyEvents = this.checkViewKeyEvents();
      const keyEvent = this.checkKeyEvents(keyEvents, event);
      keyEvent && keyEventInfos.push(keyEvent);
    }
    return keyEventInfos;
  }

  checkKeyEvents(keyEvents, event) {
    const { key, code } = event;
    return keyEvents && keyEvents.find(keyEvent => {
      if (keyEvent.key.includes('+') && keyEvent.key.length > 1) {
        const keys = keyEvent.key.split('+');
        if (keys.length == 2) {
          switch (keys[0]) {
            case 'Control': return event.ctrlKey && [key, code].includes(keys[1]);
            case 'Alt': return event.altKey && [key, code].includes(keys[1]);
            case 'Meta': return event.metaKey && [key, code].includes(keys[1]);
            case 'Shift': return event.shirtKey && [key, code].includes(keys[1]);
          }
        }
        return false;
      }
      else {
        return [key, code].includes(keyEvent.key);
      }
    });
  }

  checkViewKeyEvents(): Array<ShortcutKeyEvent> {
    const selector = Object.keys(this.shortcutViewMap).find(selector => {
      // this.log('onKeyEvent', selector, selector.substr(0, selector.length - 2));
      if (selector.endsWith('*')) {
        return this.router.url.startsWith(selector.substr(0, selector.length - 2));
      }
      else {
        return selector == this.router.url;
      }
    });
    if (selector) {
      return this.shortcutViewMap[selector].keyEvents;
    }
    return null;
  }

  async shortcutHint(show: boolean) {
    this.log('shortcutHint', { show });
    const ionApp = $('ion-app');
    if (ionApp) {
      if (show) {
        const divShortcutHintScreen = `<div id="shortcut-hint-screen" style="z-index: 100000; position: absolute; pointer-events: none; width: 100%; height: 100%;"></div>`;
        ionApp.append(divShortcutHintScreen);
        const hintScreen = ionApp.children('#shortcut-hint-screen');
        const hintInfos = [];

        const popover = await this.popoverCtrl.getTop(); if (popover) return;

        const alert = await this.alertCtrl.getTop(); if (alert) return;

        const modal = await this.modalCtrl.getTop();
        if (modal) {
          const localNames = this.enumLocalName(modal);
          this.log('shortcutHint.localNames', localNames);
          for (const selector of Object.keys(this.shortcutModalMap)) {
            if (localNames[selector]) {
              const shortcutEvent = this.shortcutModalMap[selector];
              const keyEvents = shortcutEvent.keyEvents;
              hintInfos.push(...keyEvents.map(e => {
                return {
                  key: e.key,
                  position: $(`#${e.elementId}`).offset(),
                }
              }));
            }
          }
        }
        else {// view
          this.log('shortcutHint', this.router.url);
          const keyEvents = this.checkViewKeyEvents();
          if (keyEvents) {
            hintInfos.push(...keyEvents.filter(e => e.elementId).map(e => {
              return {
                key: e.key,
                position: $(`#${e.elementId}`).offset(),
              }
            }));
          }
        }

        this.log('shortcutHint.hintInfos', hintInfos);
        for (const hintInfo of hintInfos) {
          if (!hintInfo.position) continue;
          switch (hintInfo.key) {
            case 'Enter': hintInfo.key = '↵'; break;
            case 'ArrowLeft': hintInfo.key = '←'; break;
            case 'ArrowRight': hintInfo.key = '→'; break;
            case 'ArrowUp': hintInfo.key = '↑'; break;
            case 'ArrowDown': hintInfo.key = '↓'; break;
          }
          hintScreen.append(`<div class="shortcut-hint" style="left: ${hintInfo.position.left}px; top: ${hintInfo.position.top - 20}px; ">${hintInfo.key}</div>`);
        }
      }
      else {
        ionApp.children('#shortcut-hint-screen').remove();
      }
    }

  }

  register(args: ShortcutEvent) {
    if (args.type == 'element') {
      this.shortcutModalMap[args.selector] = args;
    }
    else if (args.type == 'view') {
      this.shortcutViewMap[args.selector] = args;
    }
  }

  unregister(args: ShortcutEvent) {
    if (args.type == 'element') {
      delete this.shortcutModalMap[args.selector];
    }
    else if (args.type == 'view') {
      delete this.shortcutViewMap[args.selector];
    }
  }

  enumLocalName(element: Element): { [key: string]: boolean } {
    const result = {};
    result[element.localName] = true;
    // this.log('findChild.push', element.localName);
    Array.from(element.children).forEach((ele: Element) => {
      Object.assign(result, this.enumLocalName(ele));
      // this.log('findChild.concat', result);
    });
    return result;
  }

  findModalName(element: Element): string {
    // this.log('findModalName', element.localName, element.classList);
    if (Array.from(element.classList).findIndex(c => c == 'ion-page') >= 0) {
      return element.localName;
    }
    for (const ele of Array.from(element.children)) {
      const localName = this.findModalName(ele);
      if (localName) return localName;
    }
    return null;
  }

  prevEnableTriggerEnter: boolean = false;
  enableTriggerEnter(forceTrigger: boolean = false) {
    const activeElement = document.activeElement;
    const result = forceTrigger || !activeElement || !['input', 'select', 'button', 'textarea', 'ion-button', 'ion-select'].includes(activeElement.tagName.toLowerCase());
    this.log('enableTriggerEnter', activeElement && activeElement.tagName.toLowerCase(), result);
    this.prevEnableTriggerEnter = result;
    return result;
  }
}
