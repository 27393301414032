import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';


@Component({
  selector: 'ft-toggle-button',
  templateUrl: './ft-toggle-button.html',
  styleUrls: ['./ft-toggle-button.scss'],
})
export class FtToggleButtonComponent extends BaseComponent implements OnInit {
  @Input() fillStyle: 'clear' | 'outline' | 'solid' = 'outline';
  @Input() value: boolean = true;
  @Input() size: string = '14px';
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  onClick() {
    this.value = !this.value;
    this.valueChange && this.valueChange.emit(this.value);
  }
}
