import { GymUser } from '@fitt-lib/models/fitt-models';
import { FmtSurvey } from './fmt-survey';

const guideVideos: Array<{ id: string, url: string, name: string, desc: string }> = [
  { id: '6Ho0Q1Yf_-k', url: 'http://i.ytimg.com/vi/6Ho0Q1Yf_-k/hqdefault.jpg', name: '1. 경추움직임 평가', desc: `움직임능력평가(FMT) \n 경추움직임 평가 설명 영상` },
  { id: 'uzSPzIXpjAQ', url: 'http://i.ytimg.com/vi/uzSPzIXpjAQ/hqdefault.jpg', name: '2. 오버헤드스쿼트', desc: `움직임능력평가(fmt) \n 오버헤드스쿼트 설명 영상` },
  { id: 'gyHsMyl-IwI', url: 'http://i.ytimg.com/vi/gyHsMyl-IwI/hqdefault.jpg', name: '3. 싱글레그 스텝', desc: `움직임능력평가(fmt) \n 싱글레그 스텝 설명 영상` },
  { id: 'OZLZ1IqGu4A', url: 'http://i.ytimg.com/vi/OZLZ1IqGu4A/hqdefault.jpg', name: '4. 스플릿 스쿼트', desc: `움직임능력평가(fmt) \n 스플릿 스쿼트 설명 영상` },
  { id: '6bji_KhrvbM', url: 'http://i.ytimg.com/vi/6bji_KhrvbM/hqdefault.jpg', name: '5. 숄더 로테이션 터치', desc: `움직임능력평가(fmt) \n 숄더 로테이션 터치 설명 영상` },
  { id: '54605uVkIZQ', url: 'http://i.ytimg.com/vi/54605uVkIZQ/hqdefault.jpg', name: '6. 스트레이트 레그 레이즈', desc: `움직임능력평가(fmt) \n 스트레이트 레그 레이즈 설명 영상` },
  { id: 'eRkEQasR_ns', url: 'http://i.ytimg.com/vi/eRkEQasR_ns/hqdefault.jpg', name: '7. 푸쉬업', desc: `움직임능력평가(fmt) \n 푸쉬업 설명 영상` },
  { id: 'dLibOK1o9dI', url: 'http://i.ytimg.com/vi/dLibOK1o9dI/hqdefault.jpg', name: '8. 버드독', desc: `움직임능력평가(fmt) \n 버드독 설명 영상` },
]

const fmtItemList: Array<FmtItem> = [{
  subject: '경추 움직임평가', description: '흉추와 견갑대의 안정성을 필요로 하며 경추의 시상면과 횡단면에서의 가동 범위를 확인하는 검사', position: ['A', 'P', 'L', 'R'], // 'A 3 / P 2 / L 1 / R ',
  img: { normal: 'assets/fitt-lib/img/group-14-copy-11.svg', active: 'assets/fitt-lib/img/group-14-copy-10.svg', deactive: 'assets/fitt-lib/img/group-14-copy-9.svg', isActive: true },
  children: [
    {
      title: '전측 (A)', type: 'options', desc: '고개를 아래로 숙여 턱이 흉골에 닿도록 한다.', options: [
        '턱이 흉골에 닿는다.',
        '턱이 흉골에 닿지만, 보상 패턴이 나타난다.',
        '턱이 흉골에 닿지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '후측 (P)', type: 'options', desc: '고개를 뒤로 젖혀 얼굴면이 천장과 평행이 되도록 한다.', options: [
        '천장과 얼굴이 평행하다.',
        '10도 이내로 평행이 만들어진다.',
        '10도 이내로 평행이 되지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '좌측 (L)', type: 'options', desc: '고개를 좌측으로 돌리고 턱을 아래로 내린다.', options: [
        '고개를 돌렸을 때 코가 쇄골의 중간을 넘으며 턱이 쇄골에 닿는다.',
        '경추 회전 후 굴곡하여 턱이 쇄골에 닿지 않거나, 보상 패턴이 나타나며 닿는다.',
        '경추 회전이 80도가 되지 않고 흉골과 귀가 수직이 아니다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '고개를 우측으로 돌리고 턱을 아래로 내린다.', options: [
        '고개를 돌렸을 때 코가 쇄골의 중간을 넘으며 턱이 쇄골에 닿는다.',
        '경추 회전 후 굴곡하여 턱이 쇄골에 닿지 않거나, 보상 패턴이 나타나며 닿는다.',
        '경추 회전이 80도가 되지 않고 흉골과 귀가 수직이 아니다.',
        '통증이 있다.',
      ]
    },

    { title: '굴곡 시 턱이 흉골에 닿는다.', type: 'boolean', positive: true },
    { title: '신전 시 10도 이내의 평행을 이룬다.', type: 'boolean', positive: true },
    { title: '우측 굴곡 시 턱이 쇄골 중앙에 닿는다.', type: 'boolean', positive: true },
    { title: '좌측 굴곡 시 턱이 쇄골 중앙에 닿는다.', type: 'boolean', positive: true },
    { title: '동작 수행 시 과도한 노력을 한다.', type: 'boolean', positive: false },
    { title: '통증이 있다.', type: 'boolean', positive: false },
    { title: '보상 패턴이 나타난다.', type: 'boolean', positive: false },
  ],
  guideId: '6Ho0Q1Yf_-k'
},
{
  subject: '오버헤드 스쿼트', description: '코어와 흉추, 신전, 어깨의 기능을 필요로 하며, 고관절과 무릎, 발목 양측의 가동성, 안정성을 확인하는 검사', position: ['O'], // 'O',
  img: { normal: 'assets/fitt-lib/img/group-3-copy-18.svg', active: 'assets/fitt-lib/img/group-3-copy-19.svg', deactive: 'assets/fitt-lib/img/group-3-copy-20.svg', isActive: false },
  children: [
    {
      title: '동측 (B)', type: 'options', desc: '머리 위로 양팔을 올려 유지하며 스쿼트 자세를 취한다.', options: [
        '1. 경골과 몸통이 평행하다.\n2. 허벅지가 수평보다 내려간다.\n3. 양팔이 머리 위에 위치한다.\n4. 무릎이 수직 정렬되어 있다.',
        '뒤꿈치에 2~3cm의 굽을 대고 실시해야 동작이 바르게 나온다.',
        '뒤꿈치에 2~3cm의 굽을 댄 후에도 동작이 바르지 않다.',
        '통증이 있다.',
      ]
    },
    { title: '정강이(Tibia)와 흉추(Spine)가 평행이다.', type: 'boolean', positive: true },
    { title: '들어 올린 양팔이 머리 앞으로 기울어진다.', type: 'boolean', positive: false },
    { title: '등이 둥글게 말리고 있다.', type: 'boolean', positive: false },
    { title: '발목이 외측회전을 하고 있다.', type: 'boolean', positive: false },
    { title: '무릎에 Valgus, Varus가 나타난다.', type: 'boolean', positive: false },
    { title: '허벅지가 수평보다 내려가지 않는다.', type: 'boolean', positive: false },
    { title: '움직임이 부자연스럽다.', type: 'boolean', positive: false },
  ],
  guideId: 'uzSPzIXpjAQ',
},
{
  subject: '싱글레그 스텝', description: '스틱을 활용하여 고유 수용성 감각 및 고관절과 발목의 가동성과 안정성을 확인하는 검사', position: ['L', 'R'], // 'L / R',
  img: { normal: 'assets/fitt-lib/img/group-14-copy-17.svg', active: 'assets/fitt-lib/img/group-14-copy-18.svg', deactive: 'assets/fitt-lib/img/group-14-copy-16.svg', isActive: false },
  children: [
    {
      title: '좌측 (L)', type: 'options', desc: '스틱을 활용한 편측 검사로, 들어 올리는 하지 동작을 취한다.', options: [
        '차렷 자세로 몸통을 곧게 하여 고관절과 무릎의 각도를 90도로 유지한다.',
        '동작 수행 시 많은 흔들림과 잘못된 패턴을 보이며 수행한다.',
        '동작 수행이 되지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '스틱을 활용한 편측 검사로, 들어 올리는 하지 동작을 취한다.', options: [
        '차렷 자세로 몸통을 곧게 하여 고관절과 무릎의 각도를 90도로 유지한다.',
        '동작 수행 시 많은 흔들림과 잘못된 패턴을 보이며 수행한다.',
        '동작 수행이 되지 않는다.',
        '통증이 있다.',
      ]
    },

    { title: '눈을 감고 10초간 서있을 수 있다.', type: 'boolean', positive: true },
    { title: '눈을 뜨고 10초간 서있을 수 있다.', type: 'boolean', positive: true },
    { title: '고관절의 회전(IR-ER)이 나타난다.', type: 'boolean', positive: false },
    { title: '몸통이 뒤틀리거나 옆으로 굽어진다.', type: 'boolean', positive: false },
    { title: '동작 수행 시 지지하는 발의 안정성이 확보되어 있다.', type: 'boolean', positive: true },
    { title: '라인을 터치했다.', type: 'boolean', positive: false },
    { title: '척추의 굽힘 없이 정렬이 유지되고 있다.', type: 'boolean', positive: true },
    { title: '움직임이 부자연스럽다.', type: 'boolean', positive: false },
  ],
  guideId: 'gyHsMyl-IwI',
},
{
  subject: '스플릿 스쿼트', description: '양쪽 하지의 발목, 무릎, 고관절의 가동성과 어깨, 흉추의 가동성 및 상, 하지의 협응 패턴과 동적인 안정성을 확인하는 검사', position: ['L', 'R'], // 'L / R',
  img: { normal: 'assets/fitt-lib/img/group-14-copy-19.svg', active: 'assets/fitt-lib/img/group-14-copy-20.svg', deactive: 'assets/fitt-lib/img/group-14-copy-15.svg', isActive: false },
  children: [
    {
      title: '좌측 (L)', type: 'options', desc: '스틱을 활용한 편측 검사로, 앞으로 뻗는 다리 동작을 취한다.', options: [
        '머리, 흉추, 엉치뼈는 스틱과 떨어지지 않으며 뒷무릎이 앞발 뒤꿈치에 닿고 시상면을 유지한다.',
        '동작 수행 시 흔들림과 함께 허리가 숙여지며 수행한다.',
        '동작 수행이 되지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '스틱을 활용한 편측 검사로, 앞으로 뻗는 다리 동작을 취한다.', options: [
        '머리, 흉추, 엉치뼈는 스틱과 떨어지지 않으며 뒷무릎이 앞발 뒤꿈치에 닿고 시상면을 유지한다.',
        '동작 수행 시 흔들림과 함께 허리가 숙여지며 수행한다.',
        '동작 수행이 되지 않는다.',
        '통증이 있다.',
      ]
    },
    { title: '몸이 앞으로 숙여진다.', type: 'boolean', positive: false },
    { title: '앉아 내려갈 때 무릎이 밖으로 벗어난다.', type: 'boolean', positive: false },
    { title: '흔들림 없이 균형을 유지한다.', type: 'boolean', positive: true },
    { title: '척추 3포인트가 스틱과 밀착하고 있다.', type: 'boolean', positive: true },
    { title: '움직임이 부자연스럽다.', type: 'boolean', positive: false },
  ],
  guideId: 'OZLZ1IqGu4A',
},
{
  subject: '숄더 로테이션 터치', description: '양 어깨의 내/외 회전, 신전과 굴곡, 내/외전을 평가하여 어깨와 흉추의 가동 범위를 확인하는 검사', position: ['L', 'R'], // 'L / R',
  img: { normal: 'assets/fitt-lib/img/group-14-copy-22.svg', active: 'assets/fitt-lib/img/group-14-copy-23.svg', deactive: 'assets/fitt-lib/img/group-14-copy-21.svg', isActive: false },
  children: [
    {
      title: '좌측 (L)', type: 'options', desc: '왼손 끝이 오른쪽 견갑골극(Spine of the Scapula)과 견갑골의 하각(Inferior angle)에 닿는지 평가한다.', options: [
        '1. 왼팔을 머리 위에서 아래로 내리며 오른쪽 견갑골극에 닿는다.\n2. 왼팔을 아래에서 뒤로 보내 오른쪽 견갑골 하각에 닿는다.',
        '손끝이 견갑골극과 견갑골 하각에 닿지 않았을 때 트레이너가 수동적인 보조힘을 주면 정상범위가 나온다.',
        '손끝이 견갑골극과 견갑골 하각에 닿지 않고 트레이너가 수동적인 보조힘을 가해도 정상범위가 나오지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '오른손 끝이 왼쪽 견갑골극(Spine of the Scapula)과 견갑골의 하각(Inferior angle)에 닿는지 평가한다.', options: [
        '1. 오른팔을 머리 위에서 아래로 내리며 왼쪽 견갑골극에 닿는다.\n2. 오른팔을 아래에서 뒤로 보내 왼쪽 견갑골 하각에 닿는다.',
        '손끝이 견갑골극과 견갑골 하각에 닿지 않았을 때 트레이너가 수동적인 보조힘을 주면 정상범위가 나온다.',
        '손끝이 견갑골극과 견갑골 하각에 닿지 않고 트레이너가 수동적인 보조힘을 가해도 정상범위가 나오지 않는다.',
        '통증이 있다.',
      ]
    },
    { title: '오른손이 왼쪽 견갑골극에 닿는다.', type: 'boolean', positive: true },
    { title: '오른손이 왼쪽 견갑골 하각에 닿는다.', type: 'boolean', positive: true },
    { title: '왼손이 오른쪽 견갑골극에 닿는다.', type: 'boolean', positive: true },
    { title: '왼손이 오른쪽 견갑골 하각에 닿는다.', type: 'boolean', positive: true },
    { title: '보상 패턴이 나타난다.', type: 'boolean', positive: false },
    { title: '통증이 있다.', type: 'boolean', positive: false },
  ],
  guideId: '6bji_KhrvbM',
},
{
  subject: '스트레이트 레그 레이즈', description: '들어 올리는 다리 고관절의 가동성, 골반 컨트롤 능력, 코어의 안정성, 반대쪽 고관절의 신전 능력을 확인하는 검사', position: ['L', 'R'], // 'L / R',
  img: { normal: 'assets/fitt-lib/img/group-3-copy-25.svg', active: 'assets/fitt-lib/img/group-3-copy-26.svg', deactive: 'assets/fitt-lib/img/group-3-copy-24.svg', isActive: false },
  children: [
    {
      title: '좌측 (L)', type: 'options', desc: '바닥에 누워 체중이 실리지 않은 자세에서 왼쪽 다리를 들어 올린 후 스틱을 이용해 비대칭을 평가한다.', options: [
        '들어 올린 왼발 뒤꿈치가 스틱을 넘으며, 움직임 시 발목은 중립을 유지하고 무릎은 곧게 편다.',
        '바닥에 있는 오른쪽 다리의 허벅지 1/2 지점을 기준으로 들어 올린 왼쪽 다리가 지나치지 못했다.',
        '바닥에 있는 오른쪽 다리의 무릎 지점을 기준으로 들어 올린 왼쪽 다리가 지나치지 못했다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '바닥에 누워 체중이 실리지 않은 자세에서 오른쪽 다리를 들어 올린 후 스틱을 이용해 비대칭을 평가한다.', options: [
        '들어 올린 오른발 뒤꿈치가 스틱을 넘으며, 움직임 시 발목은 중립을 유지하고 무릎은 곧게 편다.',
        '바닥에 있는 왼쪽 다리의 허벅지 1/2 지점을 기준으로 들어 올린 오른쪽 다리가 지나치지 못했다.',
        '바닥에 있는 왼쪽 다리의 무릎 지점을 기준으로 들어 올린 오른쪽 다리가 지나치지 못했다.',
        '통증이 있다.',
      ]
    },
    { title: '들어 올리는 다리가 곧게 펴져 있다.', type: 'boolean', positive: true },
    { title: '올리지 않은 다리가 신전 되어 있다.', type: 'boolean', positive: true },
    { title: '올리지 않은 다리의 발목이 외측회전 한다.', type: 'boolean', positive: false },
    { title: '양손 발끝 터치시 중심부 통증을 느낀다.', type: 'boolean', positive: false },
  ],
  guideId: '54605uVkIZQ',
},
{
  subject: '푸쉬 업', description: '척추와 엉덩이의 보상 움직임 없이 고역치의 코어 안정성과 상지 근력의 협응 패턴을 확인하는 검사', position: ['O'], // 'O',
  img: { normal: 'assets/fitt-lib/img/group-3-copy-27.svg', active: 'assets/fitt-lib/img/group-3-copy-30.svg', deactive: 'assets/fitt-lib/img/group-3-copy-29.svg', isActive: false },
  children: [
    {
      title: '동측 (B)', type: 'options', desc: '엄지손가락을 눈썹과 수평, 어깨와 수직으로 맞춘 뒤 실시한다. 남성과 여성의 시작자세를 다르게 해야 한다.', options: [
        '동작 수행 시 고관절, 척추의 신전과 회전이 나타나지 않는다.',
        '동작 시작 시 손 위치를 턱 밑에서 실시하며, 고관절, 척추의 신전과 회전이 나타나지 않는다.',
        '고관절, 척추의 신전이 나타난다.',
        '어깨나 허리에 통증이 있다.',
      ]
    },
    { title: '허리가 아래로 쳐진다.', type: 'boolean', positive: false },
    { title: '몸통의 회전 및 뒤틀림이 나타난다.', type: 'boolean', positive: false },
    { title: '웨이브 없이 한 번에 들어 올려진다.', type: 'boolean', positive: true },
    { title: '코어테스트에서 중심부 통증이 발생한다.', type: 'boolean', positive: false },
  ],
  guideId: 'eRkEQasR_ns',
},
{
  subject: '버드 독', description: '상지와 하지가 결합된 사선 움직임에서 코어, 고관절, 견갑대 안정성을 확인하는 검사', position: ['L', 'R'], // 'L / R',
  img: { normal: 'assets/fitt-lib/img/group-3-copy-31.svg', active: 'assets/fitt-lib/img/group-3-copy-33.svg', deactive: 'assets/fitt-lib/img/group-3-copy-32.svg', isActive: false },
  children: [
    {
      title: '좌측 (L)', type: 'options', desc: '편측 검사로, 매트를 접어 댄 뒤 양 무릎과 양손을 바닥에 모아 지지하고 발등을 바닥에서 들어 올려 왼손과 오른발을 교차하여 뻗은 뒤 다시 팔꿈치와 무릎에 대는 동작을 평가한다.', options: [
        '버드독 동작을 3번 반복하며 흔들림 없이 안정되게 수행한다.',
        '3점 자세의 수행이 불가능하고, 양 무릎과 양손을 골반 넓이로 벌려 다시 시도하여 안정적으로 수행한다.',
        '2점 자세 또한 자연스럽게 이루어지지 않는다.',
        '통증이 있다.',
      ]
    },
    {
      title: '우측 (R)', type: 'options', desc: '편측 검사로, 매트를 접어 댄 뒤 양 무릎과 양손을 바닥에 모아 지지하고 발등을 바닥에서 들어 올려 오른손과 왼발을 교차하여 뻗은 뒤 다시 팔꿈치와 무릎에 대는 동작을 평가한다.', options: [
        '버드독 동작을 3번 반복하며 흔들림 없이 안정되게 수행한다.',
        '3점 자세의 수행이 불가능하고, 양 무릎과 양손을 골반 넓이로 벌려 다시 시도하여 안정적으로 수행한다.',
        '2점 자세 또한 자연스럽게 이루어지지 않는다.',
        '통증이 있다.',
      ]
    },
    { title: '손끝과 발끝이 앞뒤 수평으로 잘 뻗어있다.', type: 'boolean', positive: true },
    { title: '팔꿈치와 무릎 터치 시 키트 안에 들어온다.', type: 'boolean', positive: true },
    { title: '복부 정중앙에서 터치가 이루어진다.', type: 'boolean', positive: true },
    { title: '전체적인 움직임이 부자연스럽다.', type: 'boolean', positive: false },
  ],
  guideId: 'dLibOK1o9dI'
},
];

export type FmtItem = {
  subject: string;
  description: string;
  position: Array<string>;
  img: { normal: string, active: string, deactive: string, isActive: boolean };
  children: Array<{
    title: string, type: 'options' | 'boolean',
    desc?: string,
    options?: Array<string>,
    answer?: string,
    positive?: boolean
  }>,
  guideId?: string // 검사방법 영상(유튜브) key값
};

export type FmtReport = {
  correctiveExercises: { mobility: string[]; stability: string[]; motorControl: string[]; };
  name: string;
  height: number;
  gender: Gender;
  date: Date;
  age: number;
  weight: number;

  movability: 'Good' | 'Normal' | 'Bad';
  stability: 'Good' | 'Normal' | 'Bad';
  motorControl: 'Good' | 'Normal' | 'Bad';
  asymmetryList: Array<number>;
  shoulderPain: 'Yes' | 'Yes_L' | 'Yes_R' | 'Yes_LR' | 'No';
  corePain: 'Yes' | 'No';
  cervicalPain: 'Yes' | 'No';
  fmtScore: number;
  maxScore: number;
  cervicalResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' };
  motorControlResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' };
  pelvisLegResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' };
  coreResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' };
  thoracicResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' };
  shoulderResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' | 'Pain Bad' };
};

export type FmtNoteType = 'front' | 'back' | 'general';

export class FmtNote {
  type: FmtNoteType;
  x?: number;
  y?: number;
  contents: string;
  date: Date = new Date();
}

export class FMT {
  private static fmtItemList: Array<FmtItem> = fmtItemList;

  private static guideVideos: Array<{ id: string, url: string, name: string, desc: string }> = guideVideos;

  public static getFmtItemList(): Array<FmtItem> {
    return this.fmtItemList;
  }

  public static getGuideVideos(): Array<{ id: string, url: string, name: string, desc: string }> {
    return this.guideVideos;
  }

  public static calcFmt(gymUser: GymUser, weight: number, height: number, inDate: Date | string, fmtSurvey: FmtSurvey): FmtReport {
    const report: FmtReport = new Object() as FmtReport;

    report.name = gymUser.user.uName;
    report.height = height;
    report.gender = gymUser.user.uGender;
    report.date = new Date(inDate);
    report.age = gymUser.user.getAgeFromDate(report.date);
    report.weight = weight;
    report.movability = fmtSurvey.getMovability();
    report.stability = fmtSurvey.getStability();
    report.motorControl = fmtSurvey.getMotorControl();
    report.asymmetryList = fmtSurvey.getAsymmetryList();
    report.shoulderPain = fmtSurvey.getShoulderPain();
    report.corePain = fmtSurvey.getCorePain();
    report.cervicalPain = fmtSurvey.getCervicalPain();
    report.fmtScore = fmtSurvey.getFmtScore();
    report.maxScore = fmtSurvey.getMaxScore();
    report.cervicalResult = fmtSurvey.getCervicalResult();
    report.motorControlResult = fmtSurvey.getMotorControlResult();
    report.pelvisLegResult = fmtSurvey.getPelvisLegResult();
    report.coreResult = fmtSurvey.getCoreResult();
    report.thoracicResult = fmtSurvey.getThoracicResult();
    report.shoulderResult = fmtSurvey.getShoulderResult();
    report.correctiveExercises = fmtSurvey.getCorrectiveExercises();

    return report;
  }
}
