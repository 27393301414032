import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-flex-row',
  templateUrl: './ft-flex-row.component.html',
  styleUrls: ['./ft-flex-row.component.scss'],
})
export class FtFlexRowComponent extends BaseComponent implements OnInit {
  @Input() leftSize: number = 4;
  @Input() borderBottom: string = '1px solid var(--item-border-color)';
  @Input() contentZIndex = 0;

  constructor(
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
  }
}
