import { ApiService } from '@fitt-lib/services/api.service';
import { BaseComponent } from '@shared-lib/base.component';
import { FittUtilService } from '@fitt-lib/services/fitt-util.service';

export class FmesBaseComponent extends BaseComponent {
  public gApi: ApiService;
  public fittUtil: FittUtilService;


  constructor($param?) {
    super($param);

    this.gApi = BaseComponent.injector.get(ApiService);
    this.fittUtil = BaseComponent.injector.get(FittUtilService);
  }
}
