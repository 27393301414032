import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';
import { StringUnion } from '@shared-lib/utils/string-union';

const TRI_STATE = StringUnion('off', 'custom', 'on');
export type TriState = typeof TRI_STATE.type;

@Component({
  selector: 'ft-tri-button',
  templateUrl: './ft-tri-button.html',
  styleUrls: ['./ft-tri-button.scss'],
})
export class FtTriButtonComponent extends BaseComponent implements OnInit {
  @Input() fillStyle: 'clear' | 'outline' | 'solid' = 'outline';
  @Input() value: TriState = 'on';
  @Input() size: string = '14px';
  @Output() valueChange: EventEmitter<TriState> = new EventEmitter<TriState>();

  constructor(
  ) {
    super();
  }

  ngOnInit() {
  }

  onClick() {
    this.value = this.value == 'off' ? 'on' : 'off';
    this.valueChange && this.valueChange.emit(this.value);
  }
}
