import { NgModule } from '@angular/core';
import { BasicModule } from 'src/app/basic.module';
import { FtFmesSideProfileComponent } from './ft-fmes-side-profile';



@NgModule({
  declarations: [FtFmesSideProfileComponent],
  imports: [
    BasicModule,
  ],
  exports: [FtFmesSideProfileComponent],
})
export class FtFmesSideProfileCompModule { }
