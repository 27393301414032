<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="black2">{{'컴포넌트.메모 기록'|translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" [disabled]="isDisabled()" (click)="save()">
        <ion-text color="main">{{'저장'|translate}}</ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-item style="--border-color: transparent; height: 100%; --highlight-color-valid: var(--ft-color-main);">
  <ion-textarea [(ngModel)]="uMemo" placeholder={{memoPlaceholder}} autoGrow="true" maxlength="500"
    style="font-size: 14px; margin: 0;">
  </ion-textarea>
</ion-item>
