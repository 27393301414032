import { Injector, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppComponent } from "src/app/app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BaseComponent } from "@shared-lib/base.component";
import { LocalNotifications as NativeLocalNotifications } from '@ionic-native/local-notifications/ngx';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { CommonShareModules } from "@shared-lib";
import { environment } from "src/environments/environment";
import packageJson from '../../package.json'
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FittLibModule } from '@fitt-lib';
import { NgxMaskModule } from "ngx-mask";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { TranslationLocPipeModule } from '@shared-lib/pipe/trans-loc/trans-loc.module';
import { DatePickerModalModule } from '@shared-lib/modals/date-picker/date-picker.module'
import { FtFmesSideProfileCompModule } from './components/ft-fmes-side-profile/ft-fmes-side-profile.module';
import { MobileFMTGuideModalModule } from '@fitt-lib/modals/mobile-exercise-test/add-mobile-exercise-test/add-mobile-fmt-detail/mobile-fmt-guide/mobile-fmt-guide.module';
import { FmesSettingsMainModalModule } from 'src/app/modals/fmes-settings-main/fmes-settings-main.module';
import { FmesSubscribeMainModalModule } from 'src/app/modals/fmes-subscribe-main/fmes-subscribe-main.module';
import { FmesFmtGuideModalModule } from 'src/app/modals/fmes-fmt-guide/fmes-fmt-guide.module';


export function createTranslateLoader(http: HttpClient) {
  // 다국어 파일의 확장자와 경로를 지정
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ mode: "md" }),
    NgxMaskModule.forRoot(),
    CommonShareModules.forRoot({ environment }),
    TranslationLocPipeModule.forRoot('translations/app'),
    FittLibModule.forRoot({ environment, packageJson }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule, HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    DatePickerModalModule,
    FtFmesSideProfileCompModule,
    MobileFMTGuideModalModule,
    FmesSettingsMainModalModule,
    FmesSubscribeMainModalModule,
    FmesFmtGuideModalModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeLocalNotifications,
    SocialSharing
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    BaseComponent.injector = injector;
    BaseComponent.timestamp = Date.now();
  }
}
