import { APostState } from '@fitt-lib/models/posts-types';
import { PackageType, TrainerAuthType } from '@fitt-lib/models/schedule-models';
import { Image } from '@shared-lib/components/image-uploader/image-uploader';
import { StringUnion } from '@shared-lib/utils/string-union';

function assignValue(value) { return value ? value : null; }
function assignDateValue(value) { return value ? new Date(value) : null; }

// 매출관리등 화면 출력시 분류. 여기서 ETCS는 HEALTH, PT, GX를 제외한 모두를 의미한다.
export const DISP_PACKAGE_TYPE = StringUnion('HEALTH', 'PT', 'GX', 'POINT', 'ETCS');
export type DispPackageType = typeof DISP_PACKAGE_TYPE.type;

export type MemberRole = 'trainer' | 'user' | 'none';

export class FittMember { // 내 정보
  // gApi: ApiService;  // 이게 여기 왜있지..?
  member: Member;
  gSeq?: number;
  private user?: User;
  private trainer?: Trainer;  // 내가 트레이너일 경우 표시됨. 나를 담당하는 트레이너 아님.
  gyms?: Array<Gym>;
  gymPayment: any;

  constructor(args: { member: Member, user?: User, trainer?: Trainer, gyms?: Array<Gym> }) {
    this.member = args.member;
    this.user = args.user;
    this.trainer = args.trainer;
    this.gyms = args.gyms;
    this.getTrainer();  // Trainer 생성용 dummy 호출
  }

  isOwner(): boolean {
    if (!this.trainer) this.getTrainer();
    return (this.trainer && (this.trainer.tAuth === 'OWNER'));
  }

  isSupervisor(): boolean {
    if (!this.trainer) this.getTrainer();
    const gym = this.getGym();
    return (this.trainer && (['OWNER', 'SUPERVISOR'].includes(this.trainer.tAuth)) && gym.gSeq == gym.ggHeadSeq);
  }

  isManager(): boolean {
    if (!this.trainer) this.getTrainer();
    return (this.trainer && (['OWNER', 'SUPERVISOR', 'MANAGER'].includes(this.trainer.tAuth)));
  }

  isCommon(): boolean {
    if (!this.trainer) this.getTrainer();
    return (this.trainer && (this.trainer.tAuth === 'COMMON'));
  }

  isTrainer(): boolean {
    if (!this.trainer) this.getTrainer();
    return (this.trainer && (this.trainer.tAuth === 'TRAINER'));
  }

  isUser(): boolean {
    return this.getRole() === 'user';
  }

  isGymMember(): boolean {
    return this.getGymSeq() > 0 ? true : false;
  }

  isHeadGroupGym(): boolean {
    const gym = this.getGym();
    return gym && gym.gSeq == gym.ggHeadSeq;
  }

  isGroupGym(): boolean {
    const gym = this.getGym();
    return gym && Boolean(gym.ggHeadSeq);
  }

  setGymSeq(gSeq) {
    if (this.gyms.filter((el) => el.gSeq == gSeq).length > 0) {
      localStorage.gSeq = gSeq;
    }
    else {
      localStorage.gSeq = undefined;
      this.trainer = null;
    }
  }

  getGymSeq() {
    if (this.gyms.length == 0) {
      return null;
    }
    else if (isNaN(localStorage.gSeq)) {
      if (this.gyms.filter((el) => el.tSeq != null).length > 0) {
        localStorage.gSeq = this.gyms.find((el) => el.tSeq != null).gSeq;
      }
      else if (this.gyms.filter((el) => el.uSeq != null).length > 0) {
        localStorage.gSeq = this.gyms.find((el) => el.uSeq != null).gSeq;
      }
      else {
        this.setGymSeq(null);
      }
    }
    return Number(localStorage.gSeq);
  }
  getGym(): Gym {
    return this.gyms.find((el) => el.gSeq == Number(this.getGymSeq())) || null;
  }
  getTrainerSeq(): number {
    return this.getTrainer() != null ? this.getTrainer().tSeq : -1;
  }
  getRole(): MemberRole {
    return this.getTrainerSeq() > 0 ? 'trainer' : 'user';
  }
  getTrainer(): Trainer | null {
    const tInfo = this.gyms.find((el) => el.gSeq == Number(this.getGymSeq()));
    this.trainer = tInfo != null ? new Trainer(tInfo) : null;
    return this.trainer;
  }

  /**
   * 1. isGymUser = true,
   *    트레이너가 추가한 회원 uSeq (USER_Origin = 0)
   * 2. isGymUser = false,
   *    회원이 가입한 계정 uSeq (USER_Origin = 1)
   * @param  {boolean} isGymUser
   * @returns {number} uSeq
   */
  getUserSeq(isGymUser: boolean = false): number {
    this.getUser(isGymUser);
    return this.user.uSeq;
  }

  getUser(isGymUser: boolean = false): User {
    if (!isGymUser) {
      this.user = new User(this.member);
    }
    else {
      const uInfo = this.gyms.filter((el) => el.gSeq == Number(this.getGymSeq()))[0];
      this.user = (uInfo != null ? new User(uInfo) : new User(this.member));
    }
    return this.user;
  }
}

export class GymUser {  // 짐 회원
  user?: User;
  member?: Member;      // 연동하지 않으면 데이터가 없다.
  trainer?: Trainer;    // 나를 담당하는 트레이너. 마지막 결제기준
  isDeleted?: boolean;  // 삭제된 회원의 경우 설정한다. 화면 표시용으로만 사용한다.

  constructor(values: { user?, member?, trainer?} = {}) {
    const { user, member, trainer } = values
    this.user = user ? new User(user) : undefined;
    this.member = member ? new Member(member) : undefined;
    this.trainer = trainer ? new Trainer(trainer) : undefined;
  }
}

export class Gym {
  gId?: string;
  gSeq?: number;
  gName: string;
  gEmail: string;
  gTel: string;
  gAddress: string;
  gInfo: string;
  gTotal: number;
  gActive: number;
  gOwnerEmail?: string;
  gTimeZone?: string;
  gLogo?: string;
  ggHeadSeq: number;
  ggState: boolean;
  ggJoin: boolean;

  tSeq?: number;
  tAuth?: string;
  uSeq?: number;
  uProfile?: string;

  pkSeq?: number;
  pkName?: string;
  pkTitle?: string;
  pkPrice?: number;
  amount?: number;
  inDate?: string;
  schedulerType?: string;
  expiredDate?: string;
  expiredDays?: number;
  expiredDaysToMins?: number;
  cancelDate?: string;
  tgrSeq?: number;
  tgrCode?: number;
  tgrSession?: number;
  trCount?: number;
  uConnect?: number;
  isCurrentLangKo?: boolean;
  displayName?: string;
  constructor(values) {
    this.gId = values.gId;
    this.gSeq = values.gSeq;
    this.gName = values.gName;
    this.gEmail = values.gEmail;
    this.gTel = values.gTel;
    this.gAddress = values.gAddress;
    this.gInfo = values.gInfo;
    this.gTotal = values.gTotal;
    this.gActive = values.gActive;
    this.ggHeadSeq = values.ggHeadSeq;
    this.ggState = values.ggState;
    this.ggJoin = values.ggJoin;

    this.tSeq = values.tSeq;
    this.tAuth = values.tAuth;
    this.uSeq = values.uSeq;
    this.uProfile = values.uProfile;

    this.pkSeq = values.pkSeq;
    this.pkName = values.pkName;
    this.pkTitle = values.pkTitle;
    this.pkPrice = values.pkPrice;
    this.amount = values.amount;
    this.inDate = values.inDate;
    this.schedulerType = values.schedulerType;
    this.expiredDate = values.expiredDate;
    this.expiredDays = values.expiredDays;
    this.expiredDaysToMins = values.expiredMinutes;
    this.cancelDate = values.cancelDate;
    this.tgrSeq = values.tgrSeq;
    this.tgrCode = values.tgrCode;
    this.tgrSession = values.tgrSession;
    this.trCount = values.trCount;
    this.uConnect = values.uConnect;
    this.isCurrentLangKo = values.isCurrentLangKo;
  }
}

export class GymPayRec {
  pSeq?: number;
  pkSeq?: number;
  expiredDate?: string;
  pState?: string;
  constructor(values) {
    this.pSeq = values.pSeq;
    this.pkSeq = values.pkSeq;
    this.expiredDate = values.expiredDate;
    this.pState = values.pState;
  }
}

export class PlanPermission {
  gymNoticeMgt: boolean;
  gymLockerMgt: boolean;
  scheduler: boolean;
  schedulerType: string;    // 'NONE' || 'PT' || 'GX' || 'ALL'
  centerMgt: boolean;
  gymData: boolean;
  payrollMgt: boolean;
  salesMgt: boolean;
  gymUserFilter: boolean;
  gymUserPayment: boolean;
  tmtFlag: boolean;
  smtFlag: boolean;
  fmtFlag: boolean;
  lacticFlag: boolean;

  constructor(values) {
    this.gymNoticeMgt = values.gymNoticeMgt;
    this.gymLockerMgt = values.gymLockerMgt;
    this.scheduler = values.scheduler;
    this.schedulerType = values.schedulerType;
    this.centerMgt = values.centerMgt;
    this.gymData = values.gymData;
    this.payrollMgt = values.payrollMgt;
    this.salesMgt = values.salesMgt;
    this.gymUserFilter = values.gymUserFilter;
    this.gymUserPayment = values.gymUserPayment;
    this.tmtFlag = values.tmtFlag;
    this.smtFlag = values.smtFlag;
    this.fmtFlag = values.fmtFlag;
    this.lacticFlag = values.lacticFlag;
  }
}

export class Member {
  mSeq?: number;
  mEmail: string;
  mName: string;
  mAgreeTerms: boolean;
  mAgreePolicy: boolean;
  mAgreeMarketing: boolean;
  mS1?: boolean;
  mS2?: boolean;
  mS3?: boolean;
  mS4?: boolean;
  mSnsId?: string;
  mSnsType?: string;
  mProfile?: string;
  mInDate?: Date;
  uProfile?: string;
  uBirth?: Date;
  uBodyFat?: number;
  uGender?: string;
  uHeight?: number;
  uName?: string;
  uSeq?: number;
  uTel?: string;
  uTel2?: string; // 비상연락처
  uWaist?: number;
  uWeight?: number;
  uWhr?: number;
  uMemo?: string;
  constructor(values) {
    this.mSeq = values.mSeq;
    this.mEmail = values.mEmail;
    this.mName = values.mName;
    this.mAgreeTerms = values.mAgreeTerms;
    this.mAgreePolicy = values.mAgreePolicy;
    this.mAgreeMarketing = values.mAgreeMarketing;
    this.mS1 = values.mS1;
    this.mS2 = values.mS2;
    this.mS3 = values.mS3;
    this.mS4 = values.mS4;
    this.mSnsId = values.mSnsId;
    this.mSnsType = values.mSnsType;
    this.mProfile = values.mProfile;
    this.mInDate = values.mInDate ? new Date(values.mInDate) : undefined;
    this.uProfile = values.uProfile;
    // this.uBirth = values.uBirth;
    this.uBirth = values.uBirth ? new Date(values.uBirth) : undefined;
    this.uBodyFat = values.uBodyFat;
    this.uGender = values.uGender;
    this.uHeight = values.uHeight;
    this.uName = values.uName;
    this.uSeq = values.uSeq;
    this.uTel = values.uTel;
    this.uTel2 = values.uTel2;
    this.uWaist = values.uWaist;
    this.uWeight = values.uWeight;
    this.uWhr = values.uWhr;
    this.uMemo = values.uMemo;
  }
}

export class User {
  uSeq?: number;
  gSeq?: number;
  uName: string;
  uAge?: number;
  uGender: Gender;
  uBirth: Date;
  uTel: string;
  uTel2?: string; // 비상연락처
  uEmail?: string;
  uProfile?: string; // gym에서 등록하는 사진으로 출입시 표시하는 사진.
  uState: number;  // 휴면회원: 0, 진행회원: 1
  uInDate?: Date;
  tSeq?: number;
  tName?: string;

  uWeight?: number;
  uHeight?: number;
  uBodyFat?: number;
  uWaist?: number;
  uWhr?: number;
  uMemo?: string;
  uFriendSeq?: number;

  pDiv?: string;
  pType?: string;
  pTotalCnt?: number;
  pTotalAmount?: number;
  pFirstRegistDate?: Date;
  pLastRegistDate?: Date;
  sLastScheduleDate?: Date;
  recentPackageExpireDate?: Date

  isNewMember?: boolean = false;
  mInDate?: Date;

  descText?: string;

  uVisible?: boolean;
  uConnect?: number;
  isCurrentLangKo?: boolean;

  constructor(values) {
    this.uSeq = values.uSeq;
    this.gSeq = values.gSeq;
    this.uName = values.uName;
    this.uAge = values.uAge ? values.uAge : undefined;
    this.uGender = values.uGender;
    this.uBirth = values.uBirth ? new Date(values.uBirth) : undefined;
    this.uTel = values.uTel;
    this.uTel2 = values.uTel2;
    this.uEmail = values.uEmail;
    this.uProfile = values.uProfile;
    this.uState = values.uState;
    this.uInDate = values.uInDate ? new Date(values.uInDate) : undefined;
    this.tSeq = values.tSeq;
    this.tName = values.tName

    this.uWeight = values.uWeight;
    this.uHeight = values.uHeight;
    this.uBodyFat = values.uBodyFat;
    this.uWaist = values.uWaist;
    this.uWhr = values.uWhr;
    this.uMemo = values.uMemo;
    this.uFriendSeq = values.uFriendSeq;

    this.pDiv = values.pDiv;
    this.pType = values.pType;
    this.pTotalCnt = values.pTotalCnt;
    this.pTotalAmount = values.pTotalAmount || 0;
    this.pFirstRegistDate = values.pFirstRegistDate ? new Date(values.pFirstRegistDate) : undefined;
    this.pLastRegistDate = values.pLastRegistDate ? new Date(values.pLastRegistDate) : undefined;
    this.sLastScheduleDate = values.sLastScheduleDate ? new Date(values.sLastScheduleDate) : undefined;
    this.recentPackageExpireDate = values.recentPackageExpireDate ? new Date(values.recentPackageExpireDate) : undefined;
    this.descText = values.descText ? values.descText : undefined;
    if (this.uInDate) {
      this.isNewMember = this.uInDate.within('month', 0);  // 신규 멤버는 1일로 설정
    }
    this.mInDate = values.mInDate ? new Date(values.mInDate) : undefined;
    this.uVisible = values.uVisible;
    this.uConnect = values.uConnect;
    this.isCurrentLangKo = values.isCurrentLangKo;
  }

  getAge() {
    if (this.uAge !== undefined) { return this.uAge; }
    else if (this.uBirth === undefined) { return 0; }
    const ageDifMs = Date.now() - new Date(this.uBirth).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  getAgeFromDate(date: Date | string) {
    const ageDifMs = new Date(date || new Date()).getTime() - new Date(this.uBirth).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}

export class Trainer {
  tSeq?: number;
  gSeq?: number;
  tName: string;
  tEmail: string;
  tJob: string;
  tBirth: Date;
  tTel: string;
  tGender: Gender;
  tSubject: string;
  tBelong: string;
  tLocation: string;
  tProfile?: string;
  tEntryDate?: Date;
  tRetire: Date;
  tAuth: TrainerAuthType;
  tOrder?: number;
  mInDate: Date;
  tConnect?: number;
  tState?: number;
  tWeight: number;
  tHeight: number;

  constructor(values) {
    this.tSeq = values.tSeq;
    this.gSeq = values.gSeq;
    this.tName = values.tName;
    this.tEmail = values.tEmail;
    this.tJob = values.tJob;
    this.tBirth = values.tBirth ? new Date(values.tBirth) : undefined;
    this.tTel = values.tTel;
    this.tGender = values.tGender;
    this.tSubject = values.tSubject;
    this.tBelong = values.tBelong;
    this.tLocation = values.tLocation;
    this.tProfile = values.tProfile;
    this.tEntryDate = values.tEntryDate ? new Date(values.tEntryDate) : undefined;
    this.tRetire = values.tRetire ? new Date(values.tRetire) : undefined;
    this.tAuth = values.tAuth;
    this.tOrder = values.tOrder;

    this.mInDate = values.mInDate ? new Date(values.mInDate) : undefined;
    this.tConnect = values.tConnect;
    this.tState = values.tState;
    this.tWeight = values.tWeight;
    this.tHeight = values.tHeight;
  }
}

export class PtReport {
  rKey?: string;
  sSeq: number;
  gSeq: number;
  tSeq: number;
  tObj?: Trainer;
  uSeq: number;
  uObj?: User;
  rSubject: string;
  rStartDate: Date;
  rEndDate: Date;
  kUsedSession: string;
  rText: string;
  rFiles?: Array<Image>;
  constructor(values) {
    this.rKey = assignValue(values.rKey);
    this.sSeq = assignValue(values.sSeq);
    this.gSeq = assignValue(values.gSeq);
    this.tSeq = assignValue(values.tSeq);
    this.tObj = assignValue(values.tObj);
    this.uSeq = assignValue(values.uSeq);
    this.rSubject = assignValue(values.rSubject);
    this.rStartDate = assignDateValue(values.rStartDate);
    this.rEndDate = assignDateValue(values.rEndDate);
    this.kUsedSession = assignValue(values.kUsedSession);
    this.rText = assignValue(values.rText);
    this.rFiles = assignValue(values.rFiles);
  }
}

export class Post {
  uid: string;
  uEmail: string;
  uName: string;
  uAge: number;
  uTel: string;
  uLocation: string;
  uGender: number;
  title: string;
  content: string;
  state: APostState;
  channel: string;
  inDate: Date;
  writer: any;
  tSeq: number;
  tName: string;
  isCurrentLangKo: boolean;

  files?: Array<string>;
  // 기타 필요한 부분 정의. 작성일을 정의하지 않은 것은 key로 역생성가능.
}
export class Comment {
  content: string;
  state: string;
  tSeq: number;
  tName: string;
}

export class PayrollExcel {
  tName: string;
  ptAmount: string;
  ptCount: string;
  gxCount: string;
  etcCount: string;
  basicSalary: string;
  ptSalary: string;
  gxSalary: string;
  otSalary: string;
  commissionAmount: string;
  finalSalary: string;
  finalSalaryTax: string;
  ptDesc: string;
  gxDesc: string;
  etcDesc: string;
  commissionDesc: string;
  ptStandard: string;
  gxStandard: string;
  constructor(values) {
    this.tName = values.tName;
    this.ptAmount = values.ptAmount;
    this.ptCount = values.ptCount;
    this.gxCount = values.gxCount;
    this.etcCount = values.etcCount;
    this.basicSalary = values.basicSalary;
    this.ptSalary = values.ptSalary;
    this.gxSalary = values.gxSalary;
    this.otSalary = values.otSalary;
    this.commissionAmount = values.commissionAmount;
    this.finalSalary = values.finalSalary;
    this.finalSalaryTax = values.finalSalaryTax;
    this.ptDesc = values.ptDesc.join('\n');
    this.gxDesc = values.gxDesc.join('\n');
    this.etcDesc = values.etcDesc;
    this.commissionDesc = values.commissionDesc;
    this.ptStandard = values.ptStandard;
    this.gxStandard = values.gxStandard.join('\n');
  }
}

export class PayrollSheet {
  header: string[];
  jsonData: PayrollExcel[];
  constructor(header, jsonData) {
    this.header = header;
    this.jsonData = jsonData;
  }
}

export class GymSalarySetting {
  gSeq: number;
  useBasicSalarySetting: boolean;
  trainerSalary: Array<{
    tSeq: number,
    tName: string,
    profile: string,
    basicSalary: number,
    PTSalaryType: 'FIXED' | 'RATIO',
    PTFixedAmount: number,
    PTRatioAmount: number,
    GXFixedAmount: number,
  }>;
  PTSalarySettingType: 'TOTAL' | 'TRAINER' | 'USER';
  PTTotalSalary: number;
  userFeePerPT: Array<{
    uSeq: number,
    pSeq: number,
    uProfile: string,
    uName: string,
    uBirth: Date,
    uState: number,
    uConnect: number,
    tName: string,
    pSession: number,
    pAmount: number,
    userPTAmount: number,
    salary: number,
  }>;
  useGXSalarySetting: boolean;
  GXSalarySettingType: 'TOTAL' | 'TRAINER' | 'CLASS' | 'COUNT';
  GXSalaryPerClass: Array<{
    cSeq: number,
    cName: number,
    amount: number
  }>;
  GXTotalSalary: number;
  GXSalaryPerCount: Array<{
    count: number,
    salary: number
  }>;
  useOTSalarySetting: number;
  OTTotalSalary: number;
  useTESTSalarySetting: number;
  TESTTotalSalary: number;
  usePtCommissionSetting: number;
  ptCommissionSettingType: 'TOTAL' | 'OVER';
  ptCommissionOverTotalAmount: number;
  ptCommissionOverTotalRatio: number;
  ptCommissionOverTotalDistributionIndex: number = 0;
  ptCommissionOverTrainerAmount: number;
  ptCommissionOverTrainerRatio: number;
  useGxCommissionSetting: number;
  gxCommissionSettingType: 'TOTAL' | 'OVER';
  gxCommissionOverTotalAmount: number;
  gxCommissionOverTotalRatio: number;
  gxCommissionOverTotalDistributionIndex: number = 0;
  gxCommissionOverTrainerAmount: number;
  gxCommissionOverTrainerRatio: number;
  usePTStandard: boolean;
  PTStandardCount: number;
  useGXStandard: number;
  useGXOverCount: number;
  GXOverCount: number;
  useGXOverPeople: number;
  GXOverPeople: number;
  constructor() {
  }

  setGymSetting(gymSetting: any) {
    const {
      gSeq,
      useBasicSalarySetting,
      PTSalarySettingType,
      PTTotalSalary,
      useGXSalarySetting,
      GXSalarySettingType,
      GXTotalSalary,
      useOTSalarySetting,
      OTTotalSalary,
      useTESTSalarySetting,
      TESTTotalSalary,
      usePtCommissionSetting,
      ptCommissionSettingType,
      ptCommissionOverTotalAmount,
      ptCommissionOverTotalRatio,
      ptCommissionOverTotalDistributionIndex,
      ptCommissionOverTrainerAmount,
      ptCommissionOverTrainerRatio,
      useGxCommissionSetting,
      gxCommissionSettingType,
      gxCommissionOverTotalAmount,
      gxCommissionOverTotalRatio,
      gxCommissionOverTotalDistributionIndex,
      gxCommissionOverTrainerAmount,
      gxCommissionOverTrainerRatio,
      usePTStandard,
      PTStandardCount,
      useGXStandard,
      useGXOverCount,
      useGXOverPeople,
      GXOverCount,
      GXOverPeople
    } = gymSetting;
    this.gSeq = gSeq;
    this.useBasicSalarySetting = useBasicSalarySetting || false;
    this.PTSalarySettingType = PTSalarySettingType;
    this.PTTotalSalary = PTTotalSalary;
    this.useGXSalarySetting = useGXSalarySetting || false;
    this.GXSalarySettingType = GXSalarySettingType;
    this.GXTotalSalary = GXTotalSalary;
    this.useOTSalarySetting = useOTSalarySetting || false;
    this.OTTotalSalary = OTTotalSalary;
    this.useTESTSalarySetting = useTESTSalarySetting || false;
    this.TESTTotalSalary = TESTTotalSalary;
    this.usePtCommissionSetting = usePtCommissionSetting || false;
    this.ptCommissionSettingType = ptCommissionSettingType || 'TOTAL';
    this.ptCommissionOverTotalAmount = ptCommissionOverTotalAmount;
    this.ptCommissionOverTotalRatio = ptCommissionOverTotalRatio;
    this.ptCommissionOverTotalDistributionIndex = ptCommissionOverTotalDistributionIndex || 0;
    this.ptCommissionOverTrainerAmount = ptCommissionOverTrainerAmount;
    this.ptCommissionOverTrainerRatio = ptCommissionOverTrainerRatio;
    this.useGxCommissionSetting = useGxCommissionSetting || false;
    this.gxCommissionSettingType = gxCommissionSettingType || 'TOTAL';
    this.gxCommissionOverTotalAmount = gxCommissionOverTotalAmount;
    this.gxCommissionOverTotalRatio = gxCommissionOverTotalRatio;
    this.gxCommissionOverTotalDistributionIndex = gxCommissionOverTotalDistributionIndex || 0;
    this.gxCommissionOverTrainerAmount = gxCommissionOverTrainerAmount;
    this.gxCommissionOverTrainerRatio = gxCommissionOverTrainerRatio;
    this.usePTStandard = usePTStandard;
    this.PTStandardCount = PTStandardCount;
    this.useGXStandard = useGXStandard || false;
    this.useGXOverCount = useGXOverCount || false;
    this.useGXOverPeople = useGXOverPeople || false;
    this.GXOverCount = GXOverCount;
    this.GXOverPeople = GXOverPeople;
  }
}

export class GymSalarySettingUnsetState {
  unsetBasicSalary: boolean = false;
  unsetPTTotalSalary: boolean = false;
  unsetPTAmountPerTrainer: boolean = false;
  unsetUserFeePerPT: boolean = false;
  unsetGXSalarySetting: boolean = false;
  unsetGXTotalSalary: boolean = false;
  unsetGXAmountPerTrainer: boolean = false;
  unsetGXAmountPerClass: boolean = false;
  unsetGXSalaryPerCount: boolean = false;
  unsetOTTotalSalary: boolean = false;
  unsetTESTTotalSalary: boolean = false;
  unsetPtCommissionSetting: boolean = false;
  unsetPTCommissionTotal: boolean = false;
  unsetPTCommissionOver: boolean = false;
  unsetGxCommissionSetting: boolean = false;
  unsetGXCommissionTotal: boolean = false;
  unsetGXCommissionOver: boolean = false;
  unsetPTStandardCount: boolean = false;
  unsetGXOverCount: boolean = false;
}

// /members/uid/settings/notification
export class SettingsNotification {
  enable: boolean;
  privacy?: { enable: true, startTime: string, endTime: string };
  payment?: boolean;
  schedule?: boolean;
  health?: boolean;
  runner?: boolean;
}

// /members/uid/settings/member-notification
export class SettingsMemberNotification {
  enable: boolean;
  birthday?: boolean;
  memberSign?: boolean;
  payment?: boolean;
  sign?: boolean;
}

// localStorage
export class SettingsRunning {
  autoPause: boolean;
  voiceFeedback: boolean;
  countDownSec: number;
  vibrateOnStart: boolean;
  lockOnStart: boolean;
  muteOnNotification: boolean;
  doNotDisturb: boolean;
  constructor(args: SettingsRunning = {
    // TODO : #0002290 위해 autoPause 막아놓음. 개선 시 autoPause false 할당 로직 삭제.
    autoPause: false, voiceFeedback: true, countDownSec: 3, vibrateOnStart: true, lockOnStart: true, muteOnNotification: true, doNotDisturb: true
  }) {
    Object.assign(this, args);
  }
}

export class GuideMessage {
  black: string;
  warning: string;

  constructor(args: GuideMessage = {
    black: '', warning: ''
  }) {
    Object.assign(this, args);
  }
}

export class MonthlyTrainerInfo {
  isRetire: boolean;
  constructor(args: MonthlyTrainerInfo = {
    isRetire: false,
  }) {
    this.isRetire = args.isRetire;
  }
}

export class HsrData {
  height: number;
  weight: number;
  waist: number;
  fat: number;
  whr: number;
  bmi?: number;

  sugar: number;
  totalc: number;
  hdlc: number;
  ldlc: number;
  lipid: number;
  sbp: number;
  dbp: number;
  HbA1c: number;

  constructor(values) {
    if (values) {
      for (const key of Object.keys(values)) {
        this[key] = values[key];
      }
    }

    if (!this.bmi && this.height && this.weight) {
      this.bmi = Number((this.weight / ((this.height / 100) * (this.height / 100))).toFixed(1));
    }
  }
}

export class GymInfoRaw {  // b2b.getGymInfo
  GYM_Seq: number;
  GYM_Address: string;
  GYM_CompanyNumber: string;
  GYM_CompanyNumberLink: string;
  GYM_DepositAccount: string;
  GYM_DepositAccountLink: string;
  GYM_DepositBank: string;
  GYM_Id: string;
  GYM_InDate: string;
  GYM_Info: string;
  GYM_Name: string;
  gOwner: string;
  GYM_Tel: string;
  GYM_TimeZone: string;
}

export class TPackage {
  paSeq?: number;
  gSeq?: number;
  tSeq?: number;
  paType?: PackageType;
  paName?: string;
  paSession?: number;
  paPeriod?: number;
  paAmount?: number;
  paInDate?: string;
  paVisible?: number;
  paFixed?: number;
  paNew?: number;

  selected?: boolean;
  uptBalance?: number;

  constructor(_package: TPackage) {
    const { paSeq, gSeq, tSeq, paType, paName, paSession, paPeriod, paAmount, paInDate, paVisible, paFixed, paNew } = _package;

    this.paSeq = paSeq;
    this.gSeq = gSeq;
    this.tSeq = tSeq;
    this.paType = paType;
    this.paName = paName;
    this.paSession = paSession;
    this.paPeriod = paPeriod;
    this.paAmount = paAmount;
    this.paInDate = paInDate;
    this.paVisible = paVisible;
    this.paFixed = paFixed;
    this.paNew = paNew;
  }
}

export type Ticket = {
  paymentSeq: number;
  pStartDate: Date | string;
  pEndDate: Date | string;
  pSession: number;
  uSession: number;
  trSession: number;
  session_used: number;
  uPeriod: any;
  period: any;

  active: boolean;
  selected: boolean;
}

export class UserPayment {
  gSeq: number;
  tSeq: number;
  tName: string;
  tProfile: string;
  htSeq: number;
  htName: string;
  uSeq: number;
  uBirth: Date;
  uConnect: number
  uGender: Gender;
  uName: string;
  uProfile: string;
  uState: number;  // 휴면회원: 0, 진행회원: 1
  uTel: string;

  pSeq: number;
  pAmount: number;
  pCancelDate: Date;
  pEndDate: Date;
  pInDate: Date;
  pPeriod: number;
  pRefundAmount: number;
  pRegistDate: Date;
  pReqStatus: string;
  pSession: number;
  pSessionUsed: number;
  pStartDate: Date;
  pType: string;

  pTransferAmount: number;
  pTransferSeq: number;
  pOriginSeq: number;
  trSession: number;
  trState: 'SELLER' | 'BUYER' | 'ORIGIN'

  paSeq: number;
  paName: string;
  paType: PackageType;

  paidType: string;
  payQuickBtnTitle: string;
  payWillorDoneTitle: string;
  paymentCount: number;

  upMemo: string;

  constructor(args?: any) {
    for (const field in args) {
      if (args[field] === undefined) {
        continue;
      }
      const isDateField = args[field] && ['uBirth', 'pCancelDate', 'pEndDate', 'pInDate', 'pRegistDate', 'pStartDate'].includes(field);

      this[field] = isDateField ? new Date(args[field]) : args[field];
    }

    if (!this.pOriginSeq && !this.pTransferSeq) {
      this.trState = 'ORIGIN';
    } else if (this.pOriginSeq === this.pSeq) {
      this.trState = 'SELLER';
    } else if (this.pTransferSeq === this.pSeq) {
      this.trState = 'BUYER';
    }
  }

  isPointPayment(): boolean {
    return this.paSeq === -1 && this.paType === 'POINT';
  }

  isTransfer(): boolean {
    return this.trState !== 'ORIGIN';
  }
}

export class PaymentLogs {
  constructor(args?: any) {

  }
}
