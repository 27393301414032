import { Component, OnInit, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { ModalController } from '@ionic/angular';
import { FmtItem, FMT } from '@fitt-lib/models/exercise-test/fmt';

@Component({
  selector: 'mobile-fmt-guide',
  templateUrl: './mobile-fmt-guide.html',
  styleUrls: ['./mobile-fmt-guide.scss']
})
export class MobileFMTGuideModal extends FtLibBaseComponent implements OnInit {
  @Input() fmtCheckIndex: number; // 8개 종목 index

  fmtItemList: Array<FmtItem> = FMT.getFmtItemList();

  readonly YOUTUBE_PREFIX = 'https://www.youtube.com/embed/';
  src: string;

  constructor(
    private modalCtrl: ModalController,
  ) {
    super();
  }

  ngOnInit() {
    this.src = this.YOUTUBE_PREFIX + this.fmtItemList[this.fmtCheckIndex].guideId;
  }

  clickSNS(type: 'youtube' | 'instagram' | 'facebook' | 'blog') {
    this.util.linkSNS(type);
  }

  async close() {
    await this.modalCtrl.dismiss();
  }
}
