import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasFocusClassDirective } from './has-focus-class.directive';

@NgModule({
  declarations: [
    HasFocusClassDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    HasFocusClassDirective,
  ],
})
export class HasFocusClassDirectiveModule { }
