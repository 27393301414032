import { Injectable } from '@angular/core';
import { FormatType } from '@fitt-lib/models/schedule-setting-models';

@Injectable({
  providedIn: 'root'
})
export class UserPaymentService {

  constructor() { }

  private getPeriodDate(startDate: string, period: number): Date {
    const result = new Date(startDate);

    return period === 0 ? result : result.getNewDate('month', period).getNewDate('date', -1);
  }

  public getExpiryDate(startDate: string, period?: number) {
    const endDate = this.getPeriodDate(startDate, period);

    return {
      startDate: this.getFormattedDate(startDate, 'start'),
      endDate: this.getFormattedDate(endDate, 'end')
    };
  }

  public getFormattedDate(target: string | Date, formatType: FormatType) {
    const formats: Record<FormatType, string> = {
      start: 'yyyy-MM-ddT00:00:00',
      end: 'yyyy-MM-ddT23:59:59'
    };
    const date = typeof target === 'string' ? new Date(target) : target;

    return date.format(formats[formatType]);
  }
}
