import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSelect, ModalController } from '@ionic/angular';
import { ShortcutEvent, ShortcutService } from '@shared-lib/services/shortcut.service';
import { SelectOption } from '@shared-lib/models/component-models';
import { BookingState } from '@fitt-lib/models/booking-models';
import { Schedule } from '@fitt-lib/models/schedule-models';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

@Component({
  selector: 'select-no-show',
  templateUrl: './select-no-show.html',
  styleUrls: ['./select-no-show.scss'],
})

export class SelectNoShowComponent extends FtLibBaseComponent implements OnInit, OnDestroy {
  @Input() schedule: Schedule;
  @Input() noshowType: number = 1;
  @Input() options: SelectOption<number>[];
  @ViewChild('noshowSelect', { static: true }) noshowSelect: IonSelect;

  noShowValue: number;
  event: ShortcutEvent = {
    selector: 'select-no-show', type: 'element',
    keyEvents: [{
      key: 'Enter', elementId: 'confirmButton'
    }, {
      key: 'ArrowLeft', elementId: 'cancelButton',
    }, {
      key: 'ArrowRight', elementId: 'confirmButton',
    }, {
      key: 'ArrowDown', elementId: 'dropdownButton'
    }]
  };

  constructor(
    private modalCtrl: ModalController,
    private shortcutService: ShortcutService,
  ) {
    super();
  }

  ngOnInit() {
    this.filterOptions();
    this.noShowValue = this.getInitialValue();
    this.shortcutService.register(this.event);
  }

  ngOnDestroy(): void {
    this.shortcutService.unregister(this.event);
  }

  filterOptions() {
    const { sState, sNoShowSession } = this.schedule;

    this.options = this.options.filter(({ value }) => !BookingState.NoShow.includes(sState) || sNoShowSession !== value);
  }

  getInitialValue(): number {
    try {
      return this.isPointSchedule() ? this.schedule.paAmount : this.options[0].value;
    } catch (error) {
      this.error('getInitialValue', { error });
      return 0;
    }
  }

  async cancel() {
    await this.modalCtrl.dismiss();
  }

  async save() {
    const isValidSession = typeof this.noShowValue === 'number' && this.noShowValue >= 0 && this.noShowValue <= this.schedule.paAmount;

    if (this.isPointSchedule() && !isValidSession) {
      await this.util.alertCreate({
        title: this.translate.instant('모달.알림'),
        message: this.translate.instant(
          '모달.차감 금액을 조정해주세요.\n(_ ~ _)',
          { min: 0, max: this.schedule.paAmount.currencyFormat() }
        ),
        buttons: [{ text: 'ok', role: 'ok', }]
      });
      return;
    }
    await this.modalCtrl.dismiss({ session: this.getValidSession(+this.noShowValue) });
  }

  getValidSession(session: number) {
    return session.formatInRange(0, this.isPointSchedule() ? this.schedule.paAmount : 1);
  }

  isPointSchedule(): boolean {
    return this.schedule && this.schedule.sType === 'POINT';
  }
}
