import { CountBase, CountSummary } from '@fitt-lib/models/schedule-models';
import { StringUnion } from '@shared-lib/utils/string-union';
import { fromPairs, sortBy, toPairs } from 'lodash';

export class SaleCardData {
  totalAmount: number;
  monthly: number;
  yearly: number;
  unlimited: number;
  eventPayment?: number;

  constructor(
    _totalAmount: number,
    _monthly: number,
    _yearly: number,
    _unlimited: number,
    _eventPayment?: number,
  ) {
    this.totalAmount = _totalAmount || 0;
    this.monthly = _monthly || 0;
    this.yearly = _yearly || 0;
    this.unlimited = _unlimited || 0;
    this.eventPayment = _eventPayment;
  }
}

const PAYMENT_STATE_TYPE = StringUnion('event', 'failed', 'paid', 'waiting', 'invalid');
export type PaymentStateType = typeof PAYMENT_STATE_TYPE.type;

const STATE_COLOR_TYPE = StringUnion('var(--ft-color-black1)', 'var(--ft-color-error)');
export type StateColorType = typeof STATE_COLOR_TYPE.type;

export type FittSaleData = {
  gName: string,
  pkName: string,
  pkPeriod: number,
  pkPeriodView: string,
  useState: string,
  mbCardName: string,
  mbCardNameView: string,
  gpState: string,
  gpStateView: string,
  gpStateColor: StateColorType,
  gpInDate: Date,
  gpInDateView: string,
  gpExpiredDate: Date,
  gpCancelDate: Date,
  gpAmount: number,
  gpAmountView: string,
};

export type ChartBarData = {
  main: number,
  sub?: number,
  label?: string | string[],
  mainColor?: string,
  subColor?: string,
  customTooltip?: boolean,
};

export class UserPaymentData {
  gSeq: number;
  ggHeadSeq: number;
  paName: string;
  paSeq: number;
  paType: string;
  tName: string;
  tSeq: number;
  uSeq: number;
  uBirth: Date;
  uGender: 'male' | 'female';
  uName: string;
  upType: string;
  upAmount: number;
  upRegisterOrder: number;
  upPaymentSession: number;
  upMaxSession: number;
  upRefundAmount: number;
  upRegistDate: Date;
  upInDate: Date;
  upStartDate: Date;
  upEndDate: Date;
  upPeriod: number;
  upSession: number;
  upSeq: number;
  ptConversion?: boolean;
  constructor(args?: any) {
    for (const field in args) {
      if (args[field] !== undefined) {
        if (['uBirth', 'upRegistDate', 'upInDate', 'upStartDate', 'upEndDate'].includes(field))
          this[field] = args[field] ? new Date(args[field]) : args[field];
        else {
          this[field] = args[field];
        }
      }
    }
    if (args.paType == 'POINT' && args.paSeq == -1) {
      this.paName = 'Charge POINT';
    }
  }
}

export class UserPaymentStats implements CountBase<UserPaymentData> {
  sum = 0;
  sum_new = 0;
  sum_re = 0;
  cnt = 0;
  cnt_new = 0;
  cnt_re = 0;

  // 아래 둘은 값 초기화 하지 않는다.
  period;
  ptConversionCount;

  attendedSession = 0;
  attendedAmount = 0;
  restSession = 0;
  restAmount = 0;

  sum_card = 0;
  sum_cash = 0;
  sum_phone = 0;
  sum_account = 0;
  sum_wire = 0;

  userCnt = 0;
  uSeqMap = {};

  records: UserPaymentData[] = [];
  getUserCount() {
    return !this.uSeqMap ? 0 : Object.keys(this.uSeqMap).length;
  }
  addCount(record: UserPaymentData) {
    const upAmount = record.upAmount - record.upRefundAmount;
    if (upAmount <= 0) return;

    this.records.push(record);

    this.cnt++;
    this.sum += upAmount;
    if (record.upRegisterOrder <= 1) {
      this.cnt_new++;
      this.sum_new += upAmount;
    }
    else {
      this.cnt_re++;
      this.sum_re += upAmount;
    }

    switch (record.upType) {
      case 'card': this.sum_card += upAmount; break;
      case 'cash': this.sum_cash += upAmount; break;
      case 'phone': this.sum_phone += upAmount; break;
      case 'wire': this.sum_wire += upAmount; break;
      case 'account': this.sum_account += upAmount; break;
    }

    this.uSeqMap[record.uSeq] = (this.uSeqMap[record.uSeq] || 0) + 1;
    this.userCnt = Object.keys(this.uSeqMap).length;

    if (record.upPeriod != undefined) this.period = (this.period || 0) + record.upPeriod;
    if (record.ptConversion != undefined) this.ptConversionCount = (this.ptConversionCount || 0) + record.ptConversion;

    if (record.upPaymentSession && upAmount) {
      this.attendedSession += record.upMaxSession;
      this.attendedAmount += (Math.floor(record.upMaxSession / record.upPaymentSession * upAmount) || 0);

      const restSession = record.upPaymentSession - record.upMaxSession;
      this.restSession += restSession;
      this.restAmount += (Math.floor(restSession / record.upPaymentSession * upAmount) || 0);
    }
  }
  getPaymentOrderCount(startOrder?: number, endOrder?: number) {
    let value = 0;
    for (const record of this.records) {
      if (startOrder && startOrder > record.upRegisterOrder) continue;
      if (endOrder && endOrder < record.upRegisterOrder) continue;
      value++;
    }
    return value;
  }
  getPaymentOrderUserCount(startOrder?: number, endOrder?: number) {
    const uSeqMap = {};
    for (const record of this.records) {
      if (startOrder && startOrder > record.upRegisterOrder) continue;
      if (endOrder && endOrder < record.upRegisterOrder) continue;
      uSeqMap[record.uSeq] = (record.uSeq || 0) + 1;
    }
    return Object.keys(uSeqMap).length;
  }
  getData(dataType: string) {
    switch (dataType) {
      case 'userCnt_re': return this.getPaymentOrderUserCount(2);
      case 'reregi1': return this.getPaymentOrderCount(2, 2);
      case 'reregi2': return this.getPaymentOrderCount(3, 3);
      case 'reregi3': return this.getPaymentOrderCount(4, 4);
      case 'reregi4more': return this.getPaymentOrderCount(5);
      case 'attendedSession': return this.attendedSession;
      case 'attendedAmount': return this.attendedAmount;
      case 'restSession': return this.restSession;
      case 'restAmount': return this.restAmount;
      case 'period': return this.period;
      default: return this[dataType];
    }
    return null;
  }
}
export type UserPaymentSummary = CountSummary<UserPaymentData, UserPaymentStats>;

export class EndEstimatedData {
  endDate: Date;
  expectedAmount: number;
  gSeq: number;
  isRepayment: string;
  pPeriod: number;
  pSeq: number;
  paName: string;
  paType: string;
  realAmount: number;
  tName: string;
  ticketInfo: string;
  uBirth: Date;
  uConnect: number;
  uGender: string;
  uName: string;
  uProfile: string;
  uSeq: number;
  uState: number;
  usedPercent: string;  // TODO

  constructor(args?: any) {
    for (const field in args) {
      if (args[field] !== undefined) {
        if (['endDate', 'uBirth'].includes(field))
          this[field] = args[field] ? new Date(args[field]) : args[field];
        else {
          this[field] = args[field];
        }
      }
    }
  }
}
export class EndEstimatedStats implements CountBase<EndEstimatedData> {
  expectedAmount = 0;
  realAmount = 0;
  repaymentCnt = 0;
  expectedCnt = 0;
  endCnt = 0;

  addCount(record: EndEstimatedData) {
    if (record.isRepayment !== 'END') this.expectedAmount += record.expectedAmount;
    if (record.isRepayment === 'REPAYMENT') this.realAmount += record.realAmount;
    if (record.isRepayment === 'REPAYMENT') this.repaymentCnt++;
    if (record.isRepayment === 'EXPECTED') this.expectedCnt++;
    if (record.isRepayment === 'END') this.endCnt++;
  }

  getData(dataType: string) {
    switch (dataType) {
      case 'expiredCount': return this.expectedCnt;
      case 'expiredAmount': return this.expectedAmount;
    }
  }
}
export type EndEstimatedSummary = CountSummary<EndEstimatedData, EndEstimatedStats>;


export class UserStatusRecord {
  monthKey: string;       // 2022-07
  totalCnt: number;     // 2022-07 의 전체 회원수이다. 따라서 다른 CountSummary 들이 하듯이 각 record를 더하면 안된다.
  activeCnt: number;      // 상동
  dormantCnt: number;     // 상동
  newCnt: number;       // 2022-07 이 신규 회원수 이다. 6월~7월 기간중의 newCount는 더해서 처리한다.

  constructor(args?: any) {
    for (const field in args) {
      this[field] = args[field];
    }
  }
}
export class UserStatusStats implements CountBase<UserStatusRecord> {
  totalCnt: number = 0;
  activeCnt: number = 0;
  dormantCnt: number = 0;
  newCnt: number = 0;

  addCount(record: UserStatusRecord) {
    this.totalCnt = record.totalCnt;
    this.activeCnt = record.activeCnt;
    this.dormantCnt = record.dormantCnt;
    this.newCnt += record.newCnt;
  }

  getData(dataType: string) {
    return this[dataType];
  }
}
export type UserStatusSummary = CountSummary<UserStatusRecord, UserStatusStats>;

const PLAN_MAIN_TYPE = StringUnion(
  'Test', 'Pro', 'ProPlus', 'Business', 'Premium', 'Platinum', 'Treadmill', 'Online_Marketing'
);
export type PlanMainType = typeof PLAN_MAIN_TYPE.type;

export const PLAN_TYPE = StringUnion(
  'Test_Plan', 'Pro_Month', 'Pro_Year', 'ProPlus_Month', 'ProPlus_Year', 'Business_Month', 'Business_Year', 'Premium_Month', 'Premium_Year', 'Platinum_Month', 'Platinum_Year',
  'Online_Treadmill', 'Online_Marketing_1Promotion', 'Online_Marketing_2Promotion', 'Item_Treadmill'
);
export type PlanType = typeof PLAN_TYPE.type;

export const REGION_TYPE = StringUnion('서울', '부산', '대구', '인천', '광주', '대전', '울산', '세종', '경기', '강원', '충청', '전라', '경상', '제주');
export type RegionType = typeof REGION_TYPE.type;

export const PAYMENT_TYPE = StringUnion('newbie', 'reregi', 'canceled', 'willCanceled', 'failed', 'nextMonth');
export type PaymentType = typeof PAYMENT_TYPE.type;



export class FittPayment {
  gAddress: string;
  gId: string;
  gInDate: Date;
  gName: string
  gSeq: number;
  gpSeq: number;
  gpAmount: number;
  gpCancelDate: Date;
  gpExpiredDate: Date;
  gpInDate: Date;
  gpOrgInDate: Date;
  gpNextAmount: number;
  gpState: string;
  mbCardName: string;
  pkName: string;
  pkPeriod: number;
  constructor(args?: any) {
    for (const field in args) {
      if (args[field] !== undefined) {
        if (['gInDate', 'gpCancelDate', 'gpExpiredDate', 'gpInDate', 'gpOrgInDate'].includes(field))
          this[field] = args[field] ? new Date(args[field]) : args[field];
        else
          this[field] = args[field];
      }
    }
  }

  checkType(type: PaymentType) {
    switch (type) {
      case 'newbie': return ['paid', 'invalid'].includes(this.gpState) && new Date(this.gInDate).within('month', 0, this.gpOrgInDate);
      case 'reregi': return ['paid', 'invalid'].includes(this.gpState) && !new Date(this.gInDate).within('month', 0, this.gpOrgInDate);
      case 'canceled': return ['paid'].includes(this.gpState) && !!this.gpCancelDate && this.gpExpiredDate.getTime() < new Date().getTime();
      case 'willCanceled': return ['paid'].includes(this.gpState) && !!this.gpCancelDate && this.gpExpiredDate.getTime() > new Date().getTime();
      case 'failed': return this.gpState == 'failed';
      case 'nextMonth': {
        if (['paid'].includes(this.gpState)) {
          if (this.pkName.endsWith('Month') || this.pkName.endsWith('Year')) {
            // 연결제인경우 이번달 부터 다음달 까지 포함된 이력의 경우 예정으로 잡는다.
            const nextPaymentDate = this.pkName.endsWith('Month') ? new Date(this.gpInDate).getNewDate('month', 1) : new Date(this.gpInDate).getNewDate('year', 1);
            const now = new Date();
            const nextEndDate = now.getNewDate('month', 2).resetDate('reset', 'date');  // 다다음달 1일
            if (now.getTime() < nextPaymentDate.getTime() && nextPaymentDate.getTime() < nextEndDate.getTime()) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isNewRegistered(queryEndDate: Date) {  // 조회월의 마지막 업데이트 데이터. 당월 조회의 경우 새로 갱신된 데이터가 된다.
    const checkEndDate = queryEndDate < new Date() ? queryEndDate : new Date();
    const lastMonthText = checkEndDate.getNewDate('second', -1).format('yyyyMM');

    if (this.gpInDate.format('yyyyMM') == lastMonthText) {
      return true;
    }

    return false;
  }

  getUseDays(queryEndDate?: Date) {
    const checkEndDate = queryEndDate < new Date() ? queryEndDate : new Date();
    if (this.checkType('newbie') || this.checkType('reregi')) {
      return (checkEndDate.getTime() - this.gInDate.getTime()) / (24 * 3600 * 1000);
    }
    else if (this.checkType('canceled')) {
      return (new Date(this.gpCancelDate).getTime() - this.gInDate.getTime()) / (24 * 3600 * 1000);
    }
    return 0;
  }
}

export class FittSalesStats implements CountBase<FittPayment> {
  total = 0;
  failed = 0;
  newbie = 0;
  reregi = 0;
  canceled = 0;
  nextMonth = 0;
  plans = new PlanStats();
  addCount(record: FittPayment) {
    const { pkName, gpState, gpAmount } = record;
    switch (gpState) {
      case 'paid': case 'invalid': {
        this.total += gpAmount;
        if (record.checkType('canceled')) {
          this.canceled += gpAmount;
        }
        else if (record.checkType('newbie')) {
          this.newbie += gpAmount;
        }
        else if (record.checkType('reregi')) {
          this.reregi += gpAmount;
        }
        this.plans.addFittPayment(pkName, gpAmount);
        break;
      }
      case 'failed': {
        this.failed += gpAmount;
        break;
      }
      case 'event': {
        // 개발당시 잠시 사용한 패키지. 현재는 사용하지 않는다.
        break;
      }
      case 'waiting': {
        // TODO: 새로 추가된 상태. 통계처리에 대해 검토 필요.
        break;
      }
      default: console.warn('pState error', gpState, record); break;
    }
    if (record.checkType('nextMonth')) {
      this.nextMonth += gpAmount;
    }
  }
}
export type FittSalesSummary = CountSummary<FittPayment, FittSalesStats>;


export class GymStatus {
  gActive: number;
  gAddress: string;
  gEmail: string;
  gId: string;
  gInDate: Date;
  gMemo: string;
  gName: string;
  gOwner: string;
  gSeq: number;
  gTotal: number;
  gVisible: boolean;
  geBefore1Count: number;
  geBefore2Count: number;
  geBefore3Count: number;
  geBefore4Count: number;
  geBefore5Count: number;
  geBefore6Count: number;
  geBefore7Count: number;
  geCount: number;
  geFmt2Count: number;
  geFmt3Count: number;
  geLactate1Count: number;
  geLastInDate: number;
  geSmt2Count: number;
  geSmt3Count: number;
  geSmt4Count: number;
  geSmt5Count: number;
  geTmt2Count: number;
  geTmt3Count: number;
  geTmt4Count: number;
  geTmt5Count: number;
  geTmt6Count: number;
  geTmt7Count: number;
  ggHeadSeq: number;
  gpAmount: number;
  gpCancelDate: number;
  gpExpiredDate: Date;
  gpInDate: Date;
  gpNextAmount: number;
  gpSeq: number;
  gpState: string;
  gsCount: number;
  gsGxCount: number;
  gsLastInDate: number;
  gsNewCount: number;
  gsNewGxCount: number;
  gsNewPtCount: number;
  gsPtCount: number;
  mpSeq: number;
  mpType: string;
  newCount: number;
  newFemale: number;
  newMale: number;
  pkName: string;
  pkSeq: number;
  tcRetire: number;
  tcTotal: number;
  ucFemale: number;
  ucMale: number;
  ucTotal: number;
  ucf10: number;
  ucf20: number;
  ucf30: number;
  ucf40: number;
  ucf50: number;
  ucf60: number;
  ucf70: number;
  ucf80: number;
  ucm10: number;
  ucm20: number;
  ucm30: number;
  ucm40: number;
  ucm50: number;
  ucm60: number;
  ucm70: number;
  ucm80: number;
  constructor(args?: any) {
    for (const field in args) {
      if (args[field] !== undefined) {
        if (['gInDate', 'gpExpiredDate', 'gpInDate'].includes(field))
          this[field] = args[field] ? new Date(args[field]) : args[field];
        else {
          this[field] = args[field];
        }
      }
    }
  }
}


export class TotalGymStats implements CountBase<GymStatus> {
  entireCount = new GymCount();
  lastCount = new GymCount();
  regions = REGION_TYPE.values.reduce((a, name) => {
    a[name] = 0;
    return a;
  }, {});
  plans = { total: new PlanStats(), activate: new PlanStats(), newbie: new PlanStats(), reregi: new PlanStats(), canceled: new PlanStats() };
  members = {
    trainer: new TrCount(),
    user: new UrCount(),
    userAge: new AgeCount(),
    userRegion: REGION_TYPE.values.reduce((a, name) => {
      a[name] = { t: 0, m: 0, f: 0 };
      return a;
    }, {})
  };
  schedules = {
    total: 0, ptCount: 0, gxCount: 0, etcCount: 0,
    newCount: 0, newPtCount: 0, newGxCount: 0, newEtcCount: 0,
  };
  exercises = {
    total: 0,
    beforeTotal: 0, before1Count: 0, before2Count: 0, before3Count: 0, before4Count: 0, before5Count: 0, before6Count: 0, before7Count: 0,
    fmtTotal: 0, fmt2Count: 0, fmt3Count: 0,
    lactate1Count: 0,
    smtTotal: 0, smt2Count: 0, smt3Count: 0, smt4Count: 0, smt5Count: 0,
    tmtTotal: 0, tmt2Count: 0, tmt3Count: 0, tmt4Count: 0, tmt5Count: 0, tmt6Count: 0, tmt7Count: 0,
  }

  addCount(g: GymStatus) {

    // // 해지 기준일: 과거 조회면 queryEndDate로 현재월 까지 조회면 현재 시간으로
    // const checkEndDate = queryEndDate < new Date() ? queryEndDate : new Date();
    // const lastMonthText = checkEndDate.getNewDate('second', -1).format('yyyyMM');

    this.entireCount.total++;

    // const expireDate = new Date(g.gpExpiredDate);
    const fittPayment = new FittPayment(g);
    const { pkName, gpAmount } = g;
    if (fittPayment.checkType('canceled')) {
      this.entireCount.canceled++;
      // if (expireDate.format('yyyyMM') == lastMonthText) {
      //   this.lastCount.canceled++;
      // }
      this.plans.canceled.addFittPayment(pkName, gpAmount);
    }
    else {
      if (fittPayment.checkType('newbie')) {
        this.entireCount.newbie++;
        // if (fittPayment.gpInDate.format('yyyyMM') == lastMonthText) {
        //   this.lastCount.newbie++;
        // }
        this.plans.activate.addFittPayment(pkName, gpAmount);
        this.plans.newbie.addFittPayment(pkName, gpAmount);
      }
      if (fittPayment.checkType('reregi')) {
        this.entireCount.reregi++;
        // if (fittPayment.gpInDate.format('yyyyMM') == lastMonthText) {
        //   this.lastCount.reregi++;
        // }
        this.plans.activate.addFittPayment(pkName, gpAmount);
        this.plans.reregi.addFittPayment(pkName, gpAmount);
      }
    }
    this.plans.total.addFittPayment(pkName, gpAmount);

    const tcTotal = g.tcTotal || 0;
    const tcRetire = g.tcRetire || 0;

    this.members.trainer.total += tcTotal;
    this.members.trainer.active += (tcTotal - tcRetire);
    this.members.user.total += g.gTotal;
    this.members.user.active += g.gActive;
    this.members.user.male += g.ucMale;
    this.members.user.female += g.ucFemale;
    [10, 20, 30, 40, 50, 60, 70, 80].forEach(age => {
      const userAge = this.members.userAge[`${age}`];
      userAge.t += g[`ucm${age}`] + g[`ucf${age}`];
      userAge.m += g[`ucm${age}`];
      userAge.f += g[`ucf${age}`];
    });

    if (g.gAddress) {
      const region = REGION_TYPE.values.find(r => g.gAddress.indexOf(r) >= 0) || 'ETC';
      // 지역별 gym 수
      this.regions[region]++;

      // 지역별 member 수
      if (!this.members.userRegion[region]) {
        this.members.userRegion[region] = { t: 0, m: 0, f: 0 };
      }
      this.members.userRegion[region].t += g.gTotal;
      this.members.userRegion[region].m += g.ucMale;
      this.members.userRegion[region].f += g.ucFemale;
    }

    this.schedules.total += g.gsCount;
    this.schedules.ptCount += g.gsPtCount;
    this.schedules.gxCount += g.gsGxCount;
    this.schedules.newCount += g.gsNewCount;
    this.schedules.newPtCount += g.gsNewPtCount;
    this.schedules.newGxCount += g.gsNewGxCount;

    this.exercises.total += g.geCount;
    this.exercises.beforeTotal += (g.geBefore1Count + g.geBefore2Count + g.geBefore3Count + g.geBefore4Count + g.geBefore5Count + g.geBefore6Count + g.geBefore7Count);
    this.exercises.before1Count += g.geBefore1Count;
    this.exercises.before2Count += g.geBefore2Count;
    this.exercises.before3Count += g.geBefore3Count;
    this.exercises.before4Count += g.geBefore4Count;
    this.exercises.before5Count += g.geBefore5Count;
    this.exercises.before6Count += g.geBefore6Count;
    this.exercises.before7Count += g.geBefore7Count;
    this.exercises.fmtTotal += (g.geFmt2Count + g.geFmt3Count);
    this.exercises.fmt2Count += g.geFmt2Count;
    this.exercises.fmt3Count += g.geFmt3Count;
    this.exercises.lactate1Count += g.geLactate1Count;
    this.exercises.smtTotal += (g.geSmt2Count + g.geSmt3Count + g.geSmt4Count + g.geSmt5Count);
    this.exercises.smt2Count += g.geSmt2Count;
    this.exercises.smt3Count += g.geSmt3Count;
    this.exercises.smt4Count += g.geSmt4Count;
    this.exercises.smt5Count += g.geSmt5Count;
    this.exercises.tmtTotal += (g.geTmt2Count + g.geTmt3Count + g.geTmt4Count + g.geTmt5Count + g.geTmt6Count + g.geTmt7Count);
    this.exercises.tmt2Count += g.geTmt2Count;
    this.exercises.tmt3Count += g.geTmt3Count;
    this.exercises.tmt4Count += g.geTmt4Count;
    this.exercises.tmt5Count += g.geTmt5Count;
    this.exercises.tmt6Count += g.geTmt6Count;
    this.exercises.tmt7Count += g.geTmt7Count;

    // 정리
    this.entireCount.activate = this.entireCount.total - this.entireCount.canceled;
    this.lastCount.total = this.lastCount.newbie;
    this.lastCount.activate = this.lastCount.newbie + this.lastCount.reregi - this.lastCount.canceled;

    this.members.trainer.ratio = this.members.trainer.active / this.members.trainer.total;
    this.members.user.ratio = this.members.user.active / this.members.user.total;

    // 지역별 gym 수 정렬
    this.regions = fromPairs(sortBy(toPairs(this.regions), 1).reverse());

    // 지역별 member 수 정렬
    const userRegions = Object.keys(this.members.userRegion);
    userRegions.sort((a, b) => this.members.userRegion[a].t < this.members.userRegion[b].t ? 1 : this.members.userRegion[a].t > this.members.userRegion[b].t ? -1 : 0);
    const sortUserRegion = {};
    userRegions.forEach(r => {
      sortUserRegion[r] = this.members.userRegion[r];
    });
    this.members.userRegion = sortUserRegion;

    this.schedules.etcCount = this.schedules.total - this.schedules.ptCount - this.schedules.gxCount;
    this.schedules.newEtcCount = this.schedules.newCount - this.schedules.newPtCount - this.schedules.newGxCount;
  }
}

export class PlanStats {
  total_cnt = 0; total_sum = 0;
  periodic_cnt = 0; periodic_sum = 0;
  Test_Plan_cnt = 0; Test_Plan_sum = 0;
  Year_cnt = 0; Year_sum = 0; Month_cnt = 0; Month_sum = 0;
  Pro_cnt = 0; Pro_sum = 0; Pro_Year_cnt = 0; Pro_Year_sum = 0; Pro_Month_cnt = 0; Pro_Month_sum = 0;
  ProPlus_cnt = 0; ProPlus_sum = 0; ProPlus_Year_cnt = 0; ProPlus_Year_sum = 0; ProPlus_Month_cnt = 0; ProPlus_Month_sum = 0;
  Business_cnt = 0; Business_sum = 0; Business_Year_cnt = 0; Business_Year_sum = 0; Business_Month_cnt = 0; Business_Month_sum = 0;
  Premium_cnt = 0; Premium_sum = 0; Premium_Year_cnt = 0; Premium_Year_sum = 0; Premium_Month_cnt = 0; Premium_Month_sum = 0;
  Platinum_cnt = 0; Platinum_sum = 0; Platinum_Year_cnt = 0; Platinum_Year_sum = 0; Platinum_Month_cnt = 0; Platinum_Month_sum = 0;

  addFittPayment(pkName, gpAmount) {
    this.total_cnt++;
    this.total_sum += gpAmount;
    this[`${pkName}_cnt`]++;
    this[`${pkName}_sum`] += gpAmount;
    if (pkName != 'Test_Plan') {
      this[`periodic_cnt`]++;
      this[`periodic_sum`] += gpAmount;

      const tokens = pkName.split('_');
      const grade = tokens[0];
      const term = tokens[1];
      this[`${grade}_cnt`]++;
      this[`${grade}_sum`] += gpAmount;
      this[`${term}_cnt`]++;
      this[`${term}_sum`] += gpAmount;
    }
    return this;
  }

  getPeriodicRatio() {
    return this.periodic_sum / this.total_sum;
  }
}

export class GymCount {
  total: number = 0;
  activate: number = 0;
  newbie: number = 0;
  reregi: number = 0;
  canceled: number = 0;
}
export class TrCount {
  total: number = 0;
  active: number = 0;
  ratio: number = 0;
}
export class UrCount {
  total: number = 0;
  active: number = 0;
  ratio: number = 0;
  male: number = 0;
  female: number = 0;
}
export class AgeCount {
  '10' = { t: 0, m: 0, f: 0 };
  '20' = { t: 0, m: 0, f: 0 };
  '30' = { t: 0, m: 0, f: 0 };
  '40' = { t: 0, m: 0, f: 0 };
  '50' = { t: 0, m: 0, f: 0 };
  '60' = { t: 0, m: 0, f: 0 };
  '70' = { t: 0, m: 0, f: 0 };
  '80' = { t: 0, m: 0, f: 0 };
}

export type GymStatsSummary = CountSummary<GymStatus, TotalGymStats>;
