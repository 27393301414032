import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.html',
  styleUrls: ['./date-picker.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatePickerModal implements OnInit {
  @Input() pickerType: string;
  @Input() selectedDate: Date;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  constructor(
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private dateTimeAdapter: DateTimeAdapter<any>,
  ) {
    this.dateTimeAdapter.setLocale(this.translate.currentLang);
  }

  ngOnInit() {
    this.selectedDate = this.selectedDate || new Date();
  }
  ngOnDestroy() {
  }

  close(ok?: boolean) {
    const data = ok ? this.selectedDate : undefined;
    // this.log('close', data);
    this.modalCtrl.dismiss(data);
  }

  onChange($event) {
    // this.log('onChange', $event);
  }

  chosenYearHandler($event) {
    // this.log('chosenYearHandler', $event);
  }

  showMonthPicker: boolean = true;
  chosenMonthHandler($event) {
    // this.log('chosenMonthHandler', $event, this.minDate, this.maxDate);

    const date = new Date($event);
    if ((!this.minDate || this.minDate.getTime() < date.getTime()) && (!this.maxDate || this.maxDate.getTime() > date.getTime())) {
      this.selectedDate = date;
    }

    this.showMonthPicker = false;
    this.close(true);
  }
}
