import { Component, Input } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'ft-color-text',
  templateUrl: './ft-color-text.component.html',
  styleUrls: ['./ft-color-text.component.scss'],
})
export class FtColorTextComponent extends BaseComponent {

  @Input() value: string;
  @Input() positiveColor: string;
  @Input() negativeColor: string;

  constructor(
  ) {
    super();
  }

  getStyle() {
    const style: any = {};
    if (parseInt(this.value) >= 0) {
      style.color = this.positiveColor;
    }
    if (parseInt(this.value) < 0) {
      style.color = this.negativeColor;
    }
    return style;
  }
}
