import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { BaseComponent } from '@shared-lib/base.component';
import * as _ from 'lodash';

@Component({
  selector: 'ft-base-modal',
  templateUrl: './ft-base-modal.component.html',
  styleUrls: ['./ft-base-modal.component.scss'],
})
export class FtBaseModalComponent extends BaseComponent {
  @Input() title: string;
  @Input() isLoaded: boolean;
  @Output() onClose = new EventEmitter();

  constructor(
    private modalCtrl: ModalController,
    private elementRef: ElementRef,
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    if (this.isLoaded === undefined) this.isLoaded = true;
  }

  ngAfterViewInit() {
    this.log('ngAfterViewInit', this.elementRef);
    const ionModal = this.elementRef.nativeElement.closest('ion-modal');
    if (ionModal) {
      const [ionBackdrop] = ionModal.getElementsByTagName('ion-backdrop');
      this.log('ngAfterViewInit', ionBackdrop);
      if (ionBackdrop) {
        if (!ionBackdrop.classList.contains('backdrop-no-tappable')) {
          // ionBackdrop.style.display = 'none';
          // const newBackdrop = document.createElement('ion-backdrop');
          // newBackdrop.className = 'sc-ion-modal-md md backdrop-no-tappable hydrated';
          const newBackdrop = document.createElement('div');
          _.assignInWith(newBackdrop.style, {
            inset: '0px',
            display: 'block',
            position: 'absolute',
            transform: 'translateZ(0px)',
            contain: 'strict',
            cursor: 'pointer',
            'z-index': 3,
          });
          newBackdrop.tabIndex = -1;
          ionModal.insertBefore(newBackdrop, ionModal.firstChild);
          newBackdrop.onmousedown = (event) => {
            this.log('ngAfterViewInit.newBackdrop.onmousedown', event);
            this.close();
          }
        }
      }
    }
  }

  async close() {
    const closeEvent = new Subject<any>();
    let interceptClose = false;
    const subscription = closeEvent.subscribe((data) => {
      this.log('close.subscribe', data);
      interceptClose = true;
    });
    this.onClose.emit({ interceptClose: () => closeEvent.next() });
    subscription.unsubscribe();
    if (!interceptClose) {
      this.modalCtrl.dismiss();
    }
  }

}
