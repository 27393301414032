import { Component, OnChanges, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { ExerciseTooltip } from '@shared-lib/components/ft-fixed-list/ft-fixed-list';

interface Report {
  correctiveExercises: { mobility: string[]; stability: string[]; motorControl: string[]; };
  name: string
  height: number,
  gender: Gender
  date: Date
  age: number
  weight: number

  movability: 'Good' | 'Normal' | 'Bad'
  stability: 'Good' | 'Normal' | 'Bad'
  motorControl: 'Good' | 'Normal' | 'Bad'
  asymmetryList: Array<number>
  shoulderPain: 'Yes' | 'Yes_L' | 'Yes_R' | 'Yes_LR' | 'No'
  corePain: 'Yes' | 'No'
  cervicalPain: 'Yes' | 'No'
  fmtScore: number
  maxScore: number
  cervicalResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
  motorControlResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
  pelvisLegResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
  coreResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
  thoracicResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
  shoulderResult: { percent: number, status: 'Good' | 'Normal' | 'Bad' }
}

@Component({
  selector: 'ft-fmt-analysis',
  templateUrl: './ft-fmt-analysis.html',
  styleUrls: ['./ft-fmt-analysis.scss'],
})
export class FtFmtAnalysisComponent extends FtLibBaseComponent implements OnChanges {
  tooltipObject: ExerciseTooltip = {
    text: this.translate.instant('건강.움직임검사에서 가장 중요한 포인트는 좌우 불균형을 해결하는 것입니다.\n좌우불균형 동작이 일어나는 패턴에서 부상의 확률이 높다는 연구결과가 다수이기 때문에, 가장 우선시해서 해결해야하는 검사값입니다.\n아래 추천되는 교정운동을 매일 진행해주세요.'),
    width: 428
  };

  @Input() report: Report;
  @Input() answerList: Array<Array<any>>;

  constructor(
  ) {
    super();
  }

  ngOnChanges() {
  }

  getLeftRightSurveyData(idx: number) {
    if (idx < 1) {
      return { L: Number(this.answerList[idx][2]), R: Number(this.answerList[idx][3]) };
    } else {
      return { L: Number(this.answerList[idx][0]), R: Number(this.answerList[idx][1]) };
    }
  }

  isBalanced(idx: number) {
    if (this.getLeftRightSurveyData(idx).L - this.getLeftRightSurveyData(idx).R == 0) {
      return ['/assets/fitt-lib/img/fmt-analysis-left-circle-no.png', '/assets/fitt-lib/img/fmt-analysis-right-circle-no.png'];
    } else {
      return ['/assets/fitt-lib/img/fmt-analysis-left-circle-yes.png', '/assets/fitt-lib/img/fmt-analysis-right-circle-yes.png'];
    }
  }

  async showLeftRightBalanceGuide() {
    const alert = await this.util.alertCreate({
      title: '',
      message: this.translate.instant('건강.움직임검사에서 가장 중요한 포인트는 좌우 불균형을 해결하는 것입니다.\n좌우불균형 동작이 일어나는 패턴에서 부상의 확률이 높다는 연구결과가 다수이기 때문에, 가장 우선시해서 해결해야하는 검사값입니다.\n아래 추천되는 교정운동을 매일 진행해주세요.'),
      buttons: [{ text: 'ok', role: 'ok' }]
    });
    await alert.present();
  }
}
