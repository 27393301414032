import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateNumberOnlyDirective, MonthNumberOnlyDirective, NumberOnlyDirective, MinuteNumberOnlyDirective, SecondNumberOnlyDirective, InputValidatorDirective } from './input-number-only.directive';

@NgModule({
  declarations: [
    NumberOnlyDirective,
    MonthNumberOnlyDirective,
    DateNumberOnlyDirective,
    MinuteNumberOnlyDirective,
    SecondNumberOnlyDirective,
    InputValidatorDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    NumberOnlyDirective,
    MonthNumberOnlyDirective,
    DateNumberOnlyDirective,
    MinuteNumberOnlyDirective,
    SecondNumberOnlyDirective,
    InputValidatorDirective,
  ],
})
export class NumberOnlyDirectiveModule { }
