<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title color="black2">{{'대체.움직임능력검사'|translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ng-container *ngIf="showGuideView && fmtItem">
  <ion-content class="guide-content">
    <div class="guide-container">
      <ion-text class="index-label">{{fmtCheckIndex + 1}}/{{NUM_OF_ITEMS}}</ion-text>
      <ion-text class="title">{{fmtCheckIndex + 1}}. {{('fmt2.' + fmtItem.subject)|translate}}</ion-text>
      <ion-text class="description">{{('fmt2.' + fmtItem.description)|translate}}</ion-text>
      <img [src]="fmtItem.img.active">
      <ion-button class="video-guide-button" fill="clear" (click)="showVideoGuideModal(fmtCheckIndex)">
        <ion-label color="white">{{'대체.검사방법 영상 보기'|translate}}</ion-label>
      </ion-button>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border guide-footer">
    <ion-toolbar>
      <ion-buttons class="bottom-1-button">
        <ion-button (click)="hideGuideView()" fill="outline" color="white">
          {{'건강.검사 진행하기'|translate}}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</ng-container>
<ng-container *ngIf="!showGuideView && fmtItem">
  <ion-content class="fmt-content">
    <div class="subject-header">
      <ion-label class="subject" color="black1">
        <span class="index-number">{{fmtCheckIndex + 1}}</span>
        {{('fmt2.' + fmtItem.subject)|translate}}
      </ion-label>
      <ion-label class="index" [ngClass]="isAllAnswerdAt(fmtCheckIndex) ? 'active-main' : 'inactive'">{{fmtCheckIndex + 1}} / {{NUM_OF_ITEMS}}</ion-label>
    </div>
    <div *ngFor="let item of fmtItem.children; index as itemIndex;" class="question-item">
      <ion-label class="title" color="black2">{{fmtCheckIndex + 1}}-{{itemIndex+1}}. {{'fmt2.'+item.title|translate}}</ion-label>
      <ion-text class="option-desc" *ngIf="item.type=='options'">
          {{ isAnswered(fmtCheckIndex, itemIndex) ? 
            ('fmt2.' + item.options[item.options.length-1-surveyItems.answerList[fmtCheckIndex][itemIndex]]|translate) :('fmt2.' + item.desc)|translate }}
        </ion-text>
      <div class="option-container">
        <radio-row *ngIf="item.type == 'options'" [options]="radioOptions" [initialIdx]="getRadioInitialIdx(fmtCheckIndex, itemIndex)"
          (checkEvent)="onClickRadio(fmtCheckIndex, itemIndex, $event)" (click)="isFmtOptionfromRecord[fmtCheckIndex][itemIndex] = false"
          [isLastData]="isFmtOptionfromRecord[fmtCheckIndex][itemIndex]" [size]="24">
        </radio-row>
        <ft-tab-buttons *ngIf="item.type == 'boolean'" (click)="isFmtOptionfromRecord[fmtCheckIndex][itemIndex] = false;"
          [(selected)]="surveyItems.answerList[fmtCheckIndex][itemIndex]" [buttons]="[{id: '1', name: 'YES'}, {id: '0', name: 'NO'}]"
          [isLastData]="isFmtOptionfromRecord[fmtCheckIndex][itemIndex]" size="20px">
        </ft-tab-buttons>
      </div>
    </div>
  </ion-content>
  <ion-footer class="ion-no-border">
    <ion-toolbar>
      <ion-buttons class="bottom-2-button">
        <ion-button (click)="moveFmtCheck(-1)" fill="outline" color="main" [disabled]="fmtCheckIndex === 0">
          {{'건강.이전 종목'|translate}}
        </ion-button>
        <ion-button (click)="moveFmtCheck(1);" fill="solid" color="main" [disabled]="nextButtonDisabled">
          {{fmtCheckIndex < NUM_OF_ITEMS - 1 ? ('건강.다음 종목'|translate) : ('건강.검사 완료'|translate)}}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-footer>
</ng-container>
