import { Component, OnInit, Input, Inject } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';

@Component({
  selector: 'ft-logo',
  templateUrl: './ft-logo.component.html',
  styleUrls: ['./ft-logo.component.scss'],
})
export class LogoComponent extends FtLibBaseComponent implements OnInit {

  @Input() pSize: string = 'normal';
  @Input() logoUrl: string;
  basicLogoUrl: string = '/assets/fitt-lib/img/fitt-logo-copy-14@3x.png';
  constructor(
    @Inject('environment') private environment: any,
  ) {
    super({ enableLog: false });
    if (this.environment.project === 'fitt-fmes')
      this.basicLogoUrl = '/assets/fitt-lib/img/fmes-logo.png';
  }

  ngOnInit() {
    this.log('ngOnInit', this.pSize);
  }
}
