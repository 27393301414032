import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';

@Component({
  selector: 'radio-row',
  templateUrl: './radio-row.component.html',
  styleUrls: ['./radio-row.component.scss'],
})
export class RadioRowComponent extends BaseComponent implements OnInit {
  @ViewChild('circle') circle: ElementRef;

  @Input() initialIdx: number;
  @Input() options: Array<{ key: string, value: number }>;
  @Input() margin: number = 0;
  @Input() isLastData: boolean = false;
  @Input() size: number = 17;
  @Output() checkEvent = new EventEmitter();

  constructor(
    private renderer: Renderer2
  ) {
    super();
  }

  ngOnInit() {
    if (this.initialIdx && this.initialIdx >= 0) {
      setTimeout(() => this.radioClick(this.initialIdx), 0);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Number.isFinite(this.initialIdx)) {
      setTimeout(() => this.radioClick(this.initialIdx), 0);
    } else if (!this.initialIdx) {
      this.deactivateCircle();
    }
  }

  radioClick(idx: number, isLastData: boolean = false) {
    this.activateCircle();
    const marginValue = this.margin === 0 ? 0 : this.margin * (idx * 2 + 1);
    const leftValue = (idx + 1) * (this.size * 2) - this.size + marginValue;
    this.renderer.setStyle(this.circle.nativeElement, 'z-index', 'auto');
    this.renderer.setStyle(this.circle.nativeElement, 'left', `${leftValue}px`);
    this.renderer.setProperty(this.circle.nativeElement, 'innerHTML', this.options[idx].value);

    this.checkEvent.emit(this.options[idx]);
  }

  activateCircle() {
    setTimeout(() => this.renderer.setStyle(this.circle.nativeElement, 'opacity', '1'), 150);
  }

  deactivateCircle() {
    setTimeout(() => this.renderer.setStyle(this.circle.nativeElement, 'opacity', '0'), 150);
  }


  ngAfterViewInit() {
    this.setLabelMargin();
  }

  setLabelMargin() {
    const labels = document.getElementsByClassName('radio-row-label');
    for (let idx = 0; idx < labels.length; ++idx) {
      labels[idx].setAttribute('style', `margin: 0 ${this.margin}px;`);
    }
  }

}
