<ion-header mode="ios" *ngIf="util.isMobileApp()">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="openPointAndGuideRule()">
        <ion-icon color="black1" name="information-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{'건강.FMT 검사 가이드' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="video-container">
    <div *ngFor="let video of guideVideos">
      <div class="mobile-video-container">
        <iframe [src]="thumbnailPrefix + video.id|safe" frameborder="0" allowfullscreen class="mobile-video-frame"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
      </div>
      <div style="padding: 12px;">
        <ion-text class="font16 bold">{{video.name}}</ion-text>
      </div>
    </div>
  </div>
</ion-content>
