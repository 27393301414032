<ion-app>
  <!-- <ng-container *ngIf="util.isMobileApp() && myInfo">
    </ng-container> -->
  <ion-menu side="start" menuId="left-menu-member" contentId="main" (ionWillOpen)="ionWillOpen('left-menu-member')"
    (ionDidOpen)="ionDidOpen('left-menu-member')" (ionDidClose)="ionDidClose()">
    <!-- (onClick)="popupModal($event)" -->
    <ft-fmes-side-profile menuId="left-menu-member" (onClick)="popupModal($event)">
    </ft-fmes-side-profile>
    <ion-content>
      <div class="subscribe-box">
        <ion-item lines="none">
          <ion-label>{{'마이페이지.구독 플랜'|translate}}</ion-label>
        </ion-item>
        <ng-container *ngIf="!isSubscribe">
          <ion-item lines="none">
            <ion-text class="font12">{{'마이페이지.구독중인 플랜이 없습니다.'|translate}}</ion-text>
          </ion-item>
        </ng-container>
        <ng-container *ngIf="isSubscribe" style="margin-left: 16px;">
          <ion-text class="font12">
            {{'마이페이지.월 결제 무제한 플랜'|translate}}<br>
          </ion-text>
          <ion-text color="gray6">
            {{'마이페이지.결제일' | translate}} : {{paymentDueDate| ftdate: 'yyyy.MM.dd'}}
          </ion-text>
        </ng-container>
        <ion-item lines="full">
          <ion-button fill="outline" (click)="popupModal('subscribe-main')">{{'마이페이지.플랜 보기'|translate}}</ion-button>
        </ion-item>
      </div>

      <ion-list>
        <ion-item lines="full" (click)="popupModal('fmt-guide')">
          <ion-label>{{'건강.FMT 검사 가이드'|translate}}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item lines="full">
          <ion-label>{{'설정.이용약관'|translate}}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-item lines="full">
          <ion-label>{{'설정.개인정보 취급방침'|translate}}</ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list>
          <ion-item lines="full" detail (click)="popupModal('settings-main')">
            <ion-label>{{'설정.환경 설정'|translate}}</ion-label>
          </ion-item>
        </ion-list>
        <ion-item lines="full" (click)="logout()">
          <ion-label>{{'설정.로그아웃'|translate}}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>


  </ion-menu>

  <ion-router-outlet animated="false" id="main"></ion-router-outlet>
</ion-app>
