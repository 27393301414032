import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Platform } from '@ionic/angular';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { User } from '@fitt-lib/models/fitt-models';
import { Image } from '@shared-lib/components/image-uploader/image-uploader';
import { RxEvents } from '@shared-lib/services/rx-events.service';

@Component({
  selector: 'ft-user-profile',
  templateUrl: './ft-user-profile.html',
  styleUrls: ['./ft-user-profile.scss'],
})
export class FtUserProfileComponent extends FtLibBaseComponent implements OnInit {
  @Input() size: number = this.platform.is('mobile') ? 40 : 32;
  @Input() user: User;
  @Input() profileUrl: string;
  @Input() name: string;
  @Input() uploadEnable: boolean = false;
  @Input() popUpEnable: boolean = false;
  @Input() showEdit: boolean = false;
  @Input() showStateCircle: boolean = true;

  @Output() completeUpload = new EventEmitter<string>();

  linkSize: number = this.size * 0.3;
  uploadImage: Image;

  constructor(
    private events: RxEvents,
    private platform: Platform,
  ) {
    super();
  }

  ngOnInit() {
    this.uploadEnable && this.log('uploadEnable');
    this.linkSize = this.size * 0.3;
    // this.log('ngOnInit', { user: this.user });
  }

  getProfileUrl() {
    return this.profileUrl || (this.user && this.user.uProfile);
  }

  getName() {
    return this.name || this.user && this.user.uName;
  }

  async editProfile() {
    try {
      const photos = await this.util.selectPhoto({ mediaType: 'image' });
      this.uploadImage = photos[0];
    }
    catch (error) {
      this.warn(error);
      this.util.alertCreate({ message: this.translate.instant('일반.사진 등록이 취소되었습니다.') });
    }
  }

  async onCompleteUpload() {
    this.completeUpload.emit(this.uploadImage.url);
  }

  clearUploadImage() {
    this.log('clearUploadImage');
    this.uploadImage = null;
  }

  async onClick() {
    this.log('onClick', { user: this.user, uploadEnable: this.uploadEnable, popUpEnable: this.popUpEnable });
    if (this.uploadEnable) {
      await this.editProfile();
    } else if (this.popUpEnable) {
      if (!this.user || !this.user.uVisible) {
        const cannotAlert = await this.util.alertCreate({
          title: this.translate.instant('모달.알림'),
          message: this.translate.instant('회원관리.삭제된 회원입니다.'),
          buttons: [{ text: 'ok', role: 'ok' }]
        });
        cannotAlert.present();
      } else {
        this.events.publish('userInfoModal:create', this.user.uSeq);
        // this.userInfoChangeSubs = this.events.subscribe('userInfoChange', this.userInfoChange);
      }
    }
  }

  getConnectColor(): string {
    if (!this.user) {
      return 'unset';
    }
    const { uConnect } = this.user;

    return uConnect === 1 ? '#93FFAA' : '#D8D8D8';
  }
}
