import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskModule } from 'ngx-mask';
import { Camera } from '@ionic-native/camera/ngx';
import { DatePickerModalModule } from './modals/date-picker/date-picker.module';
import { CommonShareModules } from './common-share/common-share.module';

@NgModule({
  exports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    TooltipModule,
    CommonShareModules,
    RouterModule,
  ],
  imports: [
    NgxMaskModule.forRoot(),
    DatePickerModalModule,
  ],
  providers: [
    InAppBrowser,
    Camera,
  ],
})
export class SharedModule { }
