<ion-header mode="ios" *ngIf="util.isMobileApp()">
  <ion-toolbar mode="ios" (click)="tapModalTitle()">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-item lines="none">
    <ion-text id="content-title">
      월 결제 구독 플랜
    </ion-text>
  </ion-item>
  <div lines="none" [ngClass]="isSubscribe ? 'subscribe' : 'none-subscribe'">
    <ion-card>
      <ion-card-content>
        <ion-row>
          <ion-col size="3" class="subscribe-checkbox">
            <ion-item lines="none">
              <ion-checkbox slot="start" [checked]="isSubscribe" [disabled]="isSubscribe"></ion-checkbox>
            </ion-item>
          </ion-col>
          <ion-col size="9" class="subscribe-textbox">
            <ion-row>
              <ion-text class="bold">월 결제 무제한 플랜</ion-text>
            </ion-row>
            <ion-row>
              <ion-text>검사 횟수 <span class="bold">무제한</span> / 금액 <span class="bold">30$</span></ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-card-content>
    </ion-card>
  </div>

</ion-content>

<ion-footer class="ion-no-border">
  <ng-container *ngIf="isSubscribe">
    <ion-item lines="none" style="align-self: end;">
      <ion-button fill="clear" size="default" expand="full" color="primary" (click)="cancelSubscribe()">
        <ion-text>{{'해지하기' | translate}}</ion-text>
      </ion-button>
    </ion-item>
    <ion-text class="bold font14">자동결제 해지 안내</ion-text>
    <ul>
      <li>구글 플레이 스토어 결제는 구글 플레이 스토어에서 직접 해지를 신청해주세요.</li>
      <li>구글 플레이 스토어에서 해지하려면, [구글 플레이 스토어 실행] > 오른쪽 상단 [계정 아이콘] 선택 > [결제 및 정기결제] 선택 > [정기결제] 선택 > [구독 취소]를 선택해주세요.</li>
      <li>해지를 원하는 경우 결제 예정일 하루 전 구독을 취소해야 합니다.</li>
      <li>결제내역 및 환불 관련 문의는 구글 플레이 스토어 고객센터 (02-531-9000)으로 부탁드립니다.</li>
      <li>해지 예약시 다음 정기 결제일까지 상품 이용이 가능합니다.</li>
    </ul>
  </ng-container>
  <ng-container *ngIf="!isSubscribe">
    <ion-item lines="none" style="align-self: center;">
      <ion-button fill="clear" size="default" expand="full" color="primary" (click)="openPaymentCancelInfo()">
        <ion-text style="margin:0 auto; text-decoration:underline;">{{'결제 / 해지 안내' | translate}}</ion-text>
      </ion-button>
    </ion-item>
    <ion-button class="subscribe-btn">
      <ion-text>플랜 구독</ion-text>
    </ion-button>
  </ng-container>

</ion-footer>
