import { NgModule } from '@angular/core';
import { BasicModule } from 'src/app/basic.module';
import { FmesSubscribeMainModal } from './fmes-subscribe-main';
import { FtInfomationModalModule } from '@fitt-lib/modals/ft-infomation/ft-infomation.module'

@NgModule({
  declarations: [
    FmesSubscribeMainModal
  ],
  imports: [
    BasicModule,
    FtInfomationModalModule
  ],
  entryComponents: [
    FmesSubscribeMainModal
  ]
})
export class FmesSubscribeMainModalModule { }
