import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '@shared-lib/base.component';
import { ToastController } from '@ionic/angular';

@Directive({ selector: '[scaleupButton]' })
export class ScaleupButtonDirective extends BaseComponent implements OnInit {

  @Input() scaleupToast: string;
  @Output() longpressComplete: EventEmitter<any> = new EventEmitter<any>();

  longpressTimeout: number = 500;
  statsLongpress: boolean = false;
  longpressTimer: any;
  pressupTimeStamp: number = 0;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private toastCtrl: ToastController,
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    this.log('ngOnInit', this.elementRef, this.scaleupToast);
  }

  @HostListener('click', ['$event'])
  click(event) {
    this.log('click', event);
    if (new Date().getTime() - this.pressupTimeStamp > 1000) {
      this.showToast();
    }
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  press(event) {
    if (event.cancelable) event.preventDefault();
    this.log('press', event);
    this.statsLongpress = true;

    this.animStart(this.longpressTimeout);
    this.longpressTimer = setTimeout(() => {
      if (this.statsLongpress) {
        this.animStop();
        this.longpressComplete.emit();
      }
      this.longpressTimer = null;
    }, this.longpressTimeout);
  }

  @HostListener('mouseup', ['$event'])
  @HostListener('touchend', ['$event'])
  pressup(event) {
    if (event.cancelable) event.preventDefault();
    this.log('pressup', event);
    this.statsLongpress = false;
    this.pressupTimeStamp = new Date().getTime();
    this.animStop();
    if (this.longpressTimer) {
      clearTimeout(this.longpressTimer);
      this.longpressTimer = null;
    }
  }

  animStart(longpressTimeout) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', `ease-in-out ${longpressTimeout}ms transform`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'scale(1.5)');
  }

  animStop() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'transition', 'unset');
    this.renderer.setStyle(this.elementRef.nativeElement, 'transform', 'unset');
  }

  async showToast() {
    if (!this.scaleupToast) return;
    const toast = await this.toastCtrl.create({ mode: 'md', message: this.scaleupToast, duration: 2000, position: 'top' });
    await toast.present();
  }
}
