import { StringUnion } from '@shared-lib/utils/string-union';
import { merge } from 'lodash';
import { BookingState, ScState } from '../models/booking-models';
import { UsageStatus } from '../models/schedule-types';


function applyMixins(derivedCtor: any, constructors: any[]) {
  constructors.forEach((baseCtor) => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
        Object.create(null)
      );
    });
  });
}

export function assignValues(obj: Object, values: Object, gymTimeZone?: string) {
  if (!values) return;

  for (const key of Object.keys(values)) {
    if (['Date', 'Time', 'Birth'].find(name => key.endsWith(name))) {
      if (values[key]) {
        const dValue = new Date(values[key]);

        if (!isNaN(dValue.getTime())) {
          if (key === 'sEndDate' && typeof values[key] === 'string' && values[key].endsWith('24:00')) {
            dValue.setTime(dValue.getTime() - 60000);
          }
          // obj[key] = dValue;
          if (gymTimeZone && values[key] && typeof values[key] === 'string' && values[key].endsWith('+09:00')) {
            obj[key] = new Date(new Date(dValue).toLocaleString('en-US', { timeZone: gymTimeZone }));
          }
          else {
            obj[key] = dValue;
          }

          continue;
        }
      }
    }
    obj[key] = values[key];
  }
}

export type LessonState = 'open' | 'close';
export class Lesson {
  gSeq: number;

  tSeq: number;
  tName: string;

  lDate: string;
  sStartDate: Date;
  sEndDate: Date;

  cSeq: number;
  cName: string;
  cNote: string;
  sType: ScheduleType;
  cColor: string;
  cQuota: number;

  hSeq: number;
  hDay: number;
  hStartTime: string;
  hEndTime: string;

  lState: string;
  sSessionParty: number;
  lMemo: string;    // TGX_Memo 에서 온다.

  existUncheckedCanceled?: boolean;  // 같은 시간대(sStartDate)에서 이전에 미확인 취소된 예약이 있는 경우() true로 설정

  private uSeqs: string;

  constructor(values: Object) {
    assignValues(this, values);
  }

  getEntryText() {
    return this.cQuota ? `${this.sSessionParty || 0}/${this.cQuota}` : '';
  }

  isJoined(uSeq: number) {
    return this.uSeqs ? this.uSeqs.indexOf(`#${uSeq}`) >= 0 : false;
  }
}
export class Schedule {
  sSeq: number;
  sAllDay: number;
  sBundleSeq?: number;
  sEndDate: Date;
  sExplain: string;
  sInDate?: Date | undefined;
  sMemo: string;
  sMemoAllDay: boolean;
  sMemoTitle: string;
  sModifyDate?: Date | undefined;
  sNoShowConfirmDate?: Date;
  sNoShowSession: number | any;
  sOrder: number; // 패키지를 사용한 순번
  sReason: number;
  sReportKey: string;
  sSession: number;
  sSessionMax: number;
  sSessionParty: number;
  sStartDate: Date;
  sType: ScheduleType;
  sState: ScState;
  sTrainerCheckDate?: Date | undefined;
  sUserCheckDate?: Date | undefined;
  sFutureCount: number; // 패키지를 사용할 미래 수업 수

  isCurrentLangKo?: boolean; // 푸시 보낼 때 언어세팅 값(ko= true, en=false)

  cSeq: number;
  cColor: string;
  cName: string;

  gSeq: number;
  gName: string;

  hSeq: number;

  pSeq: number;
  paSeq?: number;
  pMaxSession: number; // 패키지를 사용한 수업 수
  pName: string;
  paName?: string;
  trSession: number; // 이용권 양도 시, 양도자가 사용한 수업 수
  pSession: number; // 패키지의 최대 수업 수
  paAmount: number; // 패키지 가격

  uSeq: number;
  uBirth?: Date;
  uGender: string;
  uName: string;
  uPaymentSeq: number;
  uProfile: string;
  uState?: number;
  uConnect?: number;

  tSeq: number;
  tName: string;
  tProfile: string;

  htSeq?: number;
  htName?: string;
  htAuth?: TrainerAuthType;

  cellStyle?: any;

  etcData?: any;

  gxCount?: number;

  lInDate?: Date;

  title?: string;
  tooltipColor?: string;

  uptuSeq?: number;
  uptuAmount?: number;

  gTimeZone?: string = 'Asia/Seoul';

  usageStatus: UsageStatus;

  constructor(values) {
    assignValues(this, values);
    values && this.constructUsageStatus();
  }

  protected constructUsageStatus() {
    if (!this.pSession) this.pSession = 0;
    if (!this.pMaxSession) this.pMaxSession = 0;
    if (!this.sFutureCount) this.sFutureCount = 0;
    this.usageStatus = new UsageStatus(this.getOrder(), this.sFutureCount, this.pSession, this.trSession);
  }

  /**
   * @description
   * - 이용권이 같은 스케줄을 통일해서 표시하는 법
   *  Math.max(this.pMaxSession - this.sFutureCount, 0)
   * - 스케줄이 이용권의 세션을 소비한 순서대로 표시
   *  showOrder ? this.sOrder : this.pMaxSession - this.sFutureCount;
   */
  private getOrder(): number {
    const showOrder = this.isPast() && (this.isSessionConsumed() || BookingState.NoShow.includes(this.sState));

    return showOrder ? this.sOrder : Math.max(this.pMaxSession - this.sFutureCount, 0);
  }

  getUsageStatus(includeFuture: boolean): string {
    return this.usageStatus.toText(includeFuture && this.isFuture());
  }

  setUsageStatus(newSchedule: LessonSchedule) {
    const { sState: newState, sNoShowSession, sStartDate: newStartDate, sEndDate: newEndDate } = newSchedule;
    if (newState === ScState.NoShowByTrainer && sNoShowSession === 1) {
      return;
    }

    const { sState, sStartDate, sEndDate, usageStatus: orgUsageStatus } = this;
    const [sStartTime, sEndTime, newStartTime, newEndTime] = [sStartDate.getTime(), sEndDate.getTime(), newStartDate.getTime(), newEndDate.getTime()];
    const amount = newSchedule.isSessionConsumed() ? 1 : -1;

    if (newSchedule.isFuture()) {
      orgUsageStatus.setFutureCount(amount);
    } else if (sState === ScState.RegisterVirtual || newStartTime < sStartTime || (newStartTime === sStartTime && newEndTime < sEndTime)) {
      orgUsageStatus.setOrder(amount);
    }
  }

  isSessionConsumed(): boolean {
    return BookingState.Reserved.includes(this.sState) || (BookingState.NoShow.includes(this.sState) && this.sNoShowSession === 1);
  }

  isPoint(): boolean {
    return this.sType === 'POINT';
  }

  isPast(): boolean {
    return this.sEndDate < new Date();
  }

  isFuture(): boolean {
    return this.sEndDate >= new Date();
  }

  /**
   * 과거 예약 등록은 수업 완료로 표시하기 위해 호출한다.
   */
  shouldBeCompleted(): boolean {
    return this.isPast() && this.sType !== 'POINT' && BookingState.Confirm.includes(this.sState) && ScState.Complete !== this.sState;
  }
}

export class LessonSchedule {
  constructor(values: Object) {
    assignValues(this, values);
    values && this.constructUsageStatus();
    // sExplain를 삭제하기 위해 임시로 설정.
    // delete this['sExplain'];
  }

  getTypeStateName() {
    const stateNames: { [state in ScState | 'default']: string } = {
      '-1': 'unknown',
      0: 'CLOSE',          // w
      1: 'OPEN',           // w
      2: '자동확정',       // b1
      3: 'unknown',
      4: '수업완료',       // g1
      5: '예약신청',       // b1
      6: '예약확정',       // g
      7: '예약등록',       // b1
      8: '예약반려',       // r1
      9: '취소',           // r1
      10: '트레이너취소',  // r1
      11: '예약변경신청',  // b1
      12: '예약변경승인',  // b1
      13: '예약변경반려',  // r1
      14: '가상예약',      // w
      15: '확정예약',      // b1
      16: '수업취소',      // r1
      17: '노쇼',          // y1
      18: '노쇼',          // y1
      19: '노쇼',          // y1
      20: '일정',          // g
      21: '신청취소',      // r1
      default: 'unknown',  // g
    }

    if (['PT', 'TEST', 'OT', 'POINT'].includes(this.sType)) {
      if (this.uSeq) {
        const stateName = stateNames[this.sState] || stateNames['default'];
        return stateName;
      } else {
        if (this.lState === 'open') return 'OPEN';
        if (this.lState === 'close') return 'CLOSE';
        return 'NONE';
      }
    }
    else if (this.sType === 'GX') {
      return this.cName;
    }
    else if (this.sType === 'MEMO') {
      return this.sMemoTitle;
    }
    else {
      return this.sType;
    }
  }

  isOpenClose(): boolean {
    return this.sType === 'PT' && !this.uSeq && ['open', 'close'].includes(this.lState);
  }

  isOpen(): boolean {
    return this.sType === 'PT' && !this.uSeq && 'open' === this.lState;
  }

  isAllDay(): boolean {
    return this.sAllDay === 1 && this.sType === 'MEMO';
  }

  isPaint(): boolean {
    return this.sState === ScState.Adding && this.sType === 'MEMO';
  }
}
export interface LessonSchedule extends Lesson, Schedule { }
applyMixins(LessonSchedule, [Lesson, Schedule]);


export class ScheduleClass {
  gSeq: number;
  tSeq: number;
  cSeq?: number;
  cType: string;
  cStartRepeatDate: Date;
  cEndRepeatDate?: Date;
  cName: string;
  cNote?: string;
  cColor?: string;
  cQuota?: number;
  cInDate: Date;
  cModifyDate: Date;
  hHours?: Array<{ day: number, startTime: string, endTime: string, hSeq?: number, isOpen: boolean, openDate?: Date, sSessionParty?: number }>;
  hSeq: number;
  tName: string;
  constructor(values) {
    assignValues(this, values); // localStorage.gymTimeZone 입력하지 않음.
    if (!this.hHours) this.hHours = [];
  }
}

export class DaySchedule {
  name: string;   // 2019.11.14
  count: {
    reserved: number, open: number, close: number, memo: number, health: number, pt: number, ot: number, gx: number, ch: number, virtual: number,
  };
  borderColor?: string = '#dfe6ee';
  schedules: Array<Schedule | LessonSchedule>;
  confirmedNoShowSchedules: Array<Schedule | LessonSchedule>;
  constructor(startDate) {
    this.name = startDate;
    this.count = { reserved: 0, open: 0, close: 0, memo: 0, health: 0, pt: 0, ot: 0, gx: 0, ch: 0, virtual: 0 };
    this.schedules = [];
    this.confirmedNoShowSchedules = [];
  }
}

// 순서 유지 필요. 등급에 해당한다.
export const TRAINER_AUTH = StringUnion('TRAINER', 'MANAGER', 'COMMON', 'SUPERVISOR', 'OWNER');
export type TrainerAuthType = typeof TRAINER_AUTH.type;

export const SCHEDULE_TYPE = StringUnion('PT', 'GX', 'OT', 'TEST', 'MEMO', 'POINT');
export type ScheduleType = typeof SCHEDULE_TYPE.type;

export const PACKAGE_TYPE = StringUnion('PT', 'GX', 'OT', 'TEST', 'HEALTH', 'ETC', 'PRODUCT', 'POINT');
export type PackageType = typeof PACKAGE_TYPE.type;

export const CLASS_COUNT_TYPE = StringUnion('PT', 'GX', 'OT', 'TEST', 'POINT');
export type ClassCountType = typeof CLASS_COUNT_TYPE.type;

export const MONTHLY_SCHEDULE_TYPE = StringUnion('PT', 'GX', 'ETC', 'POINT');
export type MonthlyScheduleType = typeof MONTHLY_SCHEDULE_TYPE.type;

export type Leaves<T> = {
  [key: string]: T | Leaves<T>
}

export interface CountBase<R> {
  addCount(record: R);
}

export class CountSummary<R, C extends CountBase<R>> {
  records: R[];
  total: C;
  classifiedPaths: string[] = [];
  formater;

  constructor(private ctor: { new(): C }, records: R[], formater?: { [key: string]: (data) => {} }) {
    this.records = records;
    this.formater = formater;
    this.total = new this.ctor();
    for (const record of records) {
      this.total.addCount(record);
    }
  }

  private parseParam(param: any) {
    const classifyKeys: string[] = [];
    const classifyTokens = Object.keys(param).reduce((a, c) => {
      classifyKeys.push(c);
      a.push(c);
      param[c] !== null && a.push(param[c]);
      return a;
    }, [] as Array<any>);
    return { classifyKeys, classifyTokens };
  }
  private validateClassifyRecord(classifyKeys: string[]) {
    if (classifyKeys.length === 0) return;

    const classifiedPath = classifyKeys.join('.');
    if (this.classifiedPaths.find(path => path === classifiedPath)) return;

    this.classifiedPaths.push(classifiedPath);
    const result = this.internalClassifyRecord(this.records, classifyKeys);
    merge(this, result);
  }
  private getObject(classifyTokens: string[]) {
    let targetObject: any = null;
    if (classifyTokens.length > 0) {
      targetObject = this;
      for (const key of classifyTokens) {
        targetObject = targetObject[key];
        if (!targetObject) {
          targetObject = null;
          break;
        }
      }
    }
    return targetObject;
  }

  getCountTotal(param: any = {}): C {
    const { classifyKeys, classifyTokens } = this.parseParam(param);
    this.validateClassifyRecord(classifyKeys);

    classifyTokens.push('total');
    const targetObject: any = this.getObject(classifyTokens);
    return targetObject || new this.ctor();
  }
  getClassifiedCounts(param: any = {}): C {
    const { classifyKeys, classifyTokens } = this.parseParam(param);
    this.validateClassifyRecord(classifyKeys);

    const targetObject: any = this.getObject(classifyTokens);
    return targetObject || new this.ctor();
  }

  getClassifiedKeys(param: any = {}): string[] {
    const { classifyKeys, classifyTokens } = this.parseParam(param);
    this.validateClassifyRecord(classifyKeys);

    const targetObject: any = this.getObject(classifyTokens);
    return targetObject ? Object.keys(targetObject) : [];
  }

  getClassifiedRecords(param: any = {}): R[] {
    const { classifyKeys, classifyTokens } = this.parseParam(param);
    this.validateClassifyRecord(classifyKeys);

    classifyTokens.push('records');
    const targetObject = this.getObject(classifyTokens);
    return targetObject || [];
  }

  private internalClassifyRecord(records: R[], fields: string[]): Leaves<{ total: C, datas: R[] }> {
    const field = fields.shift();

    const result: any = { total: new this.ctor(), records };
    const { total } = result;
    for (const record of records) {
      total.addCount(record);

      if (field) {
        const fieldValue: any = this.formater && this.formater[field] ? this.formater[field](record) : record[field];
        if (!result[field]) result[field] = {};

        if (!result[field][fieldValue]) result[field][fieldValue] = [];
        const subDatas = result[field][fieldValue];
        subDatas.push(record);
      }
    }

    if (field && result[field]) {
      for (const fieldValue of Object.keys(result[field])) {
        result[field][fieldValue] = this.internalClassifyRecord(result[field][fieldValue], Array.from(fields));
      }
    }

    return result;
  }

  private accumulateRecord(records: R[]): C {
    const classCount = new this.ctor();
    for (const record of records) {
      classCount.addCount(record);
    }
    return classCount;
  }

  sumCount(conditions: Array<{ field: string, operation: '<=' | '>=' | '<' | '>' | '===', operand: any }>): C {
    const filtered = this.records.filter(record => {
      for (const condition of conditions) {
        const { field, operation, operand } = condition;
        const value = record[field];
        if (value === undefined) return false;
        switch (operation) {
          case '<=':
            if (value <= operand) {
              continue;
            } else {
              break;
            }
          case '>=':
            if (value >= operand) {
              continue;
            } else {
              break;
            }
          case '<':
            if (value < operand) {
              continue;
            } else {
              break;
            }
          case '>':
            if (value > operand) {
              continue;
            } else {
              break;
            }
          case '===':
            // ! == 연산자 사용시 lint 에러
            if (value === operand) {
              continue;
            } else {
              break;
            }
        }
        return false;
      }
      return true;
    });
    // filtered.length > 0 && console.log('sumCount', { conditions, filtered });
    return this.accumulateRecord(filtered);
  }
}


export class ClassCount implements CountBase<ClassCountRecord> {
  classCount: number = 0;     // 수업 수
  quotaCount: number = 0;     // 정원 수
  attendedCount: number = 0;  // 출석 수. past count
  reservedCount: number = 0;  // 예약 수. future count
  attendedAmount: number = 0;
  uSeqMap?: { [uSeq: number]: number };
  getUserCount() {
    return !this.uSeqMap ? 0 : Object.keys(this.uSeqMap).length;
  }
  addCount(record: ClassCountRecord | ClassCount) {
    // addCount(record: ClassCountRecord) {
    if (!record) return;
    this.classCount += record.classCount || 0;
    this.quotaCount += record.quotaCount || 0;
    this.attendedCount += record.attendedCount || 0;
    this.reservedCount += record.reservedCount || 0;
    this.attendedAmount += record.attendedAmount || 0;

    if (record['uSeqs']) {
      // if (record.uSeqs) {
      const uSeqs = record['uSeqs'].split(',');
      this.uSeqMap = uSeqs.reduce((uSeqMap, uSeq) => {
        uSeqMap[uSeq] = (uSeqMap[uSeq] || 0) + 1;
        return uSeqMap;
      }, this.uSeqMap || {});
    }
  }
  getAttendedCountRatio() {
    return this.classCount && this.attendedCount ? this.attendedCount / this.quotaCount : 0;
  }
  getData(dataType: string) {
    switch (dataType) {
      case 'attendedAmount': return this.attendedAmount;
      case 'attendedCount': return this.attendedCount;
      case 'userCount': return this.getUserCount();
      default: return this[dataType];
    }
  }
}
export class ClassCountRecord extends ClassCount {
  dateKey: string; // yyyy-MM or yyyy-MM-dd
  tSeq: number;
  sType: string;
  cSeq: number;
  cName: string;
  uSeqs?: string;
}
export type ClassCountSummary = CountSummary<ClassCountRecord, ClassCount>;
export type ExTestCountSummary = CountSummary<ExTestCountRecord, ExTestCount>;


export type DispClassCountRecord = Record<ClassCountType, DispClassCount>;
export class DispClassCount {
  pastDayClassCount: number;
  futureDayClassCount: number;
  pastMonthClassCount: number;
  hasLastBookings?: boolean;
  isRetire?: boolean;

  constructor(args: DispClassCount = {
    pastDayClassCount: 0,
    futureDayClassCount: 0,
    pastMonthClassCount: 0,
    hasLastBookings: false,
    isRetire: false,
  }) {
    this.pastDayClassCount = args.pastDayClassCount;
    this.futureDayClassCount = args.futureDayClassCount;
    this.pastMonthClassCount = args.pastMonthClassCount;
    this.hasLastBookings = args.hasLastBookings;
    this.isRetire = args.isRetire;
  }

  toString() {
    return `${this.pastDayClassCount || 0}(${this.futureDayClassCount || 0})/${this.pastMonthClassCount || 0}`;
  }
}

// const testTypes: { [key in KeepRunningProgram]: string } = {
//   '빠른 회복': 'fastRecovery',  // tmt
//   '역치 파워': 'thresholdPower',  //
//   '지방대사 향상': 'fatMetabolism', //
//   '러닝 지속성 향상': 'runningSustainability',  //
//   '젖산 역치 향상 Step 1': 'lactateThresholdStep1', // lactate
//   '젖산 역치 향상 Step 2': 'lactateThresholdStep2', // lactate
// };

// const testTypes: { [key in RunAndRestProgram]: string } = {
//   'ATP-PC 향상': 'atpPc', //
//   'VLamax 향상': 'vLamax',  //
//   '고강도 간헐적 트레이닝': 'hiit', // tmt
// };

export const EX_TEST_MAIN_TYPE = StringUnion('before', 'smt', 'tmt', 'fmt', 'lactate');
export type ExTestMainType = typeof EX_TEST_MAIN_TYPE.type;

export const EX_TEST_QUERY_MODE = StringUnion('countAll', 'countExType', 'stats');
export type ExTestQueryMode = typeof EX_TEST_QUERY_MODE.type;

export type ExTestReturnTotalCount = { userCount: number, attendedCount: number };
export type ExTestReturnTypeCount = { mainExType: ExTestMainType, exType: string, userCount: number, attendedCount: number };

export class ExTestCount implements CountBase<ExTestCountRecord> {
  userCount: number = 0;
  attendedCount: number = 0;
  uSeqMap: { [uSeq: number]: number };
  getUserCount() {
    return !this.uSeqMap ? 0 : Object.keys(this.uSeqMap).length;
  }
  addCount(record: ExTestCountRecord) {
    if (!record) return;
    this.userCount += record.userCount || 0;
    this.attendedCount += record.attendedCount || 0;

    // user 중복 배제
    if (record.uSeqs) {
      const uSeqs = record.uSeqs.split(',');
      this.uSeqMap = uSeqs.reduce((uSeqMap, uSeq) => {
        uSeqMap[uSeq] = (uSeqMap[uSeq] || 0) + 1;
        return uSeqMap;
      }, this.uSeqMap || {});
    }
  }
}
export class ExTestCountRecord extends ExTestCount {
  dateKey: string; // yyyy-MM
  tSeq: number;
  exType: string;
  uSeqs: string;
}

export class PaymentHistory {
  uSeq: number;
  uName: string;
  uBirth: Date;
  uGender: string;
  uProfile: string;
  uState: number;
  uConnect: number;
  uTel: string;

  tSeq: number;
  tName: string;
  tProfile: string;

  htSeq: number;
  htName: string;

  paSeq: number;
  paName: string;
  paType: string;

  pSeq: number;
  pType: string;
  pAmount: number;
  pPeriod: number;
  pSession: number;
  pRegistDate: Date;
  pSessionUsed: number;
  pRefundAmount: number;
  upMemo: string;
  pStartDate: Date;
  pEndDate: Date;
  pInDate: Date;
  pCancelDate: Date;
  pReqStatus: string;
  paymentCount: number;

  amountText: string;

  constructor(values) {
    assignValues(this, values);
  }
}



export class gymPlanPaymentParam {
  tok?: string;
  email?: string;
  forPublic?: boolean;
  bSeq?: string;

  upgrade: boolean;
  type: string;
  packageSeq: string;
  treadmillCnt: number;
  userCnt: number;
  trainerCnt: number;
  schedule: string;
  tmtFlag: number;
  fmtFlag: number;
  smtFlag: number;
  lacticFlag: number;
  couponsSeq: Array<number>;
  gSeq: number;
  groupHead: number;
  isCurrentLangKo: boolean;
  countryCode: string;
  pMethodType?: string = 'card' || 'account';

  paypalUrl?: string;
  clientId?: string;
  clientSecret?: string;
  createPlanRst?: any;
  createSubsRst?: any;
  amount?: number;
  nextAmount?: number;
  now?: Date;
  gmtNow?: Date;
  expiredDate?: Date;
  addTrainerCnt?: number;
  addUserCnt?: number;
  state?: string;
  mSeq?: number;
  periodStr?: string;
  prevPayment?: any;
  pkgInfo?: any;
  myInfo?: any;

  depositor?: string;
}
