<ion-header mode="ios" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
  <ion-toolbar mode="md" [mode]="util.isMobileApp() ? 'ios' : 'md'">
    <ion-title>{{'건강.추천 교정운동'|translate}}</ion-title>
    <ion-buttons slot="end">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
  <ng-container *ngIf="!util.isMobileApp()">
    <iframe class="video-view" [src]="src|safe" allowfullscreen></iframe>
  </ng-container>
  <div *ngIf="util.isMobileApp()" class="video-container">
    <iframe [src]="src|safe" frameborder="0" allowfullscreen class="mobile-video-frame"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture">
    </iframe>
  </div>
  <ion-label class="video-title">{{video.name}}</ion-label>
  <!-- TODO: 모바일 버전의 경우 설명 문구 출력 필요, 기획팀 전달해줄 예정 -->
</ion-content>
