<ion-row>
  <div class="empty-bar"></div>
  <ion-icon name="{{stepIndex>0 ? 'checkmark-circle' : 'checkmark-circle-outline'}}" color="primary"></ion-icon>
  <div class="dash-bar"></div>
  <ion-icon name="{{stepIndex>1 ? 'checkmark-circle' : 'checkmark-circle-outline'}}" color="{{stepIndex<1 ? 'light' : 'primary'}}"></ion-icon>
  <div class="dash-bar"></div>
  <ion-icon name="{{stepIndex>2 ? 'checkmark-circle' : 'checkmark-circle-outline'}}" color="{{stepIndex<2 ? 'light' : 'primary'}}"></ion-icon>
  <div class="dash-bar"></div>
  <ion-icon name="{{stepIndex>3 ? 'checkmark-circle' : 'checkmark-circle-outline'}}" color="{{stepIndex<3 ? 'light' : 'primary'}}"></ion-icon>
  <div class="empty-bar"></div>
</ion-row>
<ion-row>
  <ion-label class="step-text" [ngStyle]="{'visibility': stepIndex==0 ? 'visible' : 'hidden'}">{{text0}}</ion-label>
  <ion-label class="step-text" [ngStyle]="{'visibility': stepIndex==1 ? 'visible' : 'hidden'}">{{text1}}</ion-label>
  <ion-label class="step-text" [ngStyle]="{'visibility': stepIndex==2 ? 'visible' : 'hidden'}">{{text2}}</ion-label>
  <ion-label class="step-text" [ngStyle]="{'visibility': stepIndex==3 ? 'visible' : 'hidden'}">{{text3}}</ion-label>
</ion-row>
