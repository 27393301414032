import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib';
import { ImageUploaderComponent } from './image-uploader';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
  declarations: [ImageUploaderComponent],
  imports: [
    SharedModule,
    NgCircleProgressModule.forRoot({
      radius: 30,
      maxPercent: 100,
      showTitle: false,
      showUnits: false,
      showSubtitle: false,
      outerStrokeWidth: 8,
      innerStrokeWidth: 8,
      space: -8,
      // showInnerStroke: false,
      outerStrokeColor: 'dimgray',
      innerStrokeColor: 'whitesmoke',
      animation: true,
      animationDuration: 300,
      responsive: true,
    }),
  ],
  exports: [ImageUploaderComponent],
})
export class ImageUploaderCompModule { }
