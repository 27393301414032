import { LessonState } from './schedule-models';

export interface GymSettings {
  openTime: number;
  closeTime: number;
  startTime: number;
  classTime: number;
  autoAccept: number;
  autoReject: number;
  crossTrainer: number;
  noshowSession: number;
  noshowTime: number;
  reservationLimitCount: number;
  reservationLimitCountOneDay: number;
  reservationPeriod: number;
  weekdays: { [weekday: string]: number };

  classDefault: LessonState;
}

export class GymPackage {
  paSeq: number;
  gSeq: number;
  paType: string;
  paName: string;
  paSession: number;
  paSessionText?: number;
  paPeriod: number;
  paAmount: number;
  tSeq: number;
  paInDate: Date;
  paVisible: number;
  paFixed: number;
  paNew: number;
  paGXAll: number;
  paEntranceCount: number;
  paUseStartTime: string;
  paUseEndTime: string;
  paUseDay: string[];
  paTypeText: string
}

export class UsageStatus {
  order: number;
  futureCount: number;
  session: number | '∞';
  trSession: number;

  constructor(order: number, futureCount: number, session: number, trSession: number) {
    this.order = order;
    this.futureCount = futureCount;
    this.session = session === 0 ? '∞' : session;
    this.trSession = trSession;
  }

  toText(includeFuture: boolean): string {
    const futureText = `(${this.futureCount})`;
    return `${this.order + this.trSession}${includeFuture ? futureText : ''}/${this.session}`;
  }

  getColor() {
    if (this.order === 1) {
      return 'var(--ft-color-main)';
    } else if (typeof this.session === 'number' && this.order >= this.session) {
      return 'var(--ft-color-sub4)';
    }
    return '';
  }

  setOrder(amount: number) {
    this.order += amount;
  }

  setFutureCount(amount: number) {
    this.futureCount += amount;
  }
}
