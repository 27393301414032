
import { Pipe, PipeTransform } from '@angular/core';
import { UnitType, convertUnit } from '@shared-lib/utils/unit-conversion';
import { UtilService } from '@shared-lib/services/util.service';


@Pipe({ name: 'unitConv' })
export class UnitConversionPipe implements PipeTransform {
  constructor(
    private util: UtilService
  ) { }
  transform(value: number | string, args: { from: UnitType, to: UnitType, suffixUnit?: boolean, toFixed?: number | number[], displayUnit?: 'km' | 'mi' }) {
    // const { from, to, suffixUnit = true, toFixed = 0, displayUnit = localStorage.displayUnit } = args;
    const { from, to, suffixUnit = true, toFixed = 0, displayUnit = this.util.isDisplayUnitKm() ? 'km' : 'mi' } = args;
    value = Number(value);
    if (value === undefined && value === null && isNaN(value)) {
      console.warn("Convert Pipe Warn", value);
      return;
    }
    let fixed = 0;
    if (Array.isArray(toFixed)) {
      fixed = displayUnit != 'mi' ? toFixed[0] : toFixed[1];
    }
    else {
      fixed = toFixed;
    }

    switch (to) {
      case 'm/ft': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'm' : 'ft', suffixUnit, toFixed: fixed });
      case 'cm/ft': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'cm' : 'ft', suffixUnit, toFixed: fixed });
      case 'km/mi': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'km' : 'mi', suffixUnit, toFixed: fixed });
      case 'kph/mph': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'kph' : 'mph', suffixUnit, toFixed: fixed });
      case 'm/mi': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'm' : 'mi', suffixUnit, toFixed: fixed });
      case 'kg/lbs': return convertUnit({ value, from, to: displayUnit != 'mi' ? 'kg' : 'lbs', suffixUnit, toFixed: fixed });
      default: return convertUnit({ value, from, to, suffixUnit, toFixed: fixed });
    }
  }
}
