<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button fill="clear" (click)="close()">
        <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title >{{getTitle()}}</ion-title>
    <ion-buttons slot="end" *ngIf="displayManager.save">
      <ion-button fill="clear" color="primary" (click)="save()"> {{'저장'|translate}} </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ft-user-search-item [canUserCancel]="canUserCancel" [selectedUser]="user" (onSelectUser)="onSelectUser($event)"></ft-user-search-item>

<ion-segment *ngIf="displayManager.package" color="dark" mode="md" [scrollable]="util.isMobileApp()" (ionChange)="segmentChanged($event)"
  [(ngModel)]="packageType" class="item-border-bottom">
  <ion-segment-button value="HEALTH" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'건강.헬스'|translate}}</ion-label>
  </ion-segment-button>
  <ion-segment-button *ngIf="fittUtil.planPermission.check('스케줄.스케줄러 PT')" value="PT" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'모달.PT'|translate}}</ion-label>
  </ion-segment-button>
  <ion-segment-button value="OT" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'모달.OT'|translate}}</ion-label>
  </ion-segment-button>
  <ion-segment-button value="TEST" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'대체.운동 검사'|translate}}</ion-label>
  </ion-segment-button>
  <ion-segment-button *ngIf="fittUtil.planPermission.check('스케줄.스케줄러 GX')" value="GX" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'모달.GX'|translate}}</ion-label>
  </ion-segment-button>
  <ion-segment-button value="ETC" [ngClass]="util.isMobileApp() ? 'mobile-tab' : 'web-tab'">
    <ion-label>{{'모달.기타'|translate}}</ion-label>
  </ion-segment-button>
</ion-segment>

<ion-content>
  <ft-flex-row borderBottom="none" *ngIf="displayManager.paymentInfo">
    <ion-label left> {{'급여정산.결제일'|translate}} </ion-label>
    <ion-row right>
      <ng-container *ngIf="!util.isMobileApp()">
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="4" maxlength="4" style="margin-right: 8px; flex:1" placeholder="연도" [(ngModel)]="paymentRegistYear"
            (ionChange)="setVaildDateInWeb('paymentRegistDate')">
          </ion-input>
        </ion-col>
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="margin-right: 8px; flex:1" placeholder="월" [(ngModel)]="paymentRegistMonth"
            (ionChange)="setVaildDateInWeb('paymentRegistDate')">
          </ion-input>
        </ion-col>
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="flex:1" placeholder="일" [(ngModel)]="paymentRegistDay" (ionChange)="setVaildDateInWeb('paymentRegistDate')">
          </ion-input>
        </ion-col>
      </ng-container>
      <ion-item class="input-container" lines="none" *ngIf="util.isMobileApp()" style="margin: 1px 5px; height: 44px;">
        <ion-datetime slot="start" displayFormat="YYYY/MM/DD" [(ngModel)]="paymentRegistDate" placeholder="{{'모달.결제일을 입력해 주세요.'|translate}}" (ionChange)="setVaildDateInMobile('paymentRegistDate')"
          [doneText]="'확인'|translate" [cancelText]="'취소'|translate" style="width: 100%; margin: 0;">
        </ion-datetime>
      </ion-item>
    </ion-row>
  </ft-flex-row>

  <ft-flex-row borderBottom="none" *ngIf="displayManager.paymentInfo">
    <ion-label left> {{'모달.시작일'|translate}} </ion-label>
    <ion-row right>
      <ng-container *ngIf="!util.isMobileApp()">
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="4" maxlength="4" style="margin-right: 8px; flex:1" placeholder="연도" [(ngModel)]="startYear"
            (ionChange)="setVaildDateInWeb('startDate')">
          </ion-input>
        </ion-col>
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="margin-right: 8px; flex:1" placeholder="월" [(ngModel)]="startMonth"
            (ionChange)="setVaildDateInWeb('startDate')">
          </ion-input>
        </ion-col>
        <ion-col>
          <ion-input class="input-container" numberOnly minlength="2" maxlength="2" style="flex:1" placeholder="일" [(ngModel)]="startDay" (ionChange)="setVaildDateInWeb('startDate')">
          </ion-input>
        </ion-col>
      </ng-container>
      <ion-item class="input-container" lines="none" *ngIf="util.isMobileApp()" style="margin: 1px 5px; height: 44px;">
        <ion-datetime slot="start" displayFormat="YYYY/MM/DD" [(ngModel)]="startDate" placeholder="{{'모달.시작일을 입력해 주세요.'|translate}}" (ionChange)="setVaildDateInMobile('startDate')"
          [doneText]="'확인'|translate" [cancelText]="'취소'|translate" style="width: 100%; margin: 0;">
        </ion-datetime>
      </ion-item>
    </ion-row>
  </ft-flex-row>

  <ng-container *ngIf="displayManager.package">
    <ft-flex-row borderBottom="none">
      <ion-label left> {{'모달.패키지'|translate}} </ion-label>
      <ion-row right>
        <ion-col size="12">
          <ion-select hasFocusClass class="input-container" placeholder="{{packagePlaceholder}}" [(ngModel)]="selectedPackageIndex"
            [interfaceOptions]="{header: '모달.패키지를 선택하세요'|translate, cssClass: 'web-select-option-popup'}" (ionChange)="selectPackage($event)">
            <ion-select-option *ngFor="let package of packageList; index as idx;" [value]="idx">
              {{packageType!='ETC'?'':'['+ (('packageType.'+package.paType)|translate) +']'}}{{package.paName}}
            </ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="0"></ion-col>
      </ion-row>
    </ft-flex-row>

    <ft-flex-row borderBottom="none" *ngIf="!selectedPackage||!['PRODUCT'].includes(selectedPackage.paType)">
      <ion-label left> {{'모달.세션수'|translate}} </ion-label>
      <ion-row right>
        <ion-col>
          <ion-label *ngIf="!selectedPackage || paSession === 0">{{'모달.무제한'|translate}}</ion-label>
          <ion-input *ngIf="selectedPackage && paSession" class="input-container" [(ngModel)]="sessionCount" type="number" min="1" (ionChange)="amountChange($event, 'sessionCount')"></ion-input>
        </ion-col>
      </ion-row>
    </ft-flex-row>
  </ng-container>

  <ng-container *ngIf="displayManager.point">
    <ft-flex-row borderBottom="none">
      <ion-label left> {{'짐결제.결제 금액'|translate}} </ion-label>
      <ion-row right>
        <ion-col>
          <ion-input type="number" placeholder="{{'급여정산.금액 입력' | translate}}" [(ngModel)]="pAmount"
            (ionChange)="amountChange($event, 'pAmount'); pointChange($event);" class="input-container ion-padding-end">
          </ion-input>
        </ion-col>
      </ion-row>
    </ft-flex-row>

    <ft-flex-row borderBottom="none">
      <ion-label left> {{'모달.추가 적립'|translate}} </ion-label>
      <ion-row right>
        <ion-col>
          <ion-input type="number" placeholder="{{'급여정산.금액 입력' | translate}}" [(ngModel)]="bonusAmount"
            (ionChange)="amountChange($event, 'bonusAmount'); pointChange($event)" class="input-container ion-padding-end">
          </ion-input>
        </ion-col>
      </ion-row>
    </ft-flex-row>

    <ft-flex-row borderBottom="none">
      <ion-label left> {{'모달.충전 금액'|translate}} </ion-label>
      <ion-row right>
        <ion-col>
          <ion-input readonly type="number" placeholder="{{'모달.자동 입력' | translate}}" [(ngModel)]="uptAmount"
            class="input-container ion-padding-end">
          </ion-input>
        </ion-col>
      </ion-row>
    </ft-flex-row>
  </ng-container>


  <ft-date-range *ngIf="!selectedPackage || !['PRODUCT'].includes(selectedPackage.paType)" [(startDate)]="startDate" [(months)]="packagePeriod" [periodList]="[1,2,3,4,5,6,7,8,9,10,11,12]"></ft-date-range>

  <ft-flex-row borderBottom="none" *ngIf="displayManager.package">
    <ion-label left> {{'모달.금액'|translate}} </ion-label>
    <ion-row right>
      <ion-col>
        <ion-input class="input-container ion-padding-end" [(ngModel)]="pAmount" (ionChange)="amountChange($event, 'pAmount')">
        </ion-input>
      </ion-col>
    </ion-row>
  </ft-flex-row>

  <ft-flex-row borderBottom="none" *ngIf="packageType==='PT'">
    <ion-label left> {{'모달.회당 가격'|translate}} </ion-label>
    <ion-row right>
      <ion-col>
        <ion-input class="input-container" style="--padding-end:30px !important" [value]="selectedPackage ? (parseInteger(pAmount/sessionCount)) : ''" disabled></ion-input>
      </ion-col>
    </ion-row>
  </ft-flex-row>

  <ft-flex-row borderBottom="none" *ngIf="displayManager.paymentInfo">
    <ion-label left> {{'모달.결제수단'|translate}} </ion-label>
    <ion-row right>
      <ion-col>
        <ion-select hasFocusClass class="input-container" placeholder="{{'모달.결제 수단을 선택하세요.'|translate}}" [(ngModel)]="paymentType">
          <ion-select-option value="wire">{{'모달.무통장 입금'|translate}}</ion-select-option>
          <ion-select-option value="account">{{'모달.계좌 이체'|translate}}</ion-select-option>
          <ion-select-option value="card">{{'모달.카드 결제'|translate}}</ion-select-option>
          <ion-select-option value="phone">{{'모달.휴대폰 결제'|translate}}</ion-select-option>
          <ion-select-option value="cash">{{'모달.현금'|translate}}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </ft-flex-row>

  <ft-flex-row borderBottom="none">
    <ion-label left> {{'모달.담당'|translate}} </ion-label>
    <ion-row right>
      <ion-col>
        <ion-select hasFocusClass class="input-container" [(ngModel)]="tSeq" placeholder="{{'모달.트레이너를 선택해 주세요.'|translate}}">
          <ion-select-option *ngFor="let trainer of trainerList" [value]="trainer.tSeq">
            {{trainer.tSeq ? trainer.tName + ' T' : '담당 없음'|translate}}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </ft-flex-row>

  <ft-flex-row borderBottom="none">
    <ion-label left> {{'컴포넌트.메모'|translate}} </ion-label>
    <ion-row right>
      <ion-col>
        <ion-textarea right placeholder="{{'컴포넌트.메모 추가'|translate}}" [(ngModel)]="memoString" maxlength="500" class="input-container ion-margin-bottom"
          style="--padding-bottom: 30px !important; ">
        </ion-textarea>
      </ion-col>
    </ion-row>
  </ft-flex-row>

  <div><br><br></div>
  <div *ngIf="displayManager.paymentRequest" style="padding: 20px 20px; align-items: center; background-color: rgba(0, 0, 0, 0.02); width:100%; display: flex; align-items: center; position: relative;">
    <div>
      <span style="font-size: 14px; color: var(--ft-color-black2);"><b>{{'['+('온라인결제.온라인 결제 요청'|translate)+']'}}</b></span><br>
      <span style="font-size: 12px; color: var(--ft-color-black2);">{{'온라인결제.결제 요청은 앱을 통해 회원에게 이용권 결제를 요청하는 기능입니다.'|translate}}</span><br>
      <span style="font-size: 12px; color: var(--ft-color-black2);">{{'온라인결제.요청 후 7일간 유지되며 응답이 없을 시 자동으로 요청종료 처리됩니다.'|translate}}</span>
    </div>
  </div>
</ion-content>

<ion-footer *ngIf="displayManager.paymentRequest">
  <ion-toolbar>
    <ion-buttons class="bottom-1-button">
      <ion-button color="primary" size="large" fill="outline" style="margin-top: 5px;" (click)="doPayRequest()">
        <b>{{'온라인결제.온라인 결제 요청'|translate}}</b>
      </ion-button>
    </ion-buttons>
    <ion-row style="height:30px"></ion-row>
  </ion-toolbar>

</ion-footer>
