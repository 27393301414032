
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'phonenumber' })
export class PhoneNumberPipe implements PipeTransform {
  constructor(
  ) { }
  transform(value: string) {
    return value ? value.phoneFormat() : '';
  }
}
