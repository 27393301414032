import { Directive, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BaseComponent } from '@shared-lib/base.component';

@Directive({ selector: '[focusOnInit]' })   // component 생성시 auto focus.
export class FocusOnInitDirective extends BaseComponent {
  public constructor(
    private elementRef: ElementRef,
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    setTimeout(async () => {
      if (this.elementRef.nativeElement.localName == 'ion-textarea') {
        const inputElement: HTMLElement = await this.elementRef.nativeElement.getInputElement();
        inputElement.focus();
      }
      else if (this.elementRef.nativeElement.localName == 'ion-input') {
        this.elementRef.nativeElement.setFocus();
      }
      else {
        this.elementRef.nativeElement.focus();
      }
    }, 500);
  }
}

@Directive({ selector: '[focusOnViewEnter]' })   // component 생성시 auto focus. 다른 페이지로 갔다가 다시 돌아왔을 때에도 auto focus. view에서만 사용. modal에서는 작동하지 않음.
export class FocusOnViewEnterDirective extends BaseComponent {
  private subscribedEvent: any;
  private subscribedUrl: string;
  private routeSubscription: any;
  public constructor(
    private elementRef: ElementRef,
    private router: Router,
  ) {
    super({ enableLog: false });
    this.subscribedEvent = this.router.events.pipe(filter(e => e instanceof NavigationEnd));
    this.subscribedUrl = this.router.url;
  }

  ngOnInit() {
    this.routeSubscription = this.subscribedEvent.subscribe(e => {
      if (e.url === this.subscribedUrl) {
        this.log('focusOnViewEnter.ngOnInit');
        this.elementRef.nativeElement.value = '';

        setTimeout(async () => {
          if (this.elementRef.nativeElement.localName == 'ion-textarea') {
            const inputElement: HTMLElement = await this.elementRef.nativeElement.getInputElement();
            inputElement.focus();
          }
          else if (['ion-input', 'ion-searchbar'].includes(this.elementRef.nativeElement.localName)) {
            this.elementRef.nativeElement.setFocus();
          }
          else {
            this.elementRef.nativeElement.focus();
          }
        }, 500);
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
  }
}
