import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib/shared.module';
import { MobileFMTGuideModal } from './mobile-fmt-guide';

@NgModule({
  declarations: [
    MobileFMTGuideModal
  ],
  imports: [
    SharedModule,
  ],
  entryComponents: [
    MobileFMTGuideModal
  ],
  exports: [
    MobileFMTGuideModal
  ]
})
export class MobileFMTGuideModalModule { }
