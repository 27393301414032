import { Directive, Input } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { MaskApplierService } from 'ngx-mask';
import { Subscription } from 'rxjs';
import { BaseComponent } from '@shared-lib/base.component';

@Directive({
  selector: 'ion-input'
})
export class IonicInputMaskDirective extends BaseComponent {
  @Input() mask: any;
  ionChangeSubs: Subscription;
  constructor(
    private control: IonInput,
    private maskService: MaskApplierService,
  ) {
    super({ enableLog: false });
  }

  ngOnInit() {
    this.log('ngOnInit', this.mask);
    if (this.mask) {
      this.ionChangeSubs = this.control.ionChange.subscribe(this.ionChangeCallback);
    }
  }

  ngOnDestroy() {
    this.ionChangeSubs && this.ionChangeSubs.unsubscribe();
  }

  ionChangeCallback = (r: string) => {
    this.control.value = this.maskService.applyMask(this.control.value.toString(), this.mask);
  }
}
