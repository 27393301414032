import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { MenuPopOver } from '@shared-lib/pop-over/menu-pop-over/menu-pop-over';
import { PopoverController } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { DomToImageService } from '@shared-lib/services/dom-to-image.service';


@Component({
  selector: 'ft-mobile-ellipsis-vertical',
  templateUrl: './ft-mobile-ellipsis-vertical.html',
  styleUrls: ['./ft-mobile-ellipsis-vertical.scss'],
})
export class FtMobileEllipsisVerticalComponent extends FtLibBaseComponent implements OnInit {

  constructor(
    private popoverCtrl: PopoverController,
    private socialSharing: SocialSharing,
    private domToImage: DomToImageService,
  ) {
    super();
  }

  @Input() useShare: boolean = false;
  @Input() isTrainer: boolean = true;
  @Input() shareElementRef: any;
  @Input() deleteAlertTitle = this.translate.instant('모달.검사 삭제');
  @Input() deleteAlertMsg = this.translate.instant('모달.확인 시 기록이 삭제됩니다.\n삭제한 기록은 복구할 수 없습니다.\n기록을 삭제하시겠습니까?');

  @Output() onDelete = new EventEmitter();
  @Output() onShare = new EventEmitter();


  ngOnInit() {
  }

  async more($event) {
    const menuItems = [{ 'name': this.translate.instant('모달.삭제') }];
    if (this.useShare) menuItems.push({ 'name': this.translate.instant('일반.공유') });
    try {
      const popover = await this.popoverCtrl.create({ component: MenuPopOver, event: $event, componentProps: { buttons: menuItems } });
      await popover.present();
      const { data } = await popover.onDidDismiss();
      if (data) {
        switch (data.name) {
          case this.translate.instant('모달.삭제'): {
            const alert = await this.util.alertCreate({
              title: this.deleteAlertTitle,
              message: this.deleteAlertMsg,
              buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok' }]
            });
            alert.present();
            const res = await alert.onDidDismiss();
            if (res && res.role !== 'ok') {
              return;
            }
            this.onDelete.emit()
            break;
          }
          case this.translate.instant('일반.공유'): {
            if (this.isTrainer) {
              await this.util.showLoadingPopup(true);
              let interrupPromise = false;
              try {
                const images: Array<any> = [];
                await this.util.promiseTimeout(new Promise(async (resolve) => {
                  // 배열로 전달 받은 경우
                  if (this.shareElementRef.length) {
                    for (let i = 0; i < this.shareElementRef.length; i++) {
                      images[i] = await this.domToImage.domToBase64(this.shareElementRef[i].nativeElement);
                    }
                  } else {
                    images[0] = await this.domToImage.domToBase64(this.shareElementRef.nativeElement);
                  }

                  !interrupPromise && this.socialSharing.share(null, null, images, null);

                  resolve(undefined);
                }), 10000);
              }
              catch (error) {
                interrupPromise = true;
                this.log('share.error', error);

                this.util.alertCreate({ message: '오류가 발생했습니다.' });
              }
              finally {
                await this.util.showLoadingPopup(false);
              }
              break;
            }


            this.onShare.emit();
            break;
          }
        }
      }
    }
    catch (error) {
      this.warn(error);
    }
  }
}
