<ion-item lines="none">
  <ion-avatar slot="start" class="profile avatar-border">
    <img src="{{trainer.tProfile}}" *ngIf="trainer.tProfile">
    <img [src]="fittUtil.getDefaultUrl(trainer && trainer.tGender)" *ngIf="!trainer.tProfile">
  </ion-avatar>
  <ion-label style="margin-left: 8px;">
    <h2> {{trainer.tName}} </h2>
    <h4 style="font-size: 11px;"> {{trainer.tJobName || ('트레이너'|translate)}} </h4>
  </ion-label>
</ion-item>
