import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'action-pop-over',
  templateUrl: './action-pop-over.html',
  styleUrls: ['./action-pop-over.scss'],
})
export class ActionPopOver implements OnInit {

  buttons: Array<{ name: string }> = [];
  constructor(
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
  ) {
  }
  ngOnInit() {
    this.buttons = this.navParams.get('buttons');
  }

  clickMenu(button) {
    button.handler && button.handler();
    this.popoverCtrl.dismiss(button);
  }

}
