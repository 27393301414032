import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'fttime' })
export class FtTimePipe implements PipeTransform {
  constructor(
  ) { }
  transform(value, param) {
    if (!value) {
      return '00:00';
    }
    else if (value < 3600) {
      return `${Math.floor(value / 60).pad(2)}:${Math.round(value % 60).pad(2)}`;
    } else {
      return `${Math.floor(value / 3600)}:${Math.floor(value % 3600 / 60).pad(2)}:${Math.round(value % 60).pad(2)}`;
    }
  }
}