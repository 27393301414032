<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-button fill="clear" slot="start" (click)="close()">
      <ion-icon color="black1" name="chevron-back-outline"></ion-icon>
    </ion-button>
    <ion-title>{{'대체.움직임능력검사'|translate}}</ion-title>
    <ft-mobile-ellipsis-vertical slot="end" (onDelete)="onDelete()" [useShare]="isTrainer" [shareElementRef]="reportRef">
    </ft-mobile-ellipsis-vertical>
  </ion-toolbar>
</ion-header>
<ion-item *ngIf="record" lines="full">
  {{record.EXERCISE_TEST_InDate|ftdate:'yyyy. MM. dd (E)' }} - {{reportComponenets[activateIndex].title|translate}}
</ion-item>
<ion-content>
  <ion-slides *ngIf="record && report" (ionSlideDidChange)="ionSlideDidChange()" pager>
    <ion-slide>
      <ft-fmt-details [fmtItemList]="fmtItemList" [answerList]="record.EXERCISE_TEST_Data.answerList"></ft-fmt-details>
    </ion-slide>
    <ion-slide>
      <div #reportRef>
        <ft-fmt-report class="report-component" [gymId]="gymId" [gymUser]="gymUser" [report]="report"></ft-fmt-report>
      </div>
    </ion-slide>
    <ion-slide>
      <ft-fmt-analysis [report]="report" [answerList]="record.EXERCISE_TEST_Data.answerList"></ft-fmt-analysis>
    </ion-slide>
    <ion-slide>
      <ft-fmt-corrective style="width: 100%;" [mobility]="report.correctiveExercises.mobility" [stability]="report.correctiveExercises.stability"
        [motorControl]="report.correctiveExercises.motorControl">
      </ft-fmt-corrective>
    </ion-slide>
  </ion-slides>
</ion-content>
