import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib';
import { CommonFittModules } from '@fitt-lib/common-fitt/common-fitt.module';

@NgModule({
  exports: [
    SharedModule,
    CommonFittModules
  ],
})
export class BasicModule { }
