<ion-item #userSearchItem lines="full" cdk-overlay-origin #trigger="cdkOverlayOrigin">
  <ion-input #userSearchInput *ngIf="!selectedUser" class="name-input" placeholder="{{'컴포넌트.회원 이름을 입력하세요.'|translate}}" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (click)="onClick()"
    (ionChange)="inputChange($event)" (ionBlur)="inputBlur()" (ionFocus)="inputFocus()"></ion-input>
  <ng-container *ngIf="selectedUser">
    <div style="width:100%; display: flex; align-items: center; height: 78px; position: relative;">
      <ft-user-profile [user]="selectedUser" [popUpEnable]="true"></ft-user-profile>
      <div class="selected-text-container">
        <div class="up">
          <span style="font-weight: bold;">{{selectedUser.uName}}</span>
          <span>{{selectedUser.uGender === 'male' ? ('컴포넌트.남'|translate) : ('컴포넌트.여'|translate)}} /
            {{'컴포넌트.만 _세'|translate:"{age:" + util.getAge(selectedUser.uBirth) + "}"}}</span>
          <!-- <ion-icon name="close-outline" slot="end" *ngIf="canUserCancel" style="float: right;" (click)="cancelUser($event)"></ion-icon> -->
        </div>
      </div>
    </div>
    <ion-button id="cancel-user" slot="end" fill="clear" *ngIf="canUserCancel" (click)="cancelUser()">
      <ion-icon color="black1" name="close-outline"></ion-icon>
    </ion-button>
  </ng-container>
</ion-item>

<ng-template cdk-connected-overlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayPositions]="positions" [cdkConnectedOverlayOpen]="!selectedUser && showSearchList">
  <ion-content #content [ngStyle]="{width: windowWidth + 'px', height: restHeight + 'px'}">
    <ion-item lines="full" *ngIf="notFound">
      <ion-label> {{'모달.검색 결과가 없습니다.'|translate}} </ion-label>
      <ion-button (click)="onClickAddUser()" size="normal">
        <ion-icon color="white" name="person-add-sharp"></ion-icon>
        <ion-label>{{'회원관리.신규회원 등록'|translate}}</ion-label>
      </ion-button>
    </ion-item>
    <ion-virtual-scroll #virtualScroll [items]="searchList" style="width:100%" approxItemHeight="78px" *ngIf="isLoad">
      <ion-item lines="full" *virtualItem="let user; index as idx;" class="member-box-list user-item" [ngClass]="{activate: idx==activateIndex}" (click)="selectUser(user.user)" detail>
        <ft-user-profile [user]="user.user"></ft-user-profile>
        <div class="text-container">
          <div style="font-weight: bold;">{{user.user.uName}}</div>
          <div>{{user.user.uGender === 'male' ? ('컴포넌트.남'|translate) : ('컴포넌트.여'|translate)}} /
            {{'컴포넌트.만 _세'|translate:"{age:" + util.getAge(user.user.uBirth) + "}"}} / {{user.user.uTel}}</div>
        </div>
      </ion-item>
    </ion-virtual-scroll>
    <ion-spinner *ngIf="!isLoad" style="position: absolute; left: calc(50% - 14px); top:calc(50% - 14px);"></ion-spinner>
  </ion-content>
</ng-template>