import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { FmtNote, FmtItem, FMT } from '@fitt-lib/models/exercise-test/fmt';
import { ModalController, IonContent } from '@ionic/angular';
import { MobileFMTGuideModal } from './mobile-fmt-guide/mobile-fmt-guide';

@Component({
  selector: 'add-mobile-fmt-detail',
  templateUrl: './add-mobile-fmt-detail.html',
  styleUrls: ['./add-mobile-fmt-detail.scss']
})
export class AddMobileFMTDetailModal extends FtLibBaseComponent implements OnInit {
  @ViewChild(IonContent) ionContent: IonContent;

  @Input() surveyItems: { fmtMemoList: Array<FmtNote>, answerList: Array<Array<any>> };
  @Input() showGuideView: boolean = true;
  @Input() fmtCheckIndex: number; // 8개 종목 index
  @Input() isFmtOptionfromRecord: Array<Array<boolean>>;

  readonly NUM_OF_ITEMS: number = 8; // FMT 검사 종목 개수

  fmtItemList: Array<FmtItem> = FMT.getFmtItemList();
  fmtItem: FmtItem;

  radioOptions: Array<{ key: string, value: number }> = [
    { key: '3', value: 3 },
    { key: '2', value: 2 },
    { key: '1', value: 1 },
    { key: '0', value: 0 },
  ];

  nextButtonDisabled: boolean;

  constructor(
    private modalCtrl: ModalController,
  ) {
    super();
  }

  ngOnInit() {
    this.log('ngOnInit', this.surveyItems, this.fmtCheckIndex);
    this.fmtItem = this.fmtItemList[this.fmtCheckIndex];
  }

  async showVideoGuideModal() {
    const modal = await this.modalCtrl.create({
      mode: 'ios',
      component: MobileFMTGuideModal,
      cssClass: 'swipeable-modal',
      showBackdrop: true,
      componentProps: {
        fmtCheckIndex: this.fmtCheckIndex
      }
    });
    await modal.present();
  }

  hideGuideView() {
    this.showGuideView = false;
    this.setNextDisabled();
  }

  isAnswered(fmtCheckIndex: number, itemIndex: number): boolean {
    return !!this.surveyItems.answerList[fmtCheckIndex] && !!this.surveyItems.answerList[fmtCheckIndex][itemIndex];
  }

  getRadioInitialIdx(fmtCheckIndex: number, itemIndex: number): number {
    if (!this.isAnswered(fmtCheckIndex, itemIndex)) {
      return undefined;
    }
    const answer = this.surveyItems.answerList[fmtCheckIndex][itemIndex];
    const idx = this.radioOptions.findIndex(option => option.key === answer);
    return idx;
  }

  onClickRadio(rowIdx: number, colIdx: number, option: { key: string, value: number }) {
    this.surveyItems.answerList[rowIdx][colIdx] = option.key;
    this.setNextDisabled();
  }

  async moveFmtCheck(value: number) {
    const next = async () => {
      let isAllAnswered = true;
      for (const answer of this.surveyItems.answerList[this.fmtCheckIndex]) {
        if (!answer) {
          isAllAnswered = false;
          break;
        }
      }

      if (!isAllAnswered) {
        const alert = await this.util.alertCreate({
          title: this.translate.instant('모달.미입력 문항 있음'),
          message: this.translate.instant('모달.입력하지 않은 문항이 있습니다.\n다음 종목으로 넘어가시겠습니까?'),
          buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok' }]
        });
        await alert.present();
        const alertResult = await alert.onDidDismiss();
        if (!alertResult || alertResult.role !== 'ok') {
          return;
        }
      }

      moveFmtItem();
    }

    const moveFmtItem = () => {
      this.fmtCheckIndex += value;
      this.fmtItem = this.fmtItemList[this.fmtCheckIndex];
      this.setNextDisabled();
      this.ionContent.scrollToTop();
    }

    if (value > 0) {
      if (this.fmtCheckIndex < this.NUM_OF_ITEMS - 1) {
        // 다음 종목 버튼 동작
        next();
      } else {
        // 검사 완료 버튼 동작
        this.close(true);
        return;
      }
    } else {
      // 이전 종목 버튼 동작
      moveFmtItem();
    }
  }

  someFunc(a) {
    return a;
  }

  isAllAnswerdAt(fmtCheckIndex): boolean {
    const answerdListOfIndex = this.surveyItems.answerList[fmtCheckIndex];
    return answerdListOfIndex.filter(answer => answer === undefined).length === 0;
  }

  setNextDisabled() {
    if (this.fmtCheckIndex < this.NUM_OF_ITEMS - 1) {
      this.nextButtonDisabled = false;
      return;
    }

    let isAllAnswered = false;
    for (const answers of this.surveyItems.answerList) {
      for (const answer of answers) {
        if (!answer) {
          isAllAnswered = true;
          break;
        }
      }
      if (!isAllAnswered) break;
    }
    this.nextButtonDisabled = isAllAnswered;
  }

  async close(showToast: boolean = false) {
    await this.modalCtrl.dismiss({
      surveyItems: this.surveyItems,
      showToast: showToast
    });
  }
}
