import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TextBoxComponent } from './ft-text-box/ft-text-box.component';
import { StepProgressComponent } from './ft-step-progress/ft-step-progress.component';
import { InputComponent } from './ft-input/ft-input.component';
import { FtDigitLabelComponent } from './ft-digit-label/ft-digit-label.component';
import { FtRollingLabelComponent } from './ft-rolling-label/ft-rolling-label.component';
import { FtBadgeButtonComponent } from './ft-badge-button/ft-badge-button.component';
import { FtTabButtonsComponent } from './ft-tab-buttons/ft-tab-buttons';
import { FtFlexRowComponent } from './ft-flex-row/ft-flex-row.component';
import { FtBaseModalComponent } from './ft-base-modal/ft-base-modal.component';
import { FtColorTextComponent } from './ft-color-text/ft-color-text.component';
import { FtToggleButtonComponent } from './ft-toggle-button/ft-toggle-button';
import { FtToggleButtonGroupComponent } from './ft-toggle-button-group/ft-toggle-button-group';
import { FtTriButtonComponent } from './ft-tri-button/ft-tri-button';
import { FtTriButtonGroupComponent } from './ft-tri-button-group/ft-tri-button-group';
import { FtDatePipeModule } from '../pipe/ft-date/ft-date.module';
import { FtTimePipeModule } from '../pipe/ft-time/ft-time.module';
import { NewDatePipeModule } from '../pipe/newDate/newDate.module';
import { TranslationLocPipeModule } from '../pipe/trans-loc/trans-loc.module';
import { PhoneNumberPipeModule } from '../pipe/phone-number/phone-number.module';
import { TextMaskPipeModule } from '../pipe/text-mask/text-mask.module';
import { MetricPipeModule } from '../pipe/metric/metric.module';
import { UnitConversionPipeModule } from '../pipe/unit-conv/unit-conv.module';
import { SafeModule } from '../pipe/safe/safe.module';

import { NumberOnlyDirectiveModule } from '../directives/input-number-only/input-number-only.module';
import { HasFocusClassDirectiveModule } from '../directives/has-focus-class/has-focus-class.module';
import { FocusOnInitDirectiveModule } from '../directives/foucs-on-init/focus-on-init.module';
import { IonicInputMaskDirectiveModule } from '../directives/ionic-input-mask/ionic-input-mask.module';
import { ScaleupButtonModule } from '../directives/scaleup-button/scaleup-button.module';

import { MenuPopOverModule } from '../pop-over/menu-pop-over/menu-pop-over.module';
import { ActionPopOverModule } from '../pop-over/action-pop-over/action-pop-over.module';
import { WebAlert } from './web-alert/web-alert';
import { InputAlert } from './input-alert/input-alert';

@NgModule({
  declarations: [
    TextBoxComponent,
    StepProgressComponent,
    InputComponent,
    FtDigitLabelComponent,
    FtRollingLabelComponent,
    FtBadgeButtonComponent,
    FtTabButtonsComponent,
    FtFlexRowComponent,
    FtBaseModalComponent,
    FtColorTextComponent,
    FtToggleButtonComponent,
    FtToggleButtonGroupComponent,
    FtTriButtonComponent,
    FtTriButtonGroupComponent,
    WebAlert,
    InputAlert
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    IonicModule,
    // IonicModule.forRoot(),  // 여기서 warning 발생. 이론적으로는 필요가 없는데 지우면 다른 에러가 발생함. 일단 유지.
    // Ionic Angular was already initialized. Make sure IonicModule.forRoot() is just called once.
    TranslateModule,

    FtDatePipeModule,
    FtTimePipeModule,
    NewDatePipeModule,
    MetricPipeModule,
    TranslationLocPipeModule,
    PhoneNumberPipeModule,
    TextMaskPipeModule,
    UnitConversionPipeModule,
    SafeModule,

    IonicInputMaskDirectiveModule,
    NumberOnlyDirectiveModule,
    HasFocusClassDirectiveModule,
    ScaleupButtonModule,

    MenuPopOverModule,
    ActionPopOverModule,
  ],
  exports: [
    TextBoxComponent,
    StepProgressComponent,
    InputComponent,
    FtDigitLabelComponent,
    FtRollingLabelComponent,
    FtBadgeButtonComponent,
    FtTabButtonsComponent,
    FtFlexRowComponent,
    FtBaseModalComponent,
    FtColorTextComponent,
    FtToggleButtonComponent,
    FtToggleButtonGroupComponent,
    FtTriButtonComponent,
    FtTriButtonGroupComponent,
    FtDatePipeModule,
    FtTimePipeModule,
    NewDatePipeModule,
    MetricPipeModule,
    TranslationLocPipeModule,
    PhoneNumberPipeModule,
    TextMaskPipeModule,
    UnitConversionPipeModule,
    SafeModule,
    IonicInputMaskDirectiveModule,
    NumberOnlyDirectiveModule,
    HasFocusClassDirectiveModule,
    FocusOnInitDirectiveModule,
    ScaleupButtonModule,
    MenuPopOverModule,
    ActionPopOverModule,
    WebAlert,
    InputAlert
  ],
  entryComponents: [
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonShareModules {
  public static forRoot(environment: any): ModuleWithProviders<CommonShareModules> {
    return {
      ngModule: CommonShareModules,
      providers: [
        {
          provide: 'environment', // you can also use InjectionToken
          useValue: environment
        }
      ]
    };
  }
}
