import { NgModule } from '@angular/core';
import { SharedModule } from '@shared-lib/shared.module';
import { FtInfomationModal } from './ft-infomation';

@NgModule({
  declarations: [FtInfomationModal],
  imports: [
    SharedModule,
  ],
  entryComponents: [FtInfomationModal],
  exports: [FtInfomationModal]
})

export class FtInfomationModalModule { }
