import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// import { ModalController } from '@ionic/angular';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { FittMember, Gym, User } from '@fitt-lib/models/fitt-models';
import { NavService } from '@shared-lib/services/nav.service';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ft-fmes-side-profile',
  templateUrl: './ft-fmes-side-profile.html',
  styleUrls: ['./ft-fmes-side-profile.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FtFmesSideProfileComponent extends FmesBaseComponent implements OnInit {

  @Input() title: string;
  @Input() menuId: string;
  @Input() enableSearch: boolean = true;

  myInfo: FittMember;
  user: User;
  gyms: Array<Gym>;
  curGymSeq: number;
  badgeCount: number = 0;

  name: string;
  email: string;

  @Output() onClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public navSvc: NavService,
    private events: RxEvents,
    private cdr: ChangeDetectorRef,
    // private modalCtrl: ModalController,    
  ) {
    super({ enableLog: false });
  }

  authSubscribe: any;
  myInfoSubs: Subscription;

  async ngOnInit() {
    // badge
    this.myInfo = await this.gApi.auth.getMyInfo();
    this.name = this.myInfo.member.mName;
    this.email = this.myInfo.member.mEmail;
    this.log('ngOnInit', this.myInfo, localStorage.gSeq, this.curGymSeq);
    this.user = this.myInfo.getUser();
    this.gyms = this.myInfo.gyms.filter(gym => gym.gName !== null);
    this.curGymSeq = this.myInfo.getGymSeq();
    !this.cdr['destroyed'] && this.cdr.detectChanges();

    this.myInfoSubs = this.events.subscribe('myInfoPage:pop', async () => {
      this.myInfo = await this.gApi.auth.getMyInfo({});
      this.user = this.myInfo.getUser();
      !this.cdr['destroyed'] && this.cdr.detectChanges();
    });
  }
  ngOnDestroy() {
    this.authSubscribe && this.authSubscribe();
    this.myInfoSubs && this.myInfoSubs.unsubscribe();
  }

  close() {
    this.util.menuClose();
  }

  clickButton(eventType) {
    this.util.menuClose();
    console.log("eventType", eventType);
    this.onClick.emit(eventType);
  }

  async profileClick() {
    await this.navSvc.goPage('myinfo');
  }

}
