import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '@shared-lib/services/util.service';

export class BaseComponent {
  static injector: Injector;
  static timestamp: number;
  public util: UtilService;
  // public unitConvPipe: UnitConversionPipe;
  protected translate: TranslateService;

  $prefix = '[FL]';
  $tagName: string;
  $enableLog: boolean = false;

  static isCordova: boolean;
  static mobile: string;
  static browserName: string;

  constructor($param?: { tagName?: string, enableLog?: boolean, isCordova?: boolean }) {
    this.util = BaseComponent.injector.get(UtilService);
    this.translate = BaseComponent.injector.get(TranslateService);

    if ($param === undefined || $param.enableLog === undefined) {
      this.$enableLog = this.util.isDebugMode();
    }

    // if (!BaseComponent.browserName) {  // TODO: 추후 필요하면 다시 개발한다.
    //   const { detect } = require('detect-browser');
    //   const browser = detect();
    //   BaseComponent.browserName = browser.name;
    // }

    if ($param) {
      this.$enableLog = $param.enableLog;
      this.$tagName = $param.tagName;
      if ($param.isCordova !== undefined) {
        BaseComponent.isCordova = $param.isCordova;
        const ua = navigator.userAgent;
        BaseComponent.mobile = /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua) ? 'o' : '';
        this.log(`BaseComponent.constructor cordova:${$param.isCordova} mobile:${BaseComponent.mobile}`);
      }
    }

    // if (environment.production) {  // production mode는 $enableLog: false로 강제한다. 단, setEnableLog() 로 활성화 하는 것은 막지 않는다.
    //   this.$enableLog = false;
    // }

    if (!this.$tagName) {
      // const tagName = this.constructor.name;
      // this.$tagName = tagName.endsWith('Component') ? tagName.substr(0, tagName.length - 9) : tagName;
      // const tagName = this.constructor.name;
      this.$tagName = this.constructor.name;
    }
  }

  setEnableLog(enableLog: boolean) {
    this.$enableLog = enableLog;
  }

  genLogs(args) {
    let logs = [this.$tagName];
    if (!BaseComponent.isCordova) logs.unshift(new Date().format('HH:mm:ss.fff'));
    if (BaseComponent.isCordova && this.$prefix) logs.unshift(this.$prefix);
    if (this.util.isDebugMode()) logs.push(`${this.elapsedTime()}`);
    /**
     * 테스트 코드
     * JSON.stringfy() circular reference 발생
     */
    // let testObj = {
    //   name: 'name',
    //   value: 1,
    //   array: [1, 2, 3],
    //   object: { a: 'a', b: 'b' },
    // };
    // testObj['self'] = testObj;

    // // logs.push(JSON.stringify(testObj));
    // logs.push(JSON.stringify(this.objectStringify(testObj)));

    // for (const arg of args) logs.push(BaseComponent.isCordova && arg instanceof Object ? JSON.stringify(arg) : arg);   // TypeError: Converting circular structure to JSON 발생
    for (const arg of args) logs.push(BaseComponent.isCordova && arg instanceof Object ? JSON.stringify(this.objectStringify(arg)) : arg);

    if (BaseComponent.isCordova && BaseComponent.mobile == 'a') logs = [logs.join(' ')];
    return logs;
  }

  objectStringify(obj, depth: number = 0) {
    if (obj === undefined) {
      return 'undefined';
    }
    else if (obj === null) {
      return 'null';
    }
    else if (Array.isArray(obj)) {
      if (depth == 0) {
        return `[${obj.map(o => this.objectStringify(o, depth + 1)).join(', ')}]`;
      }
      else {
        return '[Array]';
      }
    }
    else if (typeof obj == 'object') {
      if (depth == 0) {
        return `{${Object.keys(obj).map(k => `${k}: ${this.objectStringify(obj[k], depth + 1)}`).join(', ')}}`;
      }
      else {
        return '[Object]';
      }
    }
    else {
      return '' + obj;
    }
  }

  log(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.log.apply(this, logs);
  }

  log_must(...args) {
    const logs = this.genLogs(args);
    console.log.apply(this, logs);
  }

  info(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.info.apply(this, logs);
  }

  warn(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.warn.apply(this, logs);
  }

  error(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.error.apply(this, logs);
  }

  time(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.time.apply(this, logs);
  }

  timeEnd(...args) {
    if (!this.$enableLog) return;
    const logs = this.genLogs(args);
    console.timeEnd.apply(this, logs);
  }

  elapsedTime() {
    return Date.now() - BaseComponent.timestamp;
  }
}
