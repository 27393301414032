<div class="wrapper">
  <div class="icon">
    <ion-icon name="{{icon}}"></ion-icon>
  </div>
  <div style="height: 148px;">
    <p>{{context}}</p>
    <ng-container *ngIf="body">
      <div *ngFor="let row of body">
        <ion-label>{{row}}</ion-label>
      </div>
    </ng-container>
  </div>
  <div class="footer">
    <button (click)="close()">{{'확인'|translate}}</button>
  </div>
</div>