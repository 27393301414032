export * from './shared.module';
// export * from './base.component';

export * from './common-share/common-share.module';

export { TabButton } from './common-share/ft-tab-buttons/ft-tab-buttons';
export { InputComponent } from './common-share/ft-input/ft-input.component';
export { ToggleGroupButtons } from './common-share/ft-toggle-button-group/ft-toggle-button-group';
export { TriGroupButtons, FtTriButtonGroupComponent } from './common-share/ft-tri-button-group/ft-tri-button-group';

declare global {
  interface Date {
    // constructor(): Date;
    toString(): string;
    format(f: string, utc?: boolean): string;
    compare(type: 'second' | 'minute' | 'hour' | 'date' | 'month', value: number, operand?: Date): number;
    within(type: 'hour' | 'date' | 'week' | '2week' | 'month', value: number, operand?: Date, noYearCompare?: boolean): boolean;
    difference(type: 'second' | 'minute' | 'hour' | 'date' | 'month', operand?: Date);
    getNewDate(type: 'second' | 'minute' | 'hour' | 'date' | 'month' | 'year', value: number): Date;
    zeroHour(): Date;
    resetDate(mode: 'reset' | 'full', type: 'milisecond' | 'second' | 'minute' | 'hour' | 'date' | 'month'): Date;
    isLeapYear(): boolean;
    getDaysInMonth(year: number, month: number): number;
    addMonths(value: number): Date;
  }
  interface String {
    string(len: number): string;
    pad(len: number): string;
    padRight(len: number): string;
    currencyFormat(): string;
    phoneFomatter(hideMid?: boolean): string;
    splice(index, count, add): string;
    phoneFormat(hideMid?: boolean): string;
  }
  interface Number {
    pad(len: number): string;
    padRight(len: number): string;
    toTimeString(format?: string): string;
    currencyFormat(): string;
    formatInRange(min: number, max: number): number;
    toFixedNumber(x: number, base?: number): number;
  }
  interface CanvasRenderingContext2D {
    strokeArcGradient(x, y, radius, startAngle, endAngle, colorStops, options);
    fillArcGradient(x, y, startAngle, endAngle, colorStops, outerRadius, innerRadius, options);
  }


}
