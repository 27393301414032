import { Directive, HostListener } from '@angular/core';

@Directive({ selector: '[hasFocusClass]' })
export class HasFocusClassDirective {
  constructor(
    // private host: ElementRef
  ) {
  }
  @HostListener('focus', ['$event'])
  @HostListener('ionFocus', ['$event'])
  onFocus(event) {
    event.srcElement.classList.add('has-focus');
  }
  @HostListener('blur', ['$event'])
  @HostListener('ionBlur', ['$event'])
  onBlur(event) {
    event.srcElement.classList.remove('has-focus');
  }
}
