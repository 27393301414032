import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UnitConversionPipe } from '@shared-lib/pipe/unit-conv/unit-conv.pipe';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import $ from 'jquery';
import { Subscription } from 'rxjs';
import { FmesBaseComponent } from 'src/app/fitt-fmes-base.component';
import { FittMember, GymUser, Trainer, User } from '@fitt-lib/models/fitt-models';
import { Image } from '@shared-lib/components/image-uploader/image-uploader';
import { RxEvents } from '@shared-lib/services/rx-events.service';
import { AddMobileFMTModal } from '@fitt-lib/modals/mobile-exercise-test/add-mobile-exercise-test/add-mobile-fmt/add-mobile-fmt';
import { MobileExerciseTestHistoryModal } from 'src/app/modals/mobile-exercise-test-history/mobile-exercise-test-history';
// !! import { NavService } from '@shared-lib/services/nav.service';
// !! import { Router } from '@angular/router';
// !! import { TutorialService } from 'src/app/services/tutorial.service';
import { EditUserMemoModal } from '@fitt-lib/modals/edit-user-memo/edit-user-memo';

@Component({
  selector: 'user-info',
  templateUrl: './user-info.html',
  styleUrls: ['./user-info.scss'],
})
export class UserInfoModal extends FmesBaseComponent implements OnInit {
  @Input() newMode: boolean = false;
  @Input() mode: string;  //  inputPayment
  @Input() gymUser: GymUser;



  friendGymUser: GymUser;

  gymUsers: GymUser[];
  trainer: Trainer;
  uFriendSeq: number = -1;

  editMode: boolean = false;
  edited: boolean = false;

  gymName: string;
  subText: string;
  tSeq: number;
  gSeq: number;
  trainerList: Array<Trainer | any>;
  profile: string;
  name: string;
  birthday: string;
  gender: Gender;
  tel: string;
  emergencyTel: string;
  email: string;
  weight: number;
  height: number;
  activate: string;
  orgUState: string;

  birthYear: string = '';
  birthMonth: string = '';
  birthDay: string = '';
  userDivs: string;
  uploadImage: Image;
  ticketSpread: boolean = false;

  ticketList: Array<any> = [];
  lockerLogList: Array<any> = [];
  paymentHistory: Array<any> = [];
  myInfo: FittMember;
  loadState: LoadState = 'none';
  uConnectState: Map<string, number> = new Map<string, number>();
  duplicate: boolean = false;
  isChanged: boolean;


  constructor(
    public platform: Platform,
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private events: RxEvents,
    // private navSvc: NavService,
    private toastCtrl: ToastController,
    // private router: Router,
    // private tutorialService: TutorialService,
    private unitConvPipe: UnitConversionPipe,
  ) {
    super({ enableLog: true });
  }

  async ngOnInit() {
    this.log('ngOnInit', { gymUser: this.gymUser, newMode: this.newMode });

    if (this.newMode) {
      this.editMode = true;
      this.edited = true;
      this.gender = 'male';
      this.activate = '1';
    }

    this.myInfo = await this.gApi.auth.getMyInfo();
    this.gymUsers = (await this.gApi.b2b.getGymUsers({ gSeq: this.myInfo.gSeq })).result;
    this.gSeq = (await this.gApi.auth.getMyInfo()).getGymSeq();

    if (this.gymUser && this.gymUser.user) {
      this.orgUState = `${this.gymUser.user.uState}`;
      this.friendGymUser = this.gymUsers.find(gymUser => gymUser.user.uSeq == this.gymUser.user.uFriendSeq);
    }

    this.initUserInfo();
    if (!this.newMode) {
      this.isChanged = await this.alreadyChangedProfile();
    }

    this.loadState = 'loading';
    this.gymName = this.myInfo.getGym().gName;
    this.tSeq = this.myInfo.getTrainerSeq();
    // !! this.tutorialService.resetTour();
    this.trainerList = [...await this.gApi.b2b.getGymTrainers({ gSeq: this.myInfo.getGymSeq() })];
    this.trainerList.unshift({ tSeq: 0, tName: this.translate.instant('담당 없음') });

    // !! this.onUpdatePointSubs = this.events.subscribe(UserPoint.UPDATE_EVENT, () => this.setUserPoint());
    this.onAndroidBackButtonSubs = this.events.subscribe('android:back-button', this.onAndroidBackButton);
    this.loadState = 'loaded';
    !this.cdr['destroyed'] && this.cdr.detectChanges();
    this.log('ngOnInit', { newMode: this.newMode, gymUser: this.gymUser });
  }

  onUpdatePointSubs: Subscription;
  onAndroidBackButtonSubs: Subscription;
  ngOnDestroy() {
    this.onUpdatePointSubs && this.onUpdatePointSubs.unsubscribe();
    this.onAndroidBackButtonSubs && this.onAndroidBackButtonSubs.unsubscribe();
  }

  async initUserInfo() {
    if (!this.gymUser) return;

    const age = this.util.getAge(this.gymUser.user.uBirth);
    const tVal = this.translate.instant(['컴포넌트.남', '컴포넌트.여', '컴포넌트.만 _세'], { age: age });
    this.subText = `${this.gymUser.user.uGender == 'male' ? tVal['컴포넌트.남'] : tVal['컴포넌트.여']} / ${tVal['컴포넌트.만 _세']}`;
    this.userDivs = this.util.getUserDivs(this.gymUser.user);
    !this.cdr['destroyed'] && this.cdr.detectChanges();
    // this.app.tick();
  }

  onAndroidBackButton = () => {
    if (!this.editMode) return;
    this.edit(false);
  }

  async close(ok?: boolean) {
    if (ok || this.edited) {
      await this.modalCtrl.dismiss({ gymUser: this.gymUser, edited: this.edited });
    }
    else {
      await this.modalCtrl.dismiss();
    }
  }


  isOwnGym() {
    return this.myInfo && this.myInfo.getGymSeq() === this.gymUser.user.gSeq;
  }

  isDisabled() {
    let valid =
      (this.name && this.name.length) && this.birthday && this.gender !== undefined && this.activate !== undefined &&
      (this.tel && this.tel.length) && (this.email && this.email.length && !!this.email.match(this.util.EMAIL_REGEXP));
    if (valid && this.editMode && this.gymUser && this.gymUser.user) {
      valid =
        this.profile != this.gymUser.user.uProfile ||
        this.name != this.gymUser.user.uName ||
        new Date(this.birthday).format('yyyy/MM/dd') != this.gymUser.user.uBirth.format('yyyy/MM/dd') ||
        this.gender != this.gymUser.user.uGender ||
        this.tel != this.gymUser.user.uTel ||
        this.email != this.gymUser.user.uEmail ||
        this.tSeq != (this.gymUser.trainer && this.gymUser.trainer.tSeq) ||
        !!this.uploadImage
        ;
    }

    return !valid;
  }

  async edit(mode) {
    this.log('edit', mode, this.gymUser);
    if (!mode && !this.gymUser) { // 신규 등록 모드에서 상단 backward button
      await this.close();
      return;
    }
    this.editMode = mode;
    if (mode) {
      this.edited = true;
      if (this.gymUser) {
        this.profile = this.gymUser.user.uProfile;
        this.name = this.gymUser.user.uName;
        this.birthday = this.gymUser.user.uBirth.format('yyyy/MM/dd');
        this.birthYear = this.gymUser.user.uBirth.format('yyyy');
        this.birthMonth = this.gymUser.user.uBirth.format('MM');
        this.birthDay = this.gymUser.user.uBirth.format('dd');
        this.gender = this.gymUser.user.uGender;
        this.activate = this.gymUser.user.uState == 1 ? '1' : '0';
        this.tel = this.gymUser.user.uTel;
        this.emergencyTel = this.gymUser.user.uTel2;
        this.email = this.gymUser.user.uEmail;
        this.weight = Number(this.unitConvPipe.transform(this.gymUser.user.uWeight, { from: 'kg', to: 'kg/lbs', suffixUnit: false, toFixed: 0 }));
        this.height = Number(this.unitConvPipe.transform(this.gymUser.user.uHeight, { from: 'cm', to: 'cm/ft', suffixUnit: false, toFixed: [0, 2] }));
        this.tSeq = this.gymUser.trainer && this.gymUser.trainer.tSeq;
        this.uFriendSeq = this.gymUser.user.uFriendSeq;
        this.log('edit.birthday', this.birthday);
      }
    }

    (async () => {  // 안드로이드 back 버튼으로 화면 닫지 않기
      const modal: HTMLIonModalElement = await this.modalCtrl.getTop();
      this.log('edit', modal.id);
      if (mode) {
        $(`#${modal.id}`).addClass('no-android-backbutton');
      }
      else {
        $(`#${modal.id}`).removeClass('no-android-backbutton');
      }
    })();
  }

  haveUsageDetails() {
    // !! if (this.scheduleList.length > 0) {
    // !!  return true;
    // !! }
    if (this.paymentHistory.length > 0) {
      return true;
    }
    if (this.lockerLogList.length > 0) {
      return true;
    }
    return false;
  }

  async trash() {
    if (this.haveUsageDetails()) {
      const message = this.translate.instant('회원관리.검사자 삭제 불가 안내');
      const title = this.translate.instant('회원관리.검사자 삭제 불가');
      const alert = await this.util.alertCreate({
        title: title, message: message, buttons: [{ text: 'ok', role: 'ok', }]
      });
      alert.present();
    } else {
      const message = this.translate.instant('대체._님의 정보를 삭제하시겠습니까?', { uName: this.gymUser.user.uName });
      const title = this.translate.instant('회원관리.사용자 삭제');
      const alert = await this.util.alertCreate({
        title: title, message: message, buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok', }]
      });
      alert.present();
      const result = await alert.onDidDismiss();
      if (result.role != 'ok') return;

      const res = await this.gApi.b2b.deleteGymUser(this.gymUser);
      if (res.code == 1) {
        this.edited = true;
        await this.close();
      }

      const toast = await this.toastCtrl.create({
        message: this.translate.instant('회원관리.검사자 삭제가 완료되었습니다.'),
        duration: 2000,
        position: 'bottom'
      });
      await toast.present();
    }
  }

  async editProfile(event) {
    try {
      if (this.isChanged) {
        throw new Error('Unable to change customer profile picture');
      }

      const buttons = [{ text: this.translate.instant('일반.사진 선택'), role: 'photos' }];
      if (this.util.isMobileApp()) {
        buttons.push({ text: this.translate.instant('일반.사진 촬영'), role: 'camera' });
      }
      const result = await this.util.moreButtons(event, buttons);
      this.log('editProfile', result);

      let photos;
      if (result.role == 'photos') {
        photos = await this.util.selectPhoto({ mediaType: 'image' });
      } else if (result.role == 'camera') {
        photos = await this.util.takePhoto();
      }
      this.log('editProfile', photos);
      if (!photos) return;

      this.uploadImage = photos[0];
    }
    catch (error) {
      this.warn(error);
      this.util.alertCreate({ message: this.translate.instant('일반.사진 등록이 취소되었습니다.') });
    }
  }

  async alreadyChangedProfile() {
    const result = await this.gApi.b2b.alreadyChangedProfile({ email: this.gymUser.user.uEmail });
    return result[0].uProfileCnt !== 0;
  }

  onCompleteUpload() {
    this.log('onCompleteUpload', this.uploadImage);
  }

  async save() {
    try {
      await this.util.showLoadingPopup(true);
      // const gSeq = (this.myInfo && this.myInfo.getGymSeq()) || this.gymUser.user.gSeq;
      // const userLimit = await this.gApi.b2b.checkOverUserLimit({ gSeq: gSeq });
      const isChangeToHuman = !this.newMode && this.activate === '0';
      const isAddUser = this.newMode || (this.orgUState === '0' && this.activate === '1');

      this.log('save', { orgUConnect: this.orgUState, isChangeToHuman, isAddUser })

      await this.checkUserConnect();
      if (this.duplicate && (this.newMode || this.gymUser.user.uEmail != this.email)) {
        await this.util.showLoadingPopup(false);
        const alert = await this.util.alertCreate({
          title: this.translate.instant('모달.알림'),
          message: this.translate.instant('회원관리.이미 등록된 검사자의 이메일입니다. 주소를 확인해 주세요.'),
        });
        await alert.onDidDismiss();
        return;
      }
      if (this.newMode) {
        const user: User = new User({
          uName: this.name,
          uBirth: new Date(this.birthday),
          uGender: this.gender,
          uState: Number(this.activate),
          uEmail: this.email,
          uTel: this.tel,
          uTel2: this.emergencyTel,
          uProfile: this.uploadImage && this.uploadImage.url,
          uWeight: this.util.isDisplayUnitKm() ? this.weight : this.unitConvPipe.transform(this.weight, { from: 'lbs', to: 'kg', suffixUnit: false, toFixed: 10 }),
          uHeight: this.util.isDisplayUnitKm() ? this.height : this.unitConvPipe.transform(this.height, { from: 'ft', to: 'cm', suffixUnit: false, toFixed: 10 }),
          tSeq: this.tSeq,
          uFriendSeq: this.uFriendSeq,
        });
        const res = await this.gApi.b2b.addGymUser({ user: user });
        user.uSeq = res.insertId;
        const trainer = this.trainerList.find(trainer => trainer.tSeq === this.tSeq);
        this.gymUser = new GymUser({ user: user, trainer: trainer });
      }
      else {
        const user: User = new User({
          gSeq: this.gymUser.user.gSeq,
          uSeq: this.gymUser.user.uSeq,
          uName: this.name,
          uBirth: new Date(this.birthday),
          uGender: this.gender,
          uState: Number(this.activate),
          uEmail: this.email,
          uTel: this.tel,
          uTel2: this.emergencyTel,
          uProfile: this.uploadImage && this.uploadImage.url || this.profile,
          uWeight: this.util.isDisplayUnitKm() ? this.weight : this.unitConvPipe.transform(this.weight, { from: 'lbs', to: 'kg', suffixUnit: false, toFixed: 10 }),
          uHeight: this.util.isDisplayUnitKm() ? this.height : this.unitConvPipe.transform(this.height, { from: 'ft', to: 'cm', suffixUnit: false, toFixed: 10 }),
          tSeq: this.tSeq,
          uFriendSeq: this.uFriendSeq,
        });
        if (this.gymUser.user.uEmail != this.email) {
          if (this.uConnectState.get('edit') < 1 && this.gymUser.user.uConnect === 1) {
            user.uConnect = 0;
          } else if (this.uConnectState.get('edit') > 0 && this.gymUser.user.uConnect != 1) {
            user.uConnect = 1;
          }
        }
        this.log('user', user);
        const res = await this.gApi.b2b.updateGymUser({ user: user });
        const trainer = this.trainerList.find(trainer => trainer.tSeq === this.tSeq);
        this.gymUser = new GymUser({ user: user, trainer: trainer });
        this.log('updateGymUser', res);
      }
      await this.ngOnInit();
    }
    catch (error) {
      this.util.errorPopup(error);
      return;
    }
    finally {
      await this.util.showLoadingPopup(false);
    }

    await this.setGymUser();
    await this.edit(false);
    this.loadState === 'loaded';

    if (this.newMode) {
      this.newMode = false;
      const toast = await this.toastCtrl.create({
        mode: 'md', message: this.translate.instant('회원관리.신규 검사자 등록이 완료되었습니다.'),
        duration: 2000,
        position: 'bottom'
      });
      await toast.present();
    }
  }

  async checkUserConnect() {
    const gSeq = (this.myInfo && this.myInfo.getGymSeq()) || this.gymUser.user.gSeq;
    const promiseArray: Array<Promise<any>> = [];
    if (!this.newMode) {
      promiseArray.push((async () => {
        const record = (await this.gApi.getRecord({
          table: 'FITT_DB.TMEMBER', fields: ['COUNT(*) AS cnt, MEMBER_InDate AS mInDate'],
          where: { MEMBER_Email: this.email },
        }))[0];
        this.uConnectState.set('edit', record.cnt);
      })());
    }
    promiseArray.push((async () => {
      const record = (await this.gApi.getRecord({
        table: 'FITT_DB.TUSER', fields: ['COUNT(*) AS cnt'],
        where: { GYM_Seq: gSeq, USER_Email: this.email, USER_Connect: 1, USER_Visible: 1 },
      }))[0];
      this.uConnectState.set('all', record.cnt);
    })());
    promiseArray.push((async () => {
      const dupRecord = (await this.gApi.getRecord({
        table: 'FITT_DB.TUSER', fields: ['COUNT(*) AS cnt'],
        where: { GYM_Seq: gSeq, USER_Email: this.email, USER_Visible: 1 },
      }))[0];
      this.duplicate = dupRecord.cnt > 0;
    })());
    await Promise.all(promiseArray);
  }

  async setGymUser() {
    if (this.gymUser.user.gSeq !== undefined && this.gymUser.user.tSeq !== undefined && this.gymUser.user.tSeq > 0) {
      const { result: gymUserList } = await this.gApi.b2b.getGymUsers({ gSeq: this.gymUser.user.gSeq, uSeq: this.gymUser.user.uSeq });
      this.gymUser = gymUserList.find((gymUser: GymUser) => gymUser.user.uSeq === this.gymUser.user.uSeq);
    }
  }



  async clickExerciseTest(type: 'add' | 'history') {
    let component;
    let componentProps;
    switch (type) {
      case 'add':
        const fmesFreeCount = (await this.gApi.getRecord({
          table: 'TGYM',
          fields: ['GYM_Fmes_Free_Count'],
          where: {
            GYM_Seq: this.gSeq,
          },
        }))[0].GYM_Fmes_Free_Count;
        // TODO 구독 확인 로직 추가
        if (fmesFreeCount === 0) {
          // TODO 텍스트 변경
          const alert = await this.util.alertCreate({
            title: '안내',
            message: '너 무료 횟수 끝났음. 더 쓰고 싶으면 구독하셈. 구독 페이지로 옮겨줌?',
            buttons: [{ text: '네', role: 'ok' }, { text: '싫은데?', role: 'cancel' }]
          });
          await alert.present();
          const result = await alert.onDidDismiss();

          if (result.role === 'ok') {
            // TODO 옮기고 지지고 볶고           

          }
          break;
        } else {
          const trainers: Array<Trainer> = await this.gApi.b2b.getGymTrainers({ gSeq: this.gSeq });
          this.trainer = trainers.find(t => t.tSeq == this.gymUser.user.tSeq);
          component = AddMobileFMTModal
          componentProps = {
            gymUser: this.gymUser,
            trainer: this.trainer,
          }
        }

        break;
      case 'history':
        component = MobileExerciseTestHistoryModal;
        componentProps = {
          gymUser: this.gymUser,
          uSeq: this.gymUser.user.uSeq,
          isEditable: true
        }
        break;
    }
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps: componentProps
    });
    await modal.present();
  }




  spreadChange(value: boolean) {
    this.ticketSpread = value;
  }


  setBirthdayInWeb() {
    if (this.birthYear.length === 4 &&
      (Number(this.birthMonth) > 0 && Number(this.birthMonth) < 13) &&
      (Number(this.birthDay) > 0 && Number(this.birthDay) < 32)) {
      this.birthday = this.birthYear + "/" + this.birthMonth + "/" + this.birthDay;
    } else {
      this.birthday = undefined;
    }
  }

  async editUserMemo() {
    const modal = await this.modalCtrl.create({
      component: EditUserMemoModal,
      componentProps: {
        gymUser: this.gymUser
      }
    });
    await modal.present();
    const result: any = await modal.onDidDismiss();
    this.log('editUserMemo result', result);

    if (result.data) {
      this.gymUser.user.uMemo = result.data;
    }
  }

  async onSelectFriend() {
    if (this.uFriendSeq < 0) return;

    const userFriend = this.gymUsers.find(gymUser => gymUser.user.uSeq == this.uFriendSeq);
    if (!userFriend || !userFriend.trainer) return;

    const alert = await this.util.alertCreate({
      title: this.translate.instant('모달.알림'),
      message: this.translate.instant("모달.담당 트레이너를 친구의 담당 트레이너[xxx]로 설정할까요?", { tName: userFriend.trainer.tName }),
      buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok', }]
    });
    const result = await alert.onDidDismiss();
    if (result.role == 'ok') {
      this.tSeq = userFriend.trainer.tSeq;
    }
  }

  async onSelectedUser(userFriend: GymUser) {
    this.log('onSelectedUser', { userFriend });
    if (!userFriend || !userFriend.trainer || !userFriend.user) return;
    const { trainer: { tName, tSeq }, user: { uSeq } } = userFriend;

    this.uFriendSeq = uSeq;
    if (this.tSeq !== tSeq) {
      const alert = await this.util.alertCreate({
        title: this.translate.instant('모달.알림'),
        message: this.translate.instant("모달.담당 트레이너를 친구의 담당 트레이너[xxx]로 설정할까요?", { tName: tName }),
        buttons: [{ text: 'cancel', role: 'cancel' }, { text: 'ok', role: 'ok', }]
      });
      const { role } = await alert.onDidDismiss();

      if (role === 'ok') {
        this.tSeq = tSeq;
      }
    }
  }
}
