<div class="container" [ngClass]="util.isMobileApp() ? 'mobile' : 'web'">
  <ng-container *ngFor="let recommended of recommendedVideos">
    <ion-label class="videos-title">{{recommended.title}}</ion-label>
    <div class="video-grid-container" style="padding-top: 16px;">
      <div class="video-card" *ngFor="let video of recommended.videos" (click)="viewDetail(video)">
        <div class="video-thumbnail-wrapper">
          <ion-img src={{video.imgURl}}> </ion-img>
        </div>
        <div class="text-wrapper">
          <p class="video-title"> {{video.name}} </p>
          <p class="video-desc" *ngIf="util.isCurrentLangKo()"> {{video.desc}}</p>
        </div>
      </div>
    </div>
  </ng-container>
</div>
