import { Component, OnInit, Input } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { Trainer, GymUser } from '@fitt-lib/models/fitt-models';
import { ModalController, ToastController } from '@ionic/angular';
import { FmtItem, FMT, FmtNote } from '@fitt-lib/models/exercise-test/fmt';
import { AddMobileFMTDetailModal } from '@fitt-lib/modals/mobile-exercise-test/add-mobile-exercise-test/add-mobile-fmt-detail/add-mobile-fmt-detail';
import { MobileFmtResultModal } from '@fitt-lib/modals/mobile-fmt-result/mobile-fmt-result';

@Component({
  selector: 'add-mobile-fmt',
  templateUrl: './add-mobile-fmt.html',
  styleUrls: ['./add-mobile-fmt.scss']
})
export class AddMobileFMTModal extends FtLibBaseComponent implements OnInit {
  @Input() gymUser: GymUser;
  @Input() trainer: Trainer;

  testInDate: Date = new Date();
  fmtItemList: Array<FmtItem> = FMT.getFmtItemList();
  surveyItems: { fmtMemoList: Array<FmtNote>, answerList: Array<Array<any>> } = {
    fmtMemoList: [],
    answerList: [],
  };

  curOrder: number;
  records: Array<any>;
  fmesGymSeq: number;
  fmesFreeCount: number;

  // 샘플 데이터 이스터 에그 관련
  tappingTimer: NodeJS.Timeout | undefined = undefined;
  numberOfTappings: number = 0;

  // 최근 내역 불러오기 값 여부
  isFmtOptionfromRecord: Array<Array<boolean>> = [
    Array.from({ length: 11 }, () => false),
    Array.from({ length: 8 }, () => false),
    Array.from({ length: 10 }, () => false),
    Array.from({ length: 7 }, () => false),
    Array.from({ length: 8 }, () => false),
    Array.from({ length: 6 }, () => false),
    Array.from({ length: 5 }, () => false),
    Array.from({ length: 6 }, () => false),
  ];

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
  ) {
    super();
    this.fmtItemList.forEach(fmtItem => this.surveyItems.answerList.push(new Array(fmtItem.children.length).fill(undefined)));
  }

  async ngOnInit() {
    this.log('ngOnInit', this.gymUser, this.trainer);
    this.checkFmesFreeCount();

    const result = (await this.gApi.exerciseApi.getUserExerciseRecords({
      uSeq: this.gymUser.user.uSeq,
      isAllData: false,
      isConnected: false // TODO: 회원 GYM 연동 값에 따라
    })).result;
    this.records = result.filter(record => record.EXERCISE_TEST_Type === 'fmt2');
    this.curOrder = this.records.length + 1;
  }

  async editTestInDate() {
    const date = await this.util.selectDate({ mode: 'dateAndTime', date: this.testInDate });
    if (date) {
      this.testInDate = date;
    }
  }

  isFmtItemAnswered(fmtCheckIndex: number): boolean {
    const len = this.fmtItemList[fmtCheckIndex].children.length;
    for (let i = 0; i < len; i++) {
      if (!(this.isAnswered(fmtCheckIndex, i))) {
        return false;
      }
    }
    return true;
  }

  isAnswered(fmtCheckIndex: number, itemIndex: number): boolean {
    return !!this.surveyItems.answerList[fmtCheckIndex] && !!this.surveyItems.answerList[fmtCheckIndex][itemIndex];
  }

  hasPainInSurvey(fmtCheckIndex: number): boolean {
    // 통증 문항 YES/NO 해당 탭 확인
    if (!this.surveyItems.answerList[fmtCheckIndex]) {
      return false;
    }

    switch (fmtCheckIndex) {
      case 0:
        if (this.surveyItems.answerList[fmtCheckIndex][9] === '1') {
          return true;
        }
        break;
      case 4:
        if (this.surveyItems.answerList[fmtCheckIndex][7] === '1') {
          return true;
        }
        break;
    }

    const length = this.fmtItemList[fmtCheckIndex].children.filter(child => child.type == 'options').length;
    for (let i = 0; i < length; i++) {
      if (this.surveyItems.answerList[fmtCheckIndex][i] === '0') {
        return true;
      }
    }

    return false;
  }

  async openFmtDetail(fmtCheckIndex: number) {
    const modal = await this.modalCtrl.create({
      component: AddMobileFMTDetailModal,
      componentProps: {
        surveyItems: this.surveyItems,
        isFmtOptionfromRecord: this.isFmtOptionfromRecord,
        fmtCheckIndex: fmtCheckIndex
      }
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    this.log('fmtDetail result', result);

    if (result && result.data.showToast) {
      this.toastCtrl.create({
        mode: 'md', message: this.translate.instant('모달.제출 버튼을 눌러주세요.'), position: 'bottom', duration: 2000,
        buttons: [{ text: 'ok', role: 'cancel' }]
      }).then((toast) => {
        toast.present();
      });
    }
  }

  async save() {
    try {
      await this.util.showLoadingPopup(true);

      const res = await this.gApi.setRecord({
        table: 'TEXERCISE_TEST', record: {
          GYM_Seq: this.trainer.gSeq,
          USER_Seq: this.gymUser.user.uSeq,
          USER_Height: this.gymUser.user.uHeight,
          USER_Weight: this.gymUser.user.uWeight,
          TRAINER_Seq: this.trainer.tSeq,
          TRAINER_Name: this.trainer.tName,
          EXERCISE_TEST_Type: 'fmt2',
          EXERCISE_TEST_InDate: this.testInDate.format('yyyy-MM-dd HH:mm:ss'),
          EXERCISE_TEST_Title: '움직임능력검사',
          EXERCISE_TEST_Data: JSON.stringify(this.surveyItems).replace(/\\/g, '\\\\'),
          EXERCISE_TEST_Device: 'MOBILE'
        }
      });

      if (res && res.insertId) {
        this.deductFmesFreeCount();
        const record = (await this.gApi.getRecord({
          table: 'TEXERCISE_TEST',
          fields: ['EXERCISE_TEST_Seq', 'USER_Seq', 'TRAINER_Name', 'EXERCISE_TEST_Order', 'EXERCISE_TEST_Type', 'EXERCISE_TEST_Title',
            `DATE_FORMAT(EXERCISE_TEST_InDate, '%Y-%m-%dT%H:%i:%s') as EXERCISE_TEST_InDate`, 'EXERCISE_TEST_Device', 'EXERCISE_TEST_Data',
            'USER_Height', 'USER_Weight'],
          where: {
            EXERCISE_TEST_Seq: res.insertId
          },
        }))[0];


        await this.util.showLoadingPopup(false);
        await this.modalCtrl.dismiss(res);
        this.showResultModal(record);
      } else {
        await this.util.showLoadingPopup(false);
        const alert = await this.util.alertCreate({
          message: this.translate.instant('저장에 실패하였습니다.'),
          buttons: [{ text: 'ok', role: 'ok' }]
        });
        await alert.present();
      }

    } catch (e) {
      await this.util.showLoadingPopup(false);
      this.error(e);
    }
  }

  isDisabled(): boolean {
    let isAllAnswered = true;
    for (const row of this.surveyItems.answerList) {
      for (const val of row) {
        if (!val) {
          isAllAnswered = false;
          break;
        }
      }
    }
    return !isAllAnswered;
  }

  loadRecentRecord() {
    if (!this.records) { return; }

    this.surveyItems = this.records[this.records.length - 1].EXERCISE_TEST_Data;
    this.isFmtOptionfromRecord.forEach(options => {
      options.fill(true);
    })
  }

  /*
  * 모달 타이틀을 5초 안에 연달아 5번 터치하면 샘플 데이터가 채워지는 이스터 에그
  */
  tapModalTitle() {
    if (!this.tappingTimer) {
      this.tappingTimer = setTimeout(() => {
        this.numberOfTappings = 0;
      }, 5000);
    } else {
      this.tappingTimer = undefined;
    }

    this.numberOfTappings++;
    if (this.numberOfTappings >= 5) {
      this.surveyItems = {
        fmtMemoList: [],
        answerList: [
          ['3', '3', '3', '3', '1', '1', '1', '1', '0', '1', '0'],
          ['0', '0', '0', '0', '0', '0', '0', '0'],
          ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
          ['0', '0', '0', '0', '0', '0', '0'],
          ['3', '3', '1', '1', '1', '1', '0', '1'],
          ['3', '3', '1', '1', '0', '0'],
          ['3', '0', '0', '0', '1'],
          ['0', '0', '0', '0', '0', '0']
        ]
      };
    }
  }

  async showResultModal(record: any) {
    const componentProps: any = {
      record: record
    }

    if (this.gymUser) {
      componentProps.isFixedData = true;
      componentProps.isTrainer = true;
    }

    const modal = await this.modalCtrl.create({
      component: MobileFmtResultModal,
      componentProps: componentProps
    });
    await modal.present();
  }

  async close() {
    await this.modalCtrl.dismiss();
  }

  async checkFmesFreeCount() {
    const gSeq = await (await this.gApi.auth.getMyInfo()).getGymSeq();

    const result = (await this.gApi.getRecord({
      table: 'TGYM',
      fields: ['GYM_Fmes_Free_Count', 'GYM_Origin'],
      where: {
        GYM_Seq: gSeq,
      },
    }))[0];

    if (result.GYM_Origin == 'FMES') {
      this.fmesGymSeq = gSeq;
      this.fmesFreeCount = result.GYM_Fmes_Free_Count;
      if (this.fmesFreeCount && this.fmesFreeCount !== 0) {
        const alert = await this.util.alertCreate({
          message: `최초 가입 혜택, 무료 검사 5회 가능\n\n <b>${this.fmesFreeCount}회 남음</b>`, buttons: [{ text: '확인', role: 'ok', }]
        });
        await alert.present();
        await alert.onDidDismiss();
      }
    }
  }

  async deductFmesFreeCount() {
    if (this.fmesGymSeq) {
      if (this.fmesFreeCount !== 0) {
        const updateResult = await this.gApi.setRecord({
          table: 'FITT_DB.TGYM',
          record: { GYM_Fmes_Free_Count: this.fmesFreeCount - 1 },
          where: { GYM_Seq: this.fmesGymSeq }
        });
        this.log("updateResult", updateResult);
      }
    }
  }
}


