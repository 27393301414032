import { Component, OnInit } from '@angular/core';
import { FtLibBaseComponent } from '@fitt-lib/fitt-lib-base.component';
import { ModalController, NavParams } from '@ionic/angular';
import { GymUser } from '@fitt-lib/models/fitt-models';

@Component({
  selector: 'edit-user-memo',
  templateUrl: './edit-user-memo.html',
  styleUrls: ['./edit-user-memo.scss']
})
export class EditUserMemoModal extends FtLibBaseComponent implements OnInit {
  uMemo: string;
  gymUser: GymUser;

  memoPlaceholder: string = this.translate.instant('모달.메모를 입력하세요');

  constructor(
    private modalCtrl: ModalController,
    private navParams: NavParams,
  ) {
    super();
  }

  ngOnInit() {
    this.gymUser = this.navParams.get('gymUser');
    this.uMemo = this.gymUser.user.uMemo;
    this.log('ngOnInit');
  }

  async close(uMemo?: string) {
    this.log('close');
    await this.modalCtrl.dismiss(uMemo);
  }

  isDisabled(): boolean {
    return this.uMemo === this.gymUser.user.uMemo;
  }

  async save() {
    await this.util.showLoadingPopup(true);
    await this.gApi.setRecord({
      table: 'TUSER',
      record: {
        USER_Memo: this.uMemo
      },
      where: {
        USER_Seq: this.gymUser.user.uSeq,
      },
    });
    await this.util.showLoadingPopup(false);
    this.close(this.uMemo);
  }
}
