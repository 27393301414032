<ion-row [ngStyle]="{'border-bottom': borderBottom}">
  <ion-col size="{{leftSize}}" [ngStyle]="{'z-index': contentZIndex}">
    <ion-item class="left" lines="none">
      <ng-content select="[left]"></ng-content>
    </ion-item>
  </ion-col>
  <ion-col size="{{12-leftSize}}" [ngStyle]="{'z-index': contentZIndex}">
    <ion-item class="right" lines="none">
      <ng-content select="[right]"></ng-content>
    </ion-item>
  </ion-col>
</ion-row>